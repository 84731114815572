import React from "react";
import "./TargetsSlide.scss";
import Input from "../../../-common/Input";
import Datepicker from "../../../-common/Datepicker";
import { isOnlyNumbersValidator } from "../../../../utils";
import { FiCalendar, FiDollarSign } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import RadioGroup from "../../../-common/RadioGroup";
import CurrencyInput from "../../../-common/CurrencyInput";

const TargetsSlide = ({
  pointMultiplier,
  setPointMultiplier,
  amount,
  setAmount,
  endDate,
  setEndDate,
  quid,
  setQuid
}) => {
  const quidOptions = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" }
  ];

  const selectedQuid = (
    quidOptions?.filter(option => option?.value === quid)[0] || {}
  ).value;

  const pointMultiplierDisplay = pointMultiplier.endsWith(".")
    ? pointMultiplier.slice(0, -1)
    : pointMultiplier;
  const showPlural = +pointMultiplier === 1 ? "" : "s";

  const pointMultiplierText = `Every $1 donated = ${pointMultiplierDisplay} priority point${showPlural}`;

  return (
    <div className="slide targets-slide">
      <div className="headline">Tell us a little more</div>
      <div className="inputs">
        <div className="double-input-container">
          <CurrencyInput
            value={amount}
            onChange={setAmount}
            label="Donation Goal"
            icon={FiDollarSign}
          />
          <Datepicker
            value={endDate}
            onChange={setEndDate}
            label="End date"
            icon={FiCalendar}
          />
        </div>
        <div className="point-multiplier-container">
          <Input
            value={pointMultiplier}
            onChange={setPointMultiplier}
            label="How many priority points?"
            icon={AiOutlineClose}
            validator={isOnlyNumbersValidator}
            inputContainerStyles={{ width: "20%" }}
            maxLength={3}
          />
          <div className="point-multiplier-explanation">
            {pointMultiplierText}
          </div>
        </div>
        <div className="quid-container">
          <RadioGroup
            label="Quid pro quo for this campaign?"
            id="quid-type"
            options={quidOptions}
            selected={selectedQuid}
            onChange={setQuid}
          />
        </div>
      </div>
    </div>
  );
};

export default TargetsSlide;
