import React from 'react';

import './ErrorText.scss';

const ErrorText = ({ children }) => (
  <p className="error-text">
    {children}
  </p>
);

export default ErrorText;
