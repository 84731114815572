import React, { useState } from "react";
import { createPortal } from "react-dom";
import { observer } from "mobx-react";
import { FiX } from "react-icons/fi";
import usePortal from "../../../hooks/usePortal";
import FullScreenModal from "../../-common/FullScreenModal";
import TeamsStore from "../../../stores/TeamsStore";
import Button from "../../-common/Button";
import Input from "../../-common/Input";
import { phoneNumberFormatter } from "../../../utils";
import "./NewTeamMemberModal.scss";
import { toast } from "react-toastify";

const NewTeamMemberModal = observer(({ close, team }) => {
  const [members, setMembers] = useState([]);
  const { teamId } = team;

  const CheckNameIsFilled = members => {
    return members?.some(m => m.name);
  };

  const teamMemberShape = {
    name: "",
    phone: ""
  };

  const addTeamMember = () => {
    setMembers(members.concat(teamMemberShape));
  };

  const removeTeamMember = index => {
    setMembers(members.filter((_, i) => i !== index));
  };

  const updateMemberName = index => newName => {
    setMembers(
      members.map((member, i) => {
        if (i === index) return { ...member, name: newName };
        else return member;
      })
    );
  };

  const updateMemberPhone = index => newPhone => {
    setMembers(
      members.map((member, i) => {
        if (i === index) return { ...member, phone: newPhone };
        else return member;
      })
    );
  };

  const addEnabled = CheckNameIsFilled(members);
  const add = async () => {
    try {
      if (members?.length) {
        const listOfMembers = members
          .map(m => {
            if (m.name || m.phone) {
              return { ...m, teamId };
            }
            return {};
          })
          .filter(m => m?.name);
        await TeamsStore.addMember(listOfMembers);
      }
      close();
    } catch (e) {
      toast("Sorry. Team member could not be added");
      close();
    }
  };

  const teamMemberInputComponents = members?.map(({ name, phone }, i) => {
    return (
      <div className="team-member-container" key={i}>
        <div className="input-fields">
          <Input
            placeholder="Name"
            onChange={updateMemberName(i)}
            value={name}
          />
        </div>
        <div className="input-fields">
          <Input
            placeholder="Phone #"
            onChange={updateMemberPhone(i)}
            value={phone}
            formatter={phoneNumberFormatter}
            maxLength={16}
          />
        </div>
        <div className="delete-team-member" onClick={() => removeTeamMember(i)}>
          <FiX size={24} />
        </div>
      </div>
    );
  });

  return (
    <FullScreenModal isOpen={true} close={close}>
      <div className="team-member-modal">
        <div className="modal-contents">
          <div className="headline">Put me in coach.</div>
          <div className="add-member" onClick={addTeamMember}>
            + New team member
          </div>
          {teamMemberInputComponents}
        </div>
        <div className="bottom-buttons">
          <Button text="cancel" theme="light" onClick={close} />
          <Button disabled={!addEnabled} text="Add" onClick={add} />
        </div>
      </div>
    </FullScreenModal>
  );
});

const NewTeamMemberModalContainer = ({ isOpen, ...props }) => {
  const portalTarget = usePortal("team-member-modal-container");
  const contents = isOpen ? <NewTeamMemberModal {...props} /> : null;
  return createPortal(contents, portalTarget);
};

export default NewTeamMemberModalContainer;
