import React from "react";
import "./WhySlide.scss";

const WhySlide = () => {
  return (
    <div className="slide why-slide">
      <div className="headline">Why do we need this?</div>

      <div className="contents">
        <div className="body-text">
          Since we are processing payments in the name of an organization instead of an individual, banks require
          details about the organization itself. We will need a few things that you may not know if the top of your
          head, so before you start, here's what we will need:
        </div>

        <div className="body-text no-indent">
          <span className="section-name">Legal Name of the Organization</span> - Athletic development structures can be
          organized in several different ways. Use the full name of the school if the athletic department is a
          university entity. If development falls under a private organizations (like a corporation or LLC), please use
          the legal name of that entity.
        </div>

        <div className="body-text no-indent">
          <span className="section-name">Tax ID</span> - The tax ID associated with the entity is required to open a
          merchant account.
        </div>

        <div className="body-text no-indent">
          <span className="section-name">Customer Service Information</span> - Where would you like donors who have
          questions to contact you at? It is best practice to include a phone number the donor could call if they have a
          question about how a donation appears on their bank statement. We also require a customer support email just
          in case.
        </div>
      </div>
    </div>
  );
};

export default WhySlide;
