import React from "react";
import { FaArrowDown } from "react-icons/fa";
import commaNumber from "comma-number";
import "./LatestDonationsTable.scss";

const SOURCE_MAPPING = {
  // "Donation via public campaign page": "Public Campaign",
  // "Donation via mobile app": "Mobile App",
  // "Donation via admin entry": "Admin Entry"

  // These are per conversations with Dan
  "Donation via public campaign page": "Campaign Donor",
  "Donation via mobile app": "Membership Activity",
  "Donation via admin entry": "Membership Activity"
};

const LatestDonationsTable = ({ data }) => {
  const rows = data.map(({ totalDonationAmount: amount, source, metadata, user }, i) => {
    const name = user?.name || "(anonymous)";
    let amountLabel;
    if (amount >= 1000) {
      amountLabel = "$" + commaNumber(Number(amount || 0)?.toFixed(2));
    } else {
      amountLabel = "$" + commaNumber(Number(amount || 0)?.toFixed(2));
    }
    return (
      <React.Fragment key={user + "--" + i}>
        <div className="donation-row-item">{name}</div>
        <div className="donation-row-item">{SOURCE_MAPPING[source]}</div>
        <div className="donation-row-item">{amountLabel}</div>
      </React.Fragment>
    );
  });
  return (
    <div className="latest-donations">
      <div className="latest-donations-title">Latest Donations</div>
      <div className="table-header">
        <div className="header-item">Name</div>
        <div className="header-item">Source</div>
        <div className="header-item">
          Amount <FaArrowDown className="header-icon" />
        </div>
      </div>
      <div className="table-body-wrapper">
        <div className="table-body">{rows}</div>
      </div>
    </div>
  );
};

export default LatestDonationsTable;
