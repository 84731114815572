import React, { useState, useCallback } from "react";
import { observer } from "mobx-react";
import { FiX } from "react-icons/fi";
import Input from "../../../-common/Input";
import Label from "../../../-common/Label";
import EditRewardOptionChoicesModal from "./EditRewardOptionChoicesModal";
import ConfirmRemoveRewardOptionModal from "./ConfirmRemoveRewardOptionModal";
import "./EditRewardOptionCard.scss";

function EditRewardOptionCard({ rewardOption, update, sorting, duringDragSortOrder }) {
  const [editRewardOptionChoicesModalOpen, setEditRewardOptionChoicesModalOpen] = useState(false);
  const openEditRewardOptionChoicesModal = () => {
    setEditRewardOptionChoicesModalOpen(true);
  };
  const closeEditRewardOptionChoicesModal = () => {
    setEditRewardOptionChoicesModalOpen(false);
  };

  const [confirmRemoveRewardOptionModalOpen, setConfirmRemoveRewardOptionModalOpen] = useState(false);
  const openConfirmRemoveRewardOptionModal = () => {
    setConfirmRemoveRewardOptionModalOpen(true);
  };
  const closeConfirmRemoveRewardOptionModal = () => {
    setConfirmRemoveRewardOptionModalOpen(false);
  };

  const { rewardOptionId, title, values } = rewardOption || {};

  const updateTitle = useCallback(
    value => {
      update({ ...rewardOption, title: value });
    },
    [rewardOption, update]
  );

  const duringDragSortIndex = duringDragSortOrder.indexOf(rewardOptionId);
  const sortLabel = duringDragSortIndex > -1 ? duringDragSortIndex + 1 : null;

  const valueLines =
    values && values?.length
      ? values.slice(0, 3)?.map(v => (
          <div className="value-line" key={v}>
            <div>{v}</div>
          </div>
        ))
      : null;
  const moreValuesLabel = values.length > 3 ? <div className="more-label">... and {values.length - 3} more</div> : null;

  return (
    <div className={`edit-reward-option-card${sorting ? " sorting" : ""}`}>
      <div className="sorting-overlay">{sortLabel}</div>
      <div className="reward-option-slides-container">
        <div className="reward-option-slide">
          <div className="top-bar">
            <div />
            <div className="reward-edit-toggle" onClick={openEditRewardOptionChoicesModal}>
              Edit Choices
            </div>
          </div>
          <div className="title">
            <Input label="Option Title" placeholder="Title" value={title} onChange={updateTitle} />
          </div>
          <div className="choices-container">
            <Label value="Choices" />
            {valueLines}
            {moreValuesLabel}
          </div>
        </div>
      </div>
      <div className="delete-reward-option-button" onClick={openConfirmRemoveRewardOptionModal}>
        <FiX size={32} />
      </div>
      <EditRewardOptionChoicesModal
        isOpen={editRewardOptionChoicesModalOpen}
        rewardOption={rewardOption}
        close={closeEditRewardOptionChoicesModal}
      />
      <ConfirmRemoveRewardOptionModal
        isOpen={confirmRemoveRewardOptionModalOpen}
        rewardOptionId={rewardOptionId}
        close={closeConfirmRemoveRewardOptionModal}
      />
    </div>
  );
}

export default observer(EditRewardOptionCard);
