import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Router, Redirect } from "@reach/router";
import { FaFilter, FaSortAmountDown } from "react-icons/fa";
import { GoPlus } from "react-icons/go";
import BulletinsStore, { sortBulletinsOptions, filterBulletinsOptions } from "../../../stores/BulletinsStore";
import GlobalSearchStore from "../../../stores/GlobalSearchStore";
import Page from "../../-common/Page";
import DropdownButton from "../../-common/DropdownButton";
import Button from "../../-common/Button";
import BulletinOverview from "../BulletinOverview";
import BulletinContent from "../BulletinContent";
import BulletinSchedule from "../BulletinSchedule";
import BulletinsTable from "./BulletinsTable";
import NewBulletinModal from "./NewBulletinModal";
import "./Bulletins.scss";

const Bulletins = observer(() => {
  useEffect(() => {
    GlobalSearchStore.setSearchAction(searchText => {
      BulletinsStore.setBulletinSearch(searchText);
    });
  }, []);

  const [newBulletinModalOpen, setNewBulletinModalOpen] = useState(false);
  const openNewBulletinModal = () => setNewBulletinModalOpen(true);
  const closeNewBulletinModal = () => setNewBulletinModalOpen(false);

  const { bulletinFilter, bulletinSort } = BulletinsStore;

  const updateFilter = filter => BulletinsStore.setBulletinFilter(filter);
  const updateSort = sort => BulletinsStore.setBulletinSort(sort);

  const titleOptions = { title: "Bulletins" };

  return (
    <Page
      titleOpts={titleOptions}
      buttons={[
        <DropdownButton
          label="Filter"
          icon={FaFilter}
          theme="light"
          options={filterBulletinsOptions}
          style={{ height: 42 }}
          value={bulletinFilter}
          onChange={updateFilter}
          key="filter"
        />,
        <DropdownButton
          label="Sort"
          icon={FaSortAmountDown}
          theme="light"
          options={sortBulletinsOptions}
          style={{ height: 42 }}
          value={bulletinSort}
          onChange={updateSort}
          key="sort"
        />,
        <Button text="Add Bulletin" icon={GoPlus} theme="dark" key="new-bulletin" onClick={openNewBulletinModal} />
      ]}
    >
      <div className="bulletins">
        <BulletinsTable />
      </div>
      <NewBulletinModal isOpen={newBulletinModalOpen} close={closeNewBulletinModal} />
    </Page>
  );
});

export default function BulletinsRouter() {
  return (
    <Router>
      <Bulletins path="/" />
      <BulletinOverview path=":bulletinId/overview" />
      <BulletinContent path=":bulletinId/content" />
      <BulletinSchedule path=":bulletinId/schedule" />
      <Redirect from="*" to="/club/bulletins" noThrow />
    </Router>
  );
}
