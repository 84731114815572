import React from "react";

export default function baseball({
  size = 24,
  fill = "none",
  stroke = "#000",
  strokeWidth = 13
}) {
  return (
    <svg
      viewBox="0 0 255.222 255.222"
      height={size}
      width={size}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    >
      <circle cx="127.611" cy="127.611" r="121.09" />
      <path d="M43.759,40.247 c28.304,27.2,42.857,67.65,35.257,109.169c-4.769,26.019-17.517,48.562-35.268,65.555" />
      <line x1="51.281" y1="69.286" x2="70.224" y2="50.9" />
      <line x1="60.554" y1="92.059" x2="84.798" y2="81.613" />
      <line x1="67.34" y1="115.65" x2="93.612" y2="113.07" />
      <line x1="51.281" y1="186.743" x2="70.224" y2="205.13" />
      <line x1="60.554" y1="163.97" x2="84.798" y2="174.416" />
      <line x1="67.34" y1="140.379" x2="93.612" y2="142.96" />
      <path d="M210.615,214.971 c-17.751-16.993-30.499-39.536-35.268-65.555c-7.6-41.519,6.953-81.969,35.257-109.169" />
      <line x1="203.083" y1="69.286" x2="184.139" y2="50.9" />
      <line x1="193.81" y1="92.059" x2="169.566" y2="81.613" />
      <line x1="187.024" y1="115.65" x2="160.751" y2="113.07" />
      <line x1="203.083" y1="186.743" x2="184.139" y2="205.13" />
      <line x1="193.81" y1="163.97" x2="169.566" y2="174.416" />
      <line x1="187.024" y1="140.379" x2="160.751" y2="142.96" />
    </svg>
  );
}
