import React from "react";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import "./StandaloneCheckbox.scss";

const StandaloneCheckbox = ({ isChecked }) => {
  const icon = isChecked ? (
    <MdCheckBox size={24} color="#9e9e9e" />
  ) : (
    <MdCheckBoxOutlineBlank size={24} color="#9e9e9e" />
  );
  return <div className="checkbox-container">{icon}</div>;
};

export default StandaloneCheckbox;
