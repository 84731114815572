import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { toast } from 'react-toastify';
import {
  MdCheck,
  MdClose,
} from 'react-icons/md';
import { FiCalendar } from 'react-icons/fi';

import { getFileURLFromFile } from '../../../utils';
import TenantStore from '../../../stores/TenantStore';
import Page from '../../-common/Page';
import Button from '../../-common/Button';
import FileUpload from '../../-common/FileUpload';
import Datepicker from '../../-common/Datepicker';
import ColorPickerButton from '../../-common/ColorPickerButton';
import Input from '../../-common/Input';
import Textarea from "../../-common/Textarea";
import { isUrl } from '../../../utils';

const titleOptions = { title: 'Ticket Renewal Popup' };

function TicketRenewalPopup() {
  const { appConfig } = TenantStore?.tenant || {};

  const [saving, setSaving] = useState(false);
  const [localMedia, setLocalMedia] = useState({});
  const [localBackgroundColor, setLocalBackgroundColor] = useState('');
  const [localStartDate, setLocalStartDate] = useState('');
  const [localEndDate, setLocalEndDate] = useState('');
  const [localRedirectUrl, setLocalRedirectUrl] = useState('');
  const [localInitialScreenHeadline, setLocalInitialScreenHeadline] = useState('');
  const [localInitialScreenFirstSubline, setLocalInitialScreenFirstSubline] = useState('');
  const [localInitialScreenSecondSubline, setLocalInitialScreenSecondSubline] = useState('');
  const [localPreRedirectScreenHeadline, setLocalPreRedirectScreenHeadline] = useState('');
  const [localPreRedirectScreenDescription, setLocalPreRedirectScreenDescription] = useState('');
  const [localReminderScreenHeadline, setLocalReminderScreenHeadline] = useState('');
  const [localReminderScreenDescription, setLocalReminderScreenDescription] = useState('');

  const setMedia = async file => {
    const preview = await getFileURLFromFile(file);

    setLocalMedia({ file, preview });
  };

  const clearLocalConfigData = () => {
    setLocalBackgroundColor('');
    setLocalStartDate('');
    setLocalEndDate('');
    setLocalRedirectUrl('');
    setLocalInitialScreenHeadline('');
    setLocalInitialScreenFirstSubline('');
    setLocalInitialScreenSecondSubline('');
    setLocalPreRedirectScreenHeadline('');
    setLocalPreRedirectScreenDescription('');
    setLocalReminderScreenHeadline('');
    setLocalReminderScreenDescription('');
  }

  const cancel = () => {
    setLocalMedia({});
    clearLocalConfigData();
  };

  const media = localMedia?.preview || appConfig?.ticketRenewalPopup?.media;
  const backgroundColor = localBackgroundColor || appConfig?.ticketRenewalPopup?.backgroundColor;
  const startDate = localStartDate || appConfig?.ticketRenewalPopup?.startDate;
  const endDate = localEndDate || appConfig?.ticketRenewalPopup?.endDate;
  const redirectUrl = localRedirectUrl || appConfig?.ticketRenewalPopup?.redirectUrl;
  const initialScreenHeadline = localInitialScreenHeadline || appConfig?.ticketRenewalPopup?.initialScreenHeadline;
  const initialScreenFirstSubline =
    localInitialScreenFirstSubline || appConfig?.ticketRenewalPopup?.initialScreenFirstSubline;
  const initialScreenSecondSubline =
    localInitialScreenSecondSubline || appConfig?.ticketRenewalPopup?.initialScreenSecondSubline;
  const preRedirectScreenHeadline =
    localPreRedirectScreenHeadline || appConfig?.ticketRenewalPopup?.preRedirectScreenHeadline;
  const preRedirectScreenDescription =
    localPreRedirectScreenDescription || appConfig?.ticketRenewalPopup?.preRedirectScreenDescription;
  const reminderScreenHeadline = localReminderScreenHeadline || appConfig?.ticketRenewalPopup?.reminderScreenHeadline;
  const reminderScreenDescription =
    localReminderScreenDescription || appConfig?.ticketRenewalPopup?.reminderScreenDescription;

  const saveEnabled =
    localMedia?.file ||
    localBackgroundColor ||
    localStartDate ||
    localEndDate ||
    localInitialScreenHeadline ||
    localInitialScreenFirstSubline ||
    localInitialScreenSecondSubline ||
    localPreRedirectScreenHeadline ||
    localPreRedirectScreenDescription ||
    localReminderScreenHeadline ||
    localReminderScreenDescription ||
    isUrl(localRedirectUrl);

  const save = async () => {
    setSaving(true);

    const ticketRenewalUpdateBody = {
      ...(localBackgroundColor ? { backgroundColor } : {}),
      ...(localStartDate ? { startDate } : {}),
      ...(localEndDate ? { endDate } : {}),
      ...(localRedirectUrl ? { redirectUrl } : {}),
      ...(localInitialScreenHeadline ? { initialScreenHeadline } : {}),
      ...(localInitialScreenFirstSubline ? { initialScreenFirstSubline } : {}),
      ...(localInitialScreenSecondSubline ? { initialScreenSecondSubline } : {}),
      ...(localPreRedirectScreenHeadline ? { preRedirectScreenHeadline } : {}),
      ...(localPreRedirectScreenDescription ? { preRedirectScreenDescription } : {}),
      ...(localReminderScreenHeadline ? { reminderScreenHeadline } : {}),
      ...(localReminderScreenDescription ? { reminderScreenDescription } : {}),
    };

    if (Object.keys(ticketRenewalUpdateBody).length) {
      await TenantStore.updateTenant({
        appConfig: { ticketRenewalPopup: ticketRenewalUpdateBody },
      })
        .then(() => {
          toast('Ticket Renewal Popup parameters updated.');
          clearLocalConfigData();
        })
        .catch(() => {
          toast.error('Error updating Ticket Renewal Popup parameters.');
        });
    }

    if (localMedia?.file) {
      await TenantStore.uploadImages({ ticketRenewalPopup: localMedia.file })
        .then(() => {
          toast('Ticket Renewal Popup image updated.');
          setLocalMedia({});
        })
        .catch(() => {
          toast.error('Error updating Ticket Renewal Popup image.');
        });
    }

    setSaving(false);
  };

  const conditionalButtons = saveEnabled
    ? [
        <Button
          text="Cancel"
          theme="light"
          icon={MdClose}
          onClick={cancel}
          disabled={saving}
          key="cancel"
        />,
        <Button
          text={saving ? 'Saving...' : 'Save'}
          theme={saving ? 'light' : null}
          icon={MdCheck}
          onClick={save}
          disabled={saving}
          key="save"
        />,
      ]
    : null;

  const labelStyles = {
    color: 'var(--Gray900)',
    fontWeight: 'initial',
  };

  const inputContainerStyles = {
    marginBottom: '18px',
  };

  return (
    <Page
      titleOpts={titleOptions}
      buttons={conditionalButtons}
    >
      <div className="club-hub-container">
        <div className="theme-item">
          <div className="theme-item-titles">
            <div className="theme-item-title">Popup Background Image</div>
            <div className="theme-item-subtitle">This will cover most of the popup.</div>
          </div>
          <div className="theme-item-content image">
            <FileUpload
              accept="image/png, image/jpeg"
              fileURL={media}
              onUpload={setMedia}
            />
          </div>
        </div>
        <div className="theme-item">
          <div className="theme-item-titles">
            <div className="theme-item-title">Background Color</div>
            <div className="theme-item-subtitle">
              This will color the overflow in the popup that the image doesn't fit into. It's best to use a plain
              background in your popup image that will match this color.
            </div>
          </div>
          <div className="theme-item-content">
            <ColorPickerButton
              color={backgroundColor || ''}
              onSelect={setLocalBackgroundColor}
            />
          </div>
        </div>
        <div className="theme-item">
          <div className="theme-item-titles">
            <div className="theme-item-title">Initial Screen Copy</div>
            <div className="theme-item-subtitle">
              Text that will appear on the first screen of the renewal flow. Please be aware of the character limits for
              each input.
            </div>
          </div>
          <div className="theme-item-content theme-item-content--extended">
            <Input
              value={initialScreenHeadline}
              onChange={setLocalInitialScreenHeadline}
              placeholder="20 Character Maximum"
              label="Headline"
              maxLength={20}
              labelStyles={labelStyles}
              inputContainerStyles={inputContainerStyles}
              countCharacters
            />
            <Input
              value={initialScreenFirstSubline}
              onChange={setLocalInitialScreenFirstSubline}
              placeholder="16 Character Maximum"
              label="Sub Headlines"
              maxLength={16}
              labelStyles={labelStyles}
              inputContainerStyles={inputContainerStyles}
              countCharacters
            />
            <Input
              value={initialScreenSecondSubline}
              onChange={setLocalInitialScreenSecondSubline}
              placeholder="16 Character Maximum"
              maxLength={16}
              labelStyles={labelStyles}
              countCharacters
            />
          </div>
        </div>
        <div className="theme-item">
          <div className="theme-item-titles">
            <div className="theme-item-title">Pre Redirect Screen Copy</div>
            <div className="theme-item-subtitle">
              Text that will appear on the screen before the user is redirected to ticketing URL. Please be aware of the
              character limits for each input.
            </div>
          </div>
          <div className="theme-item-content theme-item-content--extended">
            <Input
              value={preRedirectScreenHeadline}
              onChange={setLocalPreRedirectScreenHeadline}
              placeholder="20 Character Maximum"
              label="Headline"
              maxLength={20}
              labelStyles={labelStyles}
              inputContainerStyles={inputContainerStyles}
              countCharacters
            />
            <Textarea
              value={preRedirectScreenDescription}
              onChange={setLocalPreRedirectScreenDescription}
              placeholder="128 Character Maximum"
              label="Description"
              maxLength={128}
              labelStyles={labelStyles}
              countCharacters
            />
          </div>
        </div>
        <div className="theme-item">
          <div className="theme-item-titles">
            <div className="theme-item-title">Reminder Screen Copy</div>
            <div className="theme-item-subtitle">
              Text that will appear on the screen after the user select the option to be reminded later. Please be aware
              of the character limits for each input.
            </div>
          </div>
          <div className="theme-item-content theme-item-content--extended">
            <Input
              value={reminderScreenHeadline}
              onChange={setLocalReminderScreenHeadline}
              placeholder="20 Character Maximum"
              label="Headline"
              maxLength={20}
              labelStyles={labelStyles}
              inputContainerStyles={inputContainerStyles}
              countCharacters
            />
            <Textarea
              value={reminderScreenDescription}
              onChange={setLocalReminderScreenDescription}
              placeholder="128 Character Maximum"
              label="Description"
              maxLength={128}
              labelStyles={labelStyles}
              countCharacters
            />
          </div>
        </div>
        <div className="theme-item">
          <div className="theme-item-titles">
            <div className="theme-item-title">Pop-Up Date Range</div>
            <div className="theme-item-subtitle">
              This indicates the date when the renewal popup should be displayed to the users of the mobile app.
            </div>
          </div>
          <div className="theme-item-content theme-item-content--extended">
            <div className="theme-datepicker">
              <Datepicker
                value={startDate}
                onChange={setLocalStartDate}
                label="Start date"
                icon={FiCalendar}
                labelStyles={labelStyles}
              />
            </div>
            <div className="theme-datepicker">
              <Datepicker
                value={endDate}
                onChange={setLocalEndDate}
                label="End date"
                icon={FiCalendar}
                labelStyles={labelStyles}
              />
            </div>
          </div>
        </div>
        <div className="theme-item">
          <div className="theme-item-titles">
            <div className="theme-item-title">Redirect URL</div>
            <div className="theme-item-subtitle">
              URL address where donor will be redirected after clicking the renewal button.
            </div>
          </div>
          <div className="theme-item-content theme-item-content--extended">
            <Input
              value={redirectUrl}
              onChange={setLocalRedirectUrl}
              placeholder="https://example.com" 
            />
          </div>
        </div>
      </div>
    </Page>
  );
}

export default observer(TicketRenewalPopup);
