import React, { useState } from "react";
import { createPortal } from "react-dom";
import { observer } from "mobx-react";
import { FiType, FiHash } from "react-icons/fi";
import { isOnlyNumbersValidator } from "../../../utils";
import usePortal from "../../../hooks/usePortal";
import AchievementsStore, { categories } from "../../../stores/AchievementsStore";
import FullScreenModal from "../../-common/FullScreenModal";
import Input from "../../-common/Input";
import Textarea from "../../-common/Textarea";
import Select from "../../-common/Select2";
import Button from "../../-common/Button";
import "./AddAchievementModal.scss";

const AddAchievementModal = observer(({ close }) => {
  const [achievement, setAchievement] = useState({
    title: "",
    description: ""
  });

  const updateTitle = newTitle => {
    setAchievement({ ...achievement, title: newTitle });
  };
  const updateDescription = newDescription => {
    setAchievement({ ...achievement, description: newDescription });
  };

  const updateCategory = ({ value: newCategory }) => {
    setAchievement({ ...achievement, category: newCategory });
  };

  const updateThreshold = newThreshold => {
    setAchievement({ ...achievement, threshold: newThreshold });
  };

  const saveEnabled =
    !!achievement.title && !!achievement.description && !!achievement.threshold && Number(achievement.threshold) > -1;

  const save = async () => {
    if (saveEnabled) {
      achievement.threshold = Number(achievement.threshold);
      await AchievementsStore.addAchievement(achievement);
      close();
    }
  };

  const categorySelectValue = {
    label: achievement.category,
    value: achievement.category
  };

  return (
    <FullScreenModal isOpen={true} close={close}>
      <div className="add-achievement-modal">
        <div className="modal-contents">
          <div className="headline">Add achievement</div>
          <Input
            label="Title"
            value={achievement.title}
            placeholder="Option Title"
            onChange={updateTitle}
            maxLength={50}
            icon={FiType}
          />
          <Textarea
            label="Description"
            value={achievement.description}
            placeholder="Description"
            onChange={updateDescription}
            maxLength={200}
            icon={FiType}
          />
          <Select label="Category" value={categorySelectValue} options={categories} onChange={updateCategory} />
          <Input
            label="Threshold"
            value={achievement.threshold}
            placeholder="Threshold"
            onChange={updateThreshold}
            validator={isOnlyNumbersValidator}
            maxLength={8}
            icon={FiHash}
          />
        </div>
        <div className="bottom-buttons">
          <Button text="cancel" theme="light" onClick={close} />
          <Button disabled={!saveEnabled} text="save" onClick={save} />
        </div>
      </div>
    </FullScreenModal>
  );
});

const AddAchievementModalContainer = ({ isOpen, ...props }) => {
  const portalTarget = usePortal("add-achievement-modal-container");
  const contents = isOpen ? <AddAchievementModal {...props} /> : null;
  return createPortal(contents, portalTarget);
};

export default AddAchievementModalContainer;
