import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { navigate, Link } from "@reach/router";
import { Table } from "ka-table";
import { DataType } from "ka-table/enums";
import BulletinsStore from "../../../../stores/BulletinsStore";
import CampaignsStore from "../../../../stores/CampaignsStore";
import "./BulletinsTable.scss";

const CTACell = observer(({ rowData }) => {
  let { targetType, targetId } = rowData || {};

  let target;
  if (targetType === "campaign") target = CampaignsStore?.campaigns?.find(c => c?.campaignId === targetId);

  const link =
    targetType === "campaign" && target ? (
      <Link className="campaign-link" to={`/campaigns/manage/${targetId}/general`} state={{ useBack: true }}>
        Campaign: {target?.title}
      </Link>
    ) : null;

  return <div className="cta-cell">{link}</div>;
});

const columns = [
  {
    dataType: DataType.String,
    key: "subject",
    title: "Subject"
  },
  {
    dataType: DataType.String,
    key: "message",
    title: "Message"
  },
  {
    cell: props => <CTACell {...props} />,
    dataType: DataType.string,
    key: "targetId",
    title: "Call to Action Target"
  },
  // {
  //   dataType: DataType.Object,
  //   format: d => String(d?.length || 0),
  //   key: "schedules",
  //   title: "Publish Steps"
  // },
  {
    dataType: DataType.String,
    key: "status",
    title: "Status"
  }
];

const childAttributes = {
  cell: {
    onClick: (_, e) => {
      const { dispatch, rowData } = e.childProps;
      dispatch("Row Click", rowData);
    }
  }
};

function BulletinsTable() {
  const onTableEvent = useCallback((type, data) => {
    if (type === "Row Click") {
      const rowData = data;
      const { bulletinId, bulletin } = rowData;
      navigate(`/club/bulletins/${bulletinId || bulletin?.bulletinId}/overview`, { state: { useBack: true } });
    }
  }, []);

  return (
    <div className="bulletins-table-container">
      <Table
        childAttributes={childAttributes}
        columns={columns}
        data={toJS(BulletinsStore.bulletins)}
        onEvent={onTableEvent}
        rowKeyField="bulletinId"
      />
    </div>
  );
}

export default observer(BulletinsTable);
