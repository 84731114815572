const nextEnabled = state => {
  const { title, description, category, initialQuantity, activeSlide } = state;
  if (activeSlide === 0) return title && description;
  if (activeSlide === 1) return category && initialQuantity;
  if (activeSlide === 2) return true;
  return false;
};

export const rewardInitialState = {
  title: "",
  description: "",
  category: "",
  file: null,
  fileURL: null,
  unitPrice: 0,
  initialQuantity: "",
  rewardOptions: [],
  activeSlide: 0,
  nextEnabled: false
};

export const rewardReducer = (state, action) => {
  const { type, payload } = action;
  const withSimpleUpdates = { ...state, ...payload };
  const { file, fileURL } = withSimpleUpdates;
  let result = state;
  switch (type) {
    case "setActiveSlide":
    case "setTitle":
    case "setDescription":
    case "setCategory":
    case "setUnitPrice":
    case "setInitialQuantity":
    case "setRewardOptions":
      result = {
        ...withSimpleUpdates,
        nextEnabled: nextEnabled(withSimpleUpdates)
      };
      break;
    case "setFile":
      result = { ...state, file };
      break;
    case "setFileURL":
      result = { ...state, fileURL };
      break;
    case "resetState":
      result = { ...state, ...rewardInitialState };
      break;
    default:
      break;
  }
  return result;
};
