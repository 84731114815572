import React from "react";

const PlusMinusIcon = ({
  size = 24,
  color = "currentColor",
  strokeWidth = "0"
}) => (
  <svg
    style={{ height: size, width: (size * 55) / 24, stroke: color }}
    fill={color}
    strokeWidth={strokeWidth}
    strokeLinecap="round"
    viewBox="0 0 55 24"
  >
    <g>
      <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
    </g>
    <path d="M25,24 h2 L30,0 h-2z" />
    <g transform="translate(32, 0)">
      <path d="M5,13 v-2 h14 v2 z" />
    </g>
  </svg>
);

export default PlusMinusIcon;
