import { observable, computed, action, autorun, toJS } from "mobx";
import { toast } from "react-toastify";
import { request } from "../utils";
import AuthStore from "./AuthStore";

const searchPerks = searchText => a => {
  const search = searchText.toLowerCase();
  const matchesTitle = a.title.toLowerCase().includes(search);
  return matchesTitle;
};

class PerksStore {
  constructor() {
    autorun(() => {
      if (AuthStore.APIReady) {
        this.fetchPerks();
      } else {
        this.clear();
      }
    });
  }

  // CRUD
  @observable rawPerks = [];

  @computed get perks() {
    return this.rawPerks.filter(searchPerks(this.search));
  }

  @action async fetchPerks() {
    try {
      const perks = await request.get("/v1/perks");
      this.rawPerks = perks.sort((a, b) => a.sortIndex - b.sortIndex);
      return perks;
    } catch (err) {
      console.warn(err);
    }
  }

  @action async addPerk(perkObject, showToasts) {
    try {
      this.rawPerks = this.rawPerks.concat({
        ...perkObject,
        perkId: "optimistic"
      });

      const newPerk = await request.post("/v1/perks", {
        body: perkObject
      });
      const { perkId } = newPerk;

      this.rawPerks = this.rawPerks.map(m => {
        if (m.perkId === perkId) return newPerk;
        if (m.perkId === "optimistic") return newPerk;
        return m;
      });
      if (showToasts) toast("Perk added to level.");
      return newPerk;
    } catch (err) {
      this.rawPerks = this.rawPerks.filter(m => m.perkId !== "optimistic");
      if (showToasts) toast("Error adding perk to level.");
      console.warn(err);
    }
  }

  @action async updatePerk(perkObject) {
    try {
      const { perkId } = perkObject;
      const updatedPerk = await request.put(`/v1/perks/${perkId}`, {
        body: perkObject
      });
      this.rawPerks = this.rawPerks.map(m => {
        if (m.perkId === perkId) return updatedPerk;
        return m;
      });
      return updatedPerk;
    } catch (err) {
      console.warn(err);
    }
  }

  @action async deletePerk(perkId) {
    try {
      await request.delete(`/v1/perks/${perkId}`);
      this.rawPerks = this.rawPerks.filter(m => m.perkId !== perkId);
    } catch (err) {
      console.warn(err);
    }
  }

  @action async sortPerks(sortUpdatedPerks, showToasts) {
    const prePerks = toJS(this.rawPerks, { recurseEverything: true });
    try {
      const perkSorts = sortUpdatedPerks.map(t => ({
        perkId: t.perkId,
        sortIndex: t.sortIndex
      }));
      this.rawPerks = this.rawPerks.sort((a, b) => {
        const aSort =
          sortUpdatedPerks.find(p => p.perkId === a.perkId)?.sortIndex != null
            ? sortUpdatedPerks.find(p => p.perkId === a.perkId)?.sortIndex
            : Infinity;
        const bSort =
          sortUpdatedPerks.find(p => p.perkId === b.perkId)?.sortIndex != null
            ? sortUpdatedPerks.find(p => p.perkId === b.perkId)?.sortIndex
            : Infinity;
        return aSort - bSort;
      });
      const updatedPerks = await request.post(`/v1/perks/sort`, {
        body: { perkSorts }
      });
      this.rawPerks = updatedPerks.sort((a, b) => a.sortIndex - b.sortIndex);
      if (showToasts) toast("Perk order updated!", { autoClose: 3000 });
    } catch (err) {
      this.rawPerks = prePerks;
      if (showToasts) toast("Error updating perk order.");
      console.warn(err);
    }
  }

  // SEARCH
  @observable search = "";

  @action setSearch = search => (this.search = search);

  // CLEANUP
  @action clear() {
    this.rawPerks = [];
  }
}

export default new PerksStore();
