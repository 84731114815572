import React from "react";
import Input from "../../../../-common/Input";
import "./AccountSlide.scss";

const AccountSlide = ({ state, dispatch }) => {
  const { holderName, accountNumber, routingNumber } = state;

  const setHolderName = v => dispatch({ type: "setHolderName", payload: { holderName: v } });
  const setAccountNumber = v =>
    dispatch({ type: "setAccountNumber", payload: { accountNumber: v.replace(/[^\d]/g, "").slice(0, 17) } });
  const setRoutingNumber = v =>
    dispatch({ type: "setRoutingNumber", payload: { routingNumber: v.replace(/[^\d]/g, "").slice(0, 9) } });

  return (
    <div className="slide personal-slide">
      <div className="headline">Bank Account Specifics</div>
      <div className="inputs">
        <Input value={holderName} onChange={setHolderName} label="Account Holder Name" placeholder="Jane Doe" />
        <Input value={accountNumber} onChange={setAccountNumber} label="Account Number" placeholder="XXXXXXXXXXXX" />
        <Input value={routingNumber} onChange={setRoutingNumber} label="Routing Number" placeholder="XXXXXXXXX" />
      </div>
    </div>
  );
};

export default AccountSlide;
