import React, { useCallback } from "react";
import Label from "../Label";
import CharacterCounter from "../CharacterCounter";
import "./Textarea.scss";

const Textarea = ({
  theme = "normal",
  value, //required
  onChange, //required
  countCharacters = true,
  label,
  maxLength = 1400,
  placeholder = "",
  validator = false,
  height = 160,
  labelStyles,
  styles
}) => {
  const valueLength = (value || {}).length || 0;
  const labelComponent = label ? (
    <Label
      style={labelStyles}
      value={label}
    />
  ) : null;
  const characterCounterComponent = countCharacters ? (
    <div className="character-counter-container">
      <CharacterCounter valueLength={valueLength} maxLength={maxLength} />
    </div>
  ) : null;

  const getNewValue = useCallback(
    ({ target: { value: newValue } = {} } = {}) => {
      let passedLengthTest = true;
      let passedValidationTest = true;

      if (countCharacters || maxLength) {
        if (newValue.length <= maxLength) {
          passedLengthTest = true;
        } else {
          passedLengthTest = false;
        }
      }

      if (validator) {
        if (!!validator(newValue)) {
          passedValidationTest = true;
        } else {
          passedValidationTest = false;
        }
      }

      if (passedLengthTest && passedValidationTest) {
        onChange(newValue);
      } else {
        onChange(value);
      }
    },
    [onChange, validator, value, maxLength, countCharacters]
  );

  const textAreaStyles = {
    ...styles,
    height,
    ...(theme === "alt"
      ? {
          border: "none",
          outline: "none",
          borderRadius: 12,
          boxShadow: "0 2px 16px 0 rgba(0, 0, 0, 0.08)",
          backgroundColor: "var(--White)"
        }
      : {})
  };

  return (
    <div className="textarea-container">
      {labelComponent}
      <textarea
        style={textAreaStyles}
        value={value || ""}
        onChange={getNewValue || (() => null)}
        maxLength={maxLength}
        placeholder={placeholder}
      />
      {characterCounterComponent}
    </div>
  );
};
export default Textarea;
