import React from "react";

export default function golfHole({
  size = 24,
  fill = "none",
  stroke = "#000",
  strokeWidth = 13
}) {
  return (
    <svg
      viewBox="0 0 359 359"
      height={size}
      width={size}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <ellipse cx="209.528" cy="324.5" rx="72.333" ry="28" />
      <line x1="209.528" y1="349.904" x2="209.528" y2="6.5" />
      <polyline points="180.498,87.46 209.528,91.16 209.528,18.5 139.168,27.46 139.168,70.36" />
      <polygon points="180.495,128.49 77.138,92.161 180.495,55.833" />
      <line x1="141.528" y1="29.833" x2="179.528" y2="55.167" />
    </svg>
  );
}
