import React, {
  useState,
  useEffect,
} from 'react';
import { observer } from 'mobx-react';
import { useLocation } from '@reach/router';
import {
  FiUser,
  FiExternalLink,
  FiCreditCard,
} from 'react-icons/fi';
import {
  format,
  parseISO,
} from 'date-fns';
import commaNumber from 'comma-number';
import UsersStore from '../../../stores/UsersStore';
import ClubMembersStore from '../../../stores/ClubMembersStore';
import Page from '../../-common/Page';
import TabBar from '../../-common/TabBar';
import Button from '../../-common/Button';
import Select from '../../-common/Select2';
import Spinner from '../../-common/Spinner';
import Checkbox from '../../-common/StandaloneCheckbox';
import useClubMember from './useClubMember';
import Contributions from './Contributions';
import Pledges from './Pledges';
import Tickets from './Tickets';
import Messages from './Messages';
import Notes from './Notes';
import Benefits from './Benefits';
import AddPaymentModal from './AddPaymentModal';
import './ClubMember.scss';

const ClubMemberDetails = observer(
  ({
    userId,
    firstName,
    lastName,
    // fullName,
    nickname,
    media,
    config,
    // addresses,
    phoneNumbers,
    email,
    assignedDevelopmentOfficer,
    // YTDContributions,
    // lifetimeContributions,
    currentMembershipLevel,
    // nextMembershipLevel,
    // nextLevelAnnualContribution,
    membershipExpirationDate,
    // totalYearsOfMembership,
    currentRank,
    totalRank,
    totalPriorityPoints,
  }) => {
    useEffect(() => {
      if (userId) {
        ClubMembersStore.addToRecentlyViewed({ userId, profile: { firstName, lastName, media } });
      }
    }, [userId, firstName, lastName, media]);

    const inactiveTag =
      !config?.status === 'active' ? (
        <div className="inactive-tag">
          <div className="inactive-label">INACTIVE</div>
        </div>
      ) : null;

    const profileImage = media ? (
      <img alt="The club member" src={media} className="club-member-user-profile" />
    ) : (
      <div className="club-member-user-profile">
        <FiUser size={144} color="var(--White)" />
      </div>
    );

    const { userId: assignedDOId } = assignedDevelopmentOfficer || {};
    const [localDOId, setLocalDOId] = useState(assignedDOId);
    useEffect(() => {
      if (assignedDOId) setLocalDOId(assignedDOId);
    }, [assignedDOId]);
    const DOAssignmentOptions = UsersStore?.users?.map(u => ({
      label: `${u?.profile.firstName} ${u?.profile.lastName}`,
      value: u?.userId,
    }));
    const DOAssignment = DOAssignmentOptions?.find(o => o?.value === localDOId);

    const updateDO = async ({ value: departmentUserId }) => {
      setLocalDOId(departmentUserId);
      const success = await ClubMembersStore?.setAssignedDOForClubMember(userId, departmentUserId);
      if (!success) setLocalDOId(localDOId);
    };

    const { mobileReady } = config || {};
    const [localMobileReady, setLocalMobileReady] = useState(mobileReady);
    useEffect(() => {
      if (mobileReady != null) setLocalMobileReady(mobileReady);
    }, [mobileReady]);
    const toggleMobileReady = async () => {
      setLocalMobileReady(!localMobileReady);
      const success = await ClubMembersStore?.updateMobileReadyForClubMember(
        userId,
        !localMobileReady,
      );
      if (!success) setLocalMobileReady(localMobileReady);
    };

    if (!userId) {
      return (
        <div className="club-member-details loading">
          <Spinner color="var(--primary-color)" />
        </div>
      );
    }

    return (
      <div className="club-member-details">
        {inactiveTag}
        <div className="club-member-user">{profileImage}</div>
        <div className="club-member-name">{`${firstName} ${lastName}`}</div>
        <div className="club-member-info-row">
          <div className="info-section">
            <div className="title-line">{currentMembershipLevel}</div>
            {/* <div className="detail-line">
              {nextLevelAnnualContribution
                ? `$${nextLevelAnnualContribution - YTDContributions} from ${nextMembershipLevel}`
                : null}
            </div> */}
          </div>
          <div className="divider" />
          <div className="info-section">
            <div className="title-line">{`${commaNumber(totalPriorityPoints)} Points`}</div>
            <div className="detail-line">
              {currentRank && totalRank
                ? `Rank: ${commaNumber(currentRank)} of ${commaNumber(totalRank)}`
                : null}
            </div>
          </div>
        </div>
        {/* <div className="mobile-usage-row">
          <div className="icon-wrapper">
            <MdPhoneIphone size={18} color="var(--White)" />
          </div>
          <div className="details-wrapper">
            <div className="details-title">Mobile App Usage</div>
            <div className="details-text">Opened 1/23/20 @ 8:32pm</div>
          </div>
          <FiExternalLink size={20} color="var(--White)" />
        </div> */}
        <div className="info-row">
          <div className="info-title">Assigned Development Officer:</div>
          <Select value={DOAssignment} onChange={updateDO} options={DOAssignmentOptions} />
        </div>
        <div className="info-row single clickable" onClick={toggleMobileReady}>
          <div className="info-title">Mobile Enabled:</div>
          <div className="info-text">
            <div className="info-text-checkbox-wrapper">
              <Checkbox isChecked={localMobileReady} />
            </div>
          </div>
        </div>
        <div className="info-row single">
          <div className="info-title">Phone:</div>
          <div className="info-text">{phoneNumbers?.[0]?.phoneNumber}</div>
        </div>
        <div className="info-row single">
          <div className="info-title">Email:</div>
          <div className="info-text">{email}</div>
        </div>
        <div className="info-row single">
          <div className="info-title">Membership Expiration:</div>
          <div className="info-text">
            {membershipExpirationDate
              ? format(parseISO(membershipExpirationDate), 'MMM d, yyyy')
              : null}
          </div>
        </div>
      </div>
    );
  },
);

const tabs = [
  { id: 'Contributions', title: 'Contributions', component: Contributions },
  { id: 'Pledges', title: 'Pledges', component: Pledges },
  { id: 'Tickets', title: 'Tickets', component: Tickets },
  { id: 'Benefits', title: 'Benefits', component: Benefits },
  { id: 'Messages', title: 'Messages', component: Messages },
  { id: 'Notes', title: 'Notes', component: Notes },
];

function ClubMember({ userId }) {
  const { search } = useLocation();
  const clubMember = useClubMember(userId);
  const [selectedTab, setSelectedTab] = useState(0);
  const [addPaymentModalOpen, setAddPaymentModalOpen] = useState(false);

  const openAddPaymentModal = () => setAddPaymentModalOpen(true);
  const closeAddPaymentModal = () => setAddPaymentModalOpen(false);

  const [inviting, setInviting] = useState(false);
  const sendMobileInvite = async () => {
    setInviting(true);
    await ClubMembersStore.sendMobileInvite(userId);
    setInviting(false);
  };

  useEffect(() => {
    if (userId && search) {
      const paramStrings = search.replace('?', '')?.split('&');
      const params = Object.fromEntries(
        paramStrings?.map(ps => {
          let [key, val] = ps?.split('=');
          return [key, val == null ? true : JSON.parse(val)];
        }),
      );

      if (params.pledges) setSelectedTab(1);
      if (params.tickets) setSelectedTab(2);
      if (params.benefits) setSelectedTab(3);
      if (params.messages) setSelectedTab(4);
      if (params.notes) setSelectedTab(5);
    }
  }, [userId, search]);

  const DetailPage = tabs[selectedTab].component;
  const detailPageProps = { clubMember };

  const content = clubMember?.userId ? (
    <div className="club-member-detail">
      <ClubMemberDetails {...clubMember} />
      <div className="club-member-pages-wrapper">
        <div className="club-member-tab-bar-wrapper">
          <TabBar tabs={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        </div>
        <div className="tab-detail">
          <DetailPage {...detailPageProps} />
        </div>
      </div>
    </div>
  ) : (
    <div className="club-member-loading-container">
      <Spinner color="var(--primary-color)" />
    </div>
  );

  const inviteButtonBody = inviting ? <Spinner color="var(--White)" /> : 'Send Mobile Invite';

  return (
    <>
      <Page
        titleOpts={{
          title: 'Club Member',
          backButton: true,
        }}
        buttons={[
          <Button
            text="Process Payment"
            icon={FiCreditCard}
            onClick={openAddPaymentModal}
            key="payment"
          />,
          <Button
            icon={FiExternalLink}
            text={inviteButtonBody}
            onClick={sendMobileInvite}
            key="refund"
            disabled={
              !clubMember?.config?.mobileReady ||
              !clubMember?.assignedDevelopmentOfficer?.userId ||
              !clubMember?.email
            }
            style={inviting ? { opacity: 0.7, pointerEvents: 'none' } : null}
          />,
        ]}
      >
        {content}
      </Page>
      <AddPaymentModal
        clubMember={clubMember}
        isOpen={addPaymentModalOpen}
        close={closeAddPaymentModal}
      />
    </>
  );
}

export default observer(ClubMember);
