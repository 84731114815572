import React from "react";
import { Link } from "@reach/router";
import { format } from "date-fns";
import { Table } from "ka-table";
import { DataType } from "ka-table/enums";
import "./LedgerTable.scss";

function DetailsCell({ field, rowData, dispatch }) {
  const event = rowData[field];
  const { type, campaignTitle, campaignId, amount, userFirstName, userLastName } = event;
  let contents;
  if (type === "reserve") {
    contents = (
      <Link className="campaign-link" to={`/campaigns/manage/${campaignId}`} state={{ useBack: true }}>
        Campaign: {campaignTitle}
      </Link>
    );
  } else if (type === "purchase") {
    contents = (
      <Link className="transactions-link" to="/audit/transactions">
        Go to Transactions
      </Link>
    );
  } else if (type === "stock") {
    contents = `${amount > 0 ? "Added" : "Removed"} by ${userFirstName} ${userLastName}`;
  }

  return (
    <div className="details-cell" onClick={() => dispatch("Row Click", rowData)}>
      {contents}
    </div>
  );
}

const columns = [
  {
    dataType: DataType.String,
    key: "date",
    title: "Date",
    format: d => format(new Date(d), "M/d/yyyy"),
    style: { width: 80 }
  },
  {
    dataType: DataType.Number,
    key: "amount",
    title: "Amount",
    style: { width: 80 }
  },
  {
    dataType: DataType.String,
    key: "type",
    title: "Type",
    style: { width: 180 }
  },
  {
    cell: DetailsCell,
    dataType: DataType.Object,
    key: "details",
    title: "Details"
  }
];

function LedgerTable({ data }) {
  return (
    <div className="ledger-table-container">
      <div className="ledger-table-title">Activity</div>
      <Table columns={columns} data={data} rowKeyField="id" />
    </div>
  );
}

export default LedgerTable;
