import React from 'react';

const VideoIcon = ({
  size = 24,
  color = '#fff',
  strokeWidth = 1,
}) => (
  <svg
    style={{
      height: size,
      width: size,
      stroke: color,
    }}
    strokeWidth={strokeWidth}
    strokeLinecap="round"
    viewBox="0 0 56.94 41.92"
    fill={color}
  >
    <path
      className="cls-1"
      d="M32.41,45.2a2,2,0,0,1-.92-.22,2,2,0,0,1-1.13-1.83V29.88A2,2,0,0,1,31.49,28a2,2,0,0,1,2.12.18l9,6.63a2.06,2.06,0,0,1,0,3.32h0l-9,6.64A2,2,0,0,1,32.41,45.2Zm-.14-2.05.21.12h0l9-6.64a.13.13,0,0,0,.06-.12l-9.1-6.75-.21.12V43.15Zm9.81-5.75h0Z"
      transform="translate(-7.62 -15.46)"
    />
    <path
      className="cls-1"
      d="M36.08,57.38c-19,0-21.75-1.69-22.79-2.33-4.88-3-5.59-13.78-5.67-18.3.06-3.7.65-15.91,5.67-19,1.05-.64,3.83-2.33,22.79-2.33,17.61,0,21.85,1.44,23.58,2.7h0c4.38,3.18,4.88,14,4.9,18.53,0,5.4-.67,14.92-4.9,18C57.94,55.94,53.7,57.38,36.08,57.38Zm0-40c-12.23,0-19.56.69-21.8,2.05C11,21.44,9.64,30,9.53,36.75,9.65,43.47,11,51.4,14.28,53.42c2.23,1.36,9.56,2,21.8,2,12.78,0,20.34-.79,22.46-2.33,2.55-1.86,4.09-8,4.11-16.45,0-6.78-1.12-14.81-4.11-17C56.41,18.15,48.85,17.37,36.08,17.37Z"
      transform="translate(-7.62 -15.46)"
    />
  </svg>
);

export default VideoIcon;
