import React, { useState, useCallback } from "react";
import { observer } from "mobx-react";
import { useLocation } from "@reach/router";
import { toast } from "react-toastify";
import { FaLockOpen } from "react-icons/fa";
import ProtectedContentStore, { keys } from "../../../stores/ProtectedContentStore";
import AuthStore from "../../../stores/AuthStore";
import Button from "../Button";
import Input from "../Input";
import Spinner from "../Spinner";
import "./ProtectedContentOverlay.scss";

function ProtectedContentOverlay() {
  const { pathname } = useLocation();
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const hideOverlay = useCallback(async () => {
    setLoading(true);
    const verified = await AuthStore.verifyPassword(password);
    if (verified) {
      ProtectedContentStore.unlockPage(pathname);
    } else toast("Password not recognized.");
    setLoading(false);
  }, [pathname, password]);

  const unlocked = !!ProtectedContentStore[keys[pathname]];

  const buttonText = loading ? <Spinner /> : "Unlock";

  return unlocked ? null : (
    <div className="protected-content-overlay">
      <Input type="password" value={password} onChange={setPassword} placeholder="Password" />
      <Button icon={FaLockOpen} text={buttonText} onClick={hideOverlay} />
    </div>
  );
}

export default observer(ProtectedContentOverlay);
