import React from "react";
import { FiShoppingCart, FiDollarSign } from "react-icons/fi";
import Input from "../../../-common/Input";
import Select from "../../../-common/Select2";
import CurrencyInput from "../../../-common/CurrencyInput";
import "./NumberSlide.scss";
import { commaSeparateNumber, isOnlyNumbersValidator } from "../../../../utils";

const NumberSlide = ({
  category,
  setCategory,
  options,
  unitPrice,
  setUnitPrice,
  initialQuantity,
  setInitialQuantity
}) => {
  const onCategoryChange = selectedCategoryObject => {
    setCategory(selectedCategoryObject);
  };

  const selectedCategory = options?.find(o => o.value === category.value) || {};

  return (
    <div className="slide number-slide">
      <div className="headline">Let's talk numbers.</div>
      <div className="inputs">
        <Select
          options={options}
          value={selectedCategory}
          onChange={onCategoryChange}
          label="Choose Reward Category"
        />
        <Input
          value={initialQuantity}
          onChange={setInitialQuantity}
          label="What is the initial quantity?"
          icon={FiShoppingCart}
          formatter={commaSeparateNumber}
          validator={isOnlyNumbersValidator}
        />
        <CurrencyInput
          value={unitPrice}
          onChange={setUnitPrice}
          label="What is the unit price per item?"
          icon={FiDollarSign}
          type="currency"
        />
      </div>
    </div>
  );
};

export default NumberSlide;
