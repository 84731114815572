import React from 'react';

const NewsIcon = ({
  size = 24,
  color = '#fff',
  strokeWidth = 1,
}) => (
  <svg
    style={{
      height: size,
      width: size,
      stroke: color,
    }}
    strokeWidth={strokeWidth}
    strokeLinecap="round"
    viewBox="0 0 41.6 56.74"
    fill={color}
  >
    <path
      className="cls-1"
      d="M56.31,18.92H46.84a.94.94,0,0,1-.94-1V8.51a.95.95,0,1,1,1.89,0V17h8.52a.95.95,0,0,1,0,1.89Z"
      transform="translate(-15.65 -7.56)"
    />
    <path
      className="cls-1"
      d="M54.43,64.3h-36a2.83,2.83,0,0,1-2.81-2.84v-51a2.84,2.84,0,0,1,2.81-2.86H46.84a2.3,2.3,0,0,1,1.74.83l7.83,7.73A2.51,2.51,0,0,1,57.25,18V61.46A2.83,2.83,0,0,1,54.43,64.3Zm-36-54.84a1,1,0,0,0-.92,1v51a.94.94,0,0,0,.92.95h36a1,1,0,0,0,.93-.95V18c0-.14,0-.24-.28-.5L47.25,9.74c-.29-.28-.3-.28-.41-.28Z"
      transform="translate(-15.65 -7.56)"
    />
    <path
      className="cls-1"
      d="M48.32,35.89H24.56a.95.95,0,0,1,0-1.89H48.32a.95.95,0,0,1,0,1.89Z"
      transform="translate(-15.65 -7.56)"
    />
    <path
      className="cls-1"
      d="M48.32,43.47H24.56a.95.95,0,0,1,0-1.89H48.32a.95.95,0,0,1,0,1.89Z"
      transform="translate(-15.65 -7.56)"
    />
    <path
      className="cls-1"
      d="M48.32,51.05H24.56a.95.95,0,0,1,0-1.89H48.32a.95.95,0,0,1,0,1.89Z"
      transform="translate(-15.65 -7.56)"
    />
    <path
      className="cls-1"
      d="M37.76,28.31H24.56a.95.95,0,0,1,0-1.89h13.2a.95.95,0,0,1,0,1.89Z"
      transform="translate(-15.65 -7.56)"
    />
  </svg>
);

export default NewsIcon;
