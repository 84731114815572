import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Router, Redirect, navigate } from "@reach/router";
import { FaFilter, FaSortAmountDown } from "react-icons/fa";
import { GoPlus } from "react-icons/go";
import GlobalSearchStore from "../../../stores/GlobalSearchStore";
import CampaignsStore, { filterOptions, sortOptions } from "../../../stores/CampaignsStore";
import Page from "../../-common/Page";
import Button from "../../-common/Button";
import DropdownButton from "../../-common/DropdownButton";
import CampaignSquare from "./CampaignSquare";
import General from "../General";
import Media from "../Media";
import Tiers from "../Tiers";
import Extras from "../Extras/Extras";
import Schedule from "../Schedule";
import "./Manage.scss";

function CampaignsSection({ title, data, noTopMargin }) {
  if (data.length) {
    const openCampaign = campaignId => () => {
      navigate(`manage/${campaignId}/general`);
    };
    const squares = data.map(CampaignSquare(openCampaign));
    const titleStyles = noTopMargin ? { marginTop: 0 } : null;
    return (
      <>
        <div className="campaign-squares-title" style={titleStyles}>
          {title}
        </div>
        <div className="campaign-squares">{squares}</div>
      </>
    );
  }
  return null;
}

function ManageInner() {
  useEffect(() => {
    GlobalSearchStore.setSearchAction(searchText => CampaignsStore.setSearch(searchText));
  }, []);

  const { filter, sort, campaigns, liveCampaigns, pendingCampaigns, endedCampaigns } = CampaignsStore;

  const updateFilter = filter => CampaignsStore.setFilter(filter);
  const updateSort = sort => CampaignsStore.setSort(sort);

  const campaignSquares =
    filter.value === "All" ? (
      <>
        <CampaignsSection title={`Live (${liveCampaigns?.length || 0})`} data={liveCampaigns} noTopMargin={true} />
        <CampaignsSection title={`Pending (${pendingCampaigns?.length || 0})`} data={pendingCampaigns} />
        <CampaignsSection title={`Ended (${endedCampaigns?.length || 0})`} data={endedCampaigns} />
      </>
    ) : (
      <CampaignsSection title=" " data={campaigns} noTopMargin={true} />
    );

  return (
    <Page
      titleOpts={{ title: "Campaigns" }}
      buttons={[
        <DropdownButton
          label="Filter"
          icon={FaFilter}
          theme="light"
          options={filterOptions}
          style={{ height: 42 }}
          value={filter}
          onChange={updateFilter}
          key="filter"
        />,
        <DropdownButton
          label="Sort"
          icon={FaSortAmountDown}
          theme="light"
          options={sortOptions}
          style={{ height: 42 }}
          value={sort}
          onChange={updateSort}
          key="sort"
        />,
        <Button text="Add Campaign" icon={GoPlus} onClick={CampaignsStore.openNewCampaignModal} key="add campaign" />
      ]}
    >
      <div className="manage-campaigns">{campaignSquares}</div>
    </Page>
  );
}

const Manage = observer(ManageInner);

export default function ManageRouter() {
  return (
    <Router>
      <Manage path="/" />
      <General path=":campaignId/general" />
      <Media path=":campaignId/media" />
      <Tiers path=":campaignId/tiers" />
      <Extras path=":campaignId/extras" />
      <Schedule path=":campaignId/schedule" />
      <Redirect from="*" to="/campaigns/manage" noThrow />
    </Router>
  );
}
