function dayDistanceInWords(current, previous) {
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerWeek = msPerDay * 7;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;

  const elapsed = current.valueOf() - previous.valueOf();

  if (elapsed < msPerWeek) {
    const days = Math.round(elapsed / msPerDay);
    if (days < 1) return "today";
    else if (days === 1) return "1 day ago";
    return days + " days ago";
  } else if (elapsed < msPerMonth) {
    const weeks = Math.round(elapsed / msPerWeek);
    if (weeks === 1) return "1 week ago";
    return weeks + " weeks ago";
  } else if (elapsed < msPerYear) {
    const months = Math.round(elapsed / msPerMonth);
    if (months === 1) return "1 month ago";
    return months + " months ago";
  } else {
    const years = Math.round(elapsed / msPerYear);
    if (years === 1) return "1 year ago";
    return years + " years ago";
  }
}

export default dayDistanceInWords;
