import { observable, computed, action, autorun } from "mobx";
import sampleSize from "lodash.samplesize";
import { toast } from "react-toastify";
import { parseISO } from "date-fns";
import { request, isVideoLink, getVideoId } from "../utils";
import uploadFile from "../services/FileUploadService";
import AuthStore from "./AuthStore";
import AppStateStore from "./AppStateStore";
import TenantStore from "./TenantStore";
import RewardsStore from "./RewardsStore";

export const filterOptions = [
  { value: "All", label: "All" },
  { value: "Live", label: "Live" },
  { value: "Pending", label: "Pending" },
  { value: "Ended", label: "Ended" },
];

export const sortOptions = [
  { label: "Newest", value: "Newest" },
  { label: "Ending Soon", value: "Ending Soon" },
  { label: "A to Z", value: "A to Z" },
  { label: "Z to A", value: "Z to A" },
];

function getRandomOtherCampaigns(campaignId, campaigns = []) {
  const otherCampaigns = campaigns.filter((c) => c.campaignId !== campaignId);
  return sampleSize(otherCampaigns, 2);
}

function getFirstPublishDate(c) {
  return c?.schedules?.reduce((acc, next) => {
    if (!acc) return next?.publishDate;
    return next?.publishDate < acc ? next.publishDate : acc;
  }, null);
}

function checkCampaignPublished(c) {
  return new Date(getFirstPublishDate(c)) < new Date();
}

const filterCampaigns = (filter) => (c) => {
  const today = new Date();

  if (filter.value === "Live") {
    return c.published && new Date(c.endDate) >= today;
  } else if (filter.value === "Pending") {
    return !c.published;
  } else if (filter.value === "Ended") {
    return c.published && c.endDate && new Date(c.endDate) < today;
  }

  return true;
};

const sortCampaigns = (sort) => (c1, c2) => {
  const title1 = c1?.title?.toUpperCase();
  const title2 = c2?.title?.toUpperCase();
  if (sort.value === "Newest") {
    return new Date(c2.createdDate).valueOf() - new Date(c1.createdDate).valueOf();
  } else if (sort.value === "Ending Soon") {
    return new Date(c2.endDate).valueOf() - new Date(c1.endDate).valueOf();
  } else if (sort.value === "A to Z") {
    return title1 < title2 ? -1 : title1 > title2 ? 1 : 0;
  } else if (sort.value === "Z to A") {
    return title1 > title2 ? -1 : title1 < title2 ? 1 : 0;
  }
};

class CampaignsStore {
  constructor() {
    autorun(() => {
      if (AuthStore.APIReady) {
        this.fetchCampaigns();
      } else {
        this.clear();
      }
    });
  }

  // CAMPAIGNS
  @observable loading = false;

  @observable rawCampaigns = [];

  @computed get allCampaigns() {
    return this.rawCampaigns.map((c) => ({
      ...c,
      formattedRevenueGoal: c.revenueGoal ? c.revenueGoal / 100 : 0,
      totalDonated: c.totalDonated ? c.totalDonated / 100 : 0,
      totalDonations: c.totalDonations ? Number(c.totalDonations) : 0,
      suggestedCampaigns: getRandomOtherCampaigns(c.campaignId, this.rawCampaigns),
      tiers: c?.tiers?.map((t) => {
        return {
          ...t,
          // donationAmountDisplay: c.donationAmount ? c.donationAmount / 100 : 0,
          reward: RewardsStore.allRewards.find((r) => r.rewardId === t.rewardId),
        };
      }),
      campaignLink: `${TenantStore?.tenant?.config?.hostingURL}?campaignId=${c.campaignId}`,
      schedules: c?.schedules?.map((c) => ({ ...c, publishDate: parseISO(c?.publishDate) })),
      published: checkCampaignPublished(c),
    }));
  }

  @computed get campaigns() {
    return this.allCampaigns
      .filter(filterCampaigns(this.filter))
      .filter((c) => {
        // TODO: Implement a better, controlled search here
        return (c.title || "").toLowerCase().includes((this.search || "").toLowerCase());
      })
      .sort(sortCampaigns(this.sort));
  }

  @computed get liveCampaigns() {
    const today = new Date();
    return this.campaigns.filter((c) => c.published && new Date(c.endDate) >= today).sort(sortCampaigns(this.sort));
  }

  @computed get pendingCampaigns() {
    return this.campaigns.filter((c) => !c.published).sort(sortCampaigns(this.sort));
  }

  @computed get endedCampaigns() {
    const today = new Date();
    return this.campaigns
      .filter((c) => c.published && c.endDate && new Date(c.endDate) < today)
      .sort(sortCampaigns(this.sort));
  }

  @action async fetchCampaigns() {
    this.loading = true;
    try {
      const campaigns = await request.get("/v1/campaigns");
      this.rawCampaigns = campaigns;
      this.loading = false;
      const withThumbnails = await Promise.all(this.rawCampaigns.map(this.addVideoThumbnailToCampaign.bind(this)));
      this.rawCampaigns = withThumbnails;
      return withThumbnails;
    } catch (err) {
      this.loading = false;
      console.warn(err);
    }
  }

  @action async fetchCampaign(campaignId) {
    try {
      const campaign = await request.get(`/v1/campaigns/${campaignId}`);
      if (this.rawCampaigns.find((c) => c.campaignId === campaign.campaignId)) {
        this.updateCampaignInPlace(campaign);
      } else {
        this.rawCampaigns = this.rawCampaigns.concat(campaign);
      }
      return campaign;
    } catch (err) {
      console.warn(err);
    }
  }

  @action async addCampaign({ teamIds, title, description, revenueGoal, endDate, pointMultiplier, quid }, media) {
    AppStateStore.setLoading(true);
    try {
      let newCampaign = await request.post("/v1/campaigns", {
        body: {
          teamIds,
          title,
          description,
          revenueGoal,
          endDate,
          pointMultiplier,
          quid,
        },
      });
      if (media) {
        if (typeof media === "string") {
          newCampaign = await request.put(`/v1/campaigns/${newCampaign.campaignId}`, {
            body: { media },
          });
        } else {
          let url = await this.uploadFile(media);
          url = url.split("?")[0];
          newCampaign = await request.put(`/v1/campaigns/${newCampaign.campaignId}`, {
            body: { media: url },
          });
        }
      }
      if (newCampaign) {
        const newCampaignWithThumbnail = await this.addVideoThumbnailToCampaign(newCampaign);
        this.rawCampaigns = this.rawCampaigns.concat(newCampaignWithThumbnail);
        toast("Campaign created!", { autoClose: 3000 });
        AppStateStore.setLoading(false);
        return newCampaign;
      }
    } catch (err) {
      console.warn(err);
      toast("Error creating campaign.");
      AppStateStore.setLoading(false);
    }
  }

  @action async uploadFile(file) {
    try {
      const url = await uploadFile(file, "campaign");
      return url;
    } catch (err) {
      console.warn(err);
    }
  }

  @action async updateCampaign(campaignObject, media) {
    AppStateStore.setLoading(true);
    try {
      const campaignIdToUpdate = campaignObject.campaignId || this.currentCampaign.campaignId;
      const {
        team,
        title,
        description,
        startDate,
        endDate,
        revenueGoal,
        pointMultiplier,
        quid,
        teamIds,
      } = campaignObject;

      const updates = {
        team,
        title,
        description,
        startDate,
        endDate,
        revenueGoal,
        pointMultiplier,
        quid,
        teamIds,
      };

      if (media) {
        if (typeof media === "string") {
          updates.media = media;
          const updatedCampaign = await request.put(`/v1/campaigns/${campaignIdToUpdate}`, { body: updates });
          this.updateCampaignInPlace(updatedCampaign);
          AppStateStore.setLoading(false);
          toast("Campaign updated!", { autoClose: 3000 });
          return updatedCampaign;
        } else {
          let url = await this.uploadFile(media);
          url = url.split("?")[0];
          updates.media = url;
          const updatedCampaign = await request.put(`/v1/campaigns/${campaignIdToUpdate}`, { body: updates });
          this.updateCampaignInPlace(updatedCampaign);
          AppStateStore.setLoading(false);
          toast("Campaign updated!", { autoClose: 3000 });
          return updatedCampaign;
        }
      } else {
        const updatedCampaign = await request.put(`/v1/campaigns/${campaignIdToUpdate}`, { body: updates });
        this.updateCampaignInPlace(updatedCampaign);
        AppStateStore.setLoading(false);
        toast("Campaign updated!", { autoClose: 3000 });
        return updatedCampaign;
      }
    } catch (err) {
      console.warn(err);
      AppStateStore.setLoading(false);
      toast("Error updating campaign.");
    }
  }

  @action async updateScheduleForCampaign(campaignId, scheduleItems) {
    if (!scheduleItems?.length) return;

    AppStateStore.setLoading(true);
    try {
      const fullScheduleItems = scheduleItems.map((si) => ({
        targetId: campaignId,
        targetType: "campaign",
        publishDate: Number(si?.publishDate),
        referenceId: si?.referenceId,
        referenceType: si?.referenceId ? "membership" : "public",
      }));
      const newScheduleItems = await request.post("/v1/schedules", { body: fullScheduleItems });
      this.rawCampaigns = this.rawCampaigns?.map((c) => {
        if (c?.campaignId === campaignId) {
          return { ...c, schedules: newScheduleItems };
        } else {
          return c;
        }
      });
      AppStateStore.setLoading(false);
      toast("Campaign schedule updated.");
      return newScheduleItems;
    } catch (err) {
      console.warn(err);
      AppStateStore.setLoading(false);
      toast("Error updating schedule for campaign.");
    }
  }

  @action async deleteScheduleStepsForCampaign(campaignId, stepIdsToRemove) {
    AppStateStore.setLoading(true);
    try {
      await request.delete(`/v1/schedules?scheduleIds=${stepIdsToRemove?.join(",")}`);
      this.rawCampaigns = this.rawCampaigns?.map((c) => {
        if (c?.campaignId === campaignId) {
          return { ...c, schedules: c?.schedules?.filter((s) => !stepIdsToRemove?.includes(s?.scheduleId)) };
        } else {
          return c;
        }
      });
      AppStateStore.setLoading(false);
    } catch (err) {
      console.warn(err);
      AppStateStore.setLoading(false);
      toast("Error updating schedule for campaign.");
    }
  }

  @action async updateCampaignInPlace(updatedCampaign) {
    const updatedCampaignsArray = await Promise.all(
      this.rawCampaigns.map(async (c) => {
        if (c.campaignId === updatedCampaign.campaignId) {
          const withThumbnail = await this.addVideoThumbnailToCampaign(updatedCampaign);
          return withThumbnail;
        }
        return c;
      }),
    );
    this.rawCampaigns = updatedCampaignsArray;
  }

  @action async addVideoThumbnailToCampaign(campaign) {
    const { media, thumbnail: existingThumbnail } = campaign;
    const videoLinkType = isVideoLink(media);
    if (videoLinkType && !existingThumbnail) {
      let thumbnail;
      if (videoLinkType === "internal") {
        thumbnail = media.replace(/mp4$/g, "png");
      } else if (media.includes("vimeo.com")) {
        const response = await fetch(`https://vimeo.com/api/oembed.json?url=${media}`);
        const { thumbnail_url } = await response.json();
        thumbnail = thumbnail_url;
      } else {
        const { id: videoId } = getVideoId(media);
        thumbnail = `https://img.youtube.com/vi/${videoId}/0.jpg`;
      }
      campaign.thumbnail = thumbnail;
    }
    return campaign;
  }

  @action async setSuggestedCampaignsTypeForCampaign(campaignId, type) {
    const preCampaign = this.rawCampaigns.find((c) => c.campaignId === campaignId);
    try {
      this.updateCampaignInPlace({
        ...preCampaign,
        suggestedCampaignsType: type,
      });
      const updatedCampaign = await request.put(`/v1/campaigns/${campaignId}`, {
        body: { suggestedCampaignsType: type },
      });
      this.updateCampaignInPlace(updatedCampaign);
      toast("Campaign extras settings updated!", { autoClose: 3000 });
      return updatedCampaign;
    } catch (err) {
      console.warn(err);
      this.updateCampaignInPlace(preCampaign);
      toast("Error updating extras settings.");
      return preCampaign;
    }
  }

  // NEW CAMPAIGN MODAL
  @observable showNewCampaignModal = false;

  @action openNewCampaignModal = () => (this.showNewCampaignModal = true);

  @action closeNewCampaignModal = () => (this.showNewCampaignModal = false);

  // SEARCH
  @observable search = "";

  @action setSearch = (search) => (this.search = search);

  // FILTER AND SORT
  @observable filter = filterOptions[0];

  @action setFilter(filter) {
    this.filter = filter;
  }

  @observable sort = sortOptions[0];

  @action setSort(sort) {
    this.sort = sort;
  }

  // CURRENT CAMPAIGN
  @observable currentCampaignId;

  @computed get currentCampaign() {
    return this.allCampaigns.find((c) => c.campaignId === this.currentCampaignId) || {};
  }

  @action refreshCurrentCampaign() {
    return;
  }

  @action setSelectedCampaign = (campaignId) => {
    this.currentCampaignId = campaignId;
  };

  // TIERS
  @action async addTier(tierObject, media) {
    AppStateStore.setLoading(true);
    const campaignId = this.currentCampaignId;
    const requestBody = tierObject;

    try {
      if (media) {
        if (typeof media === "string") {
          requestBody.media = media;
        } else {
          let url = await this.uploadFile(media);
          url = url.split("?")[0];

          requestBody.media = url;
        }
      }

      await request.post(`/v1/campaigns/${campaignId}/tiers`, {
        body: requestBody,
      });
      await this.fetchCampaigns();

      AppStateStore.setLoading(false);
      toast("Tier added!", { autoClose: 3000 });
    } catch (err) {
      AppStateStore.setLoading(false);
      toast("Error adding tier.");
    }
  }

  @action async updateTier(tierObject) {
    AppStateStore.setLoading(true);
    const requestBody = tierObject;

    try {
      const { campaignId, tierId, file } = requestBody;

      if (file) {
        if (typeof file === "string") {
          requestBody.media = file;
        } else {
          let url = await this.uploadFile(file);
          url = url.split("?")[0];

          requestBody.media = url;
        }
      }

      const newTier = await request.put(`/v1/campaigns/${campaignId}/tiers/${tierId}`, {
        body: requestBody,
      });

      this.updateTierInPlace(newTier);
      AppStateStore.setLoading(false);
      toast("Tier updated!", { autoClose: 3000 });
    } catch (err) {
      console.warn(err);
      AppStateStore.setLoading(false);
      toast("Error updating tier.");
    }
  }

  @action async sortTiers(campaignId, sortUpdatedTiers) {
    AppStateStore.setLoading(true);
    try {
      const tierSorts = sortUpdatedTiers.map((t) => ({
        campaignId,
        tierId: t.tierId,
        sortIndex: t.sortIndex,
      }));
      const updatedCampaign = await request.post(`/v1/campaigns/${campaignId}/tiers/sort`, {
        body: { tierSorts },
      });
      this.updateCampaignInPlace(updatedCampaign);
      AppStateStore.setLoading(false);
      toast("Tier order updated!", { autoClose: 3000 });
    } catch (err) {
      console.warn(err);
      AppStateStore.setLoading(false);
      toast("Error updating tier order.");
    }
  }

  @action updateTierInPlace(updatedTier) {
    this.rawCampaigns = this.rawCampaigns.map((c) => {
      if (c.campaignId === updatedTier.campaignId) {
        const updatedTiers = c.tiers.map((tier) => {
          if (tier.tierId === updatedTier.tierId) return updatedTier;
          return tier;
        });
        return { ...c, tiers: updatedTiers };
      }
      return c;
    });
  }

  @action async removeTier(campaignId, tierId) {
    AppStateStore.setLoading(true);
    try {
      await request.delete(`/v1/campaigns/${campaignId}/tiers/${tierId}`);
      this.rawCampaigns = this.rawCampaigns.map((c) => {
        if (c.campaignId === campaignId) {
          return { ...c, tiers: c.tiers.filter((t) => t.tierId !== tierId) };
        }
        return c;
      });
      AppStateStore.setLoading(false);
      toast("Tier deleted!", { autoClose: 3000 });
    } catch (err) {
      console.warn(err);
      AppStateStore.setLoading(false);
      toast("Error deleting tier.");
    }
  }

  //NEW TIER
  @observable showNewTierModal = false;

  @action openNewTierModal = () => (this.showNewTierModal = true);

  @action closeNewTierModal = () => (this.showNewTierModal = false);

  // CLEANUP
  @action clear() {
    this.rawCampaigns = [];
  }
}

export default new CampaignsStore();
