import React from "react";

export default function skates({
  size = 24,
  fill = "none",
  stroke = "#000",
  strokeWidth = 13
}) {
  return (
    <svg
      viewBox="0 0 268 268"
      height={size}
      width={size}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M122.418,58.609V18.15 H14.5v110v77h129h118v-7.459c0-38.406-31.135-69.541-69.541-69.541h0C153.553,128.15,122.418,97.015,122.418,58.609z" />
      <path d="M246.47,210.38 c-0.8,21.93-18.84,39.47-40.97,39.47h-47.75H32.9H6.5" />
      <line x1="188.98" y1="205.897" x2="172.27" y2="248.803" />
      <line x1="44.98" y1="205.897" x2="28.27" y2="248.803" />
      <line x1="85.5" y1="51.15" x2="119.5" y2="51.15" />
      <line x1="90.704" y1="85.488" x2="124.687" y2="80.403" />
      <line x1="106.678" y1="116.369" x2="138.322" y2="103.931" />
      <line x1="15.5" y1="178.15" x2="254.5" y2="178.15" />
      <path d="M16.47,205.15 c-0.66-0.15-1.32-0.31-1.97-0.5" />
      <path d="M14.503,126.576 c24.76,6.67,44.12,26.52,50.08,51.57" />
    </svg>
  );
}
