import React from "react";
import Input from "../../../-common/Input";
import Textarea from "../../../-common/Textarea";
import CurrencyInput from "../../../-common/CurrencyInput";
import { FiDollarSign } from "react-icons/fi";
import "./TitleSlide.scss";

const TitleSlide = ({
  title,
  setTitle,
  description,
  setDescription,
  donationAmount,
  setDonationAmount
}) => {
  return (
    <div className="slide title-slide">
      <div className="headline">Let's give donors another option</div>
      <div className="inputs">
        <Input
          value={title}
          onChange={setTitle}
          label="Title"
          countCharacters={true}
        />
        <Textarea
          value={description}
          onChange={setDescription}
          label="Description"
        />
        <div className="donation-container">
          <CurrencyInput
            value={donationAmount}
            onChange={setDonationAmount}
            label="Donation Amount"
            icon={FiDollarSign}
          />
        </div>
      </div>
    </div>
  );
};

export default TitleSlide;
