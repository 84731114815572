import React from "react";
import { Link } from "@reach/router";
import "./PurposeCell.scss";

export default function PurposeCell({ field, rowData }) {
  const { campaignId, title } = rowData?.campaign || {};
  const link = title ? (
    <Link className="campaign-link" to={`/campaigns/manage/${campaignId}/general`} state={{ useBack: true }}>
      Campaign: {title}
    </Link>
  ) : null;
  let contents;
  if (rowData.type === "campaign donation") contents = link;
  else if (["void", "refund"].includes(rowData.type)) contents = "Refund";
  else if (rowData.type === "membership renewal") contents = "Membership Renewal";
  return <div className="purpose-cell">{contents}</div>;
}
