import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import { FiFacebook, FiInstagram, FiTwitter, FiX } from "react-icons/fi";
import { IoMdEye } from "react-icons/io";
import { GoPlus } from "react-icons/go";
import initiateAuth from "../../../services/SocialService";
import TeamsStore from "../../../stores/TeamsStore";
import UsersStore from "../../../stores/UsersStore";
import sportIcons from "../sportIcons";
import Page from "../Page";
import Button from "../Button";
import Select from "../Select2";
import Input from "../Input";
import TeamMembersTable from "./TeamMembersTable";
import NewTeamMemberModal from "../../-modals/NewTeamMemberModal";
import TeamIconModal from "./TeamIconModal";
import "./Team.scss";

const TeamDetails = ({ editMode, deleteOauth, processOauth, localTeam, setLocalTeam, openEditIconModal }) => {
  const { name, media, owner, ownerName, social, members } = localTeam || {};

  const updateOwner = ownerObj => {
    setLocalTeam({ ...localTeam, owner: ownerObj.value });
  };

  const updateName = value => {
    setLocalTeam({ ...localTeam, name: value });
  };

  const Icon = sportIcons[media];
  const iconComponent = (
    <div className={`team-icon-container${editMode ? " editing" : ""}`} onClick={editMode ? openEditIconModal : null}>
      {Icon ? <Icon size={84} /> : null}
    </div>
  );

  const ownerOptions = UsersStore.users.map(user => {
    return {
      label: user.fullName,
      value: user.userId
    };
  });
  const blockStyle = editMode ? null : { pointerEvents: "none" };

  const deleteSocialComponent = platform =>
    editMode ? (
      <div className="social-delete" onClick={deleteOauth(platform)}>
        <FiX size={24} />
      </div>
    ) : null;

  const facebookObject = (social || []).find(s => s?.platform === "facebook");
  const facebookLoginComponent = !!facebookObject ? (
    <div className="social-container" style={blockStyle}>
      {deleteSocialComponent("facebook")}
      <div className="social-tile" onClick={processOauth("facebook")}>
        <img alt="Facebook profile" className="social-profile-picture" src={facebookObject.media} />
      </div>
      <div className="social-support-text">{`${facebookObject.username}`}</div>
    </div>
  ) : (
    <div style={blockStyle} className={`social-container ${editMode ? "add" : ""}`} onClick={processOauth("facebook")}>
      <FiFacebook className="icon facebook" />
      <div className="social-support-text add">{editMode ? "add Facebook" : "no account"}</div>
    </div>
  );

  const twitterObject = (social || []).find(s => s?.platform === "twitter");
  const twitterLoginComponent = !!twitterObject ? (
    <div className="social-container" style={blockStyle}>
      {deleteSocialComponent("twitter")}
      <img alt="Twitter profile" className="social-profile-picture" src={twitterObject.media} />
      <div className="social-support-text">{`@${twitterObject.username}`}</div>
    </div>
  ) : (
    <div style={blockStyle} className={`social-container ${editMode ? "add" : ""}`} onClick={processOauth("twitter")}>
      <FiTwitter className="icon twitter" />
      <div className="social-support-text add">{editMode ? "add Twitter" : "no account"}</div>
    </div>
  );

  const instagramObject = (social || []).find(s => s?.platform === "instagram");
  const instagramLoginComponent = instagramObject ? (
    <div className="social-container" style={blockStyle}>
      {deleteSocialComponent("instagram")}
      <div className="social-tile" onClick={processOauth("instagram")}>
        <img alt="Instagram profile" className="social-profile-picture" src={instagramObject.media} />
      </div>
      <div className="social-support-text">{`@${instagramObject.username}`}</div>
    </div>
  ) : (
    <div style={blockStyle} className={`social-container ${editMode ? "add" : ""}`} onClick={processOauth("instagram")}>
      <div className="icon-container">
        <FiInstagram className="icon instagram" />
      </div>
      <div className="social-support-text add">{editMode ? "add Instagram" : "no account"}</div>
    </div>
  );

  const nameComponent = editMode ? (
    <Input value={name} onChange={updateName} />
  ) : (
    <div className="team-name">{name}</div>
  );

  const selectedOwner = ownerOptions?.find(o => o.value === owner) || {};
  const ownerComponent = editMode ? (
    <Select value={selectedOwner} onChange={updateOwner} options={ownerOptions} />
  ) : (
    <div className="team-name">{ownerName}</div>
  );

  return (
    <div className="team-details-container">
      <div className="team-title-container">
        <div className="team-icon" style={blockStyle}>
          {iconComponent}
        </div>
        <div className="team-name-container">{nameComponent}</div>
      </div>
      <div className="team-owner-container">
        <div className="label">Owner:</div>
        <div className="team-owner">{ownerComponent}</div>
      </div>

      <div className="team-roster-container">
        <div className="label">Roster:</div>
        <div className="team-roster">{(members || []).length} members</div>
      </div>
      <div className="team-social-container">
        <div className="social-auth">
          <div className="label">Social:</div>
          {facebookLoginComponent}
          {twitterLoginComponent}
          {instagramLoginComponent}
        </div>
      </div>
    </div>
  );
};

function Team({ teamId }) {
  TeamsStore.setSelectedTeam(teamId);
  const { currentTeam, teams } = TeamsStore;
  // const [state, dispatch] = useReducer(teamReducer, teamInitialState);
  const [localTeam, setLocalTeam] = useState();
  const [originalTeam, setOriginalTeam] = useState();
  const [editMode, setEditMode] = useState(false);

  const [changeMemberOpen, setChangeMemberOpen] = useState(false);
  const openChangeMember = () => setChangeMemberOpen(true);
  const closeChangeMember = () => setChangeMemberOpen(false);

  const [editIconModalOpen, setEditIconModalOpen] = useState(false);
  const openEditIconModal = () => setEditIconModalOpen(true);
  const closeEditIconModal = () => setEditIconModalOpen(false);
  const updateTeamIcon = icon => setLocalTeam({ ...localTeam, media: icon });

  useEffect(() => {
    if (currentTeam) {
      setLocalTeam(currentTeam);
      setOriginalTeam(currentTeam);
    } else if (teams.length) navigate("/settings/teams");
  }, [currentTeam, teams]);

  const addSocial = async socialTeamObj => {
    const socialTeamObject = { ...socialTeamObj, teamId: currentTeam.teamId };
    setLocalTeam({
      ...localTeam,
      social: localTeam.social.concat(socialTeamObject)
    });
  };

  const checkSaveEnabled = ({ localTeam, originalTeam }) => {
    if (localTeam?.media !== originalTeam?.media) return true;
    if (localTeam?.name !== originalTeam?.name) return true;
    if (localTeam?.owner !== originalTeam?.owner) {
      return true;
    }
    if (localTeam?.social?.length !== originalTeam?.social?.length) return true;

    // eslint-disable-next-line
    for (let { teamSocialId } of localTeam?.social || []) {
      if (!originalTeam?.social?.map(t => t.teamSocialId).includes(teamSocialId)) {
        return true;
      }
    }
    return false;
  };

  const saveEnabled = checkSaveEnabled({
    localTeam,
    originalTeam
  });

  const save = async () => {
    await TeamsStore.editTeam(localTeam, originalTeam);
    setEditMode(!editMode);
  };
  const cancel = () => {
    setLocalTeam(originalTeam);
    setEditMode(!editMode);
  };

  const processOauth = platform => () => {
    initiateAuth(platform, addSocial);
  };

  const deleteOauth = platform => e => {
    e.stopPropagation();
    deleteCurrentOauth(platform);
  };
  const deleteCurrentOauth = platform => {
    setLocalTeam({
      ...localTeam,
      social: localTeam.social.filter(l => l.platform !== platform)
    });
  };

  const buttonActions = editMode
    ? [
        <Button key="cancel" text="Cancel" icon={FiX} theme="light" onClick={cancel} />,
        <Button key="save" text="Save" icon={IoMdEye} disabled={!saveEnabled} onClick={save} />
      ]
    : [
        // <Button text="Saving..." theme="light" isStatic={true} key="saving" />,
        <Button key="preview" text="Edit Team" icon={IoMdEye} onClick={() => setEditMode(!editMode)} />,
        <Button key="publish" text="Add Team Member" onClick={openChangeMember} icon={GoPlus} />
      ];

  return (
    <Page
      key="team-page"
      titleOpts={{
        title: "Team",
        backButton: true
      }}
      buttons={buttonActions}
    >
      <div className="team">
        <div className="team-content">
          <TeamDetails
            {...localTeam}
            processOauth={processOauth}
            deleteOauth={deleteOauth}
            localTeam={localTeam}
            setLocalTeam={setLocalTeam}
            editMode={editMode}
            openEditIconModal={openEditIconModal}
          />
          <div className="team-members-container">
            <TeamMembersTable data={localTeam?.members} />
          </div>
        </div>
      </div>
      <TeamIconModal isOpen={editIconModalOpen} close={closeEditIconModal} team={localTeam} update={updateTeamIcon} />
      <NewTeamMemberModal isOpen={changeMemberOpen} close={closeChangeMember} team={localTeam} />
    </Page>
  );
}

export default observer(Team);
