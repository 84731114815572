import { useState, useEffect } from "react";
import { toJS } from "mobx";
import { navigate } from "@reach/router";
import MembershipsStore from "../../../stores/MembershipsStore";

export default function useMembership(membershipId) {
  const { memberships } = MembershipsStore;
  const [membership, setMembership] = useState();

  useEffect(() => {
    const matchingMembership = memberships.find(
      m => m.membershipId === membershipId
    );
    if (matchingMembership) {
      setMembership(toJS(matchingMembership, { recurseEverything: true }));
    } else if (memberships.length) {
      navigate("/club/levels");
    }
  }, [memberships, membershipId]);

  return membership;
}
