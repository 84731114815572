import commaNumber from "comma-number";

const commaSeparateNumber = (number, roundNumber = false) => {
  number = String(number).replace(/\s|,/g, "");
  if (!number.match(/^(\d+|\d{1,3}(,\d{3})*)(\.\d*)?$/)) return "";
  if (number.match(/\.$/)) number += "0";

  number = Number(number?.replace(",", ""));
  if (typeof roundNumber === "boolean" && roundNumber) number = Math.round(number);
  else if (typeof roundNumber === "number") number = number.toFixed(roundNumber);

  return commaNumber(number);
};

export default commaSeparateNumber;
