const commaSeparateStringList = (strs, maxItems = strs.length) => {
  return strs.reduce((acc, next, index, source) => {
    if (!acc) {
      acc = next;
      return acc;
    }
    if (index === maxItems)
      return `${acc}${maxItems > 1 ? "," : ""} and ${source.length -
        maxItems} more`;
    if (index > maxItems) return acc;
    if (source.length === 2) return acc + " and " + next;
    if (index < source.length - 1) return acc + ", " + next;
    if (index === source.length - 1) return acc + ", and " + next;
    return acc;
  }, "");
};

export default commaSeparateStringList;
