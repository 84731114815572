const nextEnabled = (state) => {
  const {
    activeSlide,
    title,
    description,
    donationAmount,
    reward,
    rewardCategory,
    quantity
  } = state;

  if (activeSlide === 0) return title && description && donationAmount;
  if (activeSlide === 1) return true;
  if (activeSlide === 2)
    return rewardCategory?.value === "none" || (rewardCategory?.value !== "none" && reward?.rewardId && quantity);
  return false;
};

export const tierInitialState = {
  activeSlide: 0,
  nextEnabled: false,
  campaign: null,
  reward: {},
  rewardCategory: {},
  title: "",
  donationAmount: 0,
  quantity: 0,
  description: "",
  file: null,
  fileURL: null,
  footnoteLabel: "",
  footnoteText: "",
};

export const tierReducer = (state, action) => {
  const { type, payload } = action;
  const withSimpleUpdates = { ...state, ...payload };
  const { file, fileURL } = withSimpleUpdates;
  let result = state;

  switch (type) {
    case "setActiveSlide":
    case "setCampaign":
    case "setRewardCategory":
    case "setTitle":
    case "setDescription":
    case "setDonationAmount":
    case "setQuantity":
    case "setFootnoteLabel":
    case "setFootnoteText":
      result = {
        ...withSimpleUpdates,
        nextEnabled: nextEnabled(withSimpleUpdates),
      };
      break;
    case "setReward":
      result = {
        ...withSimpleUpdates,
        reward: payload.reward,
      };
      break;
    case "setFile":
      result = { ...state, file };
      break;
    case "setFileURL":
      result = { ...state, fileURL };
      break;
    case "resetState":
      result = { ...state, ...tierInitialState };
      break;
    default:
      break;
  }
  return result;
};
