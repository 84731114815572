import React, { useState, useCallback } from "react";
import { createPortal } from "react-dom";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import usePortal from "../../../../hooks/usePortal";
import BulletinsStore from "../../../../stores/BulletinsStore";
import FullScreenModal from "../../../-common/FullScreenModal";
import Button from "../../../-common/Button";
import PieTimer from "../../../-common/PieTimer";
import "./ConfirmRemoveBulletinModal.scss";

const ConfirmRemoveBulletinModal = observer(({ close, bulletinId }) => {
  const [removeEnabled, setRemoveEnabled] = useState(false);

  const showRemoveButton = useCallback(() => {
    setRemoveEnabled(true);
  }, [setRemoveEnabled]);

  const remove = useCallback(async () => {
    close();
    const deletionSuccess = await BulletinsStore.deleteBulletin(bulletinId);
    if (deletionSuccess) navigate("/club/bulletins");
  }, [close, bulletinId]);

  const removeButton = removeEnabled ? (
    <Button text="Delete Bulletin" onClick={remove} />
  ) : (
    <div className="timer-container">
      <PieTimer color="var(--primary-color)" width={24} height={24} duration={3000} onComplete={showRemoveButton} />
    </div>
  );

  return (
    <FullScreenModal isOpen={true} close={close}>
      <div className="confirm-remove-bulletin-modal">
        <div className="modal-contents">
          <div className="headline">You sure?</div>
          <div className="headline">Once you delete this bulletin, it can't be undone.</div>
        </div>
        <div className="bottom-buttons">
          <Button text="Cancel" theme="light" onClick={close} />
          {removeButton}
        </div>
      </div>
    </FullScreenModal>
  );
});

const ConfirmRemoveBulletinModalContainer = ({ isOpen, ...props }) => {
  const portalTarget = usePortal("confirm-remove-bulletin-modal-container");
  const contents = isOpen ? <ConfirmRemoveBulletinModal {...props} /> : null;
  return createPortal(contents, portalTarget);
};

export default ConfirmRemoveBulletinModalContainer;
