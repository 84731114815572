import React from "react";
import Input from "../../../-common/Input";
import "./InfoSlide.scss";

const InfoSlide = ({ name, setName, email, setEmail }) => {
  return (
    <div className="slide info-slide">
      <div className="headline">More help is on the way</div>
      <div className="inputs">
        <Input
          value={name}
          onChange={setName}
          label="User name"
          // icon={FiDollarSign}
          // formatter={commaNumber}
          // TO DO: make sure space exists in the name
        />
        <Input
          value={email}
          onChange={setEmail}
          label="User email"
          // icon={FiDollarSign}
          // formatter={commaNumber}
          // TO DO: emailValidator
        />
      </div>
    </div>
  );
};

export default InfoSlide;
