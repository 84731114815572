import React, { useState } from "react";
import { createPortal } from "react-dom";
import { observer } from "mobx-react";
import usePortal from "../../../hooks/usePortal";
import MembershipsStore from "../../../stores/MembershipsStore";
import Modal from "../../-common/Modal";
import InfoSlide from "./InfoSlide";
import "./NewMembershipModal.scss";

const NewMembershipModal = observer(() => {
  const [config, setConfig] = useState({
    activeSlide: 0,
    level: "",
    annualContribution: 0,
    oneTimeContribution: 0,
    pointThreshold: 0
  });

  const updateValue = key => value => setConfig({ ...config, [key]: value });

  const close = () => {
    MembershipsStore.closeNewMembershipModal();
  };

  const finish = async () => {
    const { activeSlide, ...newLevel } = config;
    await MembershipsStore.addMembership(newLevel);
    close();
  };

  const { slides, progress, progressTitle } = {
    slides: [
      <InfoSlide
        level={config.level}
        setLevel={updateValue("level")}
        annualContribution={config.annualContribution}
        setAnnualContribution={updateValue("annualContribution")}
        oneTimeContribution={config.oneTimeContribution}
        setOneTimeContribution={updateValue("oneTimeContribution")}
        pointThreshold={config.pointThreshold}
        setPointThresholdContribution={updateValue("pointThreshold")}
      />
    ],
    progress: [{ title: "Info", description: "Add the new level" }],
    progressTitle: "Add a new level in one easy step"
  };

  return (
    <Modal
      isOpen={true}
      nextEnabled={true}
      slides={slides}
      progress={progress}
      progressTitle={progressTitle}
      activeSlide={config.activeSlide}
      setActiveSlide={updateValue("activeSlide")}
      close={close}
      finish={finish}
    />
  );
});

const NewMembershipModalContainer = ({ isOpen, ...props }) => {
  const portalTarget = usePortal("new-membership-modal-container");
  const contents = isOpen ? <NewMembershipModal {...props} /> : null;
  return createPortal(contents, portalTarget);
};

export default NewMembershipModalContainer;
