import React, { useReducer } from "react";
import { createPortal } from "react-dom";
import { observer } from "mobx-react";
import TenantStore from "../../../../stores/TenantStore";
import usePortal from "../../../../hooks/usePortal";
import Modal from "../../../-common/Modal";
import { organizationDetailsReducer, organizationDetailsInitialState } from "./organizationDetailsReducer";
import WhySlide from "./WhySlide";
import DetailsSlide from "./DetailsSlide";
import AddressSlide from "./AddressSlide";
import "./OrganizationDetailsModal.scss";

const OrganizationDetailsModal = observer(({ close }) => {
  const [state, dispatch] = useReducer(organizationDetailsReducer, organizationDetailsInitialState);
  const setActiveSlide = activeSlide => dispatch({ type: "setActiveSlide", payload: { activeSlide } });

  const finish = async () => {
    let {
      name,
      email,
      taxId,
      street1,
      street2,
      city,
      state: addressState,
      zipCode,
      descriptor,
      descriptorPhone
    } = state;

    taxId = taxId.replace(/[^\d]/g, "");

    const organizationDetails = {
      businessName: name,
      legalName: name,
      email,
      taxId,
      street1,
      street2,
      city,
      state: addressState,
      zipCode,
      descriptorPhone,
      paymentCardDescriptor: descriptor,
      directDebitDescriptor: descriptor
    };
    const { config } = TenantStore?.tenant || {};
    const { zift } = config || {};
    await TenantStore?.updateTenant({
      config: { ...config, zift: { ...zift, organizationDetails } }
    });
    close();
    dispatch({ type: "resetState" });
  };

  const slideProps = { state, dispatch };
  const { slides, progress, progressTitle } = {
    slides: [<WhySlide {...slideProps} />, <DetailsSlide {...slideProps} />, <AddressSlide {...slideProps} />],
    progress: [
      { title: "Why", description: "Why do we need this?" },
      { title: "Details", description: "Add department details" },
      { title: "Address", description: "Add department address" }
    ],
    progressTitle: "Three steps and you're there"
  };

  return (
    <Modal
      nextEnabled={state.nextEnabled}
      slides={slides}
      progress={progress}
      progressTitle={progressTitle}
      activeSlide={state.activeSlide}
      isOpen={true}
      setActiveSlide={setActiveSlide}
      close={close}
      finish={finish}
    />
  );
});

const OrganizationDetailsModalContainer = ({ isOpen, ...props }) => {
  const portalTarget = usePortal("legal-info-modal-container");
  const contents = isOpen ? <OrganizationDetailsModal {...props} /> : null;
  return createPortal(contents, portalTarget);
};

export default OrganizationDetailsModalContainer;
