import React, { useReducer } from "react";
import { createPortal } from "react-dom";
import { observer } from "mobx-react";
import card from "creditcard";
import usePortal from "../../../../hooks/usePortal";
import Modal from "../../../-common/Modal";
import { addPaymentReducer, addPaymentInitialState } from "./addPaymentReducer";
import PaymentInfoSlide from "./PaymentInfoSlide";
import DetailsSlide from "./DetailsSlide";
import VerificationSlide from "./VerificationSlide";
import processPayment from "../../../../services/PaymentService";
import "./AddPaymentModal.scss";

const AddPaymentModal = observer(({ close, clubMember }) => {
  const [state, dispatch] = useReducer(addPaymentReducer, addPaymentInitialState);
  const setActiveSlide = activeSlide => dispatch({ type: "setActiveSlide", payload: { activeSlide } });

  const finish = async () => {
    const { amount, cardNumber, cvv, zip, expDate, frequency, fund, transactionType } = state;
    const { userId } = clubMember;
    const brand = card.cardscheme(cardNumber);
    await processPayment({ userId, amount, brand, cardNumber, cvv, zip, expDate, frequency, fund, transactionType });
    close();
    dispatch({ type: "resetState" });
  };

  const slideProps = { state, dispatch, clubMember };
  const { slides, progress, progressTitle } = {
    slides: [
      <DetailsSlide {...slideProps} />,
      <PaymentInfoSlide {...slideProps} />,
      <VerificationSlide {...slideProps} />
    ],
    progress: [
      { title: "Details", description: "How will we use these funds?" },
      { title: "Card Info", description: "The essentials" },
      { title: "Verify", description: "Does everything look right?" }
    ],
    progressTitle: "Three steps and you're to the bank"
  };

  return (
    <Modal
      nextEnabled={state.nextEnabled}
      slides={slides}
      progress={progress}
      progressTitle={progressTitle}
      activeSlide={state.activeSlide}
      isOpen={true}
      setActiveSlide={setActiveSlide}
      close={close}
      finish={finish}
    />
  );
});

const AddPaymentModalContainer = ({ isOpen, ...props }) => {
  const portalTarget = usePortal("add-payment-modal-container");
  const contents = isOpen ? <AddPaymentModal {...props} /> : null;
  return createPortal(contents, portalTarget);
};

export default AddPaymentModalContainer;
