import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import { request } from "../../utils";
import { parse } from "query-string";
import TenantStore from "../../stores/TenantStore";
import "./OauthComplete.scss";

const OauthComplete = ({ location }) => {
  const { search } = location;
  useEffect(() => {
    const { code } = parse(search);

    async function sendStripeCode(code) {
      const updatedTenant = await request.post("/v1/oauth/stripe", {
        body: { code }
      });
      TenantStore.setTenant(updatedTenant);
      setTimeout(() => navigate("/settings/payments"), 3000);
    }

    if (code) sendStripeCode(code);
  }, [search]);
  return (
    <div className="oauth-container">
      <div className="title">Thank you for completing the Stripe account form</div>
    </div>
  );
};

export default observer(OauthComplete);
