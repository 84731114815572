import React from "react";
import Label from "../../../../-common/Label";
import currencyFormatter from "../../../../../utils/currencyFormatter";
import "./VerificationSlide.scss";

const VerificationSlide = ({ state, clubMember }) => {
  const { transactionType, fund, amount, frequency, cardNumber } = state;
  const {
    profile: { name }
  } = clubMember;

  const breakdownCalculation = () => {
    let divider;
    if (frequency.value === "Every 3 Months") divider = 4;
    else if (frequency.value === "Monthly") divider = 12;
    else return "";

    const breakdown = amount / 100 / divider;
    const formattedBreakdown = `(${currencyFormatter(breakdown)}/${frequency.value})`;
    return formattedBreakdown;
  };

  return (
    <div className="slide verification-slide">
      <div className="headline">Verify Donation Details</div>
      <div className="inputs">
        <div className="details-container">
          <Label value="Donation Details" />
          <div className="info-line">{name}</div>
          <div className="info-line">{transactionType?.value}</div>
          <div className="info-line">Account Fund: {fund?.value}</div>
        </div>

        <div className="details-container">
          <Label value="Amount" />
          <div className="info-line">{currencyFormatter(amount / 100)}</div>
          <div className="info-line">{breakdownCalculation()}</div>
        </div>

        <div className="details-container">
          <Label value="Payment Details" />
          <div className="info-line">Credit Card</div>
          <div className="info-line">{cardNumber}</div>
        </div>
      </div>
    </div>
  );
};

export default VerificationSlide;
