import React from 'react';

import FileUpload from '../FileUpload';
import './MediaSlide.scss';

const supportedImageExtensionsCommaSeparated = 'image/png, image/jpeg';

const MediaSlide = ({
  fileURL,
  onUpload,
  headline,
  uploadLabelPrefix,
  isSupportingVideo,
}) => {
  const supportedExtensions = isSupportingVideo
    ? `${supportedImageExtensionsCommaSeparated}, video/mp4`
    : supportedImageExtensionsCommaSeparated;

  return (
    <div className="slide media-slide">
      <div className="headline">{headline}</div>
      <div className="inputs">
        <FileUpload
          fileURL={fileURL}
          onUpload={onUpload}
          label={isSupportingVideo ? `${uploadLabelPrefix} Photo or Video` : `${uploadLabelPrefix} Photo`}
          accept={supportedExtensions}
        />
      </div>
      <span className="upload-details">{`Supported File Extensions: ${supportedExtensions}`}</span>
      <span className="upload-details">Recommended Image Size: 1MB or less</span>
      {isSupportingVideo && <span className="upload-details">Recommended Video Size: 45MB or less</span>}
    </div>
  );
};

export default MediaSlide;
