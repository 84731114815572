import React, { useState, useEffect } from "react";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import CampaignsStore from "../../../stores/CampaignsStore";
import Page from "../../-common/Page";
import RadioGroup from "../../-common/RadioGroup";
import "./Extras.scss";

const extrasOptions = [
  { label: "Let Nickel handle it", value: "nickel" },
  { label: "Don't show it", value: "none" },
  { label: "Take care of it yourself", value: "tenant", disabled: true }
];

const close = () => navigate("/campaigns/manage");

function Extras({ campaignId }) {
  CampaignsStore.setSelectedCampaign(campaignId);
  const { loading: campaignsLoading, campaigns } = CampaignsStore;
  const [campaign, setCampaign] = useState({});
  useEffect(() => {
    if (!campaignsLoading) {
      let campaign = campaigns.find(c => c.campaignId === campaignId);
      if (campaign) {
        campaign = toJS(campaign, { recurseEverything: true });
        setCampaign(campaign);
      } else if (CampaignsStore.campaigns.length) {
        close();
      }
    }
  }, [campaignsLoading, campaigns, campaignId, setCampaign]);

  const titleOptions = { title: "Extras" };

  const change = type => {
    CampaignsStore.setSuggestedCampaignsTypeForCampaign(campaignId, type);
  };

  return (
    <Page titleOpts={titleOptions}>
      <div className="campaign-extras">
        <div className="extras-section">
          <div className="extras-title">
            How would you like to handle the "Donations you might like" section?
          </div>
          <div className="extras-options">
            <RadioGroup
              id="campaign-extras-option"
              options={extrasOptions}
              selected={campaign?.suggestedCampaignsType || "nickel"}
              onChange={change}
            />
          </div>
        </div>
      </div>
    </Page>
  );
}

export default observer(Extras);
