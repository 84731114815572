import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { FaMoneyBill, FaFilter, FaBolt, FaBullhorn, FaUsers, FaStar } from "react-icons/fa";
import CampaignsStore from "../../../stores/CampaignsStore";
import StatisticsStore from "../../../stores/StatisticsStore";
import Page from "../../-common/Page";
import DropdownButton from "../../-common/DropdownButton";
import NLogo from "../../-common/NLogo";
import RevenueTrend from "./RevenueTrend";
import DonationsReceivedGauge from "./DonationsReceivedGauge";
import LatestDonationsTable from "./LatestDonationsTable";
import CampaignHealthTable from "./CampaignHealthTable";
import "./Statistics.scss";

// SLICERS FOR DASHBOARD
// 1. Date
// 2. Campaign
// 3. Team

const SummaryNumber = ({ icon: Icon, value, title }) => {
  return (
    <div className="summary-number">
      <div className="icon-wrapper">
        <Icon className="summary-icon" size={24} />
      </div>
      <div className="value">{value}</div>
      <div className="title">{title}</div>
      <div className="indicator empty"></div>
    </div>
  );
};

const SummaryNumbers = observer(({ campaignId }) => {
  const { totalCampaignDonors, newCampaignDonors, avgDonationAmount, liveCampaigns } = StatisticsStore;
  const {
    totalCampaignDonors: campaignTotalDonors,
    newCampaignDonors: campaignNewDonors,
    avgDonationAmount: campaignAvgDonationAmount,
    liveCampaigns: campaignLiveCampaigns
  } = StatisticsStore?.campaignSpecificStats;

  const summaryNumbers = [
    { icon: FaBolt, title: "Total Donors", value: campaignId ? campaignTotalDonors : totalCampaignDonors },
    {
      icon: FaBullhorn,
      title: "New Donors",
      value: campaignId ? campaignNewDonors : newCampaignDonors,
      indicator: -0.005
    },
    {
      icon: FaUsers,
      title: "Average Donation",
      value: campaignId ? campaignAvgDonationAmount : avgDonationAmount,
      indicator: 0.03
    },
    {
      icon: FaStar,
      title: "Live Campaigns",
      value: campaignId ? campaignLiveCampaigns : liveCampaigns,
      indicator: 0.003
    }
  ];
  const summaryNumberComponents = summaryNumbers.map((props, i) => <SummaryNumber {...props} key={i} />);
  return <div className="summary-numbers">{summaryNumberComponents}</div>;
});

function Statistics() {
  const filterOptions = [{ label: "All", value: "All" }].concat(
    CampaignsStore?.campaigns
      ?.filter(c => !!c.published)
      ?.map(c => ({ label: c.title, value: c.campaignId }))
      ?.sort((a, b) => (a.label === b.label ? 0 : a.label < b.label ? -1 : 1))
  );

  const [loading, setLoading] = useState(false);
  const [campaign, setCampaign] = useState();
  const { campaignId } = campaign || {};
  useEffect(() => {
    if (campaignId) {
      (async () => {
        setLoading(true);
        const success = await StatisticsStore?.fetchCampaignSpecificStats(campaignId);
        if (!success) setCampaign();
        setLoading(false);
      })();
    }
  }, [campaignId]);

  const updateSelectedCampaign = async ({ value: campaignId }) => {
    setCampaign(CampaignsStore?.campaigns?.find(c => c.campaignId === campaignId));
  };

  const { totalDonated, totalDonationGoal, revenueTrend, campaignHealth, latestDonations } = StatisticsStore;
  const {
    totalDonated: campaignTotalDonated,
    totalDonationGoal: campaignTotalDonationGoal,
    revenueTrend: campaignRevenueTrend,
    campaignHealth: campaignSpecificCampaignHealth,
    latestDonations: campaignLatestDonations
  } = StatisticsStore?.campaignSpecificStats;

  const titleOptions = { title: "Statistics" };

  const content = loading ? (
    <div className="loading">
      <NLogo height={168} />
    </div>
  ) : (
    <>
      <div className="left-col">
        <SummaryNumbers campaignId={campaignId} />
        <RevenueTrend data={(campaignId ? campaignRevenueTrend : revenueTrend) || []} />
        <CampaignHealthTable data={(campaignId ? campaignSpecificCampaignHealth : campaignHealth) || []} />
      </div>
      <div className="right-col">
        <DonationsReceivedGauge
          startAmount={0}
          endAmount={(campaignId ? campaignTotalDonationGoal : totalDonationGoal) || 0}
          filledAmount={(campaignId ? campaignTotalDonated : totalDonated) || 0}
          icon={FaMoneyBill}
        />
        <LatestDonationsTable data={(campaignId ? campaignLatestDonations : latestDonations) || []} />
      </div>
    </>
  );

  return (
    <Page
      titleOpts={titleOptions}
      buttons={[
        <DropdownButton
          label="Filter"
          icon={FaFilter}
          theme="light"
          options={filterOptions}
          style={{ height: 42 }}
          value={{ label: campaign?.title || "All", value: campaign?.campaignId || "All" }}
          onChange={updateSelectedCampaign}
          key="filter"
        />
      ]}
    >
      <div className="dashboard">{content}</div>
    </Page>
  );
}

export default observer(Statistics);
