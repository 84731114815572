import React, { useState, useEffect, useCallback } from "react";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import { format, parseISO } from "date-fns";
// import commaNumber from "comma-number";
import { MdReceipt, MdUndo } from "react-icons/md";
import { FiUser } from "react-icons/fi";
import currencyFormatter from "../../../utils/currencyFormatter";
import TransactionsStore from "../../../stores/TransactionsStore";
import Page from "../../-common/Page";
import Button from "../../-common/Button";
import ResendReceiptModal from "./ResendReceiptModal";
import IssueRefundModal from "./IssueRefundModal";
import "./Transaction.scss";

function TransactionDetails({ userMetadata: user }) {
  let { name, email, address, city, state, zip } = user || {};

  const media = user?.media;
  const rewardImage = media ? (
    <img alt="The user associated with this transaction" src={media} className="transaction-user-profile" />
  ) : (
    <div className="transaction-user-profile">
      <FiUser size={144} color="var(--White)" />
    </div>
  );

  const addressLine2 = city && state && zip ? `${city}, ${state} ${zip}` : null;
  return (
    <div className="transaction-details">
      {rewardImage}
      <div className="detail-item">
        <div className="detail-item-title">{name}</div>
        <div className="detail-item-value">{email}</div>
      </div>
      <div className="detail-item">
        <div className="detail-item-value">{address}</div>
        <div className="detail-item-value">{addressLine2}</div>
      </div>
    </div>
  );
}

function Transaction({ transactionId }) {
  const { transactions } = TransactionsStore;
  const [transaction, setTransaction] = useState({});
  const { refunded, refund } = transaction || {};

  const [resendReceiptModalOpen, setResendReceiptModalOpen] = useState(false);
  const [issueRefundModalOpen, setIssueRefundModalOpen] = useState(false);

  const openResendReceiptModal = useCallback(() => setResendReceiptModalOpen(true), [setResendReceiptModalOpen]);
  const closeResendReceiptModal = useCallback(() => setResendReceiptModalOpen(false), [setResendReceiptModalOpen]);

  const openIssueRefundModal = useCallback(() => setIssueRefundModalOpen(true), [setIssueRefundModalOpen]);
  const closeIssueRefundModal = useCallback(() => setIssueRefundModalOpen(false), [setIssueRefundModalOpen]);

  useEffect(() => {
    const matchingTransaction = transactions.find(p => p.transactionId === transactionId);
    if (matchingTransaction) {
      setTransaction(toJS(matchingTransaction, { recurseEverything: true }));
    } else if (transactions.length) {
      navigate("/audit/transactions");
    }
  }, [transactions, transactionId]);

  const { amountMetadata, processorMetadata, campaign, tier, reward } = transaction || {};
  const { totalDonationAmount: totalDonation } = amountMetadata || {};
  const { card } = processorMetadata || {};

  const refundedStyles = !!refunded ? { color: "var(--Gray400)" } : null;
  const refundIssuerName = `${refund?.issuer?.profile?.firstName} ${refund?.issuer?.profile?.lastName}`;
  const refundedMessage = !!refunded
    ? ` (refunded by ${refundIssuerName} on ${format(parseISO(refund?.date), "MMM d, yyyy")})`
    : "";

  let cardInfo;
  if (card) {
    const { brand, last4, expMonth, expYear } = card;
    const isAmex = brand === "American Express";
    const cardDots = isAmex ? "•••• •••••• •" : "•••• •••• •••• ";

    cardInfo = (
      <div className="card-info">
        <div className="card-number">
          <span className="card-dots" style={isAmex ? null : { marginRight: 8 }}>
            {cardDots}
          </span>
          <span>{last4}</span>
        </div>
        <div className="card-brand-row">
          <div>
            {String(expMonth).padStart(2, "0")}/{expYear}
          </div>
          <div>{brand}</div>
        </div>
      </div>
    );
  }

  let tierSection;
  if (tier) {
    tierSection = (
      <>
        <div className="section-title">Tier</div>
        <div className="section-info">
          <div className="info-bold">{tier?.title}</div>
        </div>
      </>
    );
  }

  let rewardSection;
  if (reward) {
    rewardSection = (
      <>
        <div className="section-title">Reward</div>
        <div className="section-info">
          <div className="info-bold">{reward?.title}</div>
        </div>
      </>
    );
  }

  return (
    <Page
      titleOpts={{
        title: "Transaction",
        backButton: true
      }}
      buttons={
        transaction?.refunded
          ? null
          : [
              <Button text="Resend Receipt" icon={MdReceipt} onClick={openResendReceiptModal} key="receipt" />,
              <Button text="Issue Refund" icon={MdUndo} onClick={openIssueRefundModal} key="refund" />
            ]
      }
    >
      <div className="transaction-detail">
        <TransactionDetails {...transaction} />
        <div className="transaction-info">
          <div className="section-title">Donation</div>
          <div className="section-info">
            <div className="info-bold" style={refundedStyles}>
              {currencyFormatter(totalDonation / 100)} {refundedMessage}
            </div>
            {cardInfo}
          </div>
          <div className="section-title">Campaign</div>
          <div className="section-info">
            <div className="info-bold">{campaign?.title}</div>
          </div>
          {tierSection}
          {rewardSection}
        </div>
      </div>
      <ResendReceiptModal isOpen={resendReceiptModalOpen} transaction={transaction} close={closeResendReceiptModal} />
      <IssueRefundModal isOpen={issueRefundModalOpen} transaction={transaction} close={closeIssueRefundModal} />
    </Page>
  );
}

export default observer(Transaction);
