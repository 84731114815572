import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import ReactPlayer from 'react-player';
import { FiX } from 'react-icons/fi';
import { IoMdEye } from 'react-icons/io';
import { FaImage } from 'react-icons/fa';
import getFileURLFromFile from '../../../utils/getFileURLFromFile';
import useBulletin from '../../../hooks/useBulletin';
import useFileDrop from '../../../hooks/useFileDrop';
import BulletinsStore from '../../../stores/BulletinsStore';
import Page from '../../-common/Page';
import FileUpload from '../../-common/FileUpload';
import Input from '../../-common/Input';
import Select from '../../-common/Select2';
import Textarea from '../../-common/Textarea';
import Button from '../../-common/Button';
import './BulletinContent.scss';
import { isVideoLink } from '../../../utils';
import { bulletinsTypeOptions } from '../../../constants/bulletins';

const BulletinContent = ({ bulletinId }) => {
  const bulletin = useBulletin(bulletinId);
  const { subject: currentSubject, message: currentMessage, media: currentMedia, type: currentType } = bulletin || {};
  const currentImage = isVideoLink(currentMedia) ? null : currentMedia;
  const currentVideo = isVideoLink(currentMedia) ? currentMedia : null;

  const [saving, setSaving] = useState(false);

  const cancel = () => {
    setLocalMedia();
    setLocalType(currentType);
    setLocalSubject(currentSubject);
    setLocalMessage(currentMessage);
  };

  const [localMedia, setLocalMedia] = useState();
  const updateMedia = async file => {
    const preview = await getFileURLFromFile(file, file.type === 'video/mp4');
    setLocalMedia({ file, preview });
  };
  const { HiddenInput, onClick: chooseFile } = useFileDrop(updateMedia);

  const localImage = localMedia?.file.type === 'video/mp4' ? null : localMedia?.preview;
  const localVideo = localMedia?.file.type === 'video/mp4' ? localMedia?.preview : null;

  const [localSubject, setLocalSubject] = useState(currentSubject);
  useEffect(() => {
    if (currentSubject) setLocalSubject(currentSubject);
  }, [currentSubject]);
  const [localMessage, setLocalMessage] = useState(currentMessage);
  useEffect(() => {
    if (currentMessage) setLocalMessage(currentMessage);
  }, [currentMessage]);
  const [localType, setLocalType] = useState(currentType);
  useEffect(() => {
    if (currentType) setLocalType(currentType);
  }, [currentType]);

  const saveEnabled =
    localSubject !== currentSubject ||
    localMessage !== currentMessage ||
    localType !== currentType ||
    !!localMedia?.file ||
    localMedia?.type === 'video';
  const save = async () => {
    console.log(localType);
    if (saveEnabled) {
      setSaving(true);
      await BulletinsStore.updateBulletin(bulletinId, {
        ...bulletin,
        ...(localMedia?.file && { file: localMedia.file }),
        ...(localMedia?.type === 'video' && { video: localMedia?.preview }),
        subject: localSubject,
        message: localMessage,
        type: localType,
      });
      setSaving(false);
    }
  };

  const updateBulletinType = type => {
    setLocalType(type.value);
  };

  const imageButton = <Button key="image" text="Replace Media" onClick={chooseFile} icon={FaImage} />;
  const mediaButtons = localMedia?.preview || currentMedia ? [imageButton] : [];
  const saveButtons = saveEnabled
    ? [
        <Button key="cancel" text="Cancel" icon={FiX} theme="light" onClick={cancel} disabled={saving} />,
        <Button
          key="save"
          text={saving ? 'Saving...' : 'Save'}
          icon={IoMdEye}
          disabled={!saveEnabled || saving}
          onClick={save}
        />,
      ]
    : [];
  const headerButtons = mediaButtons.concat(saveButtons);

  const mediaZeroState = (
    <FileUpload
      accept="image/png, image/jpeg, video/mp4"
      label="Bulletin Image or Video"
      fileURL={localImage || currentImage}
      onUpload={updateMedia}
      style={{ height: 'auto' }}
    />
  );

  const mediaPreview =
    isVideoLink(currentVideo) || localVideo?.includes('data:video/mp4') ? (
      <div className="media-preview video">
        {localVideo ? (
          <video height="100%" controls>
            <source type="video/mp4" src={localVideo} />
          </video>
        ) : (
          <ReactPlayer url={currentVideo} height="100%" width="100%" controls />
        )}
      </div>
    ) : (
      <img className="media-preview" src={localImage || currentImage} alt="Bulletin media." />
    );

  const mediaSection = localMedia?.preview || currentMedia ? mediaPreview : mediaZeroState;

  return (
    <Page titleOpts={{ title: 'Content' }} buttons={headerButtons}>
      <div className="bulletin-content">
        <div className="bulletin-content-col">
          <div className="bulletin-content-col-title">Media</div>
          {mediaSection}
        </div>
        <div className="bulletin-content-col">
          <div className="bulletin-content-col-title">Content</div>
          <Input
            value={localSubject}
            onChange={setLocalSubject}
            label="Subject"
          />
          <Select
            label="Type"
            options={bulletinsTypeOptions}
            onChange={updateBulletinType}
            autoGrow={true}
            currentValue={localType}
            value={bulletinsTypeOptions.filter(option => option.value === localType)}
          />
          <Textarea
            value={localMessage}
            onChange={setLocalMessage}
            label="Message"
            maxLength={1000}
          />
        </div>
      </div>
      <HiddenInput />
    </Page>
  );
};

export default observer(BulletinContent);
