import React from 'react';
import classNames from 'classnames';

import './AuthSubmitButton.scss';

const AuthSubmitButton = ({
  loading,
  children,
  btnStyles,
}) => (
  <button
    className={classNames('auth-button', { 'auth-button--loading': loading })}
    type="submit"
    style={btnStyles}
  >
    {children}
  </button>
);

export default AuthSubmitButton;
