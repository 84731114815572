import React from "react";
import { FiDollarSign, FiHash } from "react-icons/fi";
import Input from "../../../-common/Input";
import CurrencyInput from "../../../-common/CurrencyInput";
import "./InfoSlide.scss";
import { isOnlyNumbersValidator } from "../../../../utils";

const InfoSlide = ({
  level,
  setLevel,
  annualContribution,
  setAnnualContribution,
  oneTimeContribution,
  setOneTimeContribution,
  pointThreshold,
  setPointThresholdContribution
}) => {
  return (
    <div className="slide title-slide">
      <div className="headline">Level up</div>
      <div className="inputs">
        <Input
          value={level}
          onChange={setLevel}
          label="Level Title"
          maxLength={60}
          countCharacters={true}
        />
        <CurrencyInput
          value={annualContribution}
          onChange={setAnnualContribution}
          label="Annual Contribution"
          icon={FiDollarSign}
        />
        <CurrencyInput
          value={oneTimeContribution}
          onChange={setOneTimeContribution}
          label="One-Time Contribution"
          icon={FiDollarSign}
        />
        <Input
          value={pointThreshold}
          onChange={setPointThresholdContribution}
          label="Point Threshold"
          validator={isOnlyNumbersValidator}
          maxLength={16}
          icon={FiHash}
        />
      </div>
    </div>
  );
};

export default InfoSlide;
