import React, { useState, useEffect, useMemo } from "react";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import { format } from "date-fns";
import commaNumber from "comma-number";
import { MdEdit, MdClose } from "react-icons/md";
import RewardsStore from "../../../stores/RewardsStore";
import GlobalSearchStore from "../../../stores/GlobalSearchStore";
import Page from "../../-common/Page";
import Button from "../../-common/Button";
import PlusMinusIcon from "../../-common/PlusMinusIcon";
import LedgerTable from "./LedgerTable";
import "./RewardItemDetail.scss";
import ChangeInventoryModal from "../../-modals/ChangeInventoryModal";

const searchEvents = searchText => e => {
  const search = searchText.toLowerCase();
  const matchesDate = format(e.date, "M/d/yyyy").includes(search);
  const matchesAmount =
    String(e.amount).toLowerCase().includes(search) || commaNumber(e.amount).toLowerCase().includes(search);
  const matchesType = e.type.toLowerCase()?.includes(search);

  let matchesDetails = false;
  if (e?.details?.type === "reserve") {
    matchesDetails = `Campaign: ${e?.details?.campaignTitle}`.toLowerCase().includes(search);
  } else if (e?.details?.type === "purchase") {
    matchesDetails = "Go to Transactions".toLowerCase().includes(search);
  } else if (e?.details?.type === "stock") {
    matchesDetails = `${e.amount > 0 ? "Added" : "Removed"} by ${e?.details?.userFirstName} ${e?.details?.userLastName}`
      .toLowerCase()
      .includes(search);
  }

  return matchesDate || matchesAmount || matchesType || matchesDetails;
};

function makeTableData({ stockEvents = [], purchaseEvents = [], reserveEvents = [] }, searchText) {
  const stockData =
    stockEvents
      ?.map(se => ({ ...se, type: "stock" }))
      ?.map((se, i) => ({
        id: "se-" + i,
        date: new Date(se.date),
        type: `${se.amount > 0 ? "Added" : "Removed"} stock`,
        amount: commaNumber(se.amount > 0 ? se.amount : -se.amount),
        details: se
      })) || [];

  const purchaseData =
    purchaseEvents
      ?.map(pe => ({ ...pe, type: "purchase" }))
      ?.map((pe, i) => ({
        id: "pe-" + i,
        date: new Date(pe.campaignStartDate),
        type: "Purchased via campaign",
        amount: commaNumber(pe.purchasedQuantity),
        details: pe
      })) || [];

  const reserveData =
    reserveEvents
      ?.map(re => ({ ...re, type: "reserve" }))
      ?.map((re, i) => ({
        id: "re-" + i,
        date: new Date(re.campaignStartDate),
        type: "Reserved for campaign",
        amount: commaNumber(re.reservedQuantity),
        details: re
      })) || [];

  const tableData = stockData
    .concat(purchaseData)
    .concat(reserveData)
    .filter(e => !!e.amount)
    .filter(searchEvents(searchText))
    .sort((e1, e2) => {
      return new Date(e2.date).valueOf() - new Date(e1.date).valueOf();
    });

  return tableData;
}

function RewardDetails({ media, reservedQuantity, availableQuantity }) {
  reservedQuantity = reservedQuantity || 0;
  availableQuantity = availableQuantity || 0;
  const rewardImage = media ? (
    <img alt="The reward" src={media} className="reward-media" />
  ) : (
    <div className="reward-media" />
  );
  return (
    <div className="reward-item-details">
      {rewardImage}
      <div className="detail-item">
        <div className="detail-item-title">Reserved</div>
        <div className="detail-item-value">{commaNumber(reservedQuantity)}</div>
      </div>
      <div className="detail-item">
        <div className="detail-item-title">Available</div>
        <div className="detail-item-value">{commaNumber(availableQuantity)}</div>
      </div>
    </div>
  );
}

function RewardItem({ rewardId }) {
  const { allRewards } = RewardsStore;
  const [rewardItem, setRewardItem] = useState({});
  useEffect(() => {
    const matchingRewardItem = allRewards.find(p => p.rewardId === rewardId);
    if (matchingRewardItem) {
      setRewardItem(toJS(matchingRewardItem, { recurseEverything: true }));
    } else if (allRewards.length) {
      navigate("/campaigns/rewards");
    }
  }, [allRewards, rewardId]);

  const [changeInventoryOpen, setChangeInventoryOpen] = useState(false);
  const openChangeInventory = () => setChangeInventoryOpen(true);
  const closeChangeInventory = () => setChangeInventoryOpen(false);

  const removeRewardItem = async () => RewardsStore.removeReward(rewardId);

  const { searchText } = GlobalSearchStore;
  const data = useMemo(() => makeTableData(rewardItem, searchText), [rewardItem, searchText]);

  return (
    <Page
      titleOpts={{
        title: rewardItem.title,
        backButton: true
      }}
      buttons={[
        <Button text="Change" icon={PlusMinusIcon} onClick={openChangeInventory} key="add" />,
        <Button text="Edit" icon={MdEdit} key="edit" />,
        <Button text="Remove" icon={MdClose} onClick={removeRewardItem} key="remove" />
      ]}
    >
      <div className="reward-item-detail">
        <RewardDetails {...rewardItem} />
        <LedgerTable data={data} />
      </div>
      <ChangeInventoryModal isOpen={changeInventoryOpen} close={closeChangeInventory} reward={rewardItem} />
    </Page>
  );
}

export default observer(RewardItem);
