import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Router, Redirect, navigate } from "@reach/router";
import { FaFilter, FaSortAmountDown } from "react-icons/fa";
import { GoPlus } from "react-icons/go";
import commaNumber from "comma-number";
import RewardsStore, {
  sortOptions,
  filterOptions
} from "../../../stores/RewardsStore";
import GlobalSearchStore from "../../../stores/GlobalSearchStore";
import Page from "../../-common/Page";
import RewardItemDetail from "../RewardItemDetail";
import DropdownButton from "../../-common/DropdownButton";
import Button from "../../-common/Button";
import "./Rewards.scss";

const RewardsItemDetail = ({
  rewardId,
  title,
  description,
  availableQuantity = 0,
  reservedQuantity = 0,
  claimedQuantity = 0,
  media
}) => {
  const banner = media ? (
    <img src={media} className="banner-image" alt="The reward item." />
  ) : (
    <div className="banner-placeholder" />
  );

  return (
    <div
      className="reward-square"
      onClick={() => navigate(`rewards/${rewardId}`)}
    >
      {banner}
      <div className="info-wrapper">
        <div className="titles">
          <div className="info-title">{title}</div>
          <div className="info-description">{description}</div>
        </div>
        <div className="metrics">
          <div className="metric-wrapper total">
            <div className="metric">{commaNumber(availableQuantity)}</div>
            <div className="label">Available</div>
          </div>
          <div className="metric-wrapper reserved">
            <div className="metric">{commaNumber(reservedQuantity)}</div>
            <div className="label">Reserved</div>
          </div>
          <div className="metric-wrapper ava">
            <div className="metric">{commaNumber(claimedQuantity)}</div>
            <div className="label">Claimed</div>
          </div>
        </div>
      </div>
    </div>
  );
};

function RewardsSection({ title, data = [], noTopMargin }) {
  if (data.length) {
    const squares = data.map(r => (
      <RewardsItemDetail {...r} key={r.rewardId} />
    ));
    const titleStyles = noTopMargin ? { marginTop: 0 } : null;
    return (
      <>
        <div className="reward-squares-title" style={titleStyles}>
          {title}
        </div>
        <div className="reward-squares">{squares}</div>
      </>
    );
  }
  return null;
}

const Rewards = observer(() => {
  useEffect(() => {
    GlobalSearchStore.setSearchAction(searchText => {
      RewardsStore.setSearch(searchText);
    });
  }, []);

  const { filter, sort, rewards, rewardsByCategory } = RewardsStore;

  const updateFilter = filter => RewardsStore.setFilter(filter);
  const updateSort = sort => RewardsStore.setSort(sort);

  const titleOptions = { title: "Rewards" };

  const rewardSquares =
    filter.value === "All" ? (
      <>
        <RewardsSection
          title={`Physical Goods (${rewardsByCategory?.physical?.length || 0})`}
          data={rewardsByCategory.physical}
          noTopMargin={true}
        />
        <RewardsSection
          title={`Experiences (${rewardsByCategory?.experience?.length || 0})`}
          data={rewardsByCategory.experience}
        />
        <RewardsSection
          title={`Sponsorship or Discount Codes (${rewardsByCategory
            ?.sponsorship?.length || 0})`}
          data={rewardsByCategory.sponsorship}
        />
        <RewardsSection
          title={`Contests (${rewardsByCategory?.contest?.length || 0})`}
          data={rewardsByCategory.contest}
        />
      </>
    ) : (
      <RewardsSection title=" " data={rewards} noTopMargin={true} />
    );

  return (
    <Page
      titleOpts={titleOptions}
      buttons={[
        <DropdownButton
          label="Filter"
          icon={FaFilter}
          theme="light"
          options={filterOptions}
          style={{ height: 42 }}
          value={filter}
          onChange={updateFilter}
          key="filter"
        />,
        <DropdownButton
          label="Sort"
          icon={FaSortAmountDown}
          theme="light"
          options={sortOptions}
          style={{ height: 42 }}
          value={sort}
          onChange={updateSort}
          key="sort"
        />,
        <Button
          text="Add Reward"
          icon={GoPlus}
          theme="dark"
          key="add-rewards"
          onClick={RewardsStore.openNewRewardModal}
        />
      ]}
    >
      <div className="rewards">{rewardSquares}</div>
    </Page>
  );
});

export default function RewardsRouter() {
  return (
    <Router>
      <Rewards path="/" />
      <RewardItemDetail path=":rewardId" />
      <Redirect from="*" to="/campaigns/rewards" noThrow />
    </Router>
  );
}
