import React, {
  useState,
  useEffect,
} from 'react';
import { observer } from 'mobx-react';
import { toast } from 'react-toastify';
import {
  MdCheck,
  MdClose,
} from 'react-icons/md';

import TenantStore from '../../../stores/TenantStore';
import UsersStore from '../../../stores/UsersStore';
import Page from '../../-common/Page';
import Button from '../../-common/Button';
import Select from '../../-common/Select2';

const titleOptions = { title: 'Default Development Officer' };

function DefaultOfficer() {
  const [localDefaultOfficer, setLocalDefaultOfficer] = useState();

  const { defaultDevelopmentOfficerId } = TenantStore?.tenant || {};
  useEffect(() => {
    if (defaultDevelopmentOfficerId) setLocalDefaultOfficer(defaultDevelopmentOfficerId);
  }, [defaultDevelopmentOfficerId]);

  const [saving, setSaving] = useState(false);

  const save = async () => {
    setSaving(true);
    const success = await TenantStore.updateTenant({ defaultDevelopmentOfficerId: localDefaultOfficer });
    if (!!success) toast('Default officer updated.');
    else toast('Error updating default officer.');
    setSaving(false);
  };

  const cancel = () => setLocalDefaultOfficer(defaultDevelopmentOfficerId);

  const conditionalButtons =
    localDefaultOfficer && localDefaultOfficer !== defaultDevelopmentOfficerId
      ? [
          <Button
            text="Cancel"
            icon={MdClose}
            theme="light"
            onClick={cancel}
            disabled={saving}
            key="cancel"
          />,
          <Button
            text={saving ? 'Saving...' : 'Save'}
            theme={saving ? 'light' : null}
            icon={MdCheck}
            onClick={save}
            disabled={saving}
            key="save"
          />,
        ]
      : null;

  const updateDefaultOfficer = type => {
    setLocalDefaultOfficer(type.value);
  };

  const usersListOptions = UsersStore.users.map(user => ({
    label: `${user.profile.firstName} ${user.profile.lastName}`,
    value: user.userId,
  }));

  return (
    <Page
      titleOpts={titleOptions}
      buttons={conditionalButtons}
    >
      <div className="dxm-settings-container">
        <div className="theme-section">
          <div className="theme-section-items">
            <div className="theme-item">
              <div className="theme-item-titles">
                <div className="theme-item-title">Default Officer</div>
                <div className="theme-item-subtitle">
                  Selected person will be assigned to donors as the development officer by default, unless the donor is
                  already assigned to someone else manually in Club Hub explore tab.
                </div>
              </div>
              <div className="theme-item-content theme-item-content--with-dropdown">
                <Select
                  options={usersListOptions}
                  onChange={updateDefaultOfficer}
                  autoGrow={true}
                  currentValue={localDefaultOfficer}
                  value={usersListOptions.filter(option => option.value === localDefaultOfficer)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default observer(DefaultOfficer);
