import React, { useState, useCallback } from "react";
import { createPortal } from "react-dom";
import { observer } from "mobx-react";
import usePortal from "../../../../../hooks/usePortal";
import PerksStore from "../../../../../stores/PerksStore";
import FullScreenModal from "../../../../-common/FullScreenModal";
import Button from "../../../../-common/Button";
import PieTimer from "../../../../-common/PieTimer";
import "./ConfirmRemovePerkModal.scss";

const ConfirmRemovePerkModal = observer(({ close, perkId }) => {
  const [removeEnabled, setRemoveEnabled] = useState(false);

  const showRemoveButton = useCallback(() => {
    setRemoveEnabled(true);
  }, [setRemoveEnabled]);

  const remove = useCallback(async () => {
    close();
    PerksStore.deletePerk(perkId);
  }, [close, perkId]);

  const removeButton = removeEnabled ? (
    <Button text="Remove Perk" onClick={remove} />
  ) : (
    <div className="timer-container">
      <PieTimer
        color="var(--primary-color)"
        width={24}
        height={24}
        duration={3000}
        onComplete={showRemoveButton}
      />
    </div>
  );

  return (
    <FullScreenModal isOpen={true} close={close}>
      <div className="confirm-remove-perk-modal">
        <div className="modal-contents">
          <div className="headline">You sure?</div>
          <div className="headline">
            Once you remove this perk, it can't be undone.
          </div>
        </div>
        <div className="bottom-buttons">
          <Button text="Cancel" theme="light" onClick={close} />
          {removeButton}
        </div>
      </div>
    </FullScreenModal>
  );
});

const ConfirmRemovePerkModalContainer = ({ isOpen, ...props }) => {
  const portalTarget = usePortal("confirm-remove-perk-modal-container");
  const contents = isOpen ? <ConfirmRemovePerkModal {...props} /> : null;
  return createPortal(contents, portalTarget);
};

export default ConfirmRemovePerkModalContainer;
