import React, { useReducer, useCallback } from "react";
import { observer } from "mobx-react";
import { tierReducer, tierInitialState } from "../../../reducers/TierReducer";
import CampaignsStore from "../../../stores/CampaignsStore";
import RewardsStore from "../../../stores/RewardsStore";
import AppStateStore from "../../../stores/AppStateStore";
import getFileURLFromFile from "../../../utils/getFileURLFromFile";
import Modal from "../../-common/Modal";
import MediaSlide from "../../-common/MediaSlide";
import TitleSlide from "./TitleSlide";
import RewardsSlide from "./RewardsSlide";
import "./NewTierModal.scss";

const NewTierModal = () => {
  const [state, dispatch] = useReducer(tierReducer, tierInitialState);

  const rewardsOptions = (RewardsStore.allRewardsByCategory[state?.rewardCategory?.value] || []).map((item) => {
    return { label: item.title, value: item.rewardId, item };
  });

  const updateValue = useCallback(
    (field) => (value) => {
      const actionType = "set" + field[0].toUpperCase() + field.slice(1);

      dispatch({ type: actionType, payload: { [field]: value } });
    },
    [],
  );

  const uploadFile = async (file) => {
    const fileURL = await getFileURLFromFile(file);

    dispatch({ type: "setFile", payload: { file } });
    dispatch({ type: "setFileURL", payload: { fileURL } });
  };

  const finish = async () => {
    const {
      reward,
      title,
      description,
      donationAmount,
      quantity = 0,
      footnoteLabel,
      footnoteText,
      file,
    } = state;
    AppStateStore.setLoading(true);

    await CampaignsStore.addTier({
      rewardId: reward.rewardId,
      title,
      description,
      donationAmount,
      quantity,
      footnoteLabel,
      footnoteText,
    }, file);

    close();
    AppStateStore.setLoading(false);
  };

  const close = () => {
    dispatch({ type: "resetState", payload: null });
    CampaignsStore.closeNewTierModal();
  };

  const { slides, progress, progressTitle } = {
    slides: [
      <TitleSlide
        title={state.title}
        setTitle={updateValue("title")}
        description={state.description}
        setDescription={updateValue("description")}
        donationAmount={state.donationAmount}
        setDonationAmount={updateValue("donationAmount")}
      />,
      <MediaSlide
        fileURL={state.fileURL}
        onUpload={uploadFile}
        headline="Let's get it started."
        uploadLabelPrefix="Cover"
      />,
      <RewardsSlide
        typeOptions={RewardsStore.rewardOptions}
        rewardCategory={state.rewardCategory}
        setRewardCategory={updateValue("rewardCategory")}
        options={rewardsOptions}
        reward={state.reward}
        setReward={updateValue("reward")}
        quantity={state.quantity}
        setQuantity={updateValue("quantity")}
      />,
    ],
    progress: [
      { title: "Tier", description: "Get the tier ready" },
      { title: "Media", description: "Choose your media" },
      { title: "Reward", description: "Add reward" },
    ],
    progressTitle: "Build your Tier in 3 simple steps",
  };

  return (
    <Modal
      nextEnabled={state.nextEnabled}
      slides={slides}
      progress={progress}
      progressTitle={progressTitle}
      activeSlide={state.activeSlide}
      isOpen={CampaignsStore.showNewTierModal}
      setActiveSlide={updateValue("activeSlide")}
      close={close}
      finish={finish}
    />
  );
};

export default observer(NewTierModal);
