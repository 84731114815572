import React from "react";

export default function volleyballNet({
  size = 24,
  fill = "none",
  stroke = "#000",
  strokeWidth = 13
}) {
  return (
    <svg
      viewBox="0 0 361.667 361.667"
      height={size}
      width={size}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
    >
      <circle cx="252.736" cy="85.76" r="27.662" />
      <polyline points="180.833,140.269 180.833,174.999 180.833,213.744 180.833,252.498" />
      <polyline points="224.417,140.269 224.417,174.999 224.417,213.744 224.417,252.498" />
      <polyline points="311.583,140.269 311.583,174.999 311.583,213.744 311.583,252.498" />
      <polyline points="268,140.269 268,174.999 268,213.744 268,252.498" />
      <polyline points="137.25,140.269 137.25,174.999 137.25,213.744 137.25,252.498" />
      <polyline points="93.667,140.269 93.667,174.999 93.667,213.744 93.667,252.498" />
      <polyline points="50.083,140.269 50.083,174.999 50.083,213.744 50.083,252.498" />
      <polyline
        strokeLinecap="round"
        strokeLinejoin="round"
        points="6.5,303.569 6.5,247.179 6.5,190.804 6.5,140.269 355.167,140.269 355.167,190.804 355.167,247.179 355.167,303.569"
      />
      <path d="M6.5,252.498L6.5,252.498 c13.005,9.407,30.578,9.407,43.583,0l0,0l0,0c13.005,9.407,30.578,9.407,43.583,0l0,0l0,0c13.005,9.407,30.578,9.407,43.583,0l0,0 l0,0c13.005,9.407,30.578,9.407,43.583,0l0,0l0,0c13.005,9.407,30.578,9.407,43.583,0l0,0l0,0c13.005,9.407,30.578,9.407,43.583,0 l0,0l0,0c13.005,9.407,30.578,9.407,43.583,0v0l0,0c13.005,9.407,30.578,9.407,43.583,0l0,0" />
      <path d="M6.5,213.812L6.5,213.812 c13.005,9.407,30.578,9.407,43.583,0l0,0l0,0c13.005,9.407,30.578,9.407,43.583,0l0,0l0,0c13.005,9.407,30.578,9.407,43.583,0l0,0 l0,0c13.005,9.407,30.578,9.407,43.583,0l0,0l0,0c13.005,9.407,30.578,9.407,43.583,0l0,0l0,0c13.005,9.407,30.578,9.407,43.583,0 l0,0l0,0c13.005,9.407,30.578,9.407,43.583,0v0l0,0c13.005,9.407,30.578,9.407,43.583,0l0,0" />
      <path d="M6.5,175.125L6.5,175.125 c13.005,9.407,30.578,9.407,43.583,0l0,0l0,0c13.005,9.407,30.578,9.407,43.583,0l0,0l0,0c13.005,9.407,30.578,9.407,43.583,0l0,0 l0,0c13.005,9.407,30.578,9.407,43.583,0l0,0l0,0c13.005,9.407,30.578,9.407,43.583,0l0,0l0,0c13.005,9.407,30.578,9.407,43.583,0 l0,0l0,0c13.005,9.407,30.578,9.407,43.583,0v0l0,0c13.005,9.407,30.578,9.407,43.583,0l0,0" />
    </svg>
  );
}
