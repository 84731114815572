import React from "react";
import { FiFacebook, FiInstagram, FiTwitter, FiX } from "react-icons/fi";
import initiateAuth from "../../../../services/SocialService";
import "./SocialSlide.scss";

const SocialSlide = ({
  role,
  setRole,
  options,
  onChange,
  deleteCurrentOauth,
  social
}) => {
  const processOauth = platform => () => {
    initiateAuth(platform, onChange);
  };

  const deleteOauth = platform => e => {
    e.stopPropagation();
    deleteCurrentOauth(platform);
  };

  const facebookObject = (social || []).find(s => s.platform === "facebook");
  const facebookLoginComponent = !!facebookObject ? (
    <div className="social-container">
      <div className="social-tile" onClick={processOauth("facebook")}>
        <div className="social-delete" onClick={deleteOauth("twitter")}>
          <FiX size={24} />
        </div>
        <img
          alt="Facebook profile"
          className="social-profile-picture"
          src={facebookObject.media}
        />
      </div>
      <div className="social-username">{`${facebookObject.username}`}</div>
    </div>
  ) : (
    <div className="social-provider" onClick={processOauth("facebook")}>
      <FiFacebook className="icon facebook" size={48} />
    </div>
  );

  const twitterObject = (social || []).find(s => s.platform === "twitter");
  const twitterLoginComponent = !!twitterObject ? (
    <div className="social-container">
      <div className="social-tile" onClick={processOauth("twitter")}>
        <div className="social-delete" onClick={deleteOauth("twitter")}>
          <FiX size={24} />
        </div>
        <img
          alt="Twitter profile"
          className="social-profile-picture"
          src={twitterObject.media}
        />
      </div>
      <div className="social-username">{`@${twitterObject.username}`}</div>
    </div>
  ) : (
    <div className="social-provider" onClick={processOauth("twitter")}>
      <FiTwitter className="icon twitter" size={48} />
    </div>
  );

  return (
    <div className="slide social-slide">
      <div className="headline">Connect to Social.</div>
      <div className="inputs">
        <div className="social-auth">
          {facebookLoginComponent}
          {twitterLoginComponent}
          <div className="social-provider" onClick={processOauth("instagram")}>
            <FiInstagram className="icon instagram" size={48} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialSlide;
