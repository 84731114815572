import React, { useState } from "react";
import { observer } from "mobx-react";
import { FiCheck } from "react-icons/fi";
// import { STRIPE_CLIENT_ID } from "../../../constants";
import TenantStore from "../../../stores/TenantStore";
// import InstanceConfigStore from "../../../stores/InstanceConfigStore";
import Page from "../../-common/Page";
import Button from "../../-common/Button";
import FinancialContactModal from "./FinancialContactModal";
import OrganizationDetailsModal from "./OrganizationDetailsModal";
import BankDetailsModal from "./BankDetailsModal";
import "./PaymentProcessor.scss";

const totalTasks = 3;

const PaymentProcessor = () => {
  const titleOptions = { title: "Payment Processor" };
  const { merchantSetUp, financialContact, organizationDetails } = TenantStore?.tenant?.config?.zift || {};
  const completedTasks = !!financialContact + !!organizationDetails + !!merchantSetUp;

  const [financialContactModalOpen, setFinancialContactModalOpen] = useState(false);
  const [organizationDetailsModalOpen, setOrganizationDetailsModalOpen] = useState(false);
  const [bankDetailsModalOpen, setBankDetailsModalOpen] = useState(false);

  const openFinancialContactModal = () => setFinancialContactModalOpen(true);
  const closeFinancialContactModal = () => setFinancialContactModalOpen(false);

  const openOrganizationDetailsModal = () => setOrganizationDetailsModalOpen(true);
  const closeOrganizationDetailsModal = () => setOrganizationDetailsModalOpen(false);

  const openBankDetailsModal = () => setBankDetailsModalOpen(true);
  const closeBankDetailsModal = () => setBankDetailsModalOpen(false);

  const financialContactCheckmark = !!financialContact ? (
    <div className="inline-icon">
      <FiCheck size={28} color="var(--Green)" strokeWidth={2.5} />
    </div>
  ) : null;
  const financialContactButton = !financialContact ? (
    <Button text="Add Contact" onClick={openFinancialContactModal} />
  ) : null;

  const organizationDetailsCheckmark = !!organizationDetails ? (
    <div className="inline-icon">
      <FiCheck size={28} color="var(--Green)" strokeWidth={2.5} />
    </div>
  ) : null;
  const organizationDetailsButton = !organizationDetails ? (
    <Button text="Add Detail" onClick={openOrganizationDetailsModal} />
  ) : null;

  const bankDetailsCheckmark = !!merchantSetUp ? (
    <div className="inline-icon">
      <FiCheck size={28} color="var(--Green)" strokeWidth={2.5} />
    </div>
  ) : null;
  const bankDetailsButton = !merchantSetUp ? (
    <Button text="Add Bank" onClick={openBankDetailsModal} disabled={!financialContact || !organizationDetails} />
  ) : null;

  return (
    <>
      <Page titleOpts={titleOptions}>
        <div className="payment-processor-container">
          <div className="payment-progress">
            You've completed {completedTasks} of {totalTasks} tasks to set up your account.
          </div>
          <div className="payment-title">Financial Contact {financialContactCheckmark}</div>
          {financialContactButton}
          <div className="payment-title">Organization Details {organizationDetailsCheckmark}</div>
          {organizationDetailsButton}
          <div className="payment-title">Bank Account Details {bankDetailsCheckmark}</div>
          {bankDetailsButton}
        </div>
      </Page>
      <FinancialContactModal isOpen={financialContactModalOpen} close={closeFinancialContactModal} />
      <OrganizationDetailsModal isOpen={organizationDetailsModalOpen} close={closeOrganizationDetailsModal} />
      <BankDetailsModal isOpen={bankDetailsModalOpen} close={closeBankDetailsModal} />
    </>
  );
};

export default observer(PaymentProcessor);
