import React, { useState } from "react";
import { observer } from "mobx-react";
import { toast } from "react-toastify";
import { MdCheck, MdClose } from "react-icons/md";
import { FiHash } from "react-icons/fi";
import { getFileURLFromFile } from "../../../utils";
import TenantStore from "../../../stores/TenantStore";
import Page from "../../-common/Page";
import Button from "../../-common/Button";
import FileUpload from "../../-common/FileUpload";
import Input from "../../-common/Input";
import ColorPickerButton from "../../-common/ColorPickerButton";
import "./TicketsInterstitial.scss";

const titleOptions = { title: "Ticket Sponsorship" };

function TicketsInterstitial() {
  const { appConfig } = TenantStore?.tenant || {};

  const [saving, setSaving] = useState(false);
  const [localMedia, setLocalMedia] = useState({});
  const [localBackgroundColor, setLocalBackgroundColor] = useState("");
  const [localTimeVisible, setLocalTimeVisible] = useState();

  const setMedia = async file => {
    const preview = await getFileURLFromFile(file);
    setLocalMedia({ file, preview });
  };

  const cancel = () => {
    setLocalMedia({});
    setLocalBackgroundColor();
    setLocalTimeVisible();
  };

  const media = localMedia?.preview || appConfig?.ticketsInterstitial?.media;
  const backgroundColor = localBackgroundColor || appConfig?.ticketsInterstitial?.backgroundColor;
  const timeVisible = localTimeVisible || appConfig?.ticketsInterstitial?.timeVisible;

  const saveEnabled = localMedia?.file || localBackgroundColor || localTimeVisible;

  const save = async () => {
    setSaving(true);

    if (localBackgroundColor || localTimeVisible) {
      const parametersUpdated = await TenantStore.updateTenant({
        appConfig: { ticketsInterstitial: { backgroundColor, timeVisible } }
      });
      if (!!parametersUpdated) {
        toast("Ticket Sponsor page parameters updated.");
        setLocalBackgroundColor();
        setLocalTimeVisible();
      } else {
        toast("Error updating Ticket Sponsor page parameters.");
      }
    }

    if (localMedia?.file) {
      const imageUpdated = await TenantStore.uploadImages({ ticketsInterstitial: localMedia.file });

      if (imageUpdated) {
        toast("Ticket Sponsor page image updated.");
        setLocalMedia({});
      } else {
        toast("Error updating Ticket Sponsor page image.");
      }
    }

    setSaving(false);
  };

  const conditionalButtons = saveEnabled
    ? [
        <Button text="Cancel" icon={MdClose} theme="light" onClick={cancel} disabled={saving} key="cancel" />,
        <Button
          text={saving ? "Saving..." : "Save"}
          theme={saving ? "light" : null}
          icon={MdCheck}
          onClick={save}
          disabled={saving}
          key="save"
        />
      ]
    : null;

  return (
    <Page titleOpts={titleOptions} buttons={conditionalButtons}>
      <div className="tickets-interstitial-container">
        <div className="theme-item">
          <div className="theme-item-titles">
            <div className="theme-item-title">Promo Image</div>
            <div className="theme-item-subtitle">This will cover most of the page.</div>
          </div>
          <div className="theme-item-content image">
            <FileUpload accept="image/png, image/jpeg" fileURL={media} onUpload={setMedia} />
          </div>
        </div>
        <div className="theme-item">
          <div className="theme-item-titles">
            <div className="theme-item-title">Background Color</div>
            <div className="theme-item-subtitle">
              This will color the overflow on the page that the image doesn't fit into. It's best to use a plain
              background in your promo image that will match this color.
            </div>
          </div>
          <div className="theme-item-content">
            <ColorPickerButton color={backgroundColor || ""} onSelect={setLocalBackgroundColor} />
          </div>
        </div>
        <div className="theme-item">
          <div className="theme-item-titles">
            <div className="theme-item-title">Time Visibile (in seconds)</div>
            <div className="theme-item-subtitle">
              This controls how long the promo is visible before the user is taken to the tickets page.
            </div>
          </div>
          <div className="theme-item-content">
            <Input onChange={setLocalTimeVisible} icon={FiHash} value={timeVisible} placeholder="(seconds)" />
          </div>
        </div>
      </div>
    </Page>
  );
}

export default observer(TicketsInterstitial);
