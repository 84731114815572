import React from "react";
import { STATES } from "../../../../../constants/main";
import Input from "../../../../-common/Input";
import Select from "../../../../-common/Select2";
import "./AddressSlide.scss";

const stateOptions = STATES.map(s => ({ label: s, value: s }));

const AddressSlide = ({ state, dispatch }) => {
  const { street1, street2, city, state: addressState, zipCode } = state;

  const setStreet1 = v => dispatch({ type: "setStreet1", payload: { street1: v } });
  const setStreet2 = v => dispatch({ type: "setStreet2", payload: { street2: v } });
  const setCity = v => dispatch({ type: "setCity", payload: { city: v } });
  const setState = ({ value: v }) => dispatch({ type: "setState", payload: { state: v } });
  const setZipCode = v => {
    v = v?.replace(/[^\d]/g, "");
    if (v.length <= 5) dispatch({ type: "setZipCode", payload: { zipCode: v } });
  };

  return (
    <div className="slide address-slide">
      <div className="headline">Department Address</div>
      <div className="inputs">
        <Input value={street1} onChange={setStreet1} label="Address (line 1)" placeholder="1234 Somewhere Ln." />
        <Input value={street2} onChange={setStreet2} label="Address (line 2)" placeholder="Unit #1" />
        <div className="inputs-shared-row">
          <Input value={city} onChange={setCity} label="City" placeholder="Anytown" />
          <Select
            label="State"
            value={addressState ? { label: addressState, value: addressState } : null}
            onChange={setState}
            options={stateOptions}
          />
          <Input value={zipCode} onChange={setZipCode} label="Zip Code" placeholder="XXXXX" />
        </div>
      </div>
    </div>
  );
};

export default AddressSlide;
