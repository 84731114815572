import { AsYouType } from "libphonenumber-js";

const phoneNumberFormatter = value => {
  if (/(.?\d){4,}/.test(value)) {
    const phoneFormatter = new AsYouType("US");
    const phone = phoneFormatter.input(value);
    return phone;
  } else {
    return value;
  }
};

export default phoneNumberFormatter;
