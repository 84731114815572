import React from 'react';

import { InputErrorIcon } from '@/common/Icons';

import './AuthInput.scss';

const AuthInput = ({
  type,
  placeholder,
  value,
  onChange,
  error,
  theme,
}) => {
  const errorMessage = error ? <p className="nickel-auth-input-error">{error}</p> : null;

  const errorIcon = error && (
    <div className="nickel-auth-input-error-icon">
      <InputErrorIcon />
    </div>
  );

  const errorStyles = error ?
    { borderRight: '6px solid #E0614A' } :
    {};

  return (
    <div className="nickel-auth-input">
      <input
        className={theme}
        style={errorStyles}
        type={type}
        placeholder={placeholder}
        value={value || ''}
        onChange={onChange || (() => null)}
      />
      {errorIcon}
      {errorMessage}
    </div>
  );
};

export default AuthInput;
