import { observable, action, autorun } from "mobx";
import AuthStore from "./AuthStore";

export const keys = {
  "/audit/payouts": "financePagesUnlocked",
  "/audit/transactions": "financePagesUnlocked"
};

class ProtectedContentStore {
  constructor() {
    autorun(() => {
      if (AuthStore.APIReady) {
        try {
          this.financePagesUnlocked = JSON.parse(localStorage.getItem("Nickel:financePagesUnlocked"));
        } catch {}
      } else {
        this.financePagesUnlocked = false;
      }
    });
  }

  @observable financePagesUnlocked = false;

  @action unlockPage(page) {
    const key = keys[page];
    if (page) {
      localStorage.setItem(`Nickel:${key}`, "true");
      this[key] = true;
    }
  }
}

export default new ProtectedContentStore();
