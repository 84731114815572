import React from "react";
import { observer } from "mobx-react";
import { MdMoreVert } from "react-icons/md";
import UsersStore from "../../../../stores/UsersStore";
import "./Notes.scss";
import Button from "../../../-common/Button";
import { format, parseISO } from "date-fns";

const NoteItem = observer(({ departmentUser, message, subject, type, date }) => {
  const src = departmentUser?.media;
  const profilePicture = src ? <img src={src} alt="User profile avatar." className="profile-image" /> : null;
  return (
    <div className="note-item">
      <div className="details-row">
        <div className="profile-picture-wrapper">{profilePicture}</div>
        <div className="user-info">
          <div className="user-name">{departmentUser?.userName}</div>
          <div className="user-position">{departmentUser?.position || "Assigned Dev Officer"}</div>
        </div>
        <div className="system-info">
          <div className="date">{format(parseISO(date), "MMM d, yyyy p")}</div>
          <div className="system-name">{type}</div>
        </div>
        <div className="more-button">
          <MdMoreVert size={24} color="var(--Gray400)" />
        </div>
      </div>
      <div className="message-contents">
        {subject ? (
          <span className="message-subject">
            {subject}:{"  "}
          </span>
        ) : null}
        {message}
      </div>
    </div>
  );
});

function Notes({ clubMember }) {
  const { notes = [] } = clubMember || {};
  const allMonths = notes?.filter(({ date }) => date)?.map(({ date }) => format(parseISO(date), "MMMM yyyy"));
  const months = [...new Set(allMonths)];
  const src = UsersStore?.me?.profile?.picture;
  const profilePicture = src ? <img src={src} alt="User profile avatar." className="profile-image" /> : null;
  const monthLinks = months.map((m, i) => (
    <div className="month-link" key={i}>
      {m}
    </div>
  ));
  const noteItems = notes.map((n, i) => <NoteItem {...n} key={i} />);
  return (
    <div className="notes">
      <div className="notes-title">Create a New Note</div>
      <div className="new-note">
        <div className="profile-picture-wrapper">{profilePicture}</div>
        <textarea className="note-input" placeholder="Click to begin typing..." />
        <div className="button-col">
          <div className="fill" />
          <Button text="Add Note" />
        </div>
      </div>
      <div className="existing-notes">
        <div className="months">
          <div className="months-title">Past Notes</div>
          <div className="month-links">{monthLinks}</div>
        </div>
        <div className="note-items">{noteItems}</div>
      </div>
    </div>
  );
}

export default observer(Notes);
