function hexToRGBA(hex, alpha = null) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (result) {
    const withAlpha = alpha != null;
    const r = parseInt(result[1], 16);
    const g = parseInt(result[2], 16);
    const b = parseInt(result[3], 16);
    return `rgb${withAlpha ? "a" : ""}(${r},${g},${b}${withAlpha ? "," : ""}${
      withAlpha ? alpha : ""
    })`;
  }
}

export default hexToRGBA;
