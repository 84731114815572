import React from "react";
import { observer } from "mobx-react";
import { Table } from "ka-table";
import { navigate } from "@reach/router";
import { FiTrash2, FiUser } from "react-icons/fi";
import { DataType } from "ka-table/enums";
import UsersStore from "../../../../stores/UsersStore";
import GlobalSearchStore from "../../../../stores/GlobalSearchStore";
import "./UsersTable.scss";
import useDimensions from "react-cool-dimensions";

const resendInvite = userObject => e => {
  if (e) e.stopPropagation();
  UsersStore.resendUser(userObject);
};

const UserCell = ({ rowData, value, dispatch }) => {
  const profileDimensions = useDimensions();
  const { width: profileImageWidth, height: profileImageHeight, ref: profileImageRef } = profileDimensions;

  const { userId, email, profile, config } = rowData;
  const { firstName, lastName, picture } = profile || {};
  const { role, status } = config || {};
  const userObject = { userId, email, role };

  const profilePictureStyles = profileImageHeight > profileImageWidth ? { maxWidth: "100%" } : { maxHeight: "100%" };
  const mediaComponent = picture ? (
    <div className="profile-picture">
      <img ref={profileImageRef} style={profilePictureStyles} src={picture} alt="Profile for this user" />
    </div>
  ) : (
    <div className="profile-picture-placeholder">
      <FiUser size={24} className="profile-placeholder-icon" />
    </div>
  );

  const invitationComponent =
    status === "FORCE_CHANGE_PASSWORD" ? (
      <>
        <div className="user-pending">Invited</div>
        <div className="user-resend" onClick={resendInvite(userObject)}>
          resend invitation
        </div>
      </>
    ) : null;

  const sendClickEvent = () => dispatch("Row Click", rowData);

  return (
    <div className="user-cell" onClick={sendClickEvent}>
      {mediaComponent}
      <div className="user-name">
        {firstName} {lastName}
      </div>
      {invitationComponent}
    </div>
  );
};

const EllipsisCell = ({ field, rowData, dispatch }) => {
  const sendClickEvent = () => dispatch("Row Click", rowData);
  return (
    <div className="ellipsis-cell" onClick={sendClickEvent}>
      {rowData[field]}
    </div>
  );
};

const DeleteCell = ({ rowData }) => {
  const { userId, email } = rowData;
  const deleteUser = () => UsersStore.disableUser({ username: email, userId });
  return <FiTrash2 className="delete-cell" onClick={deleteUser} />;
};

const columns = [
  {
    cell: UserCell,
    dataType: DataType.String,
    key: "fullName",
    title: "Name",
    style: { width: 428 }
  },
  {
    cell: EllipsisCell,
    dataType: DataType.String,
    key: "email",
    title: "Email",
    style: {
      minWidth: 0,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    }
  },
  {
    cell: EllipsisCell,
    dataType: DataType.String,
    key: "role",
    title: "Role"
  },
  {
    key: "delete",
    cell: DeleteCell,
    style: { width: 40, textAlign: "center" }
  }
];

const childAttributes = {
  cell: {
    onClick: (_, e) => {
      const { dispatch, rowData } = e.childProps;
      dispatch("Row Click", rowData);
    }
  }
};

function UsersTable({ data, search }) {
  const onTableEvent = async (type, data) => {
    const { userId } = data;
    if (type === "Row Click") {
      navigate(`/settings/users/${userId}`);
    }
  };

  return (
    <div className="users-table-container">
      <Table
        childAttributes={childAttributes}
        columns={columns}
        data={UsersStore.users}
        search={GlobalSearchStore.searchText}
        onEvent={onTableEvent}
        rowKeyField="userId"
      />
    </div>
  );
}

export default observer(UsersTable);
