import React, { useCallback } from "react";
import { Table } from "ka-table";
import { navigate } from "@reach/router";
import { DataType } from "ka-table/enums";
import { format } from "date-fns";
import commaNumber from "comma-number";
import getFieldForTable from "../../../../utils/getFieldForTable";
import NameCell from "../../../-common/NameCell";
import "./PayoutTransactionsTable.scss";

const AmountCell = ({ field, rowData, dispatch }) => {
  const isRefund = rowData?.type === "refund";
  const isFeeRefund = rowData?.type === "fee refund";
  const isFee = field === "amountMetadata.totalFees";
  const blank = isFee && (isRefund || isFeeRefund);

  if (isRefund) field = "refundAmount";
  if (isFeeRefund) field = "feeRefundAmount";

  const amount = getFieldForTable(rowData, field);
  let displayAmount = "$" + commaNumber(Number(amount / 100)?.toFixed(2));
  if (isFee || isRefund) displayAmount = "-" + displayAmount;
  if (blank) displayAmount = "";

  return (
    <div
      className={`amount-cell${isFee || isRefund ? " negative" : ""}`}
      onClick={() => dispatch("Row Click", rowData)}
    >
      {displayAmount}
    </div>
  );
};

const PaymentMethodCell = ({ field, rowData, dispatch }) => {
  const isRefund = rowData?.type === "refund";
  const isFeeRefund = rowData?.type === "fee refund";
  if (isRefund || isFeeRefund) field = "transaction.processorMetadata.card";
  const { brand, last4 } = getFieldForTable(rowData, field) || {};

  return (
    <div className="cell" onClick={() => dispatch("Row Click", rowData)}>
      {brand} ({last4})
    </div>
  );
};

const TxOrRefundNameCell = ({ field, rowData, dispatch }) => {
  const isRefund = rowData?.type === "refund";
  const isFeeRefund = rowData?.type === "fee refund";
  if (isRefund || isFeeRefund) {
    field = "transaction.userMetadata.name";
    const name = getFieldForTable(rowData, field);
    const extra = `(${isFeeRefund ? "fee " : ""}refund)`;
    return (
      <div className="name-cell" onClick={() => dispatch("Row Click", rowData)}>
        {name} {extra}
      </div>
    );
  }

  return <NameCell {...{ field, rowData, dispatch }} />;
};

const columns = [
  {
    dataType: DataType.Date,
    key: "date",
    format: d => format(d, "M/d/yyyy"),
    title: "Date",
    style: { width: "10%" }
  },
  {
    cell: TxOrRefundNameCell,
    dataType: DataType.String,
    key: "userMetadata.name",
    title: "Name",
    style: { width: "25%" }
  },
  {
    cell: PaymentMethodCell,
    dataType: DataType.Object,
    key: "processorMetadata.card",
    title: "Payment Method",
    style: { width: "15%" }
  },
  {
    cell: AmountCell,
    dataType: DataType.Number,
    key: "amountMetadata.totalDonationAmount",
    format: a => "$" + commaNumber(Number(a || 0)?.toFixed(2)),
    title: "Transaction Amount"
  },
  {
    cell: AmountCell,
    dataType: DataType.Number,
    key: "amountMetadata.totalFees",
    format: a => "- $" + commaNumber(Number(a || 0)?.toFixed(2)),
    title: "Processing Fees"
  },
  {
    cell: AmountCell,
    dataType: DataType.Number,
    key: "amountMetadata.netRevenue",
    format: a => "$" + commaNumber(Number(a || 0)?.toFixed(2)),
    title: "Total"
  }
];

const childAttributes = {
  cell: {
    onClick: (_, e) => {
      const { dispatch, rowData } = e.childProps;
      dispatch("Row Click", rowData);
    }
  }
};

function PayoutTransactionsTable({ data, search, payout }) {
  const formattedPayoutDate = format(new Date(payout.remittanceDate), "MMM dd, yyyy @ hh:mm aaa");
  const onTableEvent = useCallback((type, data) => {
    if (type === "Row Click") {
      const rowData = data;
      const { transactionId } = rowData;
      navigate(`/audit/transactions/${transactionId}`);
    }
  }, []);

  return (
    <div className="payout-transactions-table-container">
      <div className="payout-transaction-table-title-container">
        <div className="payout-transactions-table-title">Transactions</div>
        <div className="payout-transactions-table-payout-date">{`Payout date: ${formattedPayoutDate}`}</div>
      </div>
      <Table
        childAttributes={childAttributes}
        columns={columns}
        data={data}
        search={search}
        onEvent={onTableEvent}
        rowKeyField="key"
      />
    </div>
  );
}

export default PayoutTransactionsTable;
