import React from "react";
import getFieldForTable from "../../../utils/getFieldForTable";
import "./NameCell.scss";

export default function NameCell({ field, rowData, dispatch }) {
  return (
    <div className="name-cell" onClick={() => dispatch("Row Click", rowData)}>
      {getFieldForTable(rowData, field)}
    </div>
  );
}
