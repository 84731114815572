import React from "react";
import { observer } from "mobx-react";
import Page from "../../-common/Page";
import "./Explorer.scss";

const Explorer = observer(() => {
  const titleOptions = { title: "Explorer" };

  return (
    <Page titleOpts={titleOptions}>
      <div className="integrations-explorer"></div>
    </Page>
  );
});

export default Explorer;
