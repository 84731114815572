import React from "react";
import { observer } from "mobx-react";
import TeamsStore from "../../../../stores/TeamsStore";
import sportIcons from "../../../-common/sportIcons";
import "./Benefits.scss";

const benefits = [
  {
    id: "1",
    teamId: "1f148a26-3f9d-49d9-9185-3d1749dc906b",
    sport: "Football",
    maxTickets: 20,
    extras: [
      "Season Tickets",
      "Tar Heel Experiences Credits",
      "Media Guide",
      "Access to Rams Club Patio Tailgate",
      "Season Parking Permit"
    ]
  },
  {
    id: "2",
    teamId: "5db15cd3-c3aa-4424-b9e8-6881839606d7",
    sport: "Men's Basketball",
    maxTickets: 4,
    extras: [
      "Full Season Tickets",
      "Tar Heel Experiences Credits",
      "Season Ticket Package (excluding Duke)",
      "Media Guide",
      "Season Parking Permit",
      "Pre-Game Hospitality"
    ]
  },
  {
    id: "3",
    teamId: "a733d496-8597-490f-a7f4-994749f7bb5b",
    sport: "Baseball",
    maxTickets: "∞",
    extras: [
      "Baseball Tar Heel Experiences Credits",
      "Individual Game & Postseason Requests",
      "Rams Club Car Decal & Membership Card",
      "Heels Deals Discounts",
      "Carolina Athletics Email Updates",
      "Born & Bred Magazine",
      "Invitation to Tar Heel Tour Events",
      "Coaches' Circle Events",
      "Donor Wall Recognition",
      "Exclusive Gameday Experience",
      "Specific Scholarship Student-Athlete Assignment",
      "Invitation to Scholarship Dinner with Student-Athletes",
      "Champion Events"
    ]
  }
];

const Benefit = observer(({ id, teamId, sport, maxTickets, section, priority, parking, extras }) => {
  const { media: teamIconName } = TeamsStore?.teams?.find(t => t?.teamId === teamId) || {};
  const Icon = teamIconName ? sportIcons[teamIconName] : () => null;

  const extraLines = extras.map(extra => (
    <div className="benefit-extra" key={extra}>
      <div className="extra-bullet">-</div>
      <div className="extra-text">{extra}</div>
    </div>
  ));
  const maxTicketsStyle = maxTickets === "∞" ? { fontSize: 96, fontWeight: "400" } : {};
  return (
    <div className="benefit-card" key={id}>
      <div className="benefit-inner">
        <div className="icon-wrapper">
          <Icon size={24} stroke="var(--White)" />
        </div>
        <div className="sport-title">{sport}</div>
        <div className="benefit-ticket-quantity" style={maxTicketsStyle}>
          {maxTickets}
        </div>
        <div className="benefit-ticket-quantity-label">Max Tickets</div>
        {/* <div className="benefit-details-container">
          <div className="benefit-detail">
            <div className="detail-label">Section</div>
            <div className="detail-value">{section}</div>
          </div>
          <div className="benefit-detail">
            <div className="detail-label">Priority</div>
            <div className="detail-value">{priority}</div>
          </div>
          <div className="benefit-detail">
            <div className="detail-label">Parking</div>
            <div className="detail-value">{parking}</div>
          </div>
        </div> */}
        <div className="benefit-extras">
          {/* <div className="benefit-extras-title">Extras</div> */}
          <div className="benefit-extras-title">Benefits</div>
          {extraLines}
        </div>
      </div>
    </div>
  );
});

function Benefits({ clubMember }) {
  const benefitCards = benefits.map(b => <Benefit {...b} key={b.id} />);
  return (
    <div className="benefits">
      <div className="title-row">
        <div className="tickets-section-title">Member Benefits</div>
      </div>
      <div className="member-benefits-container">
        <div className="member-benefits-wrapper">
          <div className="member-benefits">{benefitCards}</div>
        </div>
        <div className="fade" />
      </div>
    </div>
  );
}

export default observer(Benefits);
