import React from "react";
import { format } from "date-fns";
import commaNumber from "comma-number";
import "./CampaignHealthTable.scss";

const CampaignHealthTable = ({ data = [] }) => {
  const rows = data.map(({ title, status, endDate, totalDonors, newDonors, totalDonated }) => {
    let avgDonation = "-";
    if (totalDonated && totalDonors) {
      const avgDonationNumber = totalDonated / totalDonors;
      avgDonation = "$" + commaNumber(avgDonationNumber.toFixed(2));
    }

    return (
      <React.Fragment key={title}>
        <div className="campaign-row-item" key="title">
          {title}
        </div>
        <div className="campaign-row-item" key="status">
          <span className={`status-circle ${status}`} />
          {status}
        </div>
        <div className="campaign-row-item" key="endDate">
          {format(new Date(endDate), "MMM dd, yyyy") || "-"}
        </div>
        <div className="campaign-row-item" key="reach">
          {totalDonors ? commaNumber(totalDonors) : "-"}
        </div>
        <div className="campaign-row-item" key="donors">
          {newDonors ? commaNumber(newDonors) : "-"}
        </div>
        <div className="campaign-row-item" key="rate">
          {avgDonation}
        </div>
        <div className="campaign-row-item" key="amount">
          {totalDonated ? "$" + commaNumber(totalDonated) : "-"}
        </div>
      </React.Fragment>
    );
  });
  return (
    <div className="campaign-health-table">
      <div className="campaign-health-title">Campaign Health Snapshot</div>
      <div className="table-header">
        <div className="header-item">Title</div>
        <div className="header-item">Status</div>
        <div className="header-item">End Date</div>
        <div className="header-item">Total Donors</div>
        <div className="header-item">New Donors</div>
        <div className="header-item">Avg. Donation</div>
        <div className="header-item">Total Donations</div>
      </div>
      <div className="table-body-wrapper">
        <div className="table-body">{rows}</div>
      </div>
    </div>
  );
};

export default CampaignHealthTable;
