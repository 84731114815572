import React, { useState } from "react";
import { observer } from "mobx-react";
import Modal from "../../-common/Modal";
import SubjectSlide from "./SubjectSlide";
import InstructionsSlide from "./InstructionsSlide";
import PlansStore from "../../../stores/PlansStore";
import "./NewPlanModal.scss";

const NewPlanModal = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [type, setType] = useState("bulletin");
  const [subject, setSubject] = useState();

  const typeOptions = [{ label: "Bulletin", value: "bulletin" }];

  const finish = async () => {
    const planObject = { type, subject };
    await PlansStore.addPlan(planObject);
    close();
  };

  const close = () => {
    setActiveSlide(0);
    setType("");
    setSubject("");
    PlansStore.closeNewPlanModal();
  };

  const { slides, progress, progressTitle } = {
    slides: [
      <SubjectSlide
        subject={subject}
        setSubject={setSubject}
        type={type}
        setType={setType}
        typeOptions={typeOptions}
      />,
      <InstructionsSlide />,
    ],
    progress: [
      { title: "Subject", description: "What is this bulletin about." },
      {
        title: "Next Steps",
        description: "We'll give you some instructions",
      },
    ],
    progressTitle: "Add a new bulletin in 2 easy steps",
  };

  const nextEnabled = () => {
    if (activeSlide === 0) return subject;
    if (activeSlide === 1) return true;
    return false;
  };

  return (
    <Modal
      nextEnabled={nextEnabled()}
      slides={slides}
      progress={progress}
      progressTitle={progressTitle}
      isOpen={PlansStore.showNewPlanModal}
      activeSlide={activeSlide}
      setActiveSlide={setActiveSlide}
      close={close}
      finish={finish}
    />
  );
};

export default observer(NewPlanModal);
