const getInitials = name => {
  const allInitials = name
    .trim()
    .split(" ")
    .map(word => word[0].toUpperCase());
  const firstInitial = allInitials[0] || "";
  const lastInitial = allInitials.slice(1).slice(-1)[0] || "";
  return firstInitial + lastInitial;
};

export default getInitials;
