import React from "react";
import { FaCheck } from "react-icons/fa";
import "./ModalProgress.scss";

const Markers = ({ progress = [], activeSlide = 0 }) => {
  const progressMarkers = progress.map(({ title, description }, i) => {
    const progressStepNumber = i + 1;
    const iconText =
      activeSlide + 1 > progressStepNumber ? <FaCheck /> : progressStepNumber;
    return (
      <div className="progress-marker" key={i}>
        <div className="marker">{iconText}</div>
        <div className="title-description-container">
          <div className="title">{title}</div>
          <div className="description">{description}</div>
        </div>
      </div>
    );
  });
  return progressMarkers;
};

const ModalProgress = ({ title, progress, activeSlide }) => {
  return (
    <div className="progress-container">
      <div className="progress-title">{title}</div>
      <div className="progress-marker-container">
        <Markers progress={progress} activeSlide={activeSlide} />
      </div>
    </div>
  );
};

export default ModalProgress;
