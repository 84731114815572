import React, { useState } from "react";
import { createPortal } from "react-dom";
import { observer } from "mobx-react";
import usePortal from "../../../../hooks/usePortal";
import sportIcons from "../../sportIcons";
import FullScreenModal from "../../FullScreenModal";
import Button from "../../Button";
import "./TeamIconModal.scss";

const iconNames = Object.keys(sportIcons);

const TeamIconModal = observer(({ close, team, update }) => {
  const [icon, setIcon] = useState(team.media || sportIcons[0]);
  const selectIcon = selectedIcon => () => setIcon(selectedIcon);

  const save = async () => {
    update && icon && update(icon);
    close();
  };

  const icons = iconNames.map(i => {
    const Component = sportIcons[i];
    return (
      <div
        className={`icon-square${icon === i ? " selected" : ""}`}
        onClick={selectIcon(i)}
      >
        <Component size={40} />
      </div>
    );
  });

  const saveEnabled = icon && icon !== team.media;

  return (
    <FullScreenModal isOpen={true} close={close}>
      <div className="team-icon-modal">
        <div className="modal-contents">
          <div className="headline">Update team icon</div>
          <div>
            <div className="team-icons-container">{icons}</div>
          </div>
        </div>
        <div className="bottom-buttons">
          <Button text="cancel" theme="light" onClick={close} />
          <Button disabled={!saveEnabled} text="save" onClick={save} />
        </div>
      </div>
    </FullScreenModal>
  );
});

const TeamIconModalContainer = ({ isOpen, ...props }) => {
  const portalTarget = usePortal("team-icon-modal-container");
  const contents = isOpen ? <TeamIconModal {...props} /> : null;
  return createPortal(contents, portalTarget);
};

export default TeamIconModalContainer;
