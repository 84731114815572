import { observable, action } from "mobx";

class AppStateStore {
  @observable loading = false;

  timeoutId;

  @action setLoading = loading => {
    if (this.timeoutId) clearTimeout(this.timeoutId);
    if (loading)
      this.timeoutId = setTimeout(() => {
        this.setLoading(false);
      }, 30000);
    this.loading = loading;
  };
}

export default new AppStateStore();
