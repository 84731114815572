import { request } from "../utils";
import { FILE_CHUNK_SIZE } from "../constants/main";

export default async function uploadFile(file, path) {
  const numberOfParts = Math.ceil(file.size / FILE_CHUNK_SIZE);
  if (numberOfParts > 1) {
    const signedUrlObjects = await request.get(`/v1/files/${path}/start?numberofparts=${numberOfParts}`, {
      headers: { "file-type": file.type }
    });
    const { uuid: fileName, uploadId } = signedUrlObjects[0] || {};

    const filePartPromises = signedUrlObjects.map(({ url }, i, all) => {
      const start = i * FILE_CHUNK_SIZE;
      const end = i === all.length ? undefined : (i + 1) * FILE_CHUNK_SIZE;
      const filePart = file.slice(start, end);
      return request.bare.put(url, filePart, {
        headers: {
          "Content-Type": file.type,
          "Access-Control-Expose-Headers": "ETag"
        }
      });
    });
    await Promise.all(filePartPromises);

    const { url } = await request.post(`/v1/files/${path}/end`, {
      body: { fileName, uploadId },
      headers: { "file-type": file.type }
    });

    return url;
  } else {
    const { cloudfrontURL, url, fileType } = await request.get(`/v1/files/${path}`, {
      headers: { "file-type": file.type }
    });
    await request.bare.put(url, file, {
      headers: { "Content-Type": fileType }
    });
    return cloudfrontURL;
  }
}
