import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import { MdClose } from "react-icons/md";
import { FiType, FiHash } from "react-icons/fi";
import { isOnlyNumbersValidator } from "../../../utils";
import AchievementsStore, { categories } from "../../../stores/AchievementsStore";
import Page from "../../-common/Page";
import Input from "../../-common/Input";
import Textarea from "../../-common/Textarea";
import Select from "../../-common/Select2";
import Button from "../../-common/Button";
import useAchievement from "./useAchievement";
import ConfirmRemoveAchievementModal from "./ConfirmRemoveAchievementModal";
import "./AppAchievement.scss";

const checkSaveEnabled = (localAchievement, achievement) => {
  const different =
    localAchievement.title !== achievement.title ||
    localAchievement.description !== achievement.description ||
    localAchievement.category !== achievement.category ||
    localAchievement.threshold !== achievement.threshold;
  const newStuffValid =
    !!localAchievement.title &&
    !!localAchievement.description &&
    !!localAchievement.threshold &&
    Number(localAchievement.threshold) > -1;
  return different && newStuffValid;
};

function AppAchievement({ achievementId }) {
  const achievement = useAchievement(achievementId);

  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const openRemoveModal = () => setRemoveModalOpen(true);
  const closeRemoveModal = action => {
    setRemoveModalOpen(false);
    if (action === "delete") navigate("/club/app/achievements");
  };

  const [localAchievement, setLocalAchievement] = useState(achievement);
  useEffect(() => {
    setLocalAchievement(achievement);
  }, [achievement]);

  const updateTitle = newTitle => {
    setLocalAchievement({ ...localAchievement, title: newTitle });
  };
  const updateDescription = newDescription => {
    setLocalAchievement({ ...localAchievement, description: newDescription });
  };

  const updateCategory = ({ value: newCategory }) => {
    setLocalAchievement({ ...localAchievement, category: newCategory });
  };

  const updateThreshold = newThreshold => {
    setLocalAchievement({ ...localAchievement, threshold: newThreshold });
  };

  const saveEnabled = checkSaveEnabled(localAchievement, achievement);

  const cancel = () => setLocalAchievement(achievement);

  const save = async () => {
    if (saveEnabled) {
      localAchievement.threshold = Number(localAchievement.threshold);
      await AchievementsStore.updateAchievement(localAchievement);
    }
  };

  const categorySelectValue = {
    label: achievement.category,
    value: achievement.category
  };

  return (
    <Page
      titleOpts={{
        title: "Achievement",
        backButton: true
      }}
      buttons={[
        saveEnabled ? (
          [
            <Button theme="light" text="Cancel" onClick={cancel} key="cancel" />,
            <Button disabled={!saveEnabled} text="Save" onClick={save} key="save" />
          ]
        ) : (
          <Button text="Remove" icon={MdClose} onClick={openRemoveModal} key="remove" />
        )
      ]}
    >
      <div className="app-achievement">
        <div className="limit-width">
          <Input
            label="Title"
            value={localAchievement.title}
            placeholder="Option Title"
            onChange={updateTitle}
            maxLength={50}
            icon={FiType}
          />
          <Textarea
            label="Description"
            value={localAchievement.description}
            placeholder="Description"
            onChange={updateDescription}
            maxLength={200}
            icon={FiType}
          />
          <Select label="Category" value={categorySelectValue} options={categories} onChange={updateCategory} />
          <Input
            label="Threshold"
            value={localAchievement.threshold}
            placeholder="Threshold"
            onChange={updateThreshold}
            validator={isOnlyNumbersValidator}
            maxLength={8}
            icon={FiHash}
          />
        </div>
      </div>
      <ConfirmRemoveAchievementModal isOpen={removeModalOpen} close={closeRemoveModal} achievementId={achievementId} />
    </Page>
  );
}

export default observer(AppAchievement);
