import React from "react";
import { observer } from "mobx-react";
import { GoPlus } from "react-icons/go";
import Page from "../../-common/Page";
import Button from "../../-common/Button";
import "./Sources.scss";

const Sources = observer(() => {
  const titleOptions = { title: "Sources" };

  return (
    <Page
      titleOpts={titleOptions}
      buttons={[<Button text="Add Source" icon={GoPlus} theme="dark" key="new-source" onClick={() => null} />]}
    >
      <div className="integrations-datasource-sources"></div>
    </Page>
  );
});

export default Sources;
