import React from "react";
import { verifyWithPersona } from "../../../../../services/PersonaService";
import Input from "../../../../-common/Input";
import Button from "../../../../-common/Button";
import "./OfficerVerificationSlide.scss";

const thirtyMonths = ["04", "06", "09", "11"];
const minDay = 1;
const maxYear = new Date().getFullYear() - 21;
const minYear = 1900;

const OfficerVerificationSlide = ({ state, dispatch }) => {
  const { birthday, SSN } = state;

  const setBirthday = raw => {
    let formatted = raw.replace(/[^\d]/g, "").slice(0, 8);
    const prevFormatted = birthday?.replace(/[^\d]/g, "").slice(0, 8) || "";

    if (formatted?.length > prevFormatted?.length) {
      if (formatted?.length === 1) {
        // Month entry
        if (!["0", "1"].includes(formatted)) formatted = "0" + formatted;
      } else if (formatted?.length === 3) {
        // First digit of day entry
        const month = formatted.slice(0, 2);
        const firstDayDigit = formatted.charAt(2);
        const acceptableFirstOfTwoDigits = month === "02" ? ["0", "1", "2"] : ["0", "1", "2", "3"];
        if (!acceptableFirstOfTwoDigits.includes(firstDayDigit)) formatted = month + "0" + firstDayDigit;
        else formatted = month + firstDayDigit;
      } else if (formatted?.length === 4) {
        const month = formatted.slice(0, 2);
        let day = Number(formatted.slice(2, 4));
        let maxDay = 31;
        if (month === "02") maxDay = 28;
        else if (thirtyMonths.includes(month)) maxDay = 30;
        day = String(Math.min(Math.max(day, minDay), maxDay)).padStart(2, "0");
        formatted = month + day;
      } else if (formatted?.length > 4) {
        if (formatted.length === 8) {
          let year = formatted.slice(4, 8);
          year = String(Math.min(Math.max(year, minYear), maxYear));
          formatted = formatted.slice(0, 4) + year;
        }
      }
    } else {
      while (formatted?.charAt(formatted?.length - 1) === "0") {
        formatted = formatted.slice(0, formatted?.length - 1);
      }
    }

    if (formatted?.length > 2) formatted = formatted.slice(0, 2) + "/" + formatted.slice(2);
    if (formatted?.length > 5) formatted = formatted.slice(0, 5) + "/" + formatted.slice(5);
    dispatch({ type: "setBirthday", payload: { birthday: formatted } });
  };
  const setSSN = raw => {
    let formatted = raw.replace(/[^\d]/g, "").slice(0, 9);
    if (formatted?.length > 3) formatted = formatted.slice(0, 3) + "-" + formatted.slice(3);
    if (formatted?.length > 6) formatted = formatted.slice(0, 6) + "-" + formatted.slice(6);
    dispatch({ type: "setSSN", payload: { SSN: formatted } });
  };
  const setPersonaInquiryId = v => dispatch({ type: "setSSN", payload: { personaInquiryId: v } });

  return (
    <div className="slide officer-verification-slide">
      <div className="headline">Officer Verification Credentials</div>
      <div className="inputs">
        <Input value={birthday} onChange={setBirthday} label="Date of Birth" placeholder="MM/DD/YYYY" />
        <Input value={SSN} onChange={setSSN} label="Social Security Number" placeholder="XXX-XX-XXXX" />
        <div className="explanation">
          Merchant services require verification that you are really you by providing a photo ID. Luckily, we've made it
          super easy to do. Click the button below to get started. (When prompted, we highly recommend using your mobile
          device for the best experience.)
        </div>
        <div className="button-container">
          <Button text="Verify Photo ID" onClick={verifyWithPersona(setPersonaInquiryId)} />
        </div>
      </div>
    </div>
  );
};

export default OfficerVerificationSlide;
