import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { toast } from "react-toastify";
import { FiDownload, FiCheck } from "react-icons/fi";
import { downloadHostingFile, verifyAndSetURL } from "../../../services/HostingService";
import AppStateStore from "../../../stores/AppStateStore";
import TenantStore from "../../../stores/TenantStore";
import Page from "../../-common/Page";
import RadioGroup from "../../-common/RadioGroup";
import Button from "../../-common/Button";
import Input from "../../-common/Input";
import { NICKEL_HOSTED_URL } from "../../../constants/main";
import "./Hosting.scss";

const hostingOptions = [
  { label: "Nickel Hosted", value: "nickel" },
  { label: "Self Hosted", value: "self" }
];

function Hosting() {
  const [selected, setSelected] = useState(hostingOptions[0].value);
  const [linkURL, setLinkURL] = useState("");
  const { hostingURL } = TenantStore?.tenant?.config || {};
  useEffect(() => {
    if (hostingURL) {
      if (hostingURL === NICKEL_HOSTED_URL) {
        setSelected(hostingOptions[0].value);
      } else {
        setSelected(hostingOptions[1].value);
        setLinkURL(hostingURL);
      }
    }
  }, [hostingURL]);

  useEffect(() => {
    if (selected === "nickel" && linkURL) {
      (async () => {
        try {
          AppStateStore.setLoading(true);
          await TenantStore.updateTenant({ config: { hostingURL: "" } });
          AppStateStore.setLoading(false);
          toast("Donation pages set to Nickel hosted.");
        } catch (err) {
          AppStateStore.setLoading(false);
          toast("Error setting donation pages to Nickel hosted.");
        }
      })();
    }
  }, [selected, linkURL]);

  const verify = async () => await verifyAndSetURL(linkURL);

  const titleOptions = { title: "Hosting" };

  const verifyButtonStyles = {
    height: 40,
    marginTop: 35,
    padding: "10px 16px"
  };

  const verifyComponent =
    hostingURL && linkURL === hostingURL ? (
      <div className="verified-text-wrapper">
        <div className="verified-text-contents">
          <FiCheck size={24} />
          <div className="verified-text">You're all set!</div>
        </div>
      </div>
    ) : (
      <Button text="Verify" icon={FiCheck} style={verifyButtonStyles} onClick={verify} disabled={!linkURL} />
    );
  const instructions =
    selected === "self" ? (
      <>
        <div className="hosting-section">
          <div className="hosting-title">
            Sounds good - you'll need to download the index.html file below, then you can host it wherever you like.
          </div>
          <Button text="Download index.html" icon={FiDownload} onClick={downloadHostingFile} />
        </div>
        <div className="hosting-section">
          <div className="hosting-title">
            Once it's hosted, enter your url here and we'll verify that things are working correctly.
          </div>
          <div className="input-button-row">
            <Input label="Link URL" value={linkURL} onChange={setLinkURL} />
            {verifyComponent}
          </div>
        </div>
      </>
    ) : null;

  return (
    <Page titleOpts={titleOptions}>
      <div className="hosting-container">
        <div className="hosting-section">
          <div className="hosting-title">How would you like to host your campaign donation pages?</div>
          <div className="hosting-options">
            <RadioGroup id="hosting-type" options={hostingOptions} selected={selected} onChange={setSelected} />
          </div>
        </div>
        {instructions}
      </div>
    </Page>
  );
}

export default observer(Hosting);
