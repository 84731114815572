import React, { useReducer, useCallback } from "react";
import { observer } from "mobx-react";
import { userReducer, userInitialState } from "../../../reducers/UserReducer";
import Modal from "../../-common/Modal";
import InfoSlide from "./InfoSlide";
import UsersStore from "../../../stores/UsersStore";
import "./NewUserModal.scss";

const NewUserModal = () => {
  const [state, dispatch] = useReducer(userReducer, userInitialState);

  const updateValue = useCallback(
    (field) => (value) => {
      const actionType = "set" + field[0].toUpperCase() + field.slice(1);
      dispatch({ type: actionType, payload: { [field]: value } });
    },
    []
  );

  const finish = async () => {
    const userObject = { ...state, role: "department" };
    await UsersStore.addUser(userObject);
    close();
  };

  const close = () => {
    dispatch({ type: "resetState", payload: null });
    UsersStore.closeNewUserModal();
  };

  const { slides, progress, progressTitle } = {
    slides: [
      <InfoSlide
        name={state.name}
        setName={updateValue("name")}
        email={state.email}
        setEmail={updateValue("email")}
      />,
    ],
    progress: [
      { title: "Info", description: "Add the new user's information" },
    ],
    progressTitle: "Quickly add a new user",
  };

  return (
    <Modal
      nextEnabled={state.nextEnabled}
      slides={slides}
      progress={progress}
      progressTitle={progressTitle}
      isOpen={UsersStore.showNewUserModal}
      activeSlide={state.activeSlide}
      setActiveSlide={updateValue("activeSlide")}
      close={close}
      finish={finish}
    />
  );
};

export default observer(NewUserModal);
