import { observable, computed, action, autorun } from "mobx";
import { toast } from "react-toastify";
import { request } from "../utils";
import AuthStore from "./AuthStore";
import UsersStore from "./UsersStore";

class MessagesStore {
  constructor() {
    autorun(() => {
      if (AuthStore.APIReady) {
        this.fetchThreadPreviews();
        // this.fetchMessages();
      } else {
        this.clear();
      }
    });
  }

  @observable threadPreviews = [];

  @observable rawThreads = {};

  @computed get threads() {
    return Object.fromEntries(
      Object.keys(this.rawThreads).map(memberId => {
        return [
          memberId,
          this.rawThreads[memberId]
            .map((m, i) => {
              return {
                ...m,
                createdDate: new Date(m.createdDate)
              };
            })
            .sort((a, b) => a.createdDate - b.createdDate)
            .map((m, i) => ({
              ...m,
              last: i === this.rawThreads[memberId].length - 1
            }))
        ];
      })
    );
  }

  @action async fetchThreadPreviews() {
    try {
      const threads = await request.get(`/v1/messages/threads`);
      this.threadPreviews = threads;
      return threads;
    } catch (err) {
      console.warn(err);
    }
  }

  @action async fetchMessagesForMemberThread(memberId, forceRefresh) {
    if (this.rawThreads[memberId] && !forceRefresh) return this.rawThreads[memberId];
    try {
      const messages = await request.get(`/v1/messages?memberUserId=${memberId}`);
      this.rawThreads = {
        ...this.rawThreads,
        [memberId]: messages.length ? messages.map(m => ({ ...m, createdDate: new Date(m.createdDate) })) : []
      };
      return messages;
    } catch (err) {
      console.warn(err);
    }
  }

  @action async createMessage(memberId, message) {
    const optimisticMessageId = `optimistic-${Math.random()}`;
    try {
      const optimisticMessage = {
        archived: false,
        fromDonor: false,
        message,
        messageId: optimisticMessageId,
        officer: {
          officerId: UsersStore?.me?.userId,
          profile: UsersStore?.me?.profile
        },
        userId: memberId
      };
      this.rawThreads = {
        ...this.rawThreads,
        [memberId]: this.rawThreads[memberId].concat({ ...optimisticMessage, createdDate: new Date() })
      };

      const newMessage = await request.post(`/v1/messages?memberUserId=${memberId}`, {
        body: { message }
      });
      this.rawThreads = {
        ...this.rawThreads,
        [memberId]: this.rawThreads[memberId]
          ?.filter(m => m?.messageId !== optimisticMessageId)
          ?.concat({ ...newMessage, createdDate: new Date(newMessage.createdDate) })
      };
      return newMessage;
    } catch (err) {
      this.rawThreads = {
        ...this.rawThreads,
        [memberId]: this.rawThreads[memberId]?.filter(m => m?.messageId !== optimisticMessageId)
      };
      toast("Error sending message.");
      console.warn(err);
    }
  }

  @action async updateMessage(messageParams) {
    try {
      const updatedMessage = await request.put(`/v1/messages/${messageParams.messageId}`, {
        body: messageParams
      });
      return updatedMessage;
    } catch (err) {
      console.warn(err);
    }
  }

  @action async deleteMessage(messageParams) {
    try {
      const deletedMessage = await request.delete(`/v1/messages/${messageParams.messageId}`);
      return deletedMessage;
    } catch (err) {
      console.warn(err);
    }
  }

  @action async markMessagesReadForMemberThread(memberUserId) {
    try {
      await request.post(`/v1/messages/read`, { body: { memberUserId } });
      this.rawThreads = {
        ...this.rawThreads,
        [memberUserId]: this.rawThreads[memberUserId].map(m => ({ ...m, receipt: new Date() }))
      };
      await this.fetchThreadPreviews();
    } catch (err) {
      console.warn(err);
    }
  }

  @action async handleIncomingMessage(message) {
    if (message?.userId) {
      const { userId: memberId } = message || {};
      this.rawThreads = {
        ...this.rawThreads,
        [memberId]: (this.rawThreads[memberId] || []).concat({ ...message, createdDate: new Date(message.createdDate) })
      };
    }
  }

  // CLEANUP
  @action clear() {
    this.rawMessages = [];
  }
}

export default new MessagesStore();
