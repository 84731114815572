import React from 'react';
import { Link } from '@reach/router';

import NickelLogo from '../../-common/NickelLogo';

import './AuthHeader.scss';

const AuthHeader = () => (
  <div className="auth-header">
    <Link to="/auth">
      <NickelLogo height={30} />
    </Link>
    <div className="buttons">
      <Link to="/privacy">
        <button
          className="nav-button"
          type="button"
        >
          PRIVACY
        </button>
      </Link>
    </div>
  </div>
);

export default AuthHeader;
