import React from "react";
import { format } from "date-fns";
import { FiX } from "react-icons/fi";
import MembershipsStore from "../../../stores/MembershipsStore";
import "./PlanStep.scss";

const PlanStep = ({
  planStepId,
  planId,
  publishDate,
  membershipIds = [],
  viewOnly,
  openChangeEditPlanStep,
  setCurrentStep,
  removePlanStep,
}) => {
  // Make the tests happy
  let publishDateObject = new Date(publishDate);
  if (isNaN(publishDateObject)) publishDateObject = new Date();

  const formattedDate = format(publishDateObject, "EEEE MMMM do @ p");

  const membershipsSelected = MembershipsStore.membershipsIncludingPublic.filter((m) =>
    membershipIds.includes(m.membershipId),
  );

  const setPlanStepAndOpenModal = (planStep) => {
    setCurrentStep(planStep);
    openChangeEditPlanStep();
  };

  const removeStep = (e) => {
    e.stopPropagation();
    removePlanStep(planStepId);
  };

  const disabledStyles = viewOnly ? { pointerEvents: "none" } : null;

  const deletePlanStepOption = viewOnly ? null : (
    <div className="plan-step-delete" onClick={(e) => removeStep(e)}>
      <FiX size={24} />
    </div>
  );

  return (
    <div
      className="plan-step-details-container"
      style={disabledStyles}
      onClick={() => setPlanStepAndOpenModal(planStepId)}
    >
      <div className="who-container">
        <div className="who-label">Who:</div>
        <div className="who-text">{membershipsSelected.map((m) => m.level).join(", ")}</div>
      </div>
      <div className="when-container">
        <div className="when-label">When:</div>
        <div className="when-text">{formattedDate}</div>
      </div>
      {deletePlanStepOption}
    </div>
  );
};

export default PlanStep;
