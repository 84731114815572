import React from "react";
import "./TabBar.scss";

function Tab({ id, title, selected, select }) {
  return (
    <div className={`tab${selected ? " selected" : ""}`} onClick={select}>
      <div className="tab-title">{title}</div>
      <div className="selected-indicator" />
    </div>
  );
}

export default function TabBar({ tabs, selectedTab, setSelectedTab }) {
  const select = tab => () => setSelectedTab(tabs.indexOf(tab));
  const tabComponents = tabs.map(t => {
    const selected = tabs[selectedTab]?.id === t?.id;
    return <Tab {...t} selected={selected} select={select(t)} key={t.id} />;
  });
  return <div className="tab-bar">{tabComponents}</div>;
}
