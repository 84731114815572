import React from "react";
import { phoneNumberFormatter } from "../../../../utils";
import Input from "../../../-common/Input";
import "./TeamMembersSlide.scss";
import { FiX } from "react-icons/fi";

const TeamMembersSlide = ({ setMembers, members }) => {
  const teamMemberShape = {
    name: "",
    phone: ""
  };

  const addTeamMember = () => {
    setMembers(members.concat(teamMemberShape));
  };

  const removeTeamMember = index => {
    setMembers(members.filter((_, i) => i !== index));
  };

  const updateMemberName = index => newName => {
    setMembers(
      members.map((member, i) => {
        if (i === index) return { ...member, name: newName };
        else return member;
      })
    );
  };

  const updateMemberPhone = index => newPhone => {
    setMembers(
      members.map((member, i) => {
        if (i === index) return { ...member, phone: newPhone };
        else return member;
      })
    );
  };

  const teamMemberInputComponents = members?.map(({ name, phone }, i) => {
    return (
      <div className="team-member-container" key={i}>
        <div className="input-fields">
          <Input
            placeholder="Name"
            onChange={updateMemberName(i)}
            value={name}
          />
        </div>
        <div className="input-fields">
          <Input
            placeholder="Phone #"
            onChange={updateMemberPhone(i)}
            value={phone}
            formatter={phoneNumberFormatter}
            maxLength={16}
          />
        </div>
        <div className="delete-team-member" onClick={() => removeTeamMember(i)}>
          <FiX size={24} />
        </div>
      </div>
    );
  });

  return (
    <div className="slide team-members-slide">
      <div className="headline">Let's meet the team</div>
      <div className="inputs">
        <div className="add-custom" onClick={addTeamMember}>
          + New team member
        </div>
        {teamMemberInputComponents}
      </div>
    </div>
  );
};

export default TeamMembersSlide;
