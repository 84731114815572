import { toast } from "react-toastify";
import { request, downloadURLContents } from "../utils";
import TenantStore from "../stores/TenantStore";
import AppStateStore from "../stores/AppStateStore";

export async function verifyAndSetURL(url) {
  try {
    AppStateStore.setLoading(true);
    const updatedTenant = await request.post("/v1/hosting/verifyurl", { body: { url } });
    TenantStore.setTenant(updatedTenant);
    AppStateStore.setLoading(false);
    toast("Link verified successfully.");
    return { verified: true };
  } catch (err) {
    AppStateStore.setLoading(false);
    toast("Error verifying link.");
    return { verified: false, error: err };
  }
}

export async function downloadHostingFile() {
  const { url } = await request.get("/v1/hosting");
  downloadURLContents(url);
}
