import { parseISO } from "date-fns";
import React from "react";
import { VictoryChart, VictoryTheme, VictoryAxis, VictoryBar } from "victory";
import useDimensions from "react-cool-dimensions";
import { abbreviateNumber } from "../../../../../utils";
import "./MembershipTrend.scss";
import Spinner from "../../../../-common/Spinner";

const colors = ["Green", "Purple"].map(c => `var(--${c})`);

const MembershipTrend = ({ transactions }) => {
  const { width: renderWidth, height: renderHeight, ref: containerRef } = useDimensions();

  const data = Object.entries(
    (transactions || []).reduce((acc, next) => {
      const year = parseISO(next.date).getFullYear();
      if (acc[year]) acc[year] += next?.amountMetadata?.totalDonationAmount || 0;
      else acc[year] = next?.amountMetadata?.totalDonationAmount || 0;
      return acc;
    }, {})
  )
    .map(([year, total]) => ({
      x: "'" + String(year).slice(-2),
      y: Number((total / 100).toFixed(2))
    }))
    .slice(-20);

  const contents = data?.length ? (
    <VictoryChart
      height={renderHeight}
      width={renderWidth}
      theme={VictoryTheme.material}
      domainPadding={{
        x: [renderWidth / 25, 0],
        y: [0, 0]
      }}
      padding={{ top: 16, bottom: 50, left: 50, right: 20 }}
    >
      <VictoryAxis
        style={{
          axis: { stroke: "none" },
          ticks: { stroke: "none" },
          tickLabels: { padding: 24, fill: "var(--Gray500)" },
          grid: { stroke: "none" }
        }}
      />
      <VictoryAxis
        dependentAxis
        width={renderWidth}
        tickFormat={t => "$" + abbreviateNumber(t, 0)}
        style={{
          axis: { stroke: "none" },
          ticks: { stroke: "none" },
          tickLabels: { fill: "var(--Gray500)" },
          grid: {
            width: renderWidth,
            stroke: "var(--Gray300)"
          }
        }}
      />
      <VictoryBar style={{ data: { fill: colors[0] } }} data={data} cornerRadius={{ topLeft: 3, topRight: 3 }} />
    </VictoryChart>
  ) : (
    <div className="spinner-container">
      <Spinner color="var(--primary-color)" />
    </div>
  );

  return (
    <div className="membership-trend">
      <div className="chart-title">Membership Over the Last 20 Years</div>
      <div className="chart-wrapper" ref={containerRef}>
        {contents}
      </div>
    </div>
  );
};

export default MembershipTrend;
