import { request } from "../utils";
import { toast } from "react-toastify";
import qs from "query-string";
import TransactionsStore from "../stores/TransactionsStore";
import AppStateStore from "../stores/AppStateStore";

const formEncodedHeaders = { "Content-Type": "application/x-www-form-urlencoded" };

const fetchPaymentToken = async ({ cardNumber, expDate }) => {
  const expirationDate = expDate.replace(" / ", "");
  const { temporaryPassword, endpoint: ziftProxyEndpoint } = await request.get("/v1/transactions/auth");
  const params = qs.stringify({
    accountNumber: cardNumber,
    accountAccessory: expirationDate,
    callback: "dontCallMe",
    password: temporaryPassword
  });
  const { data: rawResponse } = await request.bare.post(ziftProxyEndpoint, params, formEncodedHeaders);
  const token = rawResponse.split("accountNumber='")[1].replace("');", "");
  return token;
};

const processPayment = async paymentParams => {
  // unused but available paymentParams: frequency, fund, transactionType, cvv
  const { userId, amount, brand, cardNumber, zip, expDate } = paymentParams;
  const [expMonth, expYear] = expDate.split(" / ");
  AppStateStore.setLoading(true);
  try {
    const paymentToken = await fetchPaymentToken({ cardNumber, expDate });
    const transactionParams = {
      userId,
      donationAmount: amount,
      paymentToken,
      singleUse: true,
      isRenewal: false,
      cardInfo: { brand, expMonth, expYear, zip, fundingType: null }
    };
    const newTransaction = await request.post("/v1/transactions", {
      body: transactionParams
    });
    TransactionsStore.addTransactionInPlace(newTransaction);
  } catch (e) {
    toast("Error processing transaction. Contact Nickel Support");
  }
  AppStateStore.setLoading(false);
};

export default processPayment;
