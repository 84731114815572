import React from 'react';
import { Link } from '@reach/router';

import NickelLogo from '../-common/NickelLogo';
import BackgroundBlobs from '../@Auth/BackgroundBlobs';

import './Privacy.scss';

const Privacy = () => (
  <div className="privacy-container">
    <div className="wrapper">
      <BackgroundBlobs />
      <section className="content">
        <header className="auth-header">
          <Link to="/auth">
            <NickelLogo height={30} />
          </Link>
        </header>
        <h1 className="title">Nickel, Inc. Privacy Policy</h1>
        <div className="section-header">Scope of this Policy</div>
        <p className="section-text">
          Nickel understands you care how information about you is collected and used. Nickel is
          committed to protecting the privacy of individuals who interact with us. This Nickel
          Privacy Policy (“Privacy Policy”) describes Nickel, Inc.’s (“Nickel”) privacy practices
          for our websites, services, and apps that link to this policy. This policy also details
          our privacy practices related to Nickel marketing, advertising, and company events.
        </p>
        <p className="section-text">
          Nickel is an online donor experience management platform. We provide the Nickel platform
          and related support, consulting, and other services to our customers. In this Privacy
          Policy, we will refer to the Nickel platform and related services collectively as our
          "Services." We will refer to our emails, newsletters, and other marketing and advertising
          practices collectively as our “Communications.”
        </p>
        <p className="section-text">
          For the purpose of this Privacy Policy, “Personal Information” means any information
          relating to an identified or identifiable natural person.
        </p>
        <div className="subsection-header">Nickel Services</div>
        <p className="section-text">
          We provide Services to our customers. As part of the Services, customer data may be stored
          on our computer systems. As our customer, when you use our Services to process data you
          have collected, you control the data you collect, what data is uploaded to the Services,
          and how that data is used. The collection, use, transfer, and retention of customer data
          in our Services are governed by our customer privacy policies, not this Privacy Policy. As
          a data processor, we will cooperate with you in your efforts to control your data.
        </p>
        <div className="section-header">Information Collected by Nickel</div>
        <div className="subsection-header">Nickel registration and customer support</div>
        <p className="section-text">
          You may provide us with information about you when you complete a Nickel web form,
          register to use a Nickel website (such as
          <a href="https://nickeldxm.com" target="_blank" rel="noopener noreferrer">
            www.nickeldxm.com
          </a>
          ) or Service, request a Services demonstration, visit our websites, use our Services,
          respond to an advertisement, contact Nickel for support or other offerings, or attend an
          event or forum in which Nickel is hosting or participating. During such processes, we
          collect Personal Information about you. For example, we may collect your name, company
          name, address, email, phone number, billing and payment information, position, or title.
          We may also collect other public and third party information about you to supplement the
          information we hold such as information about the company where you work.
        </p>
        <div className="subsection-header">Nickel websites and use of Cookies</div>
        <p className="section-text">
          We use information-gathering tools, such as cookies and web beacons, to collect
          information as you navigate our websites and Services. Through such tools, we receive and
          store information about how you use our websites and Services. For example, we receive
          information that your browser or device sends to our servers whenever you visit a Nickel
          website. Your browser or device may tell us your internet protocol (IP) address used to
          connect your computer to the Internet, computer and connection information such as browser
          type, version, language and time zone settings, browser plug-in types, operating system,
          and type of device you are using. When you visit a Nickel website, your browser may also
          tell us information such as the actions you take on our website, the page that led you to
          our website and, if applicable, the search terms you typed into a search engine that led
          you to our website. Our servers collect similar information when you are logged into our
          website or Services.
        </p>
        <p className="section-text">
          The information we receive through cookies and similar technologies may be associated with
          you, depending on the website or Services you are using. In addition, third parties may be
          able to collect information about your online activities when you use our websites or
          Services using cookies or other technologies. We use first-party and third-party cookies,
          web beacons, and similar technologies in accordance with the information and options that
          may be provided to you when those technologies are used.
        </p>
        <p className="section-text">
          We advertise online, including displaying Nickel ads across the Internet on websites and
          in apps. When we advertise online to you, we may collect information through such
          technologies about which ads are displayed to you, which ads you click on, and the web
          page where the ad was displayed to you.
        </p>
        <p className="section-text">
          For more information about the types of cookies we use and managing cookies, please see
          <i>Your rights in respect of your Personal Information</i>
          below.
        </p>
        <div className="subsection-header">Buttons, tools, and content from other companies</div>
        <p className="section-text">
          Nickel websites and Services may include buttons, tools, or content that link to other
          companies’ services (for example, a Facebook "Like" button). We may collect information
          about your use of these features. In addition, when you see or interact with these
          buttons, tools, or content, or view a Nickel web page containing them, some information
          from your browser may automatically be sent to the other company. Please read that
          company’s privacy policy for more information regarding its use of that information.
        </p>
        <div className="subsection-header">Nickel social networking pages</div>
        <p className="section-text">
          We maintain pages on many social networking sites. We may collect information when you
          interact with our social networking pages.
        </p>
        <div className="subsection-header">Public chat, message boards and user postings</div>
        <p className="section-text">
          You might provide Personal Information through your public participation in chat sessions,
          message boards, user communities associated with our Services (such as Nickel DXM
          communication services), blogs, email exchanges, or newsgroups on our websites. That
          information may be available to anyone who has access to the website. This Privacy Policy
          does not apply to information that you make generally available through such websites.
        </p>
        <p className="section-text">
          You can choose not to provide us with your Personal Information, but if you do not provide
          us with your Personal Information when we request it, we may not be able to provide you
          with the websites and Services you use, or tailor them to you.
        </p>
        <div className="section-header">Use of the information collected by Nickel</div>
        <div className="list-header">
          We may use the information we collect about you under our Services agreement terms to:
        </div>
        <ul>
          <li>
            <span className="bullet">•</span>
            <span>Provide you the Services or information you have requested</span>
          </li>
          <li>
            <span className="bullet">•</span>
            <span>Send you business notifications related to our Services</span>
          </li>
          <li>
            <span className="bullet">•</span>
            <span>Discuss payments, additional purchases, or renewal of your subscription</span>
          </li>
          <li>
            <span className="bullet">•</span>
            <span>
              Obtain feedback about our Services to enhance the Services we provide to you
            </span>
          </li>
          <li>
            <span className="bullet">•</span>
            <span>Diagnose Service problems</span>
          </li>
          <li>
            <span className="bullet">•</span>
            <span>
              Periodically communicate with you about our Services through messaging or Nickel DXM
              communication services
            </span>
          </li>
          <li>
            <span className="bullet">•</span>
            <span>Allow other Services users to communicate with you</span>
          </li>
          <li>
            <span className="bullet">•</span>
            <span>
              Undertake internal analysis of customer patterns and behavior so we can understand
              what may be of interest to you and others
            </span>
          </li>
        </ul>
        <div className="list-header">
          We may use the information we collect about you for our legitimate interests to:
        </div>
        <ul>
          <li>
            <span className="bullet">•</span>
            <span>Provide you Nickel website content</span>
          </li>
          <li>
            <span className="bullet">•</span>
            <span>Operate and improve our websites</span>
          </li>
          <li>
            <span className="bullet">•</span>
            <span>
              Personalize our website, Services, and Communications to your likely interests and
              needs
            </span>
          </li>
          <li>
            <span className="bullet">•</span>
            <span>
              Conduct market research about our current and potential customers, their interests,
              and the effectiveness of our marketing campaigns
            </span>
          </li>
          <li>
            <span className="bullet">•</span>
            <span>
              Aggregate data from our Services used for benchmarking and to improve our Services
            </span>
          </li>
          <li>
            <span className="bullet">•</span>
            <span>
              Send you information by email about Nickel, new releases, special offers, and similar
              marketing information
            </span>
          </li>
          <li>
            <span className="bullet">•</span>
            <span>
              Provide information to our customers and potential customers in the form of electronic
              or print newsletters. When you choose to subscribe to our newsletter you may be added
              to our mailing list and will receive announcements and information about Nickel. It
              will be emailed or mailed to the address that you provide when you subscribe.
            </span>
          </li>
          <li>
            <span className="bullet">•</span>
            <span>
              Contact you via telephone to discuss our Services and related offers with you
            </span>
          </li>
          <li>
            <span className="bullet">•</span>
            <span>Display personalized ads to you</span>
          </li>
          <li>
            <span className="bullet">•</span>
            <span>
              Combine information we collect about you with other public or private information
              sources in order to provide you with Communications that may be relevant to you
            </span>
          </li>
        </ul>
        <div className="list-header">
          We may use the information we collect about you for marketing purposes to:
        </div>
        <ul>
          <li>
            <span className="bullet">•</span>
            <span>
              Send you information by email about Nickel, new releases, special offers, and similar
              marketing information
            </span>
          </li>
          <li>
            <span className="bullet">•</span>
            <span>
              Provide information to our customers and potential customers in the form of electronic
              or print newsletters. When you choose to subscribe to our newsletter you may be added
              to our mailing list and will receive announcements and information about Nickel. It
              will be emailed or mailed to the address that you provide when you subscribe.
            </span>
          </li>
          <li>
            <span className="bullet">•</span>
            <span>
              Contact you via telephone to discuss our Services and related offers with you
            </span>
          </li>
          <li>
            <span className="bullet">•</span>
            <span>Display personalized ads to you</span>
          </li>
          <li>
            <span className="bullet">•</span>
            <span>
              Combine information we collect about you with other public or private information
              sources in order to provide you with Communications that may be relevant to you
            </span>
          </li>
        </ul>
        <p className="section-text">
          The information we collect about you may be combined with information from third-party
          sources. For example, your name, email address, phone number, and title may be combined
          with information about the company you work for that is obtained from professional
          networking sites and information service providers. Our processing of your Personal
          Information for marketing purposes outside of a consent requirement in law will be in our
          legitimate interest. Where required under applicable law, we will obtain your consent to
          the use of your Personal Information for marketing purposes. You can withdraw your consent
          or object to receiving further marketing information at any time as provided in our
          Communications or as described below.
        </p>
        <div className="section-header">Sharing information collected by Nickel</div>
        <div className="list-header">
          We work with companies that provide services or help support our business. These companies
          may deliver customer support, provide data hosting, process and collect payments, or send
          Communications on our behalf. These companies may have access to your Personal Information
          including:
        </div>
        <ul>
          <li>
            <span className="bullet">•</span>
            <span>
              With our affiliated companies and companies we hire under this Privacy Policy as
              reasonably necessary to provide or support our Services
            </span>
          </li>
          <li>
            <span className="bullet">•</span>
            <span>
              When you purchase a license to use or indicate interest in a third-party product or
              service through Nickel, so the third party may contact you about your purchase or
              interest
            </span>
          </li>
          <li>
            <span className="bullet">•</span>
            <span>
              When you purchase a license to use, or indicate interest in a third party app or other
              third party product or service, so the third party may contact you about your purchase
              or interest
            </span>
          </li>
          <li>
            <span className="bullet">•</span>
            <span>
              With our resellers and other sales partners for the purpose of assisting you with
              ordering or Services implementation
            </span>
          </li>
          <li>
            <span className="bullet">•</span>
            <span>
              When we hire companies to help us market our websites and Services and provide you
              with information and offers related to Nickel, including displaying ads to you across
              the Internet
            </span>
          </li>
        </ul>
        <div className="list-header">Nickel may also share your Personal Information:</div>
        <ul>
          <li>
            <span className="bullet">•</span>
            <span>
              When we are required or believe we are required to provide information in response to
              a subpoena, court order, applicable law, government statute, regulation, or other
              legal process
            </span>
          </li>
          <li>
            <span className="bullet">•</span>
            <span>
              When we have a good faith belief that the disclosure is necessary in our legitimate
              interests to prevent or respond to fraud, defend our websites or Services against
              attacks, or protect the property and security of Nickel, or the property and security
              of our customers and users
            </span>
          </li>
          <li>
            <span className="bullet">•</span>
            <span>
              To the extent necessary to meet lawful requests by public authorities, including to
              meet national security or law enforcement requirements
            </span>
          </li>
          <li>
            <span className="bullet">•</span>
            <span>
              If we merge with or are acquired by another company, we sell a website, app, or
              business unit, or all or a substantial portion of our assets are acquired by another
              company, in which case your information may be one of the assets that is transferred
              or may be shared with the other company to evaluate the potential merger, acquisition,
              or sale, subject to the terms of this Privacy Policy continuing to apply to any
              Personal Information that is transferred to that entity or third party
            </span>
          </li>
          <li>
            <span className="bullet">•</span>
            <span>
              When we aggregate and share de-identified information collected by our Services in
              order to provide statistical information or market research to third parties
            </span>
          </li>
          <li>
            <span className="bullet">•</span>
            <span>When you consent to the sharing</span>
          </li>
        </ul>
        <p className="section-text">
          Within the Nickel DXM, our customers can request and obtain access to software
          applications that are developed and supported by third-party providers. We refer to these
          applications as Non-Nickel Apps. If you install or enable a Non-Nickel App for use with
          the Nickel Platform, we will allow the provider of the Non-Nickel App to access your data
          as required for the interoperation of the Non-Nickel App with the Nickel Platform. We are
          not responsible for the use, disclosure, modification, or deletion of your data by a
          Non-Nickel App provider resulting from your use of a Non-Nickel App. Please read the
          Non-Nickel App Provider’s privacy policy and Non-Nickel App license terms for more
          information regarding its use of your data in the Non-Nickel App.
        </p>
        <div className="section-header">Information security and storage</div>
        <p className="section-text">
          We understand that the security of your Personal Information is important. We implement
          reasonable administrative, technical, and physical security controls designed to protect
          your Personal Information from loss, misuse, unauthorized access, disclosure, alteration,
          or destruction. However, despite our efforts, no security controls are completely
          effective and we cannot ensure or warrant the security of your Personal Information.
        </p>
        <p className="section-text">
          Your Personal Information and data files are stored on our servers and the servers of our
          affiliated companies and companies we hire to provide services to us. We will retain your
          Personal Information for as long as necessary to provide you with the websites and
          Services you use, as needed to comply with our legal obligations and legal rights
          including to prevent fraud or enforce our agreements, and for our internal business
          reasons such as maintenance of our archive and backup systems for a period after your use
          of our websites and Services ends. We retain aggregate information beyond this time for
          research purposes and to help us to develop and improve our services.
        </p>
        <div className="section-header">Your rights in respect of your Personal Information</div>
        <p className="section-text">
          We take reasonable steps intended to ensure that your Personal Information we collect is
          accurate, complete, and current by using the most recent information provided to us. Our
          Services may allow you to review and edit your Personal Information by accessing your
          account or similar feature of the website or Service you are using.
        </p>
        <div className="list-header">
          For our websites and advertising, the following describes your options for managing the
          various types of cookies used by Nickel on our websites and Services:
        </div>
        <ul>
          <li>
            <span className="bullet">•</span>
            <span>
              Required Cookies: Required cookies are necessary to enable the basic features of this
              site to function, such as providing secure login or using our Services. Because
              required cookies are essential to operate the Company’s Web sites and the Services,
              there is no option to opt-out of these cookies.
            </span>
          </li>
          <li>
            <span className="bullet">•</span>
            <span>
              Functionality Cookies: Functionality cookies allow us to analyze your use of the site
              to evaluate and improve our performance. They may also be used to provide a better
              customer experience on this site. For example, remembering your login details and
              other information you have entered or choices you make, displaying more personal
              features, or providing us information about how our site is used. To learn more about
              how to control functionality cookies using your browser settings, please see
              <a href="https://www.aboutcookies.org" target="_blank" rel="noopener noreferrer">
                aboutcookies.org
              </a>
              .
            </span>
          </li>
          <li>
            <span className="bullet">•</span>
            <span>
              Advertising Cookies: Advertising cookies are used to show you ads that are more
              relevant to you. We may share this information with advertisers or use it to better
              understand your interests. For example, advertising cookies may be used to share data
              with advertisers so that the ads you see are more relevant to you, allow you to share
              certain pages with social networks, or allow you to post comments on our site. To
              learn more about these and other advertising networks and your ability to opt-out of
              collection by certain third party, please visit the opt-out pages of the
              <a
                href="https://optout.networkadvertising.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Network Advertising Initiative
              </a>
              and the
              <a href="https://optout.aboutads.info/" target="_blank" rel="noopener noreferrer">
                Digital Advertising Alliance
              </a>
            </span>
          </li>
        </ul>
        <div className="subsection-header">Individual Rights</div>
        <div className="list-header">
          We respect the rights you may have under certain laws and circumstances to:
        </div>
        <ul>
          <li>
            <span className="bullet">•</span>
            <span>Be provided with a copy of your Personal Information held by us</span>
          </li>
          <li>
            <span className="bullet">•</span>
            <span>Request the correction or erasure of your Personal Information held by us</span>
          </li>
          <li>
            <span className="bullet">•</span>
            <span>Object to the further processing of your Personal Information</span>
          </li>
          <li>
            <span className="bullet">•</span>
            <span>
              Request that we restrict the processing of your Personal Information (while we verify
              or investigate your concerns with this information, for example)
            </span>
          </li>
          <li>
            <span className="bullet">•</span>
            <span>Request that your Personal Information be transferred to a third party</span>
          </li>
        </ul>
        <p className="section-text">
          You may submit a request to us by contacting us at the Nickel contact information provided
          below. Once we verify your identify, we will assist you with your request.
        </p>
        <p className="section-text">
          If you remain unhappy with a response you receive about the treatment of your Personal
          Information you can also refer your concerns to the relevant privacy or data protection
          supervisor in your jurisdiction.
        </p>
        <div className="section-header">Opting-out of Nickel Communications</div>
        <p className="section-text">
          You may opt-out of receiving Communications by modifying your website or Service profile,
          or by unsubscribing to the marketing mailings or newsletters you no longer desire. Follow
          the “Unsubscribe” instructions that are contained within the mailing, newsletter, or other
          Communication that we send to you.
        </p>
        <div className="section-header">Changes to this Privacy Policy</div>
        <p className="section-text">
          Occasionally, we may change or update this Privacy Policy. For new users, such changes
          will become effective upon posting. For existing users, the changes and updates will
          become effective 30 days after posting and where appropriate may be notified to you by
          email. We encourage you to periodically review this Privacy Policy for the latest
          information on our privacy practices.
        </p>
        <div className="section-header">Questions or concerns</div>
        <p className="section-text">
          If you have any questions related to this Privacy Policy, please contact us at:
        </p>
        <p className="section-text">Nickel, Inc.</p>
        <p className="section-text">Attn: Privacy Officer</p>
        <p className="section-text">2100 W. Pleasant Grove Blvd. Suite 250</p>
        <p className="section-text">Pleasant Grove, Utah 84062</p>
        <p className="section-text">
          Email:&nbsp;
          <a href="mailto:privacy@nickeldxm.com">privacy@nickeldxm.com</a>
        </p>
        <p className="section-text">Telephone: 1.800.899.1000 (9:00 AM to 5:00 PM Mountain)</p>
        <p className="section-text">
          You can also contact the Nickel Data Privacy Officer by email to&nbsp;
          <a href="mailto:privacy@nickeldxm.com">privacy@nickeldxm.com</a>
        </p>
      </section>
    </div>
  </div>
);

export default Privacy;
