import React from 'react';

const InputErrorIcon = () => (
  <svg viewBox="0 0 16 16">
    <circle
      cx="8"
      cy="8"
      r="8"
      fill="#E0614A"
    />
    <line
      x1="5.5"
      y1="5.5"
      x2="10.5"
      y2="10.5"
      stroke="#FFF"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <line
      x1="10.5"
      y1="5.5"
      x2="5.5"
      y2="10.5"
      stroke="#FFF"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export default InputErrorIcon;
