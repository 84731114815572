import { emailValidator } from "../utils";
const nextEnabled = (state) => {
  const { email, name, activeSlide, role } = state;
  if (activeSlide === 0) return emailValidator(email) && name;
  if (activeSlide === 1) return !!role;
  return false;
};

export const userInitialState = {
  email: "",
  name: "",
  role: null,
  activeSlide: 0,
};
export const userReducer = (state, action) => {
  const { type, payload } = action;
  const withSimpleUpdates = { ...state, ...payload };
  let result = state;
  switch (type) {
    case "setActiveSlide":
    case "setEmail":
    case "setName":
    case "setRole":
      result = {
        ...withSimpleUpdates,
        nextEnabled: nextEnabled(withSimpleUpdates),
      };
      break;
    case "setCurrentUser":
      result = { ...userInitialState, ...payload };
      break;
    case "resetState":
      result = { ...state, ...userInitialState };
      break;
    default:
      break;
  }
  return result;
};
