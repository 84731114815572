import * as Yup from 'yup';

export default Yup.object().shape({
  password: Yup.string().min(6, 'Requires at least 6 characters.')
    .required('Required'),
  confirmPassword: Yup.string().oneOf([
    Yup.ref('password'),
    null,
  ], 'Passwords must match'),
  code: Yup.string().min(6, 'Requires at least 6 characters.')
    .required('Required'),
});
