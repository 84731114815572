import React from "react";
import "./Button.scss";

const Button = ({
  text,
  hidden = false,
  disabled = false,
  isStatic = false,
  onClick,
  icon: Icon,
  theme,
  style
}) => {
  const iconComponent = Icon ? (
    <span className="button-icon">
      <Icon size={text ? 18 : 24} />
    </span>
  ) : null;
  const buttonTheme = theme ? theme : "dark";
  const buttonHidden = hidden ? "hidden" : "";
  const buttonDisabled = disabled ? "disabled" : "";
  const buttonStatic = isStatic ? "static" : "";
  const textComponent = text ? (
    <span className="button-text">{text}</span>
  ) : null;

  const wrapperStyle = {
    padding: text ? "12px 16px" : "6px 8px",
    ...style
  };

  return (
    <button
      className={`button ${buttonTheme} ${buttonHidden} ${buttonDisabled} ${buttonStatic}`}
      style={wrapperStyle}
      onClick={isStatic || disabled ? null : onClick}
    >
      {iconComponent}
      {textComponent}
    </button>
  );
};
export default Button;
