import React, { useReducer, useCallback } from "react";
import { observer } from "mobx-react";
import { toast } from "react-toastify";
import { teamReducer, teamInitialState } from "../../../reducers/TeamReducer";
import Modal from "../../-common/Modal";
import UsersStore from "../../../stores/UsersStore";
import TeamsStore from "../../../stores/TeamsStore";
import InfoSlide from "./InfoSlide";
import SocialSlide from "./SocialSlide";
import TeamMembersSlide from "./TeamMembersSlide";
import "./NewTeamModal.scss";

const NewTeamModal = () => {
  const [state, dispatch] = useReducer(teamReducer, teamInitialState);

  const options = UsersStore.users.map(user => {
    return {
      label: user.fullName,
      value: user.userId
    };
  });

  const updateValue = useCallback(
    field => value => {
      const actionType = "set" + field[0].toUpperCase() + field.slice(1);
      dispatch({ type: actionType, payload: { [field]: value } });
    },
    []
  );

  const deleteCurrentOauth = platform => {
    dispatch({ type: "deleteSocial", payload: platform });
  };

  const finish = async () => {
    const { media, name, owner, social, members } = state;
    const ownerId = owner?.value;
    try {
      const teamId = await TeamsStore.addTeam({ media, name, owner: ownerId });
      if (social?.length) {
        const listOfSocial = social.map(s => ({ ...s, teamId }));
        await TeamsStore.addSocial(listOfSocial);
      }
      if (members?.length) {
        const listOfMembers = members
          .map(m => {
            if (m.name || m.phone) {
              return { ...m, teamId };
            }
            return {};
          })
          .filter(m => m?.name);
        await TeamsStore.addMember(listOfMembers);
      }
    } catch (e) {
      toast(`Error creating team`);
    }
    close();
  };

  const close = () => {
    dispatch({ type: "resetState", payload: null });
    TeamsStore.closeNewTeamModal();
  };

  const { slides, progress, progressTitle } = {
    slides: [
      <InfoSlide
        options={options}
        media={state.media}
        setMedia={updateValue("media")}
        name={state.name}
        setName={updateValue("name")}
        owner={state.owner}
        setOwner={updateValue("owner")}
      />,
      <SocialSlide onChange={updateValue("social")} social={state.social} deleteCurrentOauth={deleteCurrentOauth} />,
      <TeamMembersSlide members={state.members} setMembers={updateValue("members")} />
    ],
    progress: [
      { title: "Info", description: "Add the new team's information" },
      {
        title: "Social",
        description: "Connect the social accounts"
      },
      {
        title: "Team Members",
        description: "Add all of the team members"
      }
    ],
    progressTitle: "Add a new team in 3 easy steps"
  };

  return (
    <Modal
      nextEnabled={state.nextEnabled}
      slides={slides}
      progress={progress}
      progressTitle={progressTitle}
      isOpen={TeamsStore.showNewTeamModal}
      activeSlide={state.activeSlide}
      setActiveSlide={updateValue("activeSlide")}
      close={close}
      finish={finish}
    />
  );
};

export default observer(NewTeamModal);
