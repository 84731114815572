import React, { useState, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { getFileURLFromFile } from '../../../../utils';
import usePortal from '../../../../hooks/usePortal';
import BulletinsStore from '../../../../stores/BulletinsStore';
import Modal from '../../../-common/Modal';
import MediaSlide from '../../../-common/MediaSlide';
import { bulletinsTypes } from '../../../../constants/bulletins';
import ContentSlide from './ContentSlide';
import CTASlide from './CTASlide';
import './NewBulletinModal.scss';

const NewBulletinModal = ({ close }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [type, setType] = useState(bulletinsTypes.NEWS);

  const [file, setFile] = useState();
  const [fileURL, setFileURL] = useState();
  const [video, setVideo] = useState('');
  const uploadFile = async uploadedFile => {
    setVideo('');
    setFile(uploadedFile);
    const previewFileURL = await getFileURLFromFile(uploadedFile, uploadedFile.type === 'video/mp4');
    setFileURL(previewFileURL);
  };

  const [targetType, setTargetType] = useState();
  const [targetId, setTargetId] = useState();

  const updateBulletinType = useCallback(type => {
    setType(type.value);
  }, []);

  const cancel = () => {
    setActiveSlide(0);
    setMessage('');
    setSubject('');
    setType(bulletinsTypes.NEWS);
    close();
  };

  const finish = async () => {
    const bulletinObject = {
      subject,
      message,
      type,
      file,
      video,
      ...(targetType !== 'none' && { targetType }),
      targetId,
    };

    await BulletinsStore.addBulletin(bulletinObject);
    cancel();
  };

  const { slides, progress, progressTitle } = {
    slides: [
      <ContentSlide {...{ subject, setSubject, message, setMessage, updateBulletinType }} />,
      <MediaSlide
        fileURL={fileURL}
        onUpload={uploadFile}
        headline="Spice it up a bit."
        uploadLabelPrefix="Bulletin"
        isSupportingVideo
      />,
      <CTASlide {...{ targetType, setTargetType, targetId, setTargetId }} />,
    ],
    progress: [
      { title: 'Content', description: 'What is this bulletin about?' },
      { title: 'Media', description: 'Add some eye candy' },
      { title: 'Call to Action', description: 'What should this bulletin have the donor do?' },
    ],
    progressTitle: 'Add a bulletin in 3 easy steps',
  };

  const nextEnabled = () => {
    if (activeSlide === 0) return subject && message;
    if (activeSlide === 1) return true;
    if (activeSlide === 2) return targetType && (targetType !== 'none' ? targetId : true);
    return false;
  };

  return (
    <Modal
      nextEnabled={nextEnabled()}
      slides={slides}
      progress={progress}
      progressTitle={progressTitle}
      isOpen={true}
      activeSlide={activeSlide}
      setActiveSlide={setActiveSlide}
      close={close}
      finish={finish}
    />
  );
};

const NewBulletinModalContainer = ({ isOpen, ...props }) => {
  const portalTarget = usePortal('new-bulletin-modal-container');
  const contents = isOpen ? <NewBulletinModal {...props} /> : null;
  return createPortal(contents, portalTarget);
};

export default NewBulletinModalContainer;
