import React, { useState, useEffect, useCallback } from "react";
import { observer } from "mobx-react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { toast } from "react-toastify";
import arrayMove from "array-move";
import { MdCheck, MdClose } from "react-icons/md";
import { GoPlus } from "react-icons/go";
import PerksStore from "../../../stores/PerksStore";
import Page from "../../-common/Page";
import Button from "../../-common/Button";
import EditPerkCard from "./EditPerkCard";
import AddPerkModal from "./AddPerkModal";
import "./Perks.scss";

const comparePerkValues = originalPerks => perk => {
  const perkContext = originalPerks?.filter(t => t.perkId === perk.perkId);
  const { title, requirements } = perkContext[0];

  if (perk.title !== title) return true;
  if (perk.requirements !== requirements) return true;
  return false;
};

const checkIfPerkHasMoved = (originalPerks, perks) => {
  const originalPerkOrder = Object.fromEntries(originalPerks.map((p, i) => [p.perkId, i]));
  return perks.some(({ perkId }, i) => originalPerkOrder[perkId] !== i);
};

function ZeroState({ addPerk }) {
  return (
    <div className="zero-state">
      <div className="zero-state-text">Looks like you haven't created any perks yet - add some to get started!</div>
      <Button text="Add Perk" icon={GoPlus} onClick={addPerk} />
    </div>
  );
}

const SortablePerkCard = SortableElement(({ localSortIndex, updatePerk, perk, sorting, duringDragSortOrder }) => {
  return (
    <EditPerkCard
      localSortIndex={localSortIndex}
      update={updatePerk}
      perk={perk}
      sorting={sorting}
      duringDragSortOrder={duringDragSortOrder}
    />
  );
});

const PerkCardsList = SortableContainer(({ items, updatePerk, sorting, duringDragSortOrder }) => {
  return (
    <div className="edit-perk-cards">
      {items.map((perk, index) => (
        <SortablePerkCard
          index={index}
          localSortIndex={index}
          updatePerk={updatePerk}
          perk={perk}
          sorting={sorting}
          duringDragSortOrder={duringDragSortOrder}
          key={perk.perkId}
        />
      ))}
    </div>
  );
});

const Perks = () => {
  const [addPerkModalOpen, setAddPerkModalOpen] = useState(false);
  const openAddPerkModal = () => setAddPerkModalOpen(true);
  const closeAddPerkModal = () => setAddPerkModalOpen(false);

  const [originalPerks, setOriginalPerks] = useState([]);
  const [perks, setPerks] = useState([]);
  const { perks: storePerks } = PerksStore;
  useEffect(() => {
    setOriginalPerks(storePerks);
    setPerks(storePerks);
  }, [storePerks]);

  const [sorting, setSorting] = useState(false);
  const [duringDragSortOrder, setDuringDragSortOrder] = useState([]);

  const [saving, setSaving] = useState(false);

  const updatePerk = useCallback(
    updatedPerk => {
      setPerks(
        perks.map(t => {
          if (t.perkId === updatedPerk.perkId) return updatedPerk;
          return t;
        })
      );
    },
    [perks, setPerks]
  );

  const sortPerks = ({ oldIndex, newIndex }) => {
    const movedPerks = arrayMove(perks, oldIndex, newIndex);
    const newPerks = movedPerks.map((p, i) => ({ ...p, sortIndex: i }));
    setPerks(newPerks);
  };

  const checkSaveEnabled = ({ perks }) => {
    if (perks?.length) {
      return (perks?.map(comparePerkValues(originalPerks)) || []).some(Boolean);
    }
    return false;
  };

  const save = useCallback(async () => {
    setSaving(true);
    try {
      const perksToSave = perks.filter(comparePerkValues(originalPerks));
      const perkSavePromises = perksToSave.map(perk => {
        return PerksStore.updatePerk(perk);
      });
      await Promise.all(perkSavePromises);
      const perkHasMoved = checkIfPerkHasMoved(originalPerks, perks);
      if (perkHasMoved) await PerksStore.sortPerks(perks);
      toast("Perks updated!");
    } catch (err) {
      console.warn(err);
    }
    setSaving(false);
  }, [setSaving, perks, originalPerks]);

  const cancel = useCallback(() => {
    setPerks(originalPerks);
  }, [originalPerks, setPerks]);

  const perkHasMoved = checkIfPerkHasMoved(originalPerks, perks);
  const saveEnabled = perkHasMoved || checkSaveEnabled({ perks });

  const conditionalButtons = saveEnabled
    ? [
        <Button text="Cancel" theme="light" icon={MdClose} onClick={cancel} disabled={saving} key="cancel" />,
        <Button
          text={saving ? "Saving..." : "Save"}
          theme={saving ? "light" : null}
          icon={MdCheck}
          onClick={save}
          disabled={saving}
          key="save"
        />
      ]
    : [<Button text="Add Perk" onClick={openAddPerkModal} icon={GoPlus} key="add" />];

  const updateBeforeSortStart = () => setSorting(true);
  const onSortOver = ({ oldIndex, newIndex }) => {
    setDuringDragSortOrder(arrayMove(duringDragSortOrder, oldIndex, newIndex));
  };
  const onSortEnd = (...args) => {
    setSorting(false);
    sortPerks(...args);
  };

  const contents = PerksStore?.perks?.length ? (
    <PerkCardsList
      axis="xy"
      pressDelay={200}
      helperClass="perk-drag-ghost sorting"
      updateBeforeSortStart={updateBeforeSortStart}
      onSortOver={onSortOver}
      onSortEnd={onSortEnd}
      items={perks}
      updatePerk={updatePerk}
      sorting={sorting}
      duringDragSortOrder={duringDragSortOrder}
    />
  ) : (
    <ZeroState addPerk={openAddPerkModal} />
  );

  return (
    <Page titleOpts={{ title: "Perks" }} buttons={conditionalButtons}>
      <div className="perks">{contents}</div>
      <AddPerkModal isOpen={addPerkModalOpen} close={closeAddPerkModal} />
    </Page>
  );
};

export default observer(Perks);
