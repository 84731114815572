const nextEnabled = state => {
  const {
    activeSlide,
    teams,
    title,
    description,
    amount,
    pointMultiplier,
    quid
  } = state;
  if (activeSlide === 0)
    return (!!teams.length || !!teams.value) && title && description;
  if (activeSlide === 1) return true;
  if (activeSlide === 2) return amount && pointMultiplier && quid;
  return false;
};

export const campaignInitialState = {
  activeSlide: 0,
  teams: [],
  amount: 0,
  endDate: "",
  title: "",
  description: "",
  pointMultiplier: "",
  quid: "",
  file: null,
  fileURL: null,
  nextEnabled: false,
  creatingCampaign: false
};
export const campaignReducer = (state, action) => {
  const { type, payload } = action;
  const withSimpleUpdates = { ...state, ...payload };
  const { file, fileURL, creatingCampaign } = withSimpleUpdates;
  let result = state;

  switch (type) {
    case "setActiveSlide":
    case "setTeams":
    case "setTitle":
    case "setDescription":
    case "setEndDate":
    case "setAmount":
    case "setPointMultiplier":
    case "setQuid":
      result = {
        ...withSimpleUpdates,
        nextEnabled: nextEnabled(withSimpleUpdates)
      };
      break;
    case "setFile":
      result = { ...state, file };
      break;
    case "setFileURL":
      result = { ...state, fileURL };
      break;
    case "setCreatingCampaign":
      result = { ...state, creatingCampaign };
      break;
    case "resetState":
      result = { ...state, ...campaignInitialState };
      break;
    default:
      break;
  }
  return result;
};
