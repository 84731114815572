import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Router } from "@reach/router";
import { FaFilter, FaSortAmountDown, FaMoneyBill, FaBolt, FaBullhorn, FaUsers, FaStar } from "react-icons/fa";
import { FiExternalLink } from "react-icons/fi";
import { commaSeparateNumber } from "../../../utils";
import TransactionsStore, { filterOptions, sortOptions } from "../../../stores/TransactionsStore";
import GlobalSearchStore from "../../../stores/GlobalSearchStore";
import Page from "../../-common/Page";
import DropdownButton from "../../-common/DropdownButton";
import Button from "../../-common/Button";
import Transaction from "../Transaction";
import TransactionsTable from "./TransactionsTable";
import "./Transactions.scss";

const SummaryNumber = ({ icon: Icon, value, title }) => (
  <div className="summary-number">
    <div className="icon-wrapper">
      <Icon className="summary-icon" size={24} />
    </div>
    <div className="value">{value}</div>
    <div className="title">{title}</div>
  </div>
);

const SummaryNumbers = observer(() => {
  const {
    totalRevenue,
    totalTransactions,
    membershipRenewals = 0,
    campaignDonations = 0,
    totalRefunds = 0
  } = TransactionsStore;

  const summaryNumbers = [
    {
      icon: FaMoneyBill,
      title: "Total Revenue",
      value: "$" + commaSeparateNumber((totalRevenue / 100).toFixed(2), 2)
    },
    {
      icon: FaBolt,
      title: "Total Transactions",
      value: commaSeparateNumber(totalTransactions)
    },
    {
      icon: FaUsers,
      title: "Membership Renewals",
      value: commaSeparateNumber(membershipRenewals)
    },
    {
      icon: FaStar,
      title: "Campaign Donations",
      value: commaSeparateNumber(campaignDonations)
    },
    {
      icon: FaBullhorn,
      title: "Refunds Issued",
      value: commaSeparateNumber(totalRefunds)
    }
  ];
  const summaryNumberComponents = summaryNumbers.map((props, i) => <SummaryNumber {...props} key={i} />);
  return <div className="summary-numbers">{summaryNumberComponents}</div>;
});

function TransactionsInner() {
  useEffect(() => {
    GlobalSearchStore.setSearchAction(searchText => TransactionsStore.setSearch(searchText));
  }, []);
  const { filter, sort } = TransactionsStore;

  const titleOptions = { title: "Transactions" };

  const updateFilter = filter => TransactionsStore.setFilter(filter);
  const updateSort = sort => TransactionsStore.setSort(sort);

  return (
    <Page
      titleOpts={titleOptions}
      protectedContent={true}
      buttons={[
        <DropdownButton
          label="Filter"
          icon={FaFilter}
          theme="light"
          options={filterOptions}
          style={{ height: 42 }}
          value={filter}
          onChange={updateFilter}
          key="filter"
        />,
        <DropdownButton
          label="Sort"
          icon={FaSortAmountDown}
          theme="light"
          options={sortOptions}
          style={{ height: 42 }}
          value={sort}
          onChange={updateSort}
          key="sort"
        />,
        <Button
          text="Export"
          icon={FiExternalLink}
          onClick={() => TransactionsStore.getTransactionsCSV()}
          key="export"
        />
      ]}
    >
      <div className="audits-transactions">
        <SummaryNumbers />
        <TransactionsTable />
      </div>
    </Page>
  );
}

const Transactions = observer(TransactionsInner);

function TransactionsRouter() {
  return (
    <Router>
      <Transactions path="/" />
      <Transaction path=":transactionId" />
    </Router>
  );
}

export default TransactionsRouter;
