import axios from "axios";
import { API_HOST } from "../constants/main";

let windowObjectReference = null;
let previousUrl = null;

const receiveMessage = onChange => event => {
  if (event.origin !== "https://dev.api.nickeldxm.com" && event.origin !== "https://api.nickeldxm.com") {
    return;
  }
  const { data } = event;
  if (!!data["platform"]) {
    onChange(data);
  }
};

let handler;
const openSignInWindow = (url, onChange, name) => {
  // remove any existing event listeners
  window.removeEventListener("message", handler);

  // window features
  const strWindowFeatures = "toolbar=no, menubar=no, width=600, height=700, top=100, left=100";

  if (windowObjectReference === null || windowObjectReference.closed) {
    windowObjectReference = window.open(url, name, strWindowFeatures);
  } else if (previousUrl !== url) {
    windowObjectReference = window.open(url, name, strWindowFeatures);
    windowObjectReference.focus();
  } else {
    windowObjectReference.focus();
  }
  handler = receiveMessage(onChange);
  window.addEventListener("message", handler, false);
  previousUrl = url;
};

const initiateAuth = async (platform, onChange) => {
  try {
    const tokenResponse = await axios.get(`${API_HOST}/v1/oauth/${platform}/token`);
    const { data } = tokenResponse;
    let authenticationUrl;

    if (platform === "twitter") {
      authenticationUrl = `https://api.twitter.com/oauth/authenticate?oauth_token=${data.oauth_token}`;
    } else if (platform === "instagram") {
      authenticationUrl = `https://api.instagram.com/oauth/authorize/?client_id=${data.clientId}&redirect_uri=${data.redirectUri}&scope=${data.scope}&response_type=${data.responseType}`;
    } else if (platform === "facebook") {
      authenticationUrl = `https://www.facebook.com/v4.0/dialog/oauth?client_id=${data.clientId}&redirect_uri=${data.redirectUri}&scope=publish_pages,manage_pages`;
    }

    openSignInWindow(authenticationUrl, onChange, `${platform}-oauth`);
  } catch (error) {
    console.warn("err", error);
  }
};

export default initiateAuth;
