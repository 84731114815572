import React from "react";
import { Router, Redirect } from "@reach/router";
import Accounts from "./Accounts";
import DataSources from "./DataSources";
import Explorer from "./Explorer";
import "./Integrations.scss";

export default function Integrations() {
  return (
    <Router>
      <Accounts path="accounts" />
      <DataSources path="datasources/*" />
      <Explorer path="explorer" />
      <Redirect from="integrations" to="/integrations/accounts" noThrow />
      <Redirect from="*" to="/integrations/accounts" noThrow />
    </Router>
  );
}
