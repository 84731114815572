import React from "react";
import { observer } from "mobx-react";
import { Router } from "@reach/router";
import { GoPlus } from "react-icons/go";
import TeamsStore from "../../../stores/TeamsStore";
import Page from "../Page";
import Button from "../Button";
import Team from "../Team";
import TeamsTable from "./TeamsTable";
import "./Teams.scss";

function TeamsInner() {
  const titleOptions = { title: "Teams" };

  return (
    <Page
      titleOpts={titleOptions}
      buttons={[
        <Button
          text="Add Team"
          icon={GoPlus}
          onClick={TeamsStore.openNewTeamModal}
          theme="dark"
          key="add-user"
        />
      ]}
    >
      <div className="teams-container">
        <TeamsTable />
      </div>
    </Page>
  );
}

const Teams = observer(TeamsInner);

function TeamsRouter() {
  return (
    <Router>
      <Teams path="/" />
      <Team path=":teamId" />
    </Router>
  );
}

export default TeamsRouter;
