import { observable, action } from "mobx";
import { globalHistory } from "@reach/router";

const rewardsRegex = /\/campaigns\/rewards\/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/g;
const payoutsRegex = /\/audit\/payouts\/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/g;
const teamsRegex = /\/settings\/teams\/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/g;

class GlobalSearchStore {
  constructor() {
    this.updatePlaceholder(window.location);
    globalHistory.listen(({ action, location }) => {
      if (action === "PUSH" || action === "POP") {
        this.setSearchAction(null);
        this.clearSearch();
        this.updatePlaceholder(location);
      }
    });
  }

  @observable placeholder = "Search...";

  @action updatePlaceholder(location) {
    const { pathname } = location;
    if (pathname === "/club/explore") {
      this.placeholder = "Search Club Members";
    } else if (pathname === "/club/app/achievements") {
      this.placeholder = "Search Achievements";
    } else if (pathname === "/campaigns/manage") {
      this.placeholder = "Search Campaigns";
    } else if (pathname === "/campaigns/rewards") {
      this.placeholder = "Search Rewards";
    } else if (pathname.match(rewardsRegex)) {
      this.placeholder = "Search Reward Activity";
    } else if (pathname === "/audit/transactions") {
      this.placeholder = "Search Transactions";
    } else if (pathname === "/audit/payouts") {
      this.placeholder = "Search Payouts";
    } else if (pathname.match(payoutsRegex)) {
      this.placeholder = "Search Payout Transactions";
    } else if (pathname === "/settings/users") {
      this.placeholder = "Search Users";
    } else if (pathname === "/settings/teams") {
      this.placeholder = "Search Teams";
    } else if (pathname.match(teamsRegex)) {
      this.placeholder = "Search Team Members";
    } else if (pathname === "/communication/bulletins") {
      this.placeholder = "Search Bulletins";
    } else {
      this.placeholder = "Search...";
    }
  }

  @observable searchText = "";

  @action clearSearch() {
    this.searchText = "";
  }

  @action setSearch(searchText, ...args) {
    this.searchText = searchText;
    if (this.searchAction && typeof this.searchAction === "function") {
      this.searchAction(searchText, ...args);
    }
  }

  @observable searchAction = () => null;

  @action setSearchAction(action) {
    if (action && typeof action === "function") {
      this.searchAction = action;
    } else {
      this.searchAction = null;
    }
  }
}

export default new GlobalSearchStore();
