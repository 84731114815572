import React from "react";
import "./WhySlide.scss";

const WhySlide = () => {
  return (
    <div className="slide why-slide">
      <div className="headline">Why do we need this?</div>

      <div className="contents">
        <div className="body-text">
          Now for the proverbial million dollar question: Where would you like us to deposit all the money? We'll need
          your bank name, routing number and account number.
        </div>

        <div className="body-text">
          And finally, in order to prove that this account was created by a real person, banks require the social
          security number and birthday of the representative associated with your organization. This is required by{" "}
          <a
            href="https://www.treasury.gov/resource-center/terrorist-illicit-finance/Terrorist-Finance-Tracking/Documents/staterule.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Section 326 of the U.S. Patriot Act
          </a>{" "}
          and we cannot begin processing transactions until this information is provided.
        </div>

        <div className="body-text">
          Don't worry, Nickel does not store this information anywhere in our systems. This information is automatically
          passed onto the bank using a secure connection.
        </div>
      </div>
    </div>
  );
};

export default WhySlide;
