import { observable, action } from "mobx";
import { request } from "../utils";

class PledgesStore {
  //INDIVIDUAL MEMBERS PLEDGES

  @observable memberPledges = {};

  @action async fetchPledgesForMember(userId) {
    try {
      const memberPledges = await request.get(`/v1/pledges?userId=${userId}`);
      this.memberPledges = { ...memberPledges, [userId]: memberPledges };
      return memberPledges;
    } catch (err) {
      console.warn(err);
    }
  }

  // CLEANUP
  @action clear() {
    this.rawMemberPledges = {};
  }
}

export default new PledgesStore();
