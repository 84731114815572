import React from "react";
import { Router, Redirect } from "@reach/router";
import Transactions from "./Transactions";
import Payouts from "./Payouts";
import "./Audit.scss";

export default function Audit() {
  return (
    <Router>
      <Transactions path="transactions/*" />
      <Payouts path="payouts/*" />
      <Redirect from="*" to="/audit/transactions" noThrow />
    </Router>
  );
}
