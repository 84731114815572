import React from "react";

export default function baseballHelmet({
  size = 24,
  fill = "none",
  stroke = "#000",
  strokeWidth = 13
}) {
  return (
    <svg
      viewBox="0 0 349.949 349.949"
      height={size}
      width={size}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
    >
      <path
        strokeLinecap="round"
        d="M173.35,200.809 c34.658-3.015,67.178-20.098,89.248-47.777"
      />
      <path d="M179.214,200.561 c14.626,1.29,28.062,10.451,34.116,24.939c8.693,20.805-1.125,44.717-21.929,53.411l6.56-3.466 c-2.156,1.223-4.348,2.376-6.559,3.469c-31.827,15.644-69.952,18.087-105.335,3.559c-65.513-26.898-96.825-101.815-69.923-167.337 s101.828-96.83,167.341-69.932c46.013,18.892,75.149,61.458,79.114,107.829" />
      <path d="M190.54,203.763 c0,0,117.742,1.315,130.271-35.492c1.376-4.042-1.232-8.363-5.405-9.267c-19.1-4.139-53.047-11.689-53.047-11.689" />
      <circle cx="172.878" cy="241.796" r="13.333" />
    </svg>
  );
}
