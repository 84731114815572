const getFileURLFromFile = async (file, useBlob) => {
  if (useBlob) {
    return window.URL.createObjectURL(file);
  }

  let urlResolver;
  const returnPromise = new Promise(resolve => (urlResolver = resolve));
  const reader = new FileReader();
  reader.onload = e =>
    typeof urlResolver === "function" && urlResolver(e.target.result);
  reader.readAsDataURL(file);
  return returnPromise;
};

export default getFileURLFromFile;
