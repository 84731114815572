import React from "react";

export default function volleyball({
  size = 24,
  fill = "none",
  stroke = "#000",
  strokeWidth = 13
}) {
  return (
    <svg
      viewBox="0 0 255.222 255.222"
      height={size}
      width={size}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
    >
      <path d="M248.722,127.611 C248.722,60.669,194.553,6.5,127.611,6.5S6.5,60.669,6.5,127.611s54.169,121.111,121.111,121.111 S248.722,194.553,248.722,127.611z" />
      <line x1="127.611" y1="127.611" x2="127.611" y2="248.722" />
      <line x1="127.611" y1="127.611" x2="232.496" y2="67.055" />
      <line x1="127.611" y1="127.611" x2="22.726" y2="67.055" />
      <path d="M168.742,241.57 c6.451-45.327,6.451-91.339,0-136.665" />
      <path d="M210.199,216.226 c11.394-44.378,11.394-90.914,0-135.292" />
      <path d="M206.005,35.239 c-42.529,16.953-82.443,39.843-118.554,67.989" />
      <path d="M163.396,11.883 C119.232,24.076,78.862,47.227,46.034,79.188" />
      <path d="M8.425,105.864 c35.937,28.367,75.709,51.502,118.133,68.717" />
      <path d="M9.486,154.443 c32.631,32.163,72.857,55.562,116.946,68.026" />
    </svg>
  );
}
