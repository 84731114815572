import { observable, computed } from "mobx";
import { parse } from "query-string";
import { getSubdomain } from "../utils";
import TenantStore from "./TenantStore";

const ssKey = `nickel:${getSubdomain()}:features`;
const enabledKey = ssKey + ":enabled";
const disabledKey = ssKey + ":disabled";
class FeatureSwitchStore {
  constructor() {
    const { _f: urlSwitchString = "" } = parse(window?.location?.search);
    const urlSwitches = urlSwitchString?.replace(/\s/g, "")?.split(",");

    let existingSessionEnabledSwitches = [];
    try {
      existingSessionEnabledSwitches = JSON.parse(sessionStorage.getItem(enabledKey) || "[]");
    } catch {}
    const enabledUrlSwitches = urlSwitches?.filter(s => !s.includes("!"))?.filter(Boolean);
    const combinedEnabledSwitches = Object.keys({
      ...Object.fromEntries(existingSessionEnabledSwitches.map(k => [k, true])),
      ...Object.fromEntries(enabledUrlSwitches.map(k => [k, true]))
    });

    let existingSessionDisabledSwitches = [];
    try {
      existingSessionDisabledSwitches = JSON.parse(sessionStorage.getItem(disabledKey) || "[]");
    } catch {}
    const disabledUrlSwitches = urlSwitches?.filter(s => s.includes("!"))?.map(s => s?.replace("!", ""));
    const combinedDisabledSwitches = Object.keys({
      ...Object.fromEntries(existingSessionDisabledSwitches.map(k => [k, true])),
      ...Object.fromEntries(disabledUrlSwitches.map(k => [k, true]))
    });

    const disabledSwitches = combinedDisabledSwitches.filter(s => !enabledUrlSwitches?.includes(s));
    const enabledSwitches = combinedEnabledSwitches.filter(s => !disabledSwitches?.includes(s));

    this.sessionEnabledSwitches = enabledSwitches;
    sessionStorage.setItem(enabledKey, JSON.stringify(enabledSwitches));

    this.sessionDisabledSwitches = disabledSwitches;
    sessionStorage.setItem(disabledKey, JSON.stringify(disabledSwitches));
  }

  @observable sessionEnabledSwitches = [];

  @observable sessionDisabledSwitches = [];

  @computed get enabledSwitches() {
    const { featureSwitches } = TenantStore?.tenant?.adminConfig || {};
    const backendSwitchEntries = (featureSwitches || []).map(s => [s, true]);
    const backendSwitches = Object.fromEntries(backendSwitchEntries);

    const sessionSwitchEntries = (this.sessionEnabledSwitches || []).map(s => [s, true]);
    const sessionSwitches = Object.fromEntries(sessionSwitchEntries);

    const sessionDisabledSwitchEntries = (this.sessionDisabledSwitches || []).map(s => [s, false]);
    const sessionDisabledSwitches = Object.fromEntries(sessionDisabledSwitchEntries);

    return { ...backendSwitches, ...sessionSwitches, ...sessionDisabledSwitches };
  }
}

export default new FeatureSwitchStore();
