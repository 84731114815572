const { request } = require("../utils");
const { default: TenantStore } = require("../stores/TenantStore");

async function uploadRenewalImages({ media90, media60, media30 }) {
  try {
    let media90Url, media60Url, media30Url;
    if (media90) {
      const { url, fileType } = await request.get("/v1/renewal/media/90/upload", {
        headers: { "file-type": media90.type }
      });
      await request.bare.put(url, media90, {
        headers: { "Content-Type": fileType }
      });
      media90Url = url;
    }

    if (media60) {
      const { url, fileType } = await request.get("/v1/renewal/media/60/upload", {
        headers: { "file-type": media60.type }
      });
      await request.bare.put(url, media60, {
        headers: { "Content-Type": fileType }
      });
      media60Url = url;
    }

    if (media30) {
      const { url, fileType } = await request.get("/v1/renewal/media/30/upload", {
        headers: { "file-type": media30.type }
      });
      await request.bare.put(url, media30, {
        headers: { "Content-Type": fileType }
      });
      media30Url = url;
    }

    const updatedTenant = await TenantStore.fetchTenant();
    return { media90Url, media60Url, media30Url, updatedTenant };
  } catch (err) {
    console.warn(err);
  }
}

export default uploadRenewalImages;
