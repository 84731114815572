import React, { useState, useCallback } from "react";
import { createPortal } from "react-dom";
import { observer } from "mobx-react";
import usePortal from "../../../../hooks/usePortal";
import FullScreenModal from "../../../-common/FullScreenModal";
import Button from "../../../-common/Button";
import PieTimer from "../../../-common/PieTimer";
import "./IssueRefundModal.scss";
import TransactionsStore from "../../../../stores/TransactionsStore";

const IssueRefundModal = observer(({ close, transaction }) => {
  const { transactionId } = transaction;

  const [issueEnabled, setIssueEnabled] = useState(false);

  const showIssueButton = useCallback(() => {
    setIssueEnabled(true);
  }, [setIssueEnabled]);

  const issue = useCallback(async () => {
    close();
    TransactionsStore.refundTransaction(transactionId);
  }, [transactionId, close]);

  const issueButton = issueEnabled ? (
    <Button text="Issue Refund" onClick={issue} />
  ) : (
    <div className="timer-container">
      <PieTimer
        color="var(--Red)"
        width={24}
        height={24}
        duration={3000}
        onComplete={showIssueButton}
      />
    </div>
  );

  return (
    <FullScreenModal isOpen={true} close={close}>
      <div className="issue-refund-modal">
        <div className="modal-contents">
          <div className="headline">You sure?</div>
          <div className="headline">
            Once you issue this refund, it can't be undone.
          </div>
        </div>
        <div className="bottom-buttons">
          <Button text="Cancel" theme="light" onClick={close} />
          {issueButton}
        </div>
      </div>
    </FullScreenModal>
  );
});

const IssueRefundModalContainer = ({ isOpen, ...props }) => {
  const portalTarget = usePortal("issue-refund-modal-container");
  const contents = isOpen ? <IssueRefundModal {...props} /> : null;
  return createPortal(contents, portalTarget);
};

export default IssueRefundModalContainer;
