import React from "react";
import { observer } from "mobx-react";
import MessagesStore from "../../../stores/MessagesStore";
import Page from "../../-common/Page";
import ThreadsTable from "./ThreadsTable";
import "./Messages.scss";

const titleOptions = { title: "Messages" };

const Messages = observer(() => {
  const hasThreads = MessagesStore.threadPreviews?.length;

  const body = hasThreads ? (
    <ThreadsTable />
  ) : (
    <div className="zero-state">
      <h2 className="zero-state-message">Looks like you're all caught up - nice work!</h2>
    </div>
  );

  return (
    <Page titleOpts={titleOptions}>
      <div className="home-messages">{body}</div>
    </Page>
  );
});

export default Messages;
