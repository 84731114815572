import React from "react";
import { FiShoppingCart } from "react-icons/fi";
import { commaSeparateNumber } from "../../../../utils";
import Input from "../../../-common/Input";
import Select from "../../../-common/Select2";
import "./RewardsSlide.scss";

const RewardsSlide = ({
  typeOptions,
  setRewardCategory,
  rewardCategory,
  options,
  reward,
  setReward,
  quantity,
  setQuantity
}) => {
  const { availableQuantity = 0 } = reward;

  const onRewardCategoryChange = selectedRewardTypeObject => {
    setRewardCategory(selectedRewardTypeObject);
    setReward({});
    setQuantity("");
  };

  const onRewardChange = ({ item: selectedReward }) => {
    setReward(selectedReward);
  };

  const selectedReward = {
    label: reward.title,
    value: reward.rewardId
  };

  const availableQuantityMessage = `${availableQuantity} more available`;

  const rewardPickerComponents = reward.rewardId ? (
    <>
      <Input
        value={quantity}
        onChange={setQuantity}
        label="Quantity"
        icon={FiShoppingCart}
        formatter={commaSeparateNumber}
      />
      <div className="reward-quantity-available">{availableQuantityMessage}</div>
    </>
  ) : null;

  const rewardCategoryPickerComponents =
    rewardCategory.value && rewardCategory.value !== "none" ? (
      <>
        <Select options={options} value={selectedReward} onChange={onRewardChange} label="Choose Reward Item" />
      </>
    ) : null;

  return (
    <div className="slide rewards-slide">
      <div className="headline">Tell us a little more</div>
      <div className="inputs">
        <Select
          options={typeOptions}
          value={rewardCategory}
          onChange={onRewardCategoryChange}
          label="What type of reward would you like to offer?"
        />
        {rewardCategoryPickerComponents}
        {rewardPickerComponents}
      </div>
    </div>
  );
};

export default RewardsSlide;
