import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { MdChevronLeft } from 'react-icons/md';
import { goBack } from '../../utils';
import FeatureSwitchStore from '../../stores/FeatureSwitchStore';
import Section from './Section';
import './Sidebar.scss';

function Sidebar({
  location,
  navigate,
  settingsOpen,
  setSettingsOpen,
  profileOpen,
  setProfileOpen,
}) {
  const [bulletinDetailsOpen, setBulletinDetailsOpen] = useState(false);
  const [campaignDetailsOpen, setCampaignDetailsOpen] = useState(false);
  const [donorAppOpen, setDonorAppOpen] = useState(false);
  const [dataSourceTargetOpen, setDataSourceTargetOpen] = useState(false);

  useEffect(() => {
    const isSettingsRoute = (location.pathname || '').match(/^\/settings/);
    setSettingsOpen(isSettingsRoute);

    const isBulletinDetailsRoute = (location.pathname || '').match(
      /^\/club\/bulletins\/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/,
    );
    setBulletinDetailsOpen(isBulletinDetailsRoute);

    const isCampaignDetailsRoute = (location.pathname || '').match(
      /^\/campaigns\/manage\/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/,
    );
    setCampaignDetailsOpen(isCampaignDetailsRoute);

    const isDonorAppRoute = (location.pathname || '').match(/^\/club\/app/);
    setDonorAppOpen(isDonorAppRoute);

    const isDataSourceTargetRoute = (location.pathname || '').match(/^\/integrations\/datasources\/[A-Za-z]+/);
    setDataSourceTargetOpen(isDataSourceTargetRoute);

    const isProfileRoute = (location.pathname || '').match(/^\/profile/);
    setProfileOpen(isProfileRoute);
  }, [
    location,
    setSettingsOpen,
    setCampaignDetailsOpen,
    setDonorAppOpen,
    setProfileOpen,
  ]);

  const { from } = location?.state || {};
  const [locationBeforeSettings, setLocationBeforeSettings] = useState();
  const [locationBeforeProfile, setLocationBeforeProfile] = useState();
  useEffect(() => {
    if (from) {
      setLocationBeforeSettings(from);
      setLocationBeforeProfile(from);
    }
  }, [from]);

  const closeSettings = () => {
    if (locationBeforeSettings && !locationBeforeSettings.match(/^\/settings/)) {
      navigate(locationBeforeSettings);
      setLocationBeforeSettings(null);
    } else {
      navigate('/campaigns/manage');
    }
    setSettingsOpen(false);
  };

  const closeProfile = () => {
    if (locationBeforeProfile && !locationBeforeProfile.match(/^\/profile/)) {
      navigate(locationBeforeProfile);
      setLocationBeforeSettings(null);
    } else {
      navigate('/campaigns/manage');
    }
    setProfileOpen(false);
  };

  const closeDetailSidebar = () => {
    if (location?.state?.useBack) {
      goBack();
    } else if (location?.pathname?.match(/^\/campaigns/)) {
      navigate('/campaigns/manage');
    } else if (location?.pathname?.match(/^\/club\/bulletins/)) {
      navigate('/club/bulletins');
    } else {
      navigate('/campaigns/manage');
    }
    setBulletinDetailsOpen(false);
    setCampaignDetailsOpen(false);
  };

  let sections = [];
  if (settingsOpen) {
    sections = sections.concat({
      title: 'Settings',
      links: [
        { title: 'Users', href: '/settings/users' },
        { title: 'Default Officer', href: '/settings/default-officer' },
        { title: 'Teams', href: '/settings/teams' },
        { title: 'Campaigns', href: '/settings/campaigns' },
        { title: 'Perks', href: '/settings/perks' },
        { title: 'Reward Options', href: '/settings/rewardoptions' },
        { title: 'Payment Processor', href: '/settings/payments' },
        { title: 'Hosting', href: '/settings/hosting' },
        { title: 'Theme', href: '/settings/theme' },
      ],
    });
  } else if (bulletinDetailsOpen) {
    const rootPathMatches = (location.pathname || '').match(
      /^\/club\/bulletins\/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/,
    );
    if (rootPathMatches) {
      const rootPath = rootPathMatches[0];
      sections = sections.concat({
        title: 'Bulletin',
        links: [
          { title: 'Overview', href: `${rootPath}/overview` },
          { title: 'Content', href: `${rootPath}/content` },
          { title: 'Schedule', href: `${rootPath}/schedule` },
        ],
      });
    } else {
      setBulletinDetailsOpen(false);
    }
  } else if (campaignDetailsOpen) {
    const rootPathMatches = (location.pathname || '').match(
      /^\/campaigns\/manage\/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/,
    );
    if (rootPathMatches) {
      const rootPath = rootPathMatches[0];
      sections = sections.concat({
        title: 'Campaign',
        links: [
          // { title: "Overview", href: rootPath },
          { title: 'General', href: `${rootPath}/general` },
          { title: 'Media', href: `${rootPath}/media` },
          { title: 'Tiers', href: `${rootPath}/tiers` },
          { title: 'Extras', href: `${rootPath}/extras` },
          { title: 'Schedule', href: `${rootPath}/schedule` },
        ],
      });
    } else {
      setCampaignDetailsOpen(false);
    }
  } else if (donorAppOpen) {
    const rootPathMatches = (location.pathname || '').match(/^\/club\/app/);
    if (rootPathMatches) {
      const rootPath = rootPathMatches[0];
      sections = sections.concat({
        title: 'Donor App',
        links: [
          { title: 'Theme', href: `${rootPath}/theme` },
          { title: 'Achievements', href: `${rootPath}/achievements` },
          { title: 'Features', href: `${rootPath}/features` },
          { title: 'Teams', href: `${rootPath}/teams` },
          { title: 'Renewal Flow', href: `${rootPath}/renewal` },
          { title: 'Ticket Renewal Popup', href: `${rootPath}/ticket-renewal-popup` },
          { title: 'Ticket Sponsorship', href: `${rootPath}/ticketsponsor` },
          { title: 'Contact Details', href: `${rootPath}/contact-details` },
        ],
      });
    } else {
      setDonorAppOpen(false);
    }
  } else if (dataSourceTargetOpen) {
    const rootPathMatches = (location.pathname || '').match(/^\/integrations\/datasources\/[A-Za-z]+/);
    if (rootPathMatches) {
      const rootPath = rootPathMatches[0];
      sections = sections.concat({
        title: 'Data Source',
        links: [
          { title: 'Sources', href: `${rootPath}/sources` },
          { title: 'History', href: `${rootPath}/history` },
          { title: 'Validation', href: `${rootPath}/validation` },
          { title: 'Mapping', href: `${rootPath}/mapping` },
        ],
      });
    } else {
      setDataSourceTargetOpen(false);
    }
  } else if (profileOpen) {
    const rootPathMatches = (location.pathname || '').match(/^\/profile/);
    if (rootPathMatches) {
      const rootPath = rootPathMatches[0];
      sections = sections.concat({
        title: 'Profile',
        links: [
          { title: 'Personal', href: `${rootPath}/personal` },
          // { title: "Notifications", href: rootPath + "/notifications" },
          // { title: "Roles", href: rootPath + "/roles" },
          // { title: "Activity", href: rootPath + "/activity" },
        ],
      });
    } else {
      setProfileOpen(false);
    }
  } else {
    if (FeatureSwitchStore.enabledSwitches['tactical-crm']) {
      sections = sections
        .concat({
          title: 'My Home',
          links: [{ title: 'Messages', href: '/home/messages' }],
        })
        .concat({
          title: 'Club Hub',
          links: [
            { title: 'Explore', href: '/club/explore' },
            { title: 'Levels', href: '/club/levels' },
            { title: 'Priority Points', href: '/club/points' },
            { title: 'Bulletins', href: '/club/bulletins' },
            { title: 'Donor App', href: '/club/app' },
          ],
        });
    }

    sections = sections.concat({
      title: 'Campaigns',
      links: [
        { title: 'Statistics', href: '/campaigns/stats' },
        { title: 'Manage', href: '/campaigns/manage' },
        { title: 'Rewards', href: '/campaigns/rewards' },
        { title: 'Theme', href: '/campaigns/theme' },
      ],
    });

    sections = sections.concat({
      title: 'Audit',
      links: [
        { title: 'Transactions', href: '/audit/transactions' },
        { title: 'Payouts', href: '/audit/payouts' },
      ],
    });

    if (FeatureSwitchStore.enabledSwitches['integrations']) {
      sections = sections.concat({
        title: 'Integrations',
        links: [
          { title: 'Accounts', href: '/integrations/accounts' },
          { title: 'Data Sources', href: '/integrations/datasources' },
          { title: 'Explorer', href: '/integrations/explorer' },
        ],
      });
    }
  }

  const showDetailBackButton =
    settingsOpen || profileOpen || bulletinDetailsOpen || campaignDetailsOpen || donorAppOpen || dataSourceTargetOpen;
  let detailBackButtonAction = closeDetailSidebar;
  if (settingsOpen) detailBackButtonAction = closeSettings;
  if (profileOpen) detailBackButtonAction = closeProfile;
  const detailBackButton = showDetailBackButton ? (
    <div className="back-button" onClick={detailBackButtonAction}>
      <MdChevronLeft size={20} />
      <div>BACK</div>
    </div>
  ) : null;

  return (
    <div className="sidebar">
      <div className="content">
        {detailBackButton}
        <div className="sections">
          {sections.map(section => (
            <Section {...section} location={location} key={section.title} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default observer(Sidebar);
