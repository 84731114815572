import React, { useState, useCallback } from "react";
import { createPortal } from "react-dom";
import { observer } from "mobx-react";
import usePortal from "../../../hooks/usePortal";
import FullScreenModal from "../../-common/FullScreenModal";
import Button from "../../-common/Button";
import Input from "../../-common/Input";
import "./ChangeInventoryModal.scss";
import RewardsStore from "../../../stores/RewardsStore";

const ChangeInventoryModal = observer(({ close, reward }) => {
  const [amount, setAmount] = useState("");
  const addEnabled = !!amount && !isNaN(+amount) && +amount > 0;
  const removeEnabled = addEnabled && +amount <= +reward.availableQuantity;

  const add = useCallback(async () => {
    if (addEnabled) {
      RewardsStore.addRewardStock(reward.rewardId, amount);
      close();
    }
  }, [addEnabled, reward, amount, close]);

  const remove = useCallback(async () => {
    if (removeEnabled) {
      RewardsStore.removeRewardStock(reward.rewardId, amount);
      close();
    }
  }, [removeEnabled, reward, amount, close]);

  return (
    <FullScreenModal isOpen={true} close={close}>
      <div className="change-inventory-modal">
        <div className="modal-contents">
          <div className="headline">Change the inventory for this reward.</div>
          <div className="sub-headline">
            (There are {reward.availableQuantity} available currently)
          </div>
          <div>
            <Input
              label="How much?"
              value={amount}
              onChange={setAmount}
              placeholder="Add or remove this amount"
            />
          </div>
        </div>
        <div className="bottom-buttons">
          <Button text="cancel" theme="light" onClick={close} />
          <div className="right-buttons">
            <Button disabled={!removeEnabled} text="Remove" onClick={remove} />
            <Button disabled={!addEnabled} text="Add" onClick={add} />
          </div>
        </div>
      </div>
    </FullScreenModal>
  );
});

const ChangeInventoryModalContainer = ({ isOpen, ...props }) => {
  const portalTarget = usePortal("change-inventory-modal-container");
  const contents = isOpen ? <ChangeInventoryModal {...props} /> : null;
  return createPortal(contents, portalTarget);
};

export default ChangeInventoryModalContainer;
