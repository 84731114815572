import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Router, Redirect, navigate } from "@reach/router";
import { FaFilter, FaSortAmountDown } from "react-icons/fa";
import { GoPlus } from "react-icons/go";
import { commaSeparateNumber } from "../../../utils";
import AchievementsStore, { sortOptions, filterOptions } from "../../../stores/AchievementsStore";
import GlobalSearchStore from "../../../stores/GlobalSearchStore";
import Page from "../../-common/Page";
import AppAchievement from "../AppAchievement";
import DropdownButton from "../../-common/DropdownButton";
import Button from "../../-common/Button";
import AddAchievementModal from "../AddAchievementModal";
import "./AppAchievements.scss";

const AchievementBox = ({ achievementId, title, description, totalUsers }) => {
  const banner = false ? (
    <img src={""} className="banner-image" alt="The achievement item." />
  ) : (
    <div className="banner-placeholder" />
  );

  return (
    <div className="achievement-square" onClick={() => navigate(`achievements/${achievementId}`)}>
      {banner}
      <div className="info-wrapper">
        <div className="titles">
          <div className="info-title">{title}</div>
          <div className="info-description">{description}</div>
        </div>
        <div className="metrics">
          <div className="metric-wrapper" />
          <div className="metric-wrapper" />
          <div className="metric-wrapper">
            <div className="metric">{commaSeparateNumber(totalUsers)}</div>
            <div className="label">Members Achieved</div>
          </div>
        </div>
      </div>
    </div>
  );
};

function AchievementsSection({ title, data = [], noTopMargin }) {
  if (data.length) {
    const squares = data.map(a => <AchievementBox {...a} key={a.achievementId} />);
    const titleStyles = noTopMargin ? { marginTop: 0 } : null;
    return (
      <>
        <div className="achievement-squares-title" style={titleStyles}>
          {title}
        </div>
        <div className="achievement-squares">{squares}</div>
      </>
    );
  }
  return null;
}

const AppAchievements = observer(() => {
  useEffect(() => {
    GlobalSearchStore.setSearchAction(searchText => {
      AchievementsStore.setSearch(searchText);
    });
  }, []);

  const { filter, sort, achievements, achievementsByCategory } = AchievementsStore;

  const updateFilter = filter => AchievementsStore.setFilter(filter);
  const updateSort = sort => AchievementsStore.setSort(sort);

  const closeModal = () => AchievementsStore.closeNewAchievementModal();

  const titleOptions = { title: "Achievements" };

  const achievementSquares =
    filter.value === "All" ? (
      <>
        <AchievementsSection
          title={`Points-Based (${achievementsByCategory?.["Points-Based"]?.length || 0})`}
          data={achievementsByCategory?.["Points-Based"]}
          noTopMargin={true}
        />
        <AchievementsSection
          title={`Time-Based (${achievementsByCategory?.["Time-Based"]?.length || 0})`}
          data={achievementsByCategory?.["Time-Based"]}
        />
        <AchievementsSection
          title={`Amount-Based (${achievementsByCategory?.["Amount-Based"]?.length || 0})`}
          data={achievementsByCategory?.["Amount-Based"]}
        />
      </>
    ) : (
      <AchievementsSection title=" " data={achievements} noTopMargin={true} />
    );

  return (
    <Page
      titleOpts={titleOptions}
      buttons={[
        <DropdownButton
          label="Filter"
          icon={FaFilter}
          theme="light"
          options={filterOptions}
          style={{ height: 42 }}
          value={filter}
          onChange={updateFilter}
          key="filter"
        />,
        <DropdownButton
          label="Sort"
          icon={FaSortAmountDown}
          theme="light"
          options={sortOptions}
          style={{ height: 42 }}
          value={sort}
          onChange={updateSort}
          key="sort"
        />,
        <Button
          text="Add Achievement"
          icon={GoPlus}
          theme="dark"
          key="add-achievements"
          onClick={AchievementsStore.openNewAchievementModal}
        />
      ]}
    >
      <div className="clubhub-app-achievements">{achievementSquares}</div>
      <AddAchievementModal isOpen={AchievementsStore.showNewAchievementModal} close={closeModal} />
    </Page>
  );
});

export default function AppAchievementsRouter() {
  return (
    <Router>
      <AppAchievements path="/" />
      <AppAchievement path=":achievementId" />
      <Redirect from="*" to="/club/app/achievements" noThrow />
    </Router>
  );
}
