import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Router, Redirect, navigate } from "@reach/router";
import { FaFilter, FaSortAmountDown } from "react-icons/fa";
import { GoPlus } from "react-icons/go";
import { commaSeparateNumber } from "../../../utils";
import GlobalSearchStore from "../../../stores/GlobalSearchStore";
import TenantStore from "../../../stores/TenantStore";
import MembershipsStore, { sortOptions, filterOptions } from "../../../stores/MembershipsStore";
import Page from "../../-common/Page";
import DropdownButton from "../../-common/DropdownButton";
import Button from "../../-common/Button";
import Level from "../Level";
import NewMembershipModal from "../NewMembershipModal";
import "./Levels.scss";

const MembershipBox = ({ membershipId, level, description, userCount }) => {
  const banner = false ? (
    <img src={""} className="banner-image" alt="The membership item." />
  ) : (
    <img src={TenantStore?.tenant?.theme?.emblem} className="banner-image" alt="The membership item." />
  );

  return (
    <div className="membership-square" onClick={() => navigate(`levels/${membershipId}`)}>
      {banner}
      <div className="info-wrapper">
        <div className="titles">
          <div className="info-title">{level}</div>
          <div className="info-description">{description}</div>
        </div>
        <div className="metrics">
          <div className="metric-wrapper" />
          <div className="metric-wrapper" />
          <div className="metric-wrapper">
            <div className="metric">{commaSeparateNumber(userCount || 0)}</div>
            <div className="label">Members</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Levels = observer(() => {
  useEffect(() => {
    GlobalSearchStore.setSearchAction(searchText => {
      MembershipsStore.setSearch(searchText);
    });
  }, []);

  const { filter, sort, memberships } = MembershipsStore;

  const updateFilter = filter => MembershipsStore.setFilter(filter);
  const updateSort = sort => MembershipsStore.setSort(sort);

  const closeModal = () => MembershipsStore.closeNewMembershipModal();

  const titleOptions = { title: "Levels" };

  const squares = memberships.map(a => <MembershipBox {...a} key={a.membershipId} />);

  return (
    <Page
      titleOpts={titleOptions}
      buttons={[
        <DropdownButton
          label="Filter"
          icon={FaFilter}
          theme="light"
          options={filterOptions}
          style={{ height: 42 }}
          value={filter}
          onChange={updateFilter}
          key="filter"
        />,
        <DropdownButton
          label="Sort"
          icon={FaSortAmountDown}
          theme="light"
          options={sortOptions}
          style={{ height: 42 }}
          value={sort}
          onChange={updateSort}
          key="sort"
        />,
        <Button
          text="Add Level"
          icon={GoPlus}
          theme="dark"
          key="add-level"
          onClick={MembershipsStore.openNewMembershipModal}
        />
      ]}
    >
      <div className="clubhub-levels">
        <div className="membership-squares">{squares}</div>
      </div>
      <NewMembershipModal isOpen={MembershipsStore.showNewMembershipModal} close={closeModal} />
    </Page>
  );
});

export default function LevelsRouter() {
  return (
    <Router>
      <Levels path="/" />
      <Level path=":membershipId" />
      <Redirect from="*" to="/club/levels" noThrow />
    </Router>
  );
}
