import { observer } from "mobx-react";
import React from "react";
import Select from "../../../../-common/Select2";
import CampaignsStore from "../../../../../stores/CampaignsStore";
import "./CTASlide.scss";

const targetTypeOptions = [
  { label: "No CTA", value: "none" },
  { label: "Open a Campaign", value: "campaign" }
];

const CTASlide = observer(({ targetType, setTargetType, targetId, setTargetId }) => {
  const updateTargetType = ({ value }) => setTargetType(value);
  const updateTargetId = ({ value }) => setTargetId(value);

  const selectedTargetType = targetTypeOptions.find(t => t?.value === targetType);

  let targetSelector;
  if (targetType === "campaign") {
    const campaignOptions = CampaignsStore?.campaigns?.map(c => ({ label: c?.title, value: c?.campaignId }));
    const selectedCampaign = campaignOptions?.find(c => c?.value === targetId);
    targetSelector = (
      <Select label="Which Campaign?" value={selectedCampaign} onChange={updateTargetId} options={campaignOptions} />
    );
  }

  return (
    <div className="slide cta-slide">
      <div className="headline">Add an action</div>
      <div className="inputs">
        <Select
          label="Action Type"
          value={selectedTargetType}
          onChange={updateTargetType}
          options={targetTypeOptions}
        />
        {targetSelector}
      </div>
    </div>
  );
});

export default CTASlide;
