import React, { useState, useCallback } from "react";
import { createPortal } from "react-dom";
import { observer } from "mobx-react";
import usePortal from "../../../../hooks/usePortal";
import FullScreenModal from "../../../-common/FullScreenModal";
import Button from "../../../-common/Button";
import PieTimer from "../../../-common/PieTimer";
import "./ResendReceiptModal.scss";
import TransactionsStore from "../../../../stores/TransactionsStore";

const ResendReceiptModal = observer(({ close, transaction }) => {
  const [sendEnabled, setSendEnabled] = useState(false);

  const showSendButton = useCallback(() => {
    setSendEnabled(true);
  }, [setSendEnabled]);

  const send = async () => {
    await TransactionsStore?.sendReceiptForTransaction(transaction?.transactionId);
    close();
  };

  const sendButton = sendEnabled ? (
    <Button text="Send" onClick={send} />
  ) : (
    <div className="timer-container">
      <PieTimer width={24} height={24} duration={3000} onComplete={showSendButton} />
    </div>
  );

  return (
    <FullScreenModal isOpen={true} close={close}>
      <div className="resend-receipt-modal">
        <div className="modal-contents">
          <div className="headline">You sure?</div>
          <div className="headline">Once you send this, it can't be undone.</div>
        </div>
        <div className="bottom-buttons">
          <Button text="Cancel" theme="light" onClick={close} />
          {sendButton}
        </div>
      </div>
    </FullScreenModal>
  );
});

const ResendReceiptModalContainer = ({ isOpen, ...props }) => {
  const portalTarget = usePortal("resend-receipt-modal-container");
  const contents = isOpen ? <ResendReceiptModal {...props} /> : null;
  return createPortal(contents, portalTarget);
};

export default ResendReceiptModalContainer;
