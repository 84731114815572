import React from "react";
import { observer } from "mobx-react";
import { goBack } from "../../../utils";
import PageHeader from "../PageHeader";
import ProtectedContentOverlay from "../ProtectedContentOverlay";
import "./Page.scss";

export default observer(
  ({ titleOpts = {}, buttons, children, noPadding, protectedContent }) => {
    const {
      title,
      backButton: titleBackButton = false,
      backAction: titleBackAction = goBack,
      fullWidthBorder: titleFullWidthBorder
    } = titleOpts;

    const wrapperStyle = noPadding ? { paddingBottom: 0 } : null;

    const contentStyle = noPadding
      ? {
          width: "calc(100% + 2 * var(--content-padding)",
          margin: "0px"
        }
      : null;

    const overlay = protectedContent ? <ProtectedContentOverlay /> : null;

    return (
      <div className="page-wrapper" style={wrapperStyle}>
        <PageHeader
          title={title}
          backButton={titleBackButton}
          backAction={titleBackAction}
          fullWidthBorder={titleFullWidthBorder}
          buttons={buttons}
        />
        <div className="content-wrapper" style={contentStyle}>
          {children}
          {overlay}
        </div>
      </div>
    );
  }
);
