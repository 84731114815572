import { observable, action } from "mobx";
import { request } from "../utils";

class NotesStore {
  //INDIVIDUAL MEMBERS PLEDGES

  @observable memberNotes = {};

  @action async fetchNotesForMember(userId) {
    try {
      const memberNotes = await request.get(`/v1/notes?userId=${userId}`);
      this.memberNotes[userId] = { ...this.memberNotes, notes: memberNotes };
      return memberNotes;
    } catch (err) {
      console.warn(err);
    }
  }

  // CLEANUP
  @action clear() {
    this.rawMemberNotes = {};
  }
}

export default new NotesStore();
