import React, { useCallback, useEffect } from "react";
import Button from "../Button";
import "./ModalButtonRibbon.scss";

const ModalButtonRibbon = ({
  isOpen,
  nextEnabled,
  close,
  finish,
  setActiveSlide,
  activeSlide,
  maxSlides
}) => {
  const cancel = () => {
    close();
  };

  const back = () => {
    setActiveSlide(Math.max(activeSlide - 1, 0));
  };

  const next = useCallback(async () => {
    if (activeSlide === maxSlides - 1) {
      finish();
    } else {
      setActiveSlide(Math.min(activeSlide + 1, maxSlides - 1));
    }
  }, [activeSlide, maxSlides, finish, setActiveSlide]);

  const handleKeyup = useCallback(
    e => {
      if (isOpen && nextEnabled && (e.key === "Enter" || e.keycode === 13)) {
        if (e?.target?.type !== "textarea") {
          next();
        }
      }
    },
    [isOpen, nextEnabled, next]
  );

  useEffect(() => {
    window.addEventListener("keyup", handleKeyup);
    return () => window.removeEventListener("keyup", handleKeyup);
  }, [handleKeyup]);

  const backHidden = () => {
    if (activeSlide === 0) return true;
    return false;
  };

  const nextButtonText = activeSlide === maxSlides - 1 ? "finish" : "next";

  return (
    <div className="slide-buttons">
      <div className="left-aligned-container">
        <Button text="cancel" theme="light" onClick={cancel} />
      </div>
      <div className="right-aligned-container">
        <Button
          hidden={backHidden()}
          text="back"
          theme="light"
          onClick={back}
        />
        <Button disabled={!nextEnabled} text={nextButtonText} onClick={next} />
      </div>
    </div>
  );
};

export default ModalButtonRibbon;
