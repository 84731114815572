import Persona from "persona";
import { PERSONA_ENV, PERSONA_TEMPLATE_ID } from "../constants/main";

const handlePersonaEvent = onComplete => (name, meta) => {
  if (name === "complete") onComplete(meta.inquiryId);
};

export const verifyWithPersona = onComplete => () => {
  const client = new Persona.Client({
    templateId: PERSONA_TEMPLATE_ID,
    environment: PERSONA_ENV,
    onEvent: handlePersonaEvent(onComplete)
  });
  client.open();
};
