import React from "react";
import { Router, Redirect } from "@reach/router";
import Explore from "./Explore";
import Levels from "./Levels";
import PriorityPoints from "./PriorityPoints";
import DonorApp from "./DonorApp";
import Bulletins from "./Bulletins";
import "./ClubHub.scss";

export default function ClubHub() {
  return (
    <Router>
      <Explore path="explore/*" />
      <Levels path="levels/*" />
      <PriorityPoints path="points" />
      <Bulletins path="bulletins/*" />
      <DonorApp path="app/*" />
      <Redirect from="*" to="/club/explore" noThrow />
    </Router>
  );
}
