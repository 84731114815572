import React from "react";
import { observer } from "mobx-react";
import ModalProgress from "../ModalProgress";
import ModalButtonRibbon from "../ModalButtonRibbon";
import FullScreenModal from "../FullScreenModal";
import "./Modal.scss";

const Modal = ({
  activeSlide,
  close,
  finish,
  isOpen,
  setActiveSlide,
  nextEnabled,
  slides,
  progress,
  progressTitle
}) => {
  const maxSlides = (slides || []).length;
  return (
    <FullScreenModal
      close={close}
      isOpen={isOpen}
      hideClose={true}
      // loading={creatingCampaign}
    >
      <div className="modal">
        <div className="slides-container">
          <div className="slide-content">{slides[activeSlide]}</div>
          <ModalButtonRibbon
            isOpen={isOpen}
            nextEnabled={nextEnabled}
            activeSlide={activeSlide}
            close={close}
            finish={finish}
            setActiveSlide={setActiveSlide}
            maxSlides={maxSlides}
          />
        </div>
        <ModalProgress
          activeSlide={activeSlide}
          progress={progress}
          title={progressTitle}
        />
      </div>
    </FullScreenModal>
  );
};

export default observer(Modal);
