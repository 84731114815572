import React from "react";
import { observer } from "mobx-react";
import { format } from "date-fns";
import { Table } from "ka-table";
import { DataType } from "ka-table/enums";
import { FiExternalLink } from "react-icons/fi";
import commaNumber from "comma-number";
import NameCell from "../../../../-common/NameCell";
import "./PledgeHistoryTable.scss";

const data = [
  { type: "Payment", date: new Date("2020-01-01"), amount: 2000, admin: "-" },
  {
    type: "Adjustment",
    date: new Date("2019-12-01"),
    amount: 1000,
    admin: "Joe Burrow"
  },
  { type: "Payment", date: new Date("2019-11-01"), amount: 2000, admin: "-" },
  {
    type: "Pledge",
    date: new Date("2019-10-01"),
    amount: 5000,
    admin: "Joe Burrow"
  },
  { type: "Payment", date: new Date("2019-09-01"), amount: 300, admin: "-" },
  {
    type: "Pledge",
    date: new Date("2019-08-01"),
    amount: 300,
    admin: "Joe Burrow"
  },
  { type: "Payment", date: new Date("2019-07-01"), amount: 800, admin: "-" },
  { type: "Payment", date: new Date("2019-06-01"), amount: 800, admin: "-" },
  { type: "Payment", date: new Date("2019-05-01"), amount: 800, admin: "-" },
  {
    type: "Adjustment",
    date: new Date("2019-04-01"),
    amount: 300,
    admin: "Joe Burrow"
  },
  { type: "Payment", date: new Date("2019-03-01"), amount: 600, admin: "-" },
  {
    type: "Pledge",
    date: new Date("2019-02-01"),
    amount: 2000,
    admin: "Joe Burrow"
  },
  { type: "Payment", date: new Date("2019-01-01"), amount: 2000, admin: "-" },
  {
    type: "Pledge",
    date: new Date("2018-12-01"),
    amount: 2000,
    admin: "Joe Burrow"
  },
  { type: "Payment", date: new Date("2018-11-01"), amount: 2000, admin: "-" },
  { type: "Payment", date: new Date("2018-10-01"), amount: 2000, admin: "-" },
  {
    type: "Adjustment",
    date: new Date("2018-09-01"),
    amount: 2000,
    admin: "Joe Burrow"
  },
  {
    type: "Pledge",
    date: new Date("2018-08-01"),
    amount: 2000,
    admin: "Joe Burrow"
  }
];

const EllipsisCell = ({ field, rowData, dispatch }) => {
  const sendClickEvent = () => dispatch("Row Click", rowData);
  return (
    <div className="ellipsis-cell" onClick={sendClickEvent}>
      {rowData[field]}
    </div>
  );
};

const AmountCell = ({ rowData, dispatch }) => {
  let { type, amount } = rowData;
  amount = "$" + commaNumber(Number(amount || 0)?.toFixed(2));

  let colorClass;
  if (type === "Payment") colorClass = "green";
  if (type === "Adjustment") colorClass = "red";
  const amountClass = `amount-cell ${colorClass}`;

  return (
    <div className={amountClass} onClick={() => dispatch("Row Click", rowData)}>
      {amount}
    </div>
  );
};

const columns = [
  {
    cell: EllipsisCell,
    dataType: DataType.String,
    key: "type",
    title: "Type"
  },
  {
    dataType: DataType.Date,
    key: "date",
    format: d => format(d, "M/d/yyyy"),
    title: "Date"
  },
  {
    cell: AmountCell,
    dataType: DataType.Number,
    key: "amount",
    title: "Amount"
  },
  {
    cell: NameCell,
    dataType: DataType.String,
    key: "admin",
    title: "Admin"
  }
];

const childAttributes = {
  cell: {
    onClick: (_, e) => {
      const { dispatch, rowData } = e.childProps;
      dispatch("Row Click", rowData);
    }
  }
};

function PledgeHistoryTable() {
  return (
    <div className="pledge-history-table-container">
      <div className="pledge-history-table-title">
        <div className="title-text">Pledge History</div>
        <FiExternalLink size={18} color="var(--Gray500)" />
      </div>
      <Table
        childAttributes={childAttributes}
        columns={columns}
        data={data}
        // onEvent={onTableEvent}
        rowKeyField="date"
      />
    </div>
  );
}

export default observer(PledgeHistoryTable);
