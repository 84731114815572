import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { observer } from "mobx-react";
import isEqual from "lodash.isequal";
import { FiX } from "react-icons/fi";
import usePortal from "../../../../../hooks/usePortal";
import RewardOptionsStore from "../../../../../stores/RewardOptionsStore";
import FullScreenModal from "../../../../-common/FullScreenModal";
import Input from "../../../../-common/Input";
import Button from "../../../../-common/Button";
import "./EditRewardOptionChoicesModal.scss";

const ChoiceRow = ({ i, choice, update, remove }) => {
  return (
    <div className="choice-row">
      <Input value={choice} onChange={update(i)} placeholder="Choice label" />
      <FiX
        className="remove-choice"
        size={40}
        color="var(--Gray500)"
        onClick={remove(i)}
      />
    </div>
  );
};

const EditRewardOptionChoicesModal = observer(({ rewardOption, close }) => {
  const [localRewardOption, setLocalRewardOption] = useState(rewardOption);
  useEffect(() => {
    setLocalRewardOption(rewardOption);
  }, [rewardOption]);

  const { values: choices } = localRewardOption || {};

  const updateChoice = i => newTitle =>
    setLocalRewardOption({
      ...localRewardOption,
      values: localRewardOption.values.map((title, j) => {
        if (i === j) return newTitle;
        return title;
      })
    });

  const addChoice = () =>
    setLocalRewardOption({
      ...localRewardOption,
      values: localRewardOption.values.concat("")
    });

  const removeChoice = i => () =>
    setLocalRewardOption({
      ...localRewardOption,
      values: localRewardOption.values.filter((_, j) => i !== j)
    });

  const saveEnabled =
    localRewardOption.values.every(Boolean) &&
    !isEqual(rewardOption.values, localRewardOption.values);

  const save = async () => {
    if (saveEnabled) {
      await RewardOptionsStore.updateRewardOption(localRewardOption);
      close();
    }
  };

  const choiceRows = (choices || []).map((c, i) => (
    <ChoiceRow
      i={i}
      choice={c}
      update={updateChoice}
      remove={removeChoice}
      key={i}
    />
  ));

  return (
    <FullScreenModal isOpen={true} close={close}>
      <div className="edit-reward-option-choices-modal">
        <div className="modal-contents">
          <div className="headline">Edit choices</div>
          <div className="choices-container">{choiceRows}</div>
          <div className="add-choice-row">
            <div className="add-choice" onClick={addChoice}>
              + Add choice
            </div>
          </div>
        </div>
        <div className="bottom-buttons">
          <Button text="cancel" theme="light" onClick={close} />
          <Button disabled={!saveEnabled} text="save" onClick={save} />
        </div>
      </div>
    </FullScreenModal>
  );
});

const EditRewardOptionChoicesModalContainer = ({ isOpen, ...props }) => {
  const portalTarget = usePortal("edit-reward-option-choices-modal-container");
  const contents = isOpen ? <EditRewardOptionChoicesModal {...props} /> : null;
  return createPortal(contents, portalTarget);
};

export default EditRewardOptionChoicesModalContainer;
