import React from "react";

export default function football({
  size = 24,
  fill = "none",
  stroke = "#000",
  strokeWidth = 13
}) {
  return (
    <svg
      viewBox="0 0 255.222 255.222"
      height={size}
      width={size}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
    >
      <path d="M6.5,127.611 c0,66.942,54.169,121.111,121.111,121.111s121.111-54.169,121.111-121.111S194.553,6.5,127.611,6.5S6.5,60.669,6.5,127.611z" />
      <line x1="127.61" y1="78.801" x2="127.61" y2="30.671" />
      <line x1="82.13" y1="112.831" x2="35.43" y2="97.661" />
      <line x1="15.894" y1="91.3" x2="15.884" y2="91.3" />
      <line x1="99.85" y1="165.811" x2="71.23" y2="205.201" />
      <line x1="59.074" y1="221.95" x2="59.074" y2="221.94" />
      <line x1="183.834" y1="204.99" x2="155.374" y2="165.81" />
      <line x1="219.14" y1="97.871" x2="173.09" y2="112.831" />
      <polygon points="99.341,165.811 81.87,112.038 127.611,78.805 173.352,112.038 155.881,165.811" />
      <polyline points="99.007,9.899 127.612,30.674 156.209,9.899" />
      <polyline points="230.229,64.366 219.124,97.93 247.604,118.866" />
      <polyline points="218.971,205.028 183.618,204.993 172.654,238.597" />
      <polyline points="82.656,238.71 71.378,205.203 36.033,205.567" />
      <polyline points="7.256,118.369 35.74,97.427 24.635,63.87" />
    </svg>
  );
}
