import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import { format } from "date-fns";
import { Table } from "ka-table";
import { DataType } from "ka-table/enums";
import TransactionsStore from "../../../../stores/TransactionsStore";
import NameCell from "../../../-common/NameCell";
import PurposeCell from "../../../-common/PurposeCell";
import currencyFormatter from "../../../../utils/currencyFormatter";
import "./TransactionsTable.scss";

const AmountCell = ({ rowData, dispatch }) => {
  let { amountMetadata: { totalDonationAmount: amount } = {} } = rowData || {};

  return (
    <div
      className="amount-cell"
      style={amount < 0 ? { color: "var(--Red)" } : null}
      onClick={() => dispatch("Row Click", rowData)}
    >
      {currencyFormatter(amount / 100)}
    </div>
  );
};

const columns = [
  {
    dataType: DataType.Date,
    key: "date",
    format: d => format(d, "M/d/yyyy"),
    title: "Date"
  },
  {
    cell: NameCell,
    dataType: DataType.String,
    key: "userMetadata.name",
    title: "Name"
  },
  {
    cell: PurposeCell,
    dataType: DataType.Object,
    key: "type",
    title: "Purpose"
  },
  {
    cell: AmountCell,
    dataType: DataType.Number,
    key: "amountMetadata.totalDonationAmount",
    title: "Amount"
  },
  {
    dataType: DataType.Object,
    key: "processorMetadata",
    title: "Source",
    format: ({ card: c }) => {
      let { brand, last4 } = c || {};
      if (brand && last4) return `${brand} (${last4})`;
    }
  }
];

const childAttributes = {
  cell: {
    onClick: (_, e) => {
      const { dispatch, rowData } = e.childProps;
      dispatch("Row Click", rowData);
    }
  }
};

function TransactionsTable() {
  const onTableEvent = useCallback((type, data) => {
    if (type === "Row Click") {
      const rowData = data;
      const { transactionId, transaction } = rowData;
      navigate(`/audit/transactions/${transactionId || transaction?.transactionId}`);
    }
  }, []);

  return (
    <div className="transactions-table-container">
      <Table
        childAttributes={childAttributes}
        columns={columns}
        data={TransactionsStore.transactions}
        onEvent={onTableEvent}
        rowKeyField="key"
      />
    </div>
  );
}

export default observer(TransactionsTable);
