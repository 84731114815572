import React, { useState, useEffect, useCallback } from "react";
import { observer } from "mobx-react";
import { toast } from "react-toastify";
import { MdCheck, MdClose } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import Page from "../../-common/Page";
import Input from "../../-common/Input";
import Button from "../../-common/Button";
import RadioGroup from "../../-common/RadioGroup";
import TenantStore from "../../../stores/TenantStore";
import "./Campaign.scss";

const quidOptions = [
  { label: "Turn off", value: "off" },
  { label: "Default = yes", value: "yes" },
  { label: "Default = no", value: "no" }
];

const Campaign = () => {
  const titleOptions = { title: "Campaign" };
  const { tenant } = TenantStore;
  const [currentTenant, setCurrentTenant] = useState();
  const [originalTenant, setOriginalTenant] = useState();
  const [saving, setSaving] = useState(false);
  const { pointMultiplier, quid } = currentTenant?.config || {};

  useEffect(() => {
    let theTenant = tenant;

    setCurrentTenant(theTenant);
    setOriginalTenant(theTenant);
  }, [tenant]);

  const updateQuid = value => {
    setCurrentTenant({
      ...currentTenant,
      config: {
        ...currentTenant.config,
        quid: value
      }
    });
  };

  const updatePointMultiplier = value => {
    setCurrentTenant({
      ...currentTenant,
      config: {
        ...currentTenant.config,
        pointMultiplier: value
      }
    });
  };

  const save = useCallback(async () => {
    setSaving(true);
    const { config } = currentTenant;
    const updatedTenant = await TenantStore.updateTenant({
      config
    });
    setOriginalTenant(updatedTenant);
    setSaving(false);
    toast("Campaign settings have been updated");
  }, [currentTenant]);

  const cancel = useCallback(() => {
    setCurrentTenant(originalTenant);
  }, [originalTenant]);

  const pointMultiplierText = `Every $1 donated = ${pointMultiplier || 1} priority point${
    +(pointMultiplier || 1) === 1 ? "" : "s"
  }`;

  const checkSaveEnabled = ({ originalTenant, currentTenant }) => {
    if (currentTenant?.config?.quid !== originalTenant?.config?.quid) return true;
    if (currentTenant?.config?.pointMultiplier !== originalTenant?.config?.pointMultiplier) {
      return true;
    }
    return false;
  };

  const saveEnabled = checkSaveEnabled({
    originalTenant,
    currentTenant
  });

  const conditionalButtons = saveEnabled
    ? [
        <Button text="Cancel" icon={MdClose} theme="light" onClick={cancel} disabled={saving} key="cancel" />,
        <Button
          text={saving ? "Saving..." : "Save"}
          theme={saving ? "light" : null}
          icon={MdCheck}
          onClick={save}
          disabled={saving}
          key="save"
        />
      ]
    : [];

  const selectedQuid = (quidOptions?.filter(option => option?.value === quid)[0] || {}).value || "off";

  return (
    <Page titleOpts={titleOptions} buttons={conditionalButtons}>
      <div className="campaign-settings-container">
        <div className="quid-title">For each campaign, how would you like to handle quid pro quo?</div>
        <div className="quid-options">
          <RadioGroup id="quid-type" options={quidOptions} selected={selectedQuid} onChange={updateQuid} />
        </div>

        <div className="point-multiplier-container">
          <div className="point-title">What is the point multiplier for each campaign by default?</div>
          <Input
            value={pointMultiplier || 1}
            onChange={updatePointMultiplier}
            icon={AiOutlineClose}
            inputContainerStyles={{ width: "10%" }}
          />
          <div className="point-multiplier-explanation">{pointMultiplierText}</div>
        </div>
      </div>
    </Page>
  );
};

export default observer(Campaign);
