import React from "react";
import { observer } from "mobx-react";
import { Table } from "ka-table";
import { navigate } from "@reach/router";
import { FiUser } from "react-icons/fi";
import { DataType } from "ka-table/enums";
import useDimensions from "react-cool-dimensions";
import MessagesStore from "../../../../stores/MessagesStore";
import "./ThreadsTable.scss";

const UserCell = ({ rowData, dispatch }) => {
  const profileDimensions = useDimensions();
  const { width: profileImageWidth, height: profileImageHeight, ref: profileImageRef } = profileDimensions;

  const { donor: { profile } = {} } = rowData || {};
  const { firstName, lastName, picture } = profile || {};

  const profilePictureStyles = profileImageHeight > profileImageWidth ? { maxWidth: "100%" } : { maxHeight: "100%" };
  const mediaComponent = picture ? (
    <div className="profile-picture">
      <img ref={profileImageRef} style={profilePictureStyles} src={picture} alt="Profile for this user" />
    </div>
  ) : (
    <div className="profile-picture-placeholder">
      <FiUser size={24} className="profile-placeholder-icon" />
    </div>
  );

  const sendClickEvent = () => dispatch("Row Click", rowData);

  return (
    <div className="user-cell" onClick={sendClickEvent}>
      {mediaComponent}
      <div className="user-name">
        {firstName} {lastName}
      </div>
    </div>
  );
};

const EllipsisCell = ({ field, rowData, dispatch }) => {
  const sendClickEvent = () => dispatch("Row Click", rowData);
  const unreadStyles = field === "totalUnread" ? { textAlign: "right" } : null;
  return (
    <div className="ellipsis-cell" onClick={sendClickEvent} style={unreadStyles}>
      {rowData[field]}
    </div>
  );
};

const columns = [
  {
    cell: UserCell,
    dataType: DataType.String,
    key: "donorId",
    title: "Name",
    style: { width: 360 }
  },
  {
    cell: EllipsisCell,
    dataType: DataType.String,
    key: "latestMessage",
    title: "Last Message",
    style: {
      minWidth: 0,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    }
  },
  {
    dataType: DataType.String,
    key: "totalUnread",
    title: "Unread Messages",
    style: { width: 140, textAlign: "right" }
  }
];

const childAttributes = {
  cell: {
    onClick: (_, e) => {
      const { dispatch, rowData } = e.childProps;
      dispatch("Row Click", rowData);
    }
  }
};

function ThreadsTable({ data, search }) {
  const onTableEvent = async (type, data) => {
    const { donor: { donorId } = {} } = data || {};
    if (type === "Row Click") {
      navigate(`/club/explore/members/${donorId}?messages`, { useBack: true });
    }
  };

  return (
    <div className="threads-table-container">
      <Table
        childAttributes={childAttributes}
        columns={columns}
        data={MessagesStore.threadPreviews}
        onEvent={onTableEvent}
        rowKeyField="messageId"
      />
    </div>
  );
}

export default observer(ThreadsTable);
