import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Table } from "ka-table";
import { FiTrash2, FiEdit2, FiX, FiCheck } from "react-icons/fi";
import { DataType } from "ka-table/enums";
import MembershipsStore from "../../../../stores/MembershipsStore";
import GlobalSearchStore from "../../../../stores/GlobalSearchStore";
import "./PerksTable.scss";
import Input from "../../../-common/Input";

const EllipsisCell = localDetails => ({ field, rowData, rowKeyValue, dispatch }) => {
  if (field === "details") rowData[field] = localDetails[rowKeyValue];
  return (
    <div className="ellipsis-cell" onClick={() => dispatch("Row Click", rowData)}>
      {rowData[field]}
    </div>
  );
};

const InputCell = (updateLocalDetails, localDetails) => ({ field, rowData, rowKeyValue }) => {
  if (field === "details") rowData[field] = localDetails[rowKeyValue];
  return (
    <div className="input-cell">
      <Input value={rowData[field]} onChange={updateLocalDetails(rowKeyValue)} />
    </div>
  );
};

const EditableCell = (localDetails, editingRow, updateLocalDetails) => props => {
  const {
    rowData: { perkId }
  } = props;
  if (perkId === editingRow) {
    return InputCell(updateLocalDetails, localDetails)(props);
  } else {
    return EllipsisCell(localDetails)(props);
  }
};

const EditCell = startEditingRow => ({ rowData }) => {
  const { perkId } = rowData;
  return <FiEdit2 className="edit-cell" onClick={startEditingRow(perkId)} />;
};

const DeleteCell = ({ rowData }) => {
  const { perkId, membershipId } = rowData;
  const deletePerk = () => MembershipsStore.removePerkFromMembership(perkId, membershipId);
  return <FiTrash2 className="delete-cell" onClick={deletePerk} />;
};

const SaveCell = (localDetails, originalDetails, stopEditingRow) => ({ rowData }) => {
  const { perkId, membershipId } = rowData;
  const details = localDetails[perkId];
  const showSave = localDetails[perkId] !== originalDetails[perkId];
  const saveStyle = { display: showSave ? "initial" : "none" };
  const editPerk = () => {
    MembershipsStore.editPerkOnMembership(membershipId, perkId, details);
    stopEditingRow();
  };
  return <FiCheck style={saveStyle} className="save-cell" onClick={editPerk} />;
};

const CancelCell = (updateLocalDetails, resetLocalDetails, stopEditingRow) => ({ rowData }) => {
  const { perkId } = rowData;
  const resetValue = () => {
    resetLocalDetails(perkId);
    stopEditingRow();
  };
  return <FiX className="cancel-cell" onClick={resetValue} />;
};

const CancelOrEditCell = (
  startEditingRow,
  localDetails,
  editingRow,
  updateLocalDetails,
  resetLocalDetails,
  originalDetails,
  stopEditingRow
) => props => {
  const {
    rowData: { perkId }
  } = props;
  if (perkId === editingRow) {
    return CancelCell(updateLocalDetails, resetLocalDetails, stopEditingRow)(props);
  } else {
    return EditCell(startEditingRow)(props);
  }
};

const DeleteOrSaveCell = (localDetails, editingRow, originalDetails, stopEditingRow) => props => {
  const {
    rowData: { perkId }
  } = props;
  if (perkId === editingRow) return SaveCell(localDetails, originalDetails, stopEditingRow)(props);
  else return <DeleteCell {...props} />;
};

function PerksTable({ perks, membership }) {
  const [originalDetails, setOriginalDetails] = useState();
  const [localDetails, setLocalDetails] = useState();
  const updateLocalDetails = perkId => value => {
    setLocalDetails({ ...localDetails, [perkId]: value });
  };
  const resetLocalDetails = perkId => {
    setLocalDetails({ ...localDetails, [perkId]: originalDetails[perkId] });
  };

  const [editingRow, setEditingRow] = useState();
  const startEditingRow = perkId => () => setEditingRow(perkId);
  const stopEditingRow = () => setEditingRow();

  useEffect(() => {
    const detailKeyedByPerkId = perks.reduce((acc, next) => {
      acc[next.perkId] = next.details;
      return acc;
    }, {});
    setLocalDetails(detailKeyedByPerkId);
    setOriginalDetails(detailKeyedByPerkId);
  }, [perks]);

  const { membershipId } = membership || {};
  const data = (perks || []).map(p => ({ ...p, membershipId })).sort((a, b) => (a.sortIndex > b.sortIndex ? 1 : -1));

  const columns = [
    {
      cell: EllipsisCell(),
      dataType: DataType.String,
      key: "title",
      title: "Perk"
    },
    {
      cell: EditableCell(localDetails, editingRow, updateLocalDetails),
      dataType: DataType.String,
      key: "details",
      title: "Details"
    },
    {
      key: "edit",
      cell: CancelOrEditCell(
        startEditingRow,
        localDetails,
        editingRow,
        updateLocalDetails,
        resetLocalDetails,
        originalDetails,
        stopEditingRow
      ),
      style: { width: 15, textAlign: "center" }
    },
    {
      key: "delete",
      cell: DeleteOrSaveCell(localDetails, editingRow, originalDetails, stopEditingRow),
      style: { width: 15, textAlign: "center" }
    }
  ];

  return (
    <div className="perks-table-container">
      <Table columns={columns} data={data} search={GlobalSearchStore.searchText} rowKeyField="perkId" />
    </div>
  );
}

export default observer(PerksTable);
