import { observable, action } from "mobx";
import { request } from "../utils";

class TicketsStore {
  //INDIVIDUAL MEMBERS TICKETS

  @observable memberTickets = {};

  @action async fetchTicketsForMember(userId) {
    try {
      const memberTickets = await request.get(`/v1/tickets?userId=${userId}`);
      this.memberTickets = { ...this.memberTickets, [userId]: memberTickets };
      return memberTickets;
    } catch (err) {
      console.warn(err);
    }
  }

  // CLEANUP
  @action clear() {
    this.memberTickets = {};
  }
}

export default new TicketsStore();
