import React, { useState } from "react";
import { observer } from "mobx-react";
import Select from "../../../-common/Select2";
import Input from "../../../-common/Input";
import Button from "../../../-common/Button";
import Label from "../../../-common/Label";
import RewardOptionsStore from "../../../../stores/RewardOptionsStore";
import "./DonorInputSlide.scss";

const CustomOptionSet = ({
  title,
  values,
  addValue,
  removeValue,
  optionIndex,
  updateOptionTitle,
  updateOptionValue,
  cancelOptions,
  saveOptions
}) => {
  const optionValues = values?.map((value, valueIndex) => (
    <div className="custom-info-value" key={valueIndex}>
      <Input
        placeholder="enter value option"
        onChange={updateOptionValue(optionIndex, valueIndex)}
        value={value}
        clearable={true}
        onClear={() => removeValue(optionIndex, valueIndex)}
      />
    </div>
  ));

  const checkDisabled = () => {
    const allFieldsAreFull = values.every(Boolean);
    const allFieldsUnique =
      Array.from(new Set(values)).length === values.length;
    if (title && values?.length && allFieldsAreFull && allFieldsUnique) {
      return false;
    }
    return true;
  };

  const valueExistsStyle = values.length ? null : { paddingLeft: 20 };

  return (
    <>
      <Label
        style={{ marginBottom: 12 }}
        value="Enter details for your new option"
      />
      <div className="custom-info-container">
        <div className="custom-info-title">
          <Input
            value={title}
            onChange={updateOptionTitle(optionIndex)}
            placeholder="enter value title"
          />
        </div>
        <div className="custom-info-value-container">
          {optionValues}
          <div
            className="add-value"
            style={valueExistsStyle}
            onClick={() => addValue(optionIndex)}
          >
            + Add value
          </div>
          <div className="button-row">
            <Button text="Cancel" theme="light" onClick={cancelOptions} />
            <Button
              text="Add to list"
              disabled={checkDisabled()}
              onClick={saveOptions}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const DonorInputSlide = ({ rewardOptions, setRewardOptions, category }) => {
  const [customOptions, setCustomOptions] = useState([]);

  const isPhysical = category?.value === "physical";
  const initialOptions = isPhysical
    ? [
        {
          label: "Shipping Address",
          value: "Shipping Address",
          isFixed: true
        }
      ]
    : [];

  const existingCustomOptions = RewardOptionsStore.rewardOptions.map(
    option => ({
      label: option.title,
      value: option.rewardOptionId
    })
  );

  const options = initialOptions.concat(existingCustomOptions);

  const optionShape = {
    title: "",
    values: []
  };

  const addCustomOption = () => {
    setCustomOptions(oldOptions => [...oldOptions, optionShape]);
  };

  const saveCustomOption = () => {
    RewardOptionsStore.addRewardOption(customOptions[0]);
    cancelCustomOption();
  };

  const cancelCustomOption = () => {
    setCustomOptions([]);
  };

  const addCustomValueToOption = index => {
    setCustomOptions(
      customOptions.map((optionSet, i) => {
        if (i === index)
          return { ...optionSet, values: optionSet.values.concat("") };
        else return optionSet;
      })
    );
  };

  const removeCustomValueFromOption = (optionindex, valueIndex) => {
    setCustomOptions(
      customOptions.map((optionSet, i) => {
        if (i === optionindex)
          return {
            ...optionSet,
            values: optionSet.values.filter((_, i) => i !== valueIndex)
          };
        else return optionSet;
      })
    );
  };

  const updateOptionTitle = index => newTitle => {
    setCustomOptions(
      customOptions.map((optionSet, i) => {
        if (i === index) return { ...optionSet, title: newTitle };
        else return optionSet;
      })
    );
  };

  const updateOptionValue = (optionindex, valueIndex) => newValue => {
    setCustomOptions(
      customOptions.map((optionSet, i) => {
        if (i === optionindex)
          return {
            ...optionSet,
            values: optionSet.values.map((value, j) => {
              if (j === valueIndex) return newValue;
              return value;
            })
          };
        else return optionSet;
      })
    );
  };

  const OptionSets = customOptions?.map((setOfOptions, i) => (
    <CustomOptionSet
      key={i}
      {...setOfOptions}
      optionIndex={i}
      addValue={addCustomValueToOption}
      removeValue={removeCustomValueFromOption}
      updateOptionTitle={updateOptionTitle}
      updateOptionValue={updateOptionValue}
      cancelOptions={cancelCustomOption}
      saveOptions={saveCustomOption}
    />
  ));

  const addCustomComponent = customOptions.length ? null : (
    <div className="add-custom">
      <Label value="Not seeing the option you need?" />
      <div onClick={addCustomOption}>+ Add new option</div>
    </div>
  );

  return (
    <div className="slide team-members-slide">
      <div className="headline">
        What donor information do we need to collect?
      </div>
      <div className="inputs">
        <Select
          // className=""
          isMulti
          options={options}
          defaultValue={isPhysical ? [options[0]] : []}
          isClearable={true}
          onChange={setRewardOptions}
          // label="Select your team"
        />
        {addCustomComponent}
        {OptionSets}
      </div>
    </div>
  );
};

export default observer(DonorInputSlide);
