import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Router } from "@reach/router";
import { FaFilter, FaSortAmountDown } from "react-icons/fa";
import { GoPlus } from "react-icons/go";
import IntegrationsStore, { sortAccountsOptions, filterAccountsOptions } from "../../../stores/IntegrationsStore";
import GlobalSearchStore from "../../../stores/GlobalSearchStore";
import Page from "../../-common/Page";
// import AccountItemDetail from "../AccountItemDetail";
import DropdownButton from "../../-common/DropdownButton";
import Button from "../../-common/Button";
import "./Accounts.scss";

const Accounts = observer(() => {
  useEffect(() => {
    GlobalSearchStore.setSearchAction(searchText => {
      IntegrationsStore.setAccountsSearch(searchText);
    });
  }, []);

  const { accountsFilter, accountsSort } = IntegrationsStore;

  const updateFilter = filter => IntegrationsStore.setAccountsFilter(filter);
  const updateSort = sort => IntegrationsStore.setAccountsSort(sort);

  const titleOptions = { title: "Accounts" };

  return (
    <Page
      titleOpts={titleOptions}
      buttons={[
        <DropdownButton
          label="Filter"
          icon={FaFilter}
          theme="light"
          options={filterAccountsOptions}
          style={{ height: 42 }}
          value={accountsFilter}
          onChange={updateFilter}
          key="filter"
        />,
        <DropdownButton
          label="Sort"
          icon={FaSortAmountDown}
          theme="light"
          options={sortAccountsOptions}
          style={{ height: 42 }}
          value={accountsSort}
          onChange={updateSort}
          key="sort"
        />,
        <Button text="Add Account" icon={GoPlus} theme="dark" key="new-account" onClick={() => null} />
      ]}
    >
      <div className="integrations-accounts"></div>
    </Page>
  );
});

export default function AccountsRouter() {
  return (
    <Router>
      <Accounts path="/" />
      {/* <AccountItemDetail path=":accountId" /> */}
    </Router>
  );
}
