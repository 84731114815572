import { observable, computed, action, autorun } from "mobx";
import { toast } from "react-toastify";
import { request } from "../utils";
import uploadFile from "../services/FileUploadService";
import InstanceConfigStore from "./InstanceConfigStore";
import AuthStore from "./AuthStore";
import AppStateStore from "./AppStateStore";

class UsersStore {
  constructor() {
    autorun(() => {
      if (AuthStore.APIReady) {
        this.fetchUsers();
      } else {
        this.clear();
      }
    });
  }

  // USERS
  @observable rawUsers = [];

  @computed get users() {
    return (
      (this.rawUsers || [])
        // .filter(c => c.first_name.includes(this.search))
        .filter(c => c.config.status !== "inactive")
        .map(c => {
          const {
            profile: { firstName, lastName }
          } = c;
          return {
            ...c,
            fullName: `${firstName} ${lastName}`
          };
        })
    );
  }

  // ME
  @computed get me() {
    if (AuthStore.currentUser) {
      const user = this.users.find(u => u.userId === AuthStore?.currentUser?.attributes?.sub);
      return user || {};
    }
    return {};
  }

  // CURRENT USER
  @observable currentUserId;

  @computed get currentUser() {
    if (this.currentUserId) {
      const user = this.users.find(u => u.userId === this.currentUserId);
      return user || {};
    }
    return {};
  }

  @action setSelectedUser = userId => {
    this.currentUserId = userId;
  };

  @action async fetchUsers() {
    try {
      const users = await request.get("/v1/users");
      this.rawUsers = users;
      return users;
    } catch (err) {
      console.warn(err);
    }
  }

  @action async addUser(signUpObject) {
    // TODO: This DEFINITELY needs to be reworked
    signUpObject.userPoolId = InstanceConfigStore?.authConfig?.userPoolId;
    AppStateStore.setLoading(true);
    try {
      await request.post("/v1/users", {
        body: signUpObject
      });
      await this.fetchUsers();
      AppStateStore.setLoading(false);
      toast("User invited!", { autoClose: 3000 });
    } catch (err) {
      console.warn("error signing up", err);
      AppStateStore.setLoading(false);
      toast("Error inviting user.");
    }
  }

  @action async updateUser(userId, userObject, file, options = {}) {
    AppStateStore.setLoading(true);
    try {
      if (file) {
        let url = await this.uploadFile(file);
        url = url.split("?")[0];
        const userObj = { profile: { ...userObject, picture: url } };
        const updatedUser = await request.put(`/v1/users/${userId}`, {
          body: userObj
        });
        this.updateUserInPlace(updatedUser);
      } else {
        const updatedUser = await request.put(`/v1/users/${userId}`, {
          body: { profile: userObject }
        });
        this.updateUserInPlace(updatedUser);
      }
      AppStateStore.setLoading(false);
      if (!options?.silent) toast("Profile updated!", { autoClose: 3000 });
    } catch (err) {
      console.warn("error updating profile up", err);
      AppStateStore.setLoading(false);
      if (!options?.silent) toast("Error updating profile.");
    }
  }

  @action updateUserInPlace(updatedUser) {
    this.rawUsers = this.rawUsers.map(u => {
      if (u.userId === updatedUser.userId) {
        return updatedUser;
      }
      return u;
    });
  }

  @action async resendUser(userObject) {
    userObject.userPoolId = InstanceConfigStore?.authConfig?.userPoolId;
    AppStateStore.setLoading(true);
    try {
      await request.post(`/v1/users/${userObject.userId}/resend`, {
        body: userObject
      });
      AppStateStore.setLoading(false);
      toast("Invitation resent!", { autoClose: 3000 });
    } catch (err) {
      console.warn("error resending invite: ", err);
      AppStateStore.setLoading(false);
      toast("Error resending invite.");
    }
  }

  @action async onboardUser() {
    let result;
    AppStateStore.setLoading(true);

    try {
      await this.fetchUsers();
      const updatedUser = await request.put(`/v1/users/${this?.me?.userId}`, {
        body: { config: { ...(this?.me?.config || {}), status: "active" } }
      });
      this.updateUserInPlace(updatedUser);
      result = true;
    } catch (err) {
      result = false;
    }

    AppStateStore.setLoading(false);
    return result;
  }

  @action async disableUser(userObject) {
    userObject.userPoolId = InstanceConfigStore?.authConfig?.userPoolId;
    AppStateStore.setLoading(true);
    try {
      await request.delete("/v1/users", {
        body: userObject
      });
      await this.fetchUsers();
      AppStateStore.setLoading(false);
      toast("User removed!", { autoClose: 3000 });
    } catch (err) {
      console.warn("error removing user", err);
      AppStateStore.setLoading(false);
      toast("Error removing user.");
    }
  }

  @action async uploadFile(file) {
    try {
      const url = await uploadFile(file, "profiles");
      return url;
    } catch (err) {
      console.warn(err);
    }
  }

  // MODAL
  @observable showNewUserModal = false;

  @action openNewUserModal = () => (this.showNewUserModal = true);

  @action closeNewUserModal = () => (this.showNewUserModal = false);

  // SEARCH
  @observable search = "";

  @action setSearch = search => (this.search = search);

  // CLEANUP
  @action clear() {
    this.rawUsers = [];
  }
}

export default new UsersStore();
