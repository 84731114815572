import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { observer } from 'mobx-react';
import { FiEdit } from 'react-icons/fi';
import {
  MdCheck,
  MdClose,
} from 'react-icons/md';
import UsersStore from '../../../stores/UsersStore';
import getFileURLFromFile from '../../../utils/getFileURLFromFile';
import NewPasswordModalContainer from '../../-modals/NewPasswordModal';
import Page from '../../-common/Page';
import FileUpload from '../../-common/FileUpload';
import Button from '../../-common/Button';
import Input from '../../-common/Input';
import Label from '../../-common/Label';
import './Personal.scss';

function Personal() {
  const {
    me: {
      userId, email, profile,
    },
  } = UsersStore;
  const [
    localProfile,
    setlocalProfile,
  ] = useState();

  useEffect(() => {
    setlocalProfile(profile);
  }, [profile]);
  const {
    picture, firstName, lastName,
  } = localProfile || {};

  const titleOptions = { title: 'Personal' };

  const updateMedia = async file => {
    const newFileURL = await getFileURLFromFile(file);

    setlocalProfile({
      ...localProfile,
      picture: newFileURL,
      pictureFile: file,
    });
  };

  const updateProperty = key => value => {
    setlocalProfile({
      ...localProfile,
      [key]: value,
    });
  };

  const [
    passwordModalOpen,
    setPasswordModalOpen,
  ] = useState(false);
  const openModal = () => {
    setPasswordModalOpen(true);
  };
  const closeModal = () => {
    setPasswordModalOpen(false);
  };

  const [
    saving,
    setSaving,
  ] = useState(false);
  const save = useCallback(async () => {
    setSaving(true);
    const {
      email,
      profile,
      addresses,
      phone_numbers,
      config,
      created,
      updated,
      pictureFile,
      ...finalUser
    } = localProfile;

    await UsersStore.updateUser(userId, finalUser, pictureFile);
    setSaving(false);
  }, [
    userId,
    localProfile,
  ]);

  const cancel = useCallback(() => {
    setlocalProfile(profile);
  }, [
    setlocalProfile,
    profile,
  ]);

  const checkSaveEnabled = ({
    localProfile, profile,
  }) => {
    if (profile?.firstName !== localProfile?.firstName) return true;

    if (profile?.lastName !== localProfile?.lastName) return true;

    if (localProfile?.pictureFile) return true;

    return false;
  };

  const saveEnabled = checkSaveEnabled({
    localProfile, profile,
  });

  const conditionalButtons = saveEnabled ?
    [
      <Button
        text="Cancel"
        icon={MdClose}
        theme="light"
        onClick={cancel}
        disabled={saving}
        key="cancel"
      />,
      <Button
        text={saving ? 'Saving...' : 'Save'}
        theme={saving ? 'light' : null}
        icon={MdCheck}
        onClick={save}
        disabled={saving}
        key="save"
      />,
    ] :
    [
      <Button
        text="Change Password"
        icon={FiEdit}
        key="preview"
        onClick={openModal}
      />,
    ];

  return (
    <Page
      titleOpts={titleOptions}
      buttons={conditionalButtons}
    >
      <div className="personal-container">
        <div className="personal-inputs">
          <div className="picture">
            <FileUpload
              fileURL={picture}
              onUpload={updateMedia}
              circular
              accept="image/png, image/jpeg"
            />
          </div>
          <div className="email-container">
            <Label value="Email" />
            <div className="email">{email}</div>
          </div>
          <div className="name-container">
            <div className="first-name">
              <Input
                value={firstName}
                onChange={updateProperty('firstName')}
                label="First name"
              />
            </div>
            <div className="last-name">
              <Input
                value={lastName}
                onChange={updateProperty('lastName')}
                label="Last name"
              />
            </div>
          </div>
          {/* <div className="phone">
            <Input
              value={phoneNumber}
              onChange={updateProperty("phoneNumber")}
              label="Phone #"
            />
          </div> */}
        </div>
      </div>
      <NewPasswordModalContainer
        user={localProfile}
        isOpen={passwordModalOpen}
        close={closeModal}
      />
    </Page>
  );
}

export default observer(Personal);
