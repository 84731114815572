import React from "react";
import { format, differenceInCalendarDays } from "date-fns";
import { commaSeparateNumber, abbreviateNumber, isVideoLink, getVideoId } from "../../../../utils";
import camera from "../../../../assets/camera.svg";
import "./CampaignSquare.scss";

const CampaignSquare = openCampaign => ({
  campaignId,
  totalDonated = 0,
  totalDonations = 0,
  formattedRevenueGoal,
  title,
  media,
  thumbnail,
  endDate
}) => {
  let subtitle;
  if (endDate) {
    const endDateObj = new Date(endDate);
    const tzOffset = endDateObj.getTimezoneOffset() * 60 * 1000;
    const adjEndDate = new Date(endDateObj.valueOf() + tzOffset);
    subtitle = `Opportunity ends ${format(adjEndDate, "MMMM do, yyyy")}`;
  }

  let image = media || camera;
  let imageAdjust = {};
  if (isVideoLink(media) && thumbnail) {
    image = thumbnail;
    if (getVideoId(media).provider === "youtube") {
      imageAdjust = { marginTop: -42, height: "calc(100% + 42px)" };
    }
  }
  const imageStyles = { backgroundImage: `url(${image})`, ...imageAdjust };

  const donatedMetric = "$" + commaSeparateNumber(totalDonated || 0, true);
  const donatedLabel =
    "Donated" + (formattedRevenueGoal ? " of $" + abbreviateNumber(formattedRevenueGoal) + " goal" : "");
  const donorsMetric = commaSeparateNumber(totalDonations || 0, true);
  const donorsLabel = "Donors";
  const daysLeft = endDate ? Math.max(differenceInCalendarDays(new Date(endDate), new Date()), 0) : null;
  const daysLeftLabel = endDate ? "Days left" : null;

  let progress = (totalDonated || 0) / (formattedRevenueGoal || 1);
  if (progress !== 0) progress = Math.max(progress, 0.025);
  const progressStyles = { width: `${(progress * 100).toFixed(2)}%` };

  return (
    <div className="campaign-square" onClick={openCampaign(campaignId)} key={campaignId}>
      <div className="image-wrapper">
        <div className="image" style={imageStyles} alt="Campaign primary asset" />
      </div>
      <div className="info-wrapper">
        <div className="titles">
          <div className="info-title">{title}</div>
          <div className="info-subtitle">{subtitle}</div>
        </div>
        <div className="metrics">
          <div className="metric-wrapper donated">
            <div className="metric">{donatedMetric}</div>
            <div className="label">{donatedLabel}</div>
          </div>
          <div className="metric-wrapper donors">
            <div className="metric">{donorsMetric}</div>
            <div className="label">{donorsLabel}</div>
          </div>
          <div className="metric-wrapper days-left">
            <div className="metric">{daysLeft}</div>
            <div className="label">{daysLeftLabel}</div>
          </div>
        </div>
        <div className="progress-bar">
          <div className="progress" style={progressStyles} />
        </div>
      </div>
    </div>
  );
};

export default CampaignSquare;
