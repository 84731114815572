import React, { useState } from "react";
import { createPortal } from "react-dom";
import { observer } from "mobx-react";
import usePortal from "../../../../hooks/usePortal";
import PerksStore from "../../../../stores/PerksStore";
import FullScreenModal from "../../../-common/FullScreenModal";
import Input from "../../../-common/Input";
import Textarea from "../../../-common/Textarea";
import Button from "../../../-common/Button";
import "./AddPerkModal.scss";

const AddPerkModal = observer(({ close, team, update }) => {
  const [perk, setPerk] = useState({ title: "", requirements: "" });
  const updateTitle = title => setPerk({ ...perk, title });
  const updateRequirements = requirements => setPerk({ ...perk, requirements });

  const save = async () => {
    await PerksStore.addPerk(perk);
    close();
  };

  const saveEnabled = perk && perk.title;

  return (
    <FullScreenModal isOpen={true} close={close}>
      <div className="add-perk-modal">
        <div className="modal-contents">
          <div className="headline">Add perk</div>
          <div>
            <Input
              label="Title"
              placeholder="Perk Title"
              value={perk?.title}
              onChange={updateTitle}
            />
            <div className="requirements-container">
              <Textarea
                label="Requirements"
                placeholder="Perk Requirements"
                value={perk?.requirements}
                onChange={updateRequirements}
                maxLength={120}
              />
            </div>
          </div>
        </div>
        <div className="bottom-buttons">
          <Button text="cancel" theme="light" onClick={close} />
          <Button disabled={!saveEnabled} text="save" onClick={save} />
        </div>
      </div>
    </FullScreenModal>
  );
});

const AddPerkModalContainer = ({ isOpen, ...props }) => {
  const portalTarget = usePortal("add-perk-modal-container");
  const contents = isOpen ? <AddPerkModal {...props} /> : null;
  return createPortal(contents, portalTarget);
};

export default AddPerkModalContainer;
