import React from "react";

export default function swimmingGoggles({
  size = 24,
  fill = "none",
  stroke = "#000",
  strokeWidth = 13
}) {
  return (
    <svg
      viewBox="0 0 305 305"
      height={size}
      width={size}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M135.804,229.5 c0,15.752-11.749,28.522-41.659,28.522s-54.156-12.77-54.156-28.522s24.247-28.522,54.156-28.522S135.804,213.748,135.804,229.5z" />
      <path d="M173.196,229.5 c0,15.752,11.749,28.522,41.659,28.522s54.156-12.77,54.156-28.522s-24.247-28.522-54.156-28.522S173.196,213.748,173.196,229.5z" />
      <line x1="173.196" y1="229.499" x2="135.804" y2="229.499" />
      <line x1="230.503" y1="203.977" x2="183.963" y2="250.517" />
      <line x1="251.503" y1="211.477" x2="205.433" y2="257.547" />
      <line x1="102.503" y1="203.977" x2="56.483" y2="249.997" />
      <line x1="123.503" y1="211.477" x2="78.203" y2="256.777" />
      <path d="M298.5,192.978c0-80.634-65.366-146-146-146 s-146,65.366-146,146l0,0c95.623-21.463,194.802-21.579,290.475-0.339L298.5,192.978z" />
      <line x1="23.17" y1="229.09" x2="39.989" y2="229.09" />
      <line x1="269.603" y1="229.09" x2="286.423" y2="229.09" />
    </svg>
  );
}
