import { navigate } from "@reach/router";

export default function goBack() {
  if (document.referrer.includes(window.location.host) && document.referrer !== window.location.href) {
    window.history.back();
  } else {
    const exceptions = { "/club/explore/members": -2 };
    const pathWithoutQuery = window.location.pathname.split("?")[0];

    const matchingException = Object.keys(exceptions).find(e => pathWithoutQuery.includes(e));
    const nextPath = pathWithoutQuery
      .split("/")
      .slice(0, matchingException ? exceptions[matchingException] : -1)
      .join("/");
    navigate(nextPath);
  }
}
