import React from "react";
import "./WhySlide.scss";

const WhySlide = () => {
  return (
    <div className="slide why-slide">
      <div className="headline">Why do we need this?</div>

      <div className="contents">
        <div className="body-text">
          Banks require that one person be designated as the primary representative and point contact of the
          organization creating a merchant account. This representative carries no financial or legal responsibility
          other than providing information that validates that you are you.{" "}
          <span className="emoji" role="img" aria-label="wink-emoji">
            😉
          </span>
        </div>
      </div>
    </div>
  );
};

export default WhySlide;
