import React, { useEffect, useState, useReducer, useCallback } from "react";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import { userReducer, userInitialState } from "../../../reducers/UserReducer";
import { FiEdit } from "react-icons/fi";
import UsersStore from "../../../stores/UsersStore";
import Page from "../../-common/Page";
import Button from "../../-common/Button";
import NewPasswordModalContainer from "../../-modals/NewPasswordModal";
import "./User.scss";
import { titleCase } from "../../../utils";

const resendInvite = userObject => e => {
  if (e) e.stopPropagation();
  UsersStore.resendUser(userObject);
};

const UserDetails = ({ userId, email, profile, config }) => {
  const { firstName, lastName, picture } = profile || {};
  const { role, status } = config || {};
  const fullName = firstName + " " + lastName;

  const mappedStatus = {
    CONFIRMED: "Active",
    FORCE_CHANGE_PASSWORD: "Invited"
  }[status];

  const userObject = { userId, email, role };

  const profileImage = picture ? <img className="profile-image" src={picture} alt="The user" /> : null;

  const ResendComponent = () =>
    mappedStatus === "Invited" ? (
      <div className="user-resend" onClick={resendInvite(userObject)}>
        resend invitation
      </div>
    ) : null;

  return (
    <div className="user-details-container">
      <div className="user-title-container">
        <div className="user-picture">{profileImage}</div>
        <div className="user-name-container">{fullName}</div>
      </div>
      <div className="user-email-container">
        <div className="label">Email:</div>
        <div className="user-email">{email}</div>
      </div>
      <div className="user-role-container">
        <div className="label">Role:</div>
        <div className="user-role">{role ? titleCase(role) + " User" : "-"}</div>
      </div>
      <div className="user-status-container">
        <div className="label">Status:</div>
        <>
          <div className="user-status">{mappedStatus || (status ? titleCase(status) : "-")}</div>
          <ResendComponent />
        </>
      </div>
    </div>
  );
};

function User({ userId }) {
  UsersStore.setSelectedUser(userId);
  const { currentUser, users, currentUserId } = UsersStore;
  const [state, dispatch] = useReducer(userReducer, userInitialState);

  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const openModal = () => {
    setPasswordModalOpen(true);
  };
  const closeModal = () => {
    setPasswordModalOpen(false);
  };

  useEffect(() => {
    if (currentUser) {
      dispatch({ type: "setCurrentUser", payload: currentUser });
    } else if (users.length) navigate("/settings/users");
  }, [currentUser, users, currentUserId]);

  const updateValue = useCallback(
    field => value => {
      const actionType = "set" + field[0].toUpperCase() + field.slice(1);
      dispatch({ type: actionType, payload: { [field]: value } });
    },
    []
  );

  return (
    <Page
      titleOpts={{
        title: "User",
        backButton: true
      }}
      buttons={[<Button text="Change Password" icon={FiEdit} key="preview" onClick={openModal} />]}
    >
      <div className="user">
        <div className="user-content">
          <UserDetails userId={userId} {...state} updateValue={updateValue} />
          <div className="user-permissions-container">
            <div className="coming-soon">(user permissions coming soon)</div>
          </div>
        </div>
      </div>
      <NewPasswordModalContainer user={currentUser} isOpen={passwordModalOpen} close={closeModal} />
    </Page>
  );
}

export default observer(User);
