import React from "react";
import Label from "../Label";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import "./Timepicker.scss";

const Timepicker = ({
  value, //required
  onChange, //required
  placeholder,
  label
}) => {
  const labelComponent = label ? <Label value={label} /> : null;

  return (
    <div className="timepicker-component-container">
      {labelComponent}
      <div className="timepicker-container">
        <TimePicker
          className="time-picker"
          inputClassName="input-time-picker"
          popupClassName="popup-time-picker"
          placeholder={placeholder}
          showSecond={false}
          minuteStep={15}
          use12Hours
          format="hh:mm a"
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default Timepicker;
