import React, { useReducer } from "react";
import { createPortal } from "react-dom";
import { observer } from "mobx-react";
import { parse } from "date-fns";
import TenantStore from "../../../../stores/TenantStore";
import usePortal from "../../../../hooks/usePortal";
import Modal from "../../../-common/Modal";
import { bankDetailsReducer, bankDetailsInitialState } from "./bankDetailsReducer";
import WhySlide from "./WhySlide";
import OfficerVerificationSlide from "./OfficerVerificationSlide";
import AddressSlide from "./AccountSlide";
import "./BankDetailsModal.scss";

const BankDetailsModal = observer(({ close }) => {
  const [state, dispatch] = useReducer(bankDetailsReducer, bankDetailsInitialState);
  const setActiveSlide = activeSlide => dispatch({ type: "setActiveSlide", payload: { activeSlide } });

  const finish = async () => {
    const { birthday, SSN, holderName, accountNumber, routingNumber, personaInquiryId } = state;
    await TenantStore?.registerTenantWithZift({
      birthDate: parse(birthday, "MM/dd/yyyy", new Date(2000, 1, 1)).toISOString().slice(0, 10),
      socialSecurity: SSN.replace(/-/g, ""),
      holderName,
      accountNumber,
      routingNumber,
      personaInquiryId
    });
    close();
    dispatch({ type: "resetState" });
  };

  const slideProps = { state, dispatch };
  const { slides, progress, progressTitle } = {
    slides: [
      <WhySlide {...slideProps} />,
      <AddressSlide {...slideProps} />,
      <OfficerVerificationSlide {...slideProps} />
    ],
    progress: [
      { title: "Why", description: "Why do we need this?" },
      { title: "Account", description: "Add bank account details" },
      { title: "Officer", description: "Add officer's verification credentials" }
    ],
    progressTitle: "Three steps and you're to the bank"
  };

  return (
    <Modal
      nextEnabled={state.nextEnabled}
      slides={slides}
      progress={progress}
      progressTitle={progressTitle}
      activeSlide={state.activeSlide}
      isOpen={true}
      setActiveSlide={setActiveSlide}
      close={close}
      finish={finish}
    />
  );
});

const BankDetailsModalContainer = ({ isOpen, ...props }) => {
  const portalTarget = usePortal("bank-details-modal-container");
  const contents = isOpen ? <BankDetailsModal {...props} /> : null;
  return createPortal(contents, portalTarget);
};

export default BankDetailsModalContainer;
