import React, { useState } from "react";
import { createPortal } from "react-dom";
import { observer } from "mobx-react";
import { FiX } from "react-icons/fi";
import usePortal from "../../../../hooks/usePortal";
import RewardOptionsStore from "../../../../stores/RewardOptionsStore";
import FullScreenModal from "../../../-common/FullScreenModal";
import Label from "../../../-common/Label";
import Input from "../../../-common/Input";
import Button from "../../../-common/Button";
import "./AddRewardOptionModal.scss";

const ChoiceRow = ({ i, choice, update, remove }) => {
  return (
    <div className="choice-row">
      <Input value={choice} onChange={update(i)} placeholder="Choice label" />
      <FiX
        className="remove-choice"
        size={40}
        color="var(--Gray500)"
        onClick={remove(i)}
      />
    </div>
  );
};

const AddRewardOptionModal = observer(({ close }) => {
  const [rewardOption, setRewardOption] = useState({
    title: "",
    values: []
  });

  const { values: choices } = rewardOption || {};

  const updateTitle = newTitle =>
    setRewardOption({ ...rewardOption, title: newTitle });

  const updateChoice = i => newTitle =>
    setRewardOption({
      ...rewardOption,
      values: rewardOption.values.map((title, j) => {
        if (i === j) return newTitle;
        return title;
      })
    });

  const addChoice = () =>
    setRewardOption({
      ...rewardOption,
      values: rewardOption.values.concat("")
    });

  const removeChoice = i => () =>
    setRewardOption({
      ...rewardOption,
      values: rewardOption.values.filter((_, j) => i !== j)
    });

  const saveEnabled =
    !!rewardOption.title && rewardOption.values.every(Boolean);

  const save = async () => {
    if (saveEnabled) {
      await RewardOptionsStore.addRewardOption(rewardOption);
      close();
    }
  };

  const choiceRows = (choices || []).map((c, i) => (
    <ChoiceRow
      i={i}
      choice={c}
      update={updateChoice}
      remove={removeChoice}
      key={i}
    />
  ));

  return (
    <FullScreenModal isOpen={true} close={close}>
      <div className="add-reward-option-modal">
        <div className="modal-contents">
          <div className="headline">Add reward option</div>
          <Input
            label="Option Title"
            value={rewardOption.title}
            placeholder="Option Title"
            onChange={updateTitle}
          />
          <div className="choices-label">
            <Label value="Choices" />
          </div>
          <div className="choices-container">{choiceRows}</div>
          <div className="add-choice-row">
            <div className="add-choice" onClick={addChoice}>
              + Add choice
            </div>
          </div>
        </div>
        <div className="bottom-buttons">
          <Button text="cancel" theme="light" onClick={close} />
          <Button disabled={!saveEnabled} text="save" onClick={save} />
        </div>
      </div>
    </FullScreenModal>
  );
});

const AddRewardOptionModalContainer = ({ isOpen, ...props }) => {
  const portalTarget = usePortal("add-reward-option-modal-container");
  const contents = isOpen ? <AddRewardOptionModal {...props} /> : null;
  return createPortal(contents, portalTarget);
};

export default AddRewardOptionModalContainer;
