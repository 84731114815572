function getFullYoutubeURLId(videoURL, base) {
  const re = new RegExp("(\\?|&)" + base + "\\=([^&]*)(&|$)");
  const matches = videoURL.match(re);
  if (matches) {
    return matches[2];
  } else {
    return "";
  }
}

function getMiniYoutubeURLId(videoURL) {
  return videoURL?.split("youtu.be/")?.[1]?.replace(/[^\w-]/g, "") || "";
}

export default function getVideoId(videoURL) {
  const result = {};
  let matches;

  if (videoURL.includes("youtube.com/watch")) {
    result.provider = "youtube";
    result.id = getFullYoutubeURLId(videoURL, "v");
  } else if (videoURL.includes("youtu.be/")) {
    result.provider = "youtube";
    result.id = getMiniYoutubeURLId(videoURL);
  } else if (videoURL.match(/vimeo.com\/(\d+)/)) {
    matches = videoURL.match(/vimeo.com\/(\d+)/);
    result.provider = "vimeo";
    result.id = matches[1];
  }
  return result;
}
