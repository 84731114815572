import React, { useEffect, useState, useCallback } from "react";
import { observer } from "mobx-react";
import commaNumber from "comma-number";
import { FiDollarSign, FiShoppingCart, FiX } from "react-icons/fi";
import RewardsStore from "../../../../stores/RewardsStore";
import Input from "../../../-common/Input";
import CurrencyInput from "../../../-common/CurrencyInput";
import Textarea from "../../../-common/Textarea";
import Select from "../../../-common/Select2";
import Label from "../../../-common/Label";
import FileUpload from "../../../-common/FileUpload";
import { getFileURLFromFile } from "../../../../utils";
import ConfirmRemoveTierModal from "./ConfirmRemoveTierModal";
import "./EditTierCard.scss";

const rewardCategoryOptions = [
  { label: "No Reward", value: "none" },
  { label: "Physical Good", value: "physical" },
  { label: "Experience", value: "experience" },
  { label: "Sponsorhip or Discount Code", value: "sponsorship" },
  { label: "Contest", value: "contest" },
];

const EDIT_TIER_STEPS = {
  DETAILS: "details",
  REWARD: "reward",
  MEDIA: "media",
};

function EditTierCard({
  localSortIndex,
  tier,
  update,
  campaignId,
  sorting,
  duringDragSortOrder,
}) {
  const [currentStep, setCurrentStep] = useState(EDIT_TIER_STEPS.DETAILS);
  const [rewardCategory, setRewardCategory] = useState(null);
  const [selectedReward, setSelectedReward] = useState(null);
  const [rewardOptions, setRewardOptions] = useState([]);
  const [fileURL, setFileURL] = useState('');
  const [confirmRemoveTierModalOpen, setConfirmRemoveTierModalOpen] = useState(false);
  const openConfirmRemoveTierModal = () => {
    setConfirmRemoveTierModalOpen(true);
  };
  const closeConfirmRemoveTierModal = () => {
    setConfirmRemoveTierModalOpen(false);
  };

  const {
    tierId,
    title,
    description,
    donationAmount,
    reward,
    quantity,
    media,
  } = tier;
  const {
    rewardId,
    category,
    availableQuantity,
  } = reward || {};
  const availableQuantityMessage = `${availableQuantity} more available`;

  const selectedRewardCategory = rewardCategoryOptions?.find((o) => o.value === category) || {};

  useEffect(() => {
    if (rewardId) {
      setRewardCategory(selectedRewardCategory);
      const newOptions = RewardsStore.allRewardsByCategory[category].map((r) => ({
        label: r.title,
        value: r.rewardId,
        reward: r,
      }));
      setRewardOptions(newOptions);
      setSelectedReward(newOptions?.find((o) => o.value === rewardId));
    } else {
      setRewardCategory(rewardCategoryOptions[0]);
    }
  }, [rewardId, category, selectedRewardCategory]);

  const updateMedia = useCallback(
    async (file) => {
      const url = await getFileURLFromFile(file);
      setFileURL(url);
      update({ ...tier, media: url, file });
    },
    [tier, update],
  );

  const updateTitle = useCallback(
    (value) => {
      update({ ...tier, title: value });
    },
    [tier, update],
  );

  const updateDescription = useCallback(
    (value) => {
      update({ ...tier, description: value });
    },
    [tier, update],
  );

  const updateDonationAmount = useCallback(
    (value) => {
      update({ ...tier, donationAmount: value });
    },
    [tier, update],
  );

  const updateRewardType = useCallback(
    ({ value }) => {
      setRewardCategory(value);
      const firstReward =
        value === "none" ? { rewardId: null, reward: null, quantity: 0 } : RewardsStore.allRewardsByCategory[value][0];
      update({
        ...tier,
        quantity: firstReward?.quantity,
        rewardId: firstReward?.rewardId,
        reward: firstReward,
      });
    },
    [tier, update],
  );

  const updateReward = useCallback(
    ({ value, reward }) => {
      update({ ...tier, rewardId: value, reward });
    },
    [tier, update],
  );

  const updateQuantity = useCallback(
    value => update({ ...tier, quantity: value }),
    [tier, update]
  );

  const reserves = (reward?.reservesByCampaign || {})[campaignId] || 0;
  const purchases = (reward?.purchasesByCampaign || {})[campaignId] || 0;

  let claimed;
  if (reserves > 0) {
    claimed = `${commaNumber(purchases)}/${commaNumber(reserves)} claimed`;
  } else if (purchases > 0) {
    claimed = `Unlimited (${commaNumber(purchases)} claimed)`;
  } else {
    claimed = "Unlimited";
  }

  const rewardTypeComponent =
    rewardCategory?.value === "none" ? null : (
      <div className="reward">
        <Select
          label="Select reward"
          options={rewardOptions}
          onChange={updateReward}
          value={selectedReward}
        />
      </div>
    );

  const rewardComponent =
    rewardCategory?.value === "none" ? null : (
      <div className="reserved-quantity">
        <Input
          placeholder="Unlimited"
          icon={FiShoppingCart}
          label="Reserve how many?"
          value={quantity || ""}
          onChange={updateQuantity}
        />
        <div className="reward-quantity-available">{availableQuantityMessage}</div>
      </div>
    );

  const duringDragSortIndex = duringDragSortOrder.indexOf(tierId);
  const sortLabel = duringDragSortIndex > -1 ? duringDragSortIndex + 1 : null;

  return (
    <div className={`edit-tier-card${sorting ? " sorting" : ""}`}>
      <div className="sorting-overlay">{sortLabel}</div>
      <div className="tier-slides-container">
        <div
          className={`tier-slide tier-edit-container ${
            currentStep === EDIT_TIER_STEPS.DETAILS ? "active" : "inactive"
          }`}
        >
          <div className="top-bar">
            <div className="total-claimed">{claimed}</div>
            <div className="edit-buttons-wrapper">
              <div className="reward-edit-toggle media-toggle" onClick={() => setCurrentStep(EDIT_TIER_STEPS.MEDIA)}>
                Edit Media
              </div>
              <div className="reward-edit-toggle" onClick={() => setCurrentStep(EDIT_TIER_STEPS.REWARD)}>
                Edit Reward
              </div>
            </div>
          </div>
          <div className="title">
            <Input
              placeholder="Tier Title"
              value={title}
              onChange={updateTitle}
            />
          </div>
          <div className="description">
            <Textarea
              placeholder="Tier Description"
              value={description}
              onChange={updateDescription}
            />
          </div>
          <div className="donate-button">
            <CurrencyInput
              placeholder="Donation Amount"
              icon={FiDollarSign}
              value={donationAmount}
              onChange={updateDonationAmount}
            />
          </div>
        </div>
        <div
          className={`tier-slide tier-edit-container ${currentStep === EDIT_TIER_STEPS.MEDIA ? "active" : "inactive"}`}
        >
          <div className="top-bar">
            <div className="reward-edit-toggle media-toggle" onClick={() => setCurrentStep(EDIT_TIER_STEPS.DETAILS)}>
              {"< Back to Tier"}
            </div>
          </div>
          <Label value="Update Image" />
          <FileUpload
            accept="image/png, image/jpeg"
            fileURL={media || fileURL}
            onUpload={updateMedia}
            style={{ height: "auto" }}
          />
        </div>
        <div
          className={`tier-slide reward-edit-container ${
            currentStep === EDIT_TIER_STEPS.REWARD ? "active" : "inactive"
          }`}
        >
          <div className="top-bar">
            <div className="reward-edit-toggle" onClick={() => setCurrentStep(EDIT_TIER_STEPS.DETAILS)}>
              {"< Back to Tier"}
            </div>
          </div>
          <div className="reward-type">
            <Select
              label="Select reward type"
              options={rewardCategoryOptions}
              onChange={updateRewardType}
              value={rewardCategory}
            />
          </div>
          {rewardTypeComponent}
          {rewardComponent}
        </div>
      </div>
      <div className="delete-tier-button" onClick={openConfirmRemoveTierModal}>
        <FiX size={32} />
      </div>
      <ConfirmRemoveTierModal
        isOpen={confirmRemoveTierModalOpen}
        campaignId={campaignId}
        tierId={tierId}
        close={closeConfirmRemoveTierModal}
      />
    </div>
  );
}

export default observer(EditTierCard);
