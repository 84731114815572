import React from "react";

export default function tennisRacquet({
  size = 24,
  fill = "none",
  stroke = "#000",
  strokeWidth = 13
}) {
  return (
    <svg
      viewBox="0 0 376.625 376.625"
      height={size}
      width={size}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    >
      <path d="M242.057,234.112 l-40.214,3.794c-41.053,3.874-79.262,22.659-107.4,52.802l0,0l-6.339-6.339l5.579-5.843c25.067-26.255,40.989-59.899,45.401-95.93 l5.613-45.845" />
      <rect
        x="42.016"
        y="273.654"
        transform="matrix(0.7071 0.7071 -0.7071 0.7071 245.7684 57.6225)"
        width="22.624"
        height="103.652"
      />
      <path d="M242.536,18.422 c37.7-13.7,76.79-9,101.82,16.03c37.93,37.92,29.18,108.15-19.53,156.86c-48.7,48.7-118.93,57.45-156.85,19.52 c-37.3-37.29-29.46-105.82,17.12-154.4" />
      <circle cx="216.345" cy="41.245" r="34.745" />
      <path d="M193.328,67.275 c0.535-0.463,1.051-0.953,1.559-1.461c13.568-13.568,13.568-35.573,0-49.141c-0.508-0.508-1.025-0.998-1.559-1.461" />
      <path d="M239.361,67.266 c-0.526-0.463-1.042-0.944-1.55-1.452c-13.577-13.568-13.577-35.573,0-49.141c0.508-0.508,1.025-0.989,1.55-1.452" />
    </svg>
  );
}
