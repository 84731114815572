import axios from 'axios';
import {
  API,
  Auth,
} from 'aws-amplify';
import { navigate } from '@reach/router';
import {
  API_HOST,
  API_NAME,
  DWP_HOST,
} from '../constants/main';

const instance = axios.create({ baseURL: `${API_HOST}` });
const dwpInstance = axios.create({ baseURL: `${DWP_HOST}` });

const makeUnpackedMethod =
  method => async (...params) => {
    const { data } = await method(...params);

    return data;
  };

const makeWrappedMethod =
  method => async (url, config = {}) => {
    const amplifyMethod = method === 'delete' ? 'del' : method;

    try {
      return await API[amplifyMethod](API_NAME, url, config);
    } catch (err) {
      if (err?.response?.status === 401) {
        await Auth.signOut();
        navigate('/auth');
      } else {
        throw err;
      }
    }
  };

const wrapper = {
  post: makeWrappedMethod('post'),
  get: makeWrappedMethod('get'),
  put: makeWrappedMethod('put'),
  delete: makeWrappedMethod('delete'),

  unauthenticated: {
    post: makeUnpackedMethod(instance.post),
    get: makeUnpackedMethod(instance.get),
    put: makeUnpackedMethod(instance.put),
    delete: makeUnpackedMethod(instance.delete),
  },

  dwp: {
    post: makeUnpackedMethod(dwpInstance.post),
    get: makeUnpackedMethod(dwpInstance.get),
    put: makeUnpackedMethod(dwpInstance.put),
    delete: makeUnpackedMethod(dwpInstance.delete),
  },

  bare: {
    post: axios.post,
    get: axios.get,
    put: axios.put,
    delete: axios.delete,
  },
};

export default wrapper;
