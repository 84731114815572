import React from "react";
import { commaSeparateNumber } from "../../../../../utils";
import "./PledgeProgressGauge.scss";
import { format } from "date-fns";

const gaugeStartAngle = (7 * Math.PI) / 8;
const gaugeEndAngle = (17 * Math.PI) / 8;
const gaugeArcLength = gaugeEndAngle - gaugeStartAngle;

const svgSize = 200;
const strokeWidth = 12;
const viewBoxStart = -(svgSize / 2) - strokeWidth / 2;
const viewBoxHeight =
  svgSize / 2 + (svgSize / 2) * Math.sin(gaugeStartAngle) + strokeWidth;
const viewBoxWidth = svgSize + strokeWidth;

const PledgeProgressGauge = ({
  startAmount = 0,
  endAmount = 100,
  filledAmount = 65,
  trackColor = "var(--Gray300)",
  fillColor = "var(--Green)",
  icon: Icon = () => null,
  paymentSchedule = "Monthly",
  nextPaymentDate = new Date("2020-04-15"),
}) => {
  const computationStartAmount = Math.min(
    Math.max(startAmount, 0),
    endAmount || 100
  );
  const computationEndAmount = Math.max(
    endAmount || 100,
    computationStartAmount
  );
  const filledPercent =
    filledAmount / (computationEndAmount - computationStartAmount);
  const computationFilledPercent = Math.min(Math.max(filledPercent, 0), 1);
  const filledArcLength = gaugeArcLength * computationFilledPercent;
  const filledEndAngle = gaugeStartAngle + filledArcLength;

  const gaugePath = `M${100 * Math.cos(gaugeStartAngle)},${
    100 * Math.sin(gaugeStartAngle)
  } A100,100 0,${gaugeArcLength > Math.PI ? 1 : 0},1 ${
    100 * Math.cos(gaugeEndAngle)
  },${100 * Math.sin(gaugeEndAngle)}`;

  const filledPath = `M${100 * Math.cos(gaugeStartAngle)},${
    100 * Math.sin(gaugeStartAngle)
  } A100,100 0,${filledArcLength > Math.PI ? 1 : 0},1 ${
    100 * Math.cos(filledEndAngle)
  },${100 * Math.sin(filledEndAngle)}`;

  return (
    <div className="pledge-progress-gauge">
      <div className="chart-title">Current Pledge</div>
      <div className="chart-wrapper">
        <svg
          viewBox={`${viewBoxStart} ${viewBoxStart} ${viewBoxWidth} ${viewBoxHeight}`}
          fill="transparent"
          stroke={trackColor}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d={gaugePath}></path>
          <path d={filledPath} stroke={fillColor}></path>
        </svg>
      </div>
      <div className="info-wrapper">
        <div className="icon-wrapper" style={{ backgroundColor: fillColor }}>
          <Icon className="summary-icon" size={24} />
        </div>
        <div className="value">${commaSeparateNumber(filledAmount)}</div>
        <div className="title">
          You are {Math.round(filledPercent * 100)}% completed on your total
          pledge of ${commaSeparateNumber(endAmount)}
        </div>
        <div className="subtitles">
          <div className="subtitle">
            <span className="parameter">{paymentSchedule}</span> Payment
            Schedule
          </div>
          <div className="subtitle">
            Next Payment:{" "}
            <span className="parameter">
              {format(nextPaymentDate, "MMMM do, yyyy")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PledgeProgressGauge;
