import React from "react";
import { observer } from "mobx-react";
import { format } from "date-fns";
import { Table } from "ka-table";
import { DataType } from "ka-table/enums";
// import { FiExternalLink } from "react-icons/fi";
import commaNumber from "comma-number";
import "./ContributionsTable.scss";
import Spinner from "../../../../-common/Spinner";

const AmountCell = ({ rowData, dispatch }) => {
  let { totalDonationAmount } = rowData?.amountMetadata;
  totalDonationAmount = "$" + commaNumber(Number(totalDonationAmount / 100 || 0)?.toFixed(2));
  return (
    <div className="amount-cell" onClick={() => dispatch("Row Click", rowData)}>
      {totalDonationAmount}
    </div>
  );
};

const columns = [
  {
    dataType: DataType.Date,
    key: "date",
    format: d => format(d, "M/d/yyyy"),
    title: "Date"
  },
  {
    cell: AmountCell,
    dataType: DataType.Number,
    key: "amountMetadata.totalDonationAmount",
    title: "Amount"
  }
];

const childAttributes = {
  cell: {
    onClick: (_, e) => {
      const { dispatch, rowData } = e.childProps;
      dispatch("Row Click", rowData);
    }
  }
};

function ContributionsTable({ transactions }) {
  const contents = transactions ? (
    <Table childAttributes={childAttributes} columns={columns} data={transactions} rowKeyField="transactionId" />
  ) : (
    <div className="spinner-container">
      <Spinner color="var(--primary-color)" />
    </div>
  );
  return (
    <div className="contributions-table-container">
      <div className="contributions-table-title">
        <div className="title-text">Contribution History</div>
        {/* <FiExternalLink size={18} color="var(--Gray500)" /> */}
      </div>
      {contents}
    </div>
  );
}

export default observer(ContributionsTable);
