export const bulletinsTypes = {
  NEWS: 'news',
  ALERT: 'alert',
  VIDEO: 'video',
  SPECIAL: 'special',
};

export const bulletinsTypeOptions = [
  {
    label: 'News',
    value: bulletinsTypes.NEWS,
  },
  {
    label: 'Alert',
    value: bulletinsTypes.ALERT,
  },
  {
    label: 'Video',
    value: bulletinsTypes.VIDEO,
  },
  {
    label: 'Special Announcement',
    value: bulletinsTypes.SPECIAL,
  },
];
