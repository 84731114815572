import {
  observable,
  action,
  computed,
  autorun,
} from 'mobx';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { navigate } from '@reach/router';
import { toast } from 'react-toastify';
import CISP from 'aws-sdk/clients/cognitoidentityserviceprovider';
import { request } from '../utils';
import InstanceConfigStore from './InstanceConfigStore';

async function setUserPassword(params, creds) {
  const cisp = new CISP({
    region: 'us-east-1',
    credentials: creds,
  });

  return cisp.adminSetUserPassword(params).promise();
}

class AuthStore {
  constructor() {
    autorun(() => {
      if (InstanceConfigStore.authConfigured) {
        try {
          this.setupCreds();
        } catch (err) {
          this.clearCreds();
        }
      }
    });
  }

  // AUTHENTICATION FOR ROUTES
  @observable authConfigured = false;

  @observable loading = false;

  @observable tenantOptions = [];

  @observable currentTenant;

  @observable currentUser;

  @observable credentials;

  @observable session;

  @observable authStateDetermined = false;

  @observable username;

  @observable forcePasswordReset = true;

  @observable newUser;

  @computed get authenticated() {
    if (!this.authStateDetermined) return null;

    return !!this.currentUser && !!this.credentials && !!this.session;
  }

  @computed get authHeaders() {
    if (this.session) {
      return { 'X-Nickel-Auth': `${this.session.getIdToken().getJwtToken()}` };
    }

    return null;
  }

  @computed get APIReady() {
    return !!this.currentUser && this.credentials && this.authHeaders;
  }

  @computed get userId() {
    return this?.currentUser?.attributes?.sub;
  }

  @computed get group() {
    return (this?.currentUser?.signInUserSession?.idToken?.payload?.['cognito:groups'] || [])[0];
  }

  @action async setupCreds() {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      const credentials = await Auth.currentCredentials();
      const session = await Auth.currentSession();

      this.currentUser = currentUser;
      this.credentials = credentials;
      this.session = session;
      this.authStateDetermined = true;
    } catch (err) {
      this.authStateDetermined = true;
    }
  }

  @action async verifyPassword(password) {
    const { username } = this.currentUser;

    if (!username) {
      this.signOut();
    } else {
      try {
        await Auth.signIn(username, password);

        return true;
      } catch {
        return false;
      }
    }
  }

  @action clearCreds() {
    this.currentUser = null;
    this.credentials = null;
    this.session = null;
  }

  @action async checkUsername(username) {
    this.loading = true;

    try {
      this.authConfigured = true;
      this.username = username;

      this.loading = false;

      return { success: true };
    } catch (err) {
      this.authConfigured = false;
      this.username = null;

      this.loading = false;

      return {
        success: false,
        message: 'Email address not found.',
      };
    }
  }

  @action async signIn(password, username) {
    this.loading = true;

    try {
      const user = await Auth.signIn(username || this.username, password);
      const { challengeName } = user || {};

      if (challengeName === 'NEW_PASSWORD_REQUIRED') {
        this.setNewUser(user);
        this.forcePasswordReset = true;
        navigate('/auth/newpassword');
      } else {
        this.setupCreds();
      }

      this.loading = false;

      return { success: true };
    } catch (err) {
      if (err.message) {
        toast.error(err.message);
      }

      if (err.code === 'UserNotConfirmedException') {
        // SHOULDN'T EVER GET HERE
        console.warn(err);
      } else if (err.code === 'PasswordResetRequiredException') {
        console.warn(err);
      } else if (err.code === 'NotAuthorizedException') {
        navigate('/auth');
      } else if (err.code === 'UserNotFoundException') {
        console.warn(err);
      } else {
        console.warn(err);
      }

      this.loading = false;

      return {
        success: false,
        err,
      };
    }
  }

  @action async signOut() {
    try {
      await Auth.signOut();
      this.clearCreds();
      this.username = null;

      // CLEAN UP FOR PROTECTED CONTENT
      localStorage.setItem('Nickel:financePagesUnlocked', 'false');

      return true;
    } catch (err) {
      console.warn(err);

      return false;
    }
  }

  @action setNewUser = user => (this.newUser = user);

  @action async adminResetPassword(username, newPassword) {
    try {
      const userPoolId = InstanceConfigStore?.authConfig?.userPoolId;
      const params = {
        Password: newPassword,
        UserPoolId: userPoolId,
        Username: username,
        Permanent: true,
      };
      const creds = this.credentials;

      await setUserPassword(params, creds);
    } catch (e) {
      console.warn('Error resetting password', e);
    }
  }

  @action async resetPassword(newPassword) {
    if (this.newUser) {
      await Auth.completeNewPassword(this.newUser, newPassword);
      await this.signIn(newPassword, this.newUser.username);
      this.setNewUser(null);
      this.forcePasswordReset = false;

      return true;
    }
  }

  @action async sendResetPasswordEmail(email) {
    try {
      this.loading = true;
      const { userPoolWebClientId: clientId } = InstanceConfigStore?.authConfig?.Auth;
      const response = await request.post('/v1/auth/forgotpassword', {
        body: {
          email,
          clientId,
        },
      });

      this.loading = false;

      return response;
    } catch (err) {
      this.loading = false;
      console.warn(err);
    }
  }

  @action async resetPasswordAfterForgot(email, newPassword, code) {
    await Auth.forgotPasswordSubmit(email, code, newPassword);
    await this.signIn(newPassword, email);
  }

  @action async sendEmailVerificationRequest(email) {
    try {
      this.loading = true;
      const data = await request.dwp.post(
        '/v1/onboarding/validate',
        { email },
        { headers: { 'X-Nickel-Tenant-Id': InstanceConfigStore.tenant.tenantId } }
      );

      this.loading = false;

      return data;
    } catch (err) {
      this.loading = false;

      return err;
    }
  }

  @action async sendRegisterRequest({
    email,
    password,
    confirmPassword,
    code,
  }) {
    try {
      this.loading = true;
      const data = await request.dwp.post(
        '/v1/onboarding',
        {
          email,
          password,
          confirmPassword,
          code,
        },
        { headers: { 'X-Nickel-Tenant-Id': InstanceConfigStore.tenant.tenantId } }
      );

      this.loading = false;

      return data;
    } catch (err) {
      this.loading = false;

      if (err.response.data.reason) {
        toast.error(err.response.data.reason);
      }

      throw err;
    }
  }
}

export default new AuthStore();
