import { observable, computed, action, autorun } from "mobx";
import { toast } from "react-toastify";
import { getSubdomain, request } from "../utils";
import AuthStore from "./AuthStore";

function getRecentlyViewedKey() {
  return `nickel:${getSubdomain()}:recentlyviewed`;
}

class ClubMembersStore {
  constructor() {
    autorun(() => {
      if (AuthStore.APIReady) {
        this.fetchClubStats();
        this.fetchClubMembers();
        this.loadRecentlyViewed();
      } else {
        this.clear();
      }
    });
  }

  // STATS
  @observable rawClubStats = {};

  @computed get clubStats() {
    return {
      ...this.rawClubStats,
      totalDonatedYTD: (this.rawClubStats?.totalDonatedYTD || 0) / 100
    };
  }

  @action async fetchClubStats() {
    try {
      const clubStats = await request.get("/v1/club/stats");
      this.rawClubStats = clubStats;
    } catch (err) {
      console.warn(err);
    }
  }

  // USERS
  @observable rawClubMembers = [];

  @computed get clubMembers() {
    return (this.rawClubMembers || []).map(c => ({
      ...c,
      profile: {
        ...(c?.profile || {}),
        fullName: `${c?.profile?.firstName} ${c?.profile?.lastName}`
      },
      membershipStartDate: c.membershipStartDate ? new Date(c.membershipStartDate) : null
    }));
  }

  @action async fetchClubMembers() {
    try {
      const clubMembers = await request.get("/v1/club/members");
      this.rawClubMembers = clubMembers;
    } catch (err) {
      console.warn(err);
    }
  }

  // USER DETAILS
  @observable rawClubMemberDetails = [];

  @computed get clubMemberDetails() {
    return (this.rawClubMemberDetails || []).map(raw => ({ ...raw, fullName: `${raw.firstName} ${raw.lastName}` }));
  }

  @action async fetchClubMemberDetails(userId) {
    try {
      const clubMemberDetails = await request.get(`/v1/club/members/${userId}`);
      this.rawClubMemberDetails = this.rawClubMemberDetails?.concat(clubMemberDetails);
      return clubMemberDetails;
    } catch (err) {
      console.warn(err);
    }
  }

  @action async setAssignedDOForClubMember(donorUserId, departmentUserId) {
    try {
      await request.post(`/v1/users/assignment`, { body: { donorUserId, departmentUserId } });
      this.rawClubMemberDetails = this.rawClubMemberDetails?.map(cm => {
        if (cm?.userId === donorUserId) return { ...cm, assignedDevelopmentOfficerId: departmentUserId };
        else return cm;
      });
      toast("Assigned Development Officer updated.");
      return true;
    } catch (err) {
      console.warn(err);
      toast("Error updating Assigned Development Officer.");
      return false;
    }
  }

  @action async updateMobileReadyForClubMember(donorUserId, mobileReady) {
    try {
      const currentConfig = this.clubMemberDetails?.find(cm => cm.userId === donorUserId)?.config || {};
      if (!currentConfig) throw new Error("Club member details not found.");

      const updatedMember = await request.put(`/v1/club/members/${donorUserId}`, {
        body: { config: { ...currentConfig, mobileReady } }
      });
      this.rawClubMemberDetails = this.rawClubMemberDetails?.map(cm => {
        if (cm?.userId === donorUserId) return updatedMember;
        else return cm;
      });
      toast(`Mobile app ${mobileReady ? "enabled" : "disabled"}.`);
      return true;
    } catch (err) {
      console.warn(err);
      toast(`Error ${mobileReady ? "enabling" : "disabling"} mobile app.`);
      return false;
    }
  }

  @action async sendMobileInvite(donorUserId) {
    try {
      const currentConfig = this.clubMemberDetails?.find(cm => cm.userId === donorUserId)?.config || {};
      if (!currentConfig) throw new Error("Club member details not found.");

      await request.post(`/v1/club/members/${donorUserId}/invite`);
      toast(`Mobile app invite sent.`);
      return true;
    } catch (err) {
      console.warn(err);
      toast(`Error sending mobile app invite.`);
      return false;
    }
  }

  // RECENTLY VIEWED
  @observable rawRecentlyViewed = [];

  @computed get recentlyViewed() {
    return this.rawRecentlyViewed?.map(c => {
      const fullClubMember = this.clubMemberDetails?.find(cm => cm?.userId === c?.userId);
      if (fullClubMember) {
        fullClubMember.profile = {
          firstName: fullClubMember?.firstName,
          lastName: fullClubMember?.lastName,
          media: fullClubMember?.media
        };
      }
      const partialClubMember = this.clubMembers?.find(cm => cm?.userId === c?.userId);
      return fullClubMember || partialClubMember || c;
    });
  }

  @action loadRecentlyViewed() {
    try {
      const encodedRecents = window.localStorage.getItem(getRecentlyViewedKey());
      const recents = JSON.parse(atob(encodedRecents));
      this.rawRecentlyViewed = recents || [];
    } catch {
      this.rawRecentlyViewed = [];
    }
  }

  @action setRecentlyViewed(recents) {
    try {
      const encodedRecents = btoa(JSON.stringify(recents));
      window.localStorage.setItem(getRecentlyViewedKey(), encodedRecents);
      this.rawRecentlyViewed = recents;
    } catch {}
  }

  @action addToRecentlyViewed({ userId, profile }) {
    this.rawRecentlyViewed = this.rawRecentlyViewed?.filter(cm => cm?.userId !== userId);
    const newRecents = this.rawRecentlyViewed?.concat({ userId, profile })?.reverse()?.slice(0, 16);
    this.setRecentlyViewed(newRecents);
  }

  // SEARCH
  @observable search = "";

  @action setSearch = search => (this.search = search);

  @computed get searchResults() {
    if (!this.search) return [];

    return this.clubMembers
      ?.filter(cm => cm?.profile?.fullName?.toLowerCase()?.includes(this.search?.toLowerCase()))
      ?.slice(0, 16);
  }

  // METRICS
  @computed get totalDonated() {
    return this.clubMembers.reduce((acc, next) => acc + next.totalDonated, 0);
  }

  // CLEANUP
  @action clear() {
    this.rawClubMembers = [];
  }
}

export default new ClubMembersStore();
