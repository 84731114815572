import React from "react";
import Select from "../../../../-common/Select2";
import Textarea from "../../../../-common/Textarea";
import "./DetailsSlide.scss";

const DetailsSlide = ({ state, dispatch }) => {
  const { transactionType, fund, notes } = state;

  const setTransactionType = v => dispatch({ type: "setTransactionType", payload: { transactionType: v } });
  const setFund = v => dispatch({ type: "setFund", payload: { fund: v } });

  const setNotes = v => dispatch({ type: "setNotes", payload: { notes: v } });

  const transactionTypeOptions = [{ value: "Membership Renewal", label: "Membership Renewal" }];

  const fundOptions = [
    { value: "Football", label: "Football" },
    { value: "Basketball", label: "Basketball" },
    { value: "Gymnastics", label: "Gymnastics" },
    { value: "Baseball", label: "Baseball" }
  ];

  return (
    <div className="slide details-slide">
      <div className="headline">Transaction Details</div>
      <div className="inputs">
        <Select
          label="What's this for?"
          value={transactionType}
          onChange={setTransactionType}
          options={transactionTypeOptions}
        />
        <Select label="Associated Fund" value={fund} onChange={setFund} options={fundOptions} />
        <Textarea value={notes} onChange={setNotes} label="Transaction Notes" />
      </div>
    </div>
  );
};

export default DetailsSlide;
