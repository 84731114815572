import React, { useState, useCallback } from "react";
import { observer } from "mobx-react";
import { FiX } from "react-icons/fi";
import Input from "../../../-common/Input";
import Textarea from "../../../-common/Textarea";
import ConfirmRemovePerkModal from "./ConfirmRemovePerkModal";
import "./EditPerkCard.scss";

function EditPerkCard({ perk, update, sorting, duringDragSortOrder }) {
  const [confirmRemovePerkModalOpen, setConfirmRemovePerkModalOpen] = useState(
    false
  );
  const openConfirmRemovePerkModal = () => {
    setConfirmRemovePerkModalOpen(true);
  };
  const closeConfirmRemovePerkModal = () => {
    setConfirmRemovePerkModalOpen(false);
  };

  const { perkId, title, requirements } = perk;

  const updateTitle = useCallback(
    value => {
      update({ ...perk, title: value });
    },
    [perk, update]
  );

  const updateRequirements = useCallback(
    value => {
      update({ ...perk, requirements: value });
    },
    [perk, update]
  );

  const duringDragSortIndex = duringDragSortOrder.indexOf(perkId);
  const sortLabel = duringDragSortIndex > -1 ? duringDragSortIndex + 1 : null;

  return (
    <div className={`edit-perk-card${sorting ? " sorting" : ""}`}>
      <div className="sorting-overlay">{sortLabel}</div>
      <div className="perk-slides-container">
        <div className="perk-slide">
          <div className="title">
            <Input
              label="Title"
              placeholder="Perk Title"
              value={title}
              onChange={updateTitle}
            />
          </div>
          <div className="requirements">
            <Textarea
              label="Requirements"
              placeholder="Perk Requirements"
              value={requirements}
              onChange={updateRequirements}
              maxLength={120}
            />
          </div>
        </div>
      </div>
      <div className="delete-perk-button" onClick={openConfirmRemovePerkModal}>
        <FiX size={32} />
      </div>
      <ConfirmRemovePerkModal
        isOpen={confirmRemovePerkModalOpen}
        perkId={perkId}
        close={closeConfirmRemovePerkModal}
      />
    </div>
  );
}

export default observer(EditPerkCard);
