import { parsePhoneNumber } from "libphonenumber-js";

const nextEnabled = state => {
  const { activeSlide, firstName, lastName, email, phone, street1, city, state: addressState, zipCode } = state || {};

  if (activeSlide === 0) {
    return true;
  } else if (activeSlide === 1) {
    try {
      return (
        !!firstName &&
        !!lastName &&
        email?.includes("@") &&
        email?.split("@")?.[0] !== "" &&
        email?.split("@")?.[1].includes(".") &&
        parsePhoneNumber(phone, "US").isValid()
      );
    } catch {
      return false;
    }
  } else if (activeSlide === 2) {
    return !!street1 && !!city && !!addressState && zipCode?.length === 5;
  }
  return false;
};

export const financialContactInitialState = { activeSlide: 0, nextEnabled: true };

export const financialContactReducer = (state, action) => {
  const { type, payload } = action;
  const withSimpleUpdates = { ...state, ...payload };
  let result = state;

  switch (type) {
    case "resetState":
      result = { ...state, ...financialContactInitialState };
      break;
    default:
      result = { ...withSimpleUpdates, nextEnabled: nextEnabled(withSimpleUpdates) };
      break;
  }
  return result;
};
