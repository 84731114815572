import React from "react";
import { observer } from "mobx-react";
import TenantStore from "../../../../stores/TenantStore";
import "./Tickets.scss";

const Ticket = observer(({ ticketId, sport, year, section, row, seats }) => {
  const { theme } = TenantStore?.tenant || {};
  const seasonLabel = "'" + year.slice(2) + " Season";
  return (
    <div className="ticket-card" key={ticketId}>
      <div className="ticket-inner">
        <div className="ticket-banner">
          <div className="banner-stripe" />
          <div className="banner-body">
            <div className="banner-contents">
              <img src={theme?.logo} className="school-logo" alt="University logo" />
              <div className="season-label">{seasonLabel}</div>
            </div>
          </div>
          <div className="banner-stripe" />
        </div>
        <div className="ticket-details">
          <div className="ticket-sport">{sport}</div>
          <div className="ticket-quantities">
            <div className="ticket-quantity">{seats?.length}</div>
            {/* <div className="ticket-quantity-label">Of {maxQuantity} Max Tickets</div> */}
            <div className="ticket-quantity-label">Tickets</div>
          </div>
          <div className="ticket-extras">
            <div className="ticket-extra">
              <div className="extra-label">Section</div>
              <div className="extra-value">{section}</div>
            </div>
            <div className="ticket-extra">
              <div className="extra-label">Row</div>
              <div className="extra-value">{row}</div>
            </div>
            <div className="ticket-extra">
              <div className="extra-label">Seats</div>
              <div className="extra-value">
                {seats?.[0]} - {seats.slice(-1)?.[0]}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

function Tickets({ clubMember }) {
  const tickets =
    clubMember?.tickets
      ?.map(({ details: { seatBlocks, ...dts } = {}, ...t }) => seatBlocks?.map(sb => ({ ...t, ...dts, ...sb })))
      ?.filter(Boolean)
      ?.flat()
      ?.filter(({ year }) => {
        const lastYear = new Date().getFullYear() - 1;
        const ticketYear = Number(year);
        return ticketYear >= lastYear;
      })
      ?.sort((a, b) => Number(b.year) - Number(a.year)) || [];
  const ticketCards = tickets.map(t => <Ticket {...t} key={t.id} />);

  return (
    <div className="tickets">
      <div className="title-row">
        <div className="tickets-section-title">Current Season Tickets</div>
      </div>
      <div className="season-tickets-container">
        <div className="season-tickets-wrapper">
          <div className="season-tickets">{ticketCards}</div>
        </div>
        <div className="fade" />
      </div>
    </div>
  );
}

export default observer(Tickets);
