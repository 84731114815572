import React from "react";

export default function hockeySticks({
  size = 24,
  fill = "none",
  stroke = "#000",
  strokeWidth = 13
}) {
  return (
    <svg
      viewBox="0 0 345.821 345.821"
      height={size}
      width={size}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
    >
      <path d="M169.8,230.79L136.01,288 c-6.04,10.23-17.04,16.51-28.92,16.51H28.84c-3.94,0-7.13,3.19-7.13,7.12v20.21c0,3.94,3.19,7.13,7.13,7.13h82.67 c10.29,0,19.81-5.44,25.04-14.3l44.46-75.27" />
      <polyline
        strokeLinejoin="round"
        points="191.49,194.06 302.26,6.5 318.72,16.22 202.71,212.65"
      />
      <line x1="44.11" y1="306.47" x2="44.11" y2="336.47" />
      <line x1="66.11" y1="306.47" x2="66.11" y2="336.47" />
      <line x1="233.699" y1="126.132" x2="245.698" y2="135.131" />
      <line x1="243.199" y1="106.632" x2="255.198" y2="115.631" />
      <line x1="256.699" y1="89.632" x2="268.698" y2="98.631" />
      <path
        strokeLinejoin="round"
        strokeLinecap="round"
        d="M54.11,19.637L72.446,8.846l95.061,161.747l14.253,7.358l70.948,117.507c2.321,3.943,6.555,6.365,11.13,6.365h50.17 c5.579,0,10.102,4.523,10.102,10.102v17.295c0,5.579-4.523,10.102-10.102,10.102h-67.638c-7.038,0-13.55-3.724-17.12-9.789 L149.444,196.97l-0.272-15.586L54.11,19.637z"
      />
      <line x1="298.531" y1="302.303" x2="298.531" y2="338.97" />
      <line x1="164.505" y1="168.717" x2="151.838" y2="177.384" />
    </svg>
  );
}
