import React from "react";
import Input from "../../../-common/Input";
import Textarea from "../../../-common/Textarea";
import FileUpload from "../../../-common/FileUpload";
import "./TitleSlide.scss";

const TitleSlide = ({ title, setTitle, description, setDescription, fileURL, onUpload }) => {
  return (
    <div className="slide title-slide">
      <div className="headline">SWAG Time</div>
      <div className="inputs">
        <div className="reward-picture-container">
          <FileUpload
            fileURL={fileURL}
            onUpload={onUpload}
            accept="image/png, image/jpeg"
            style={{ width: "50%", marginBottom: "24px" }}
          />
        </div>
        <Input value={title} onChange={setTitle} label="Item name" maxLength={60} countCharacters={true} />
        <Textarea
          value={description}
          onChange={setDescription}
          label="Description"
          maxLength={300}
          countCharacters={true}
        />
      </div>
    </div>
  );
};

export default TitleSlide;
