import React from "react";
import { Router, Redirect } from "@reach/router";
import Statistics from "./Statistics";
import Manage from "./Manage";
import Rewards from "./Rewards";
import CampaignsTheme from "./CampaignsTheme";
import "./Campaigns.scss";

export default function Campaigns() {
  return (
    <Router>
      <Statistics path="stats" />
      <Manage path="manage/*" />
      <Rewards path="rewards/*" />
      <CampaignsTheme path="theme" />
      <Redirect from="*" to="/campaigns/stats" noThrow />
    </Router>
  );
}
