const nextEnabled = state => {
  const { activeSlide } = state;
  if (activeSlide === 0) return true;
  if (activeSlide === 1)
    return (
      !!state.holderName &&
      state?.accountNumber?.length >= 5 &&
      state?.accountNumber?.length <= 17 &&
      state?.routingNumber?.length === 9
    );
  if (activeSlide === 2) return state?.birthday?.length === 10 && state?.SSN?.length === 11 && !!state.personaInquiryId;
  return false;
};

export const bankDetailsInitialState = { activeSlide: 0, nextEnabled: true };

export const bankDetailsReducer = (state, action) => {
  const { type, payload } = action;
  const withSimpleUpdates = { ...state, ...payload };
  let result = state;

  switch (type) {
    case "resetState":
      result = { ...state, ...bankDetailsInitialState };
      break;
    default:
      result = { ...withSimpleUpdates, nextEnabled: nextEnabled(withSimpleUpdates) };
      break;
  }
  return result;
};
