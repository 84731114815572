import React from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { Table } from "ka-table";
import { FiTrash2 } from "react-icons/fi";
import { navigate, useLocation } from "@reach/router";
import { DataType } from "ka-table/enums";
import TeamsStore from "../../../../stores/TeamsStore";
import GlobalSearchStore from "../../../../stores/GlobalSearchStore";
import sportIcons from "../../sportIcons";
import "./TeamsTable.scss";

const SocialCell = ({ field, rowData, dispatch }) => {
  const sendClickEvent = () => dispatch("Row Click", rowData);

  const socialObject = (rowData.social || []).find(s => s.platform === field);
  const media = (socialObject || {}).media;
  const platform = (socialObject || {}).platform;
  const username = platform === "twitter" ? `@${(socialObject || {}).username}` : (socialObject || {}).username;

  const socialCellComponent = media ? (
    <div className="social-cell" onClick={sendClickEvent}>
      <img className="social-cell-image" src={media} alt="" />
      <div className="social-cell-handle"> {username}</div>
    </div>
  ) : (
    <div className="social-cell-placeholder" onClick={sendClickEvent} />
  );

  return socialCellComponent;
};

// TODO: Put this in the Teams Store to get rid of duplicate key warnings
const socialCellSearch = platformName => (s, rowData) => {
  const socialObject = (rowData.social || []).find(s => s.platform === platformName);
  const platform = (socialObject || {}).platform;
  const username = platform === "twitter" ? `@${(socialObject || {}).username}` : (socialObject || {}).username;
  return (username || "").toLowerCase().includes(s);
};

const EllipsisCell = ({ field, rowData, dispatch }) => {
  const sendClickEvent = () => dispatch("Row Click", rowData);
  if (field === "members" && rowData?.[field]) rowData[field] = rowData[field]?.length || 0;
  return (
    <div className="ellipsis-cell" onClick={sendClickEvent}>
      {rowData[field]}
    </div>
  );
};

const TeamCell = ({ rowData, value, dispatch }) => {
  const { media = null } = rowData;
  const Icon = sportIcons[media];
  const mediaComponent = Icon ? (
    // <img className="profile-picture" src={media} alt="Profile for this team" />
    <div className="profile-picture">
      <Icon size={48} />
    </div>
  ) : (
    <div className="profile-picture-placeholder" />
  );
  const sendClickEvent = () => dispatch("Row Click", rowData);
  return (
    <div className="team-cell" onClick={sendClickEvent}>
      {mediaComponent}
      <div className="team-name">{value}</div>
    </div>
  );
};

const DeleteCell = ({ rowData }) => {
  const { teamId } = rowData;
  const deleteUser = () => TeamsStore.deleteTeam(teamId);
  return <FiTrash2 className="delete-cell" onClick={deleteUser} />;
};

const columns = [
  {
    cell: TeamCell,
    dataType: DataType.String,
    key: "name",
    title: "Name",
    style: { width: 228 }
  },
  {
    cell: EllipsisCell,
    dataType: DataType.String,
    key: "ownerName",
    title: "Owner"
  },
  {
    cell: SocialCell,
    dataType: DataType.String,
    key: "facebook",
    title: "Facebook",
    search: socialCellSearch("facebook")
  },
  {
    cell: SocialCell,
    dataType: DataType.String,
    key: "twitter",
    title: "Twitter",
    search: socialCellSearch("twitter")
  },
  {
    cell: SocialCell,
    dataType: DataType.String,
    key: "instagram",
    title: "Instagram",
    search: socialCellSearch("instagram")
  },
  {
    cell: EllipsisCell,
    dataType: DataType.Object,
    key: "members",
    title: "Team Members"
  },
  {
    key: "delete",
    cell: DeleteCell,
    style: { width: 40, textAlign: "center" }
  }
];

const childAttributes = {
  cell: {
    onClick: (_, e) => {
      const { dispatch, rowData } = e.childProps;
      dispatch("Row Click", rowData);
    }
  }
};

function TeamsTable() {
  const location = useLocation();
  const onTableEvent = async (type, data) => {
    const { teamId } = data;
    if (type === "Row Click") {
      navigate(`${location.pathname}/${teamId}`);
    }
  };

  return (
    <div className="teams-table-container">
      <Table
        childAttributes={childAttributes}
        columns={columns}
        data={toJS(TeamsStore.teams, { recurseEverything: true })}
        search={GlobalSearchStore.searchText}
        onEvent={onTableEvent}
        rowKeyField="teamId"
      />
    </div>
  );
}

export default observer(TeamsTable);
