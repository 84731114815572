import React, { useRef, useState } from "react";
import { FiCheck } from "react-icons/fi";
import { getElementAncestry } from "../../../utils";
import Button from "../Button";
import "./DropdownButton.scss";

const MenuOptions = ({ items, onChange }) => {
  return (items || []).map(item => {
    const onClick = e => {
      e.stopPropagation();
      onChange(item);
    };

    const icon = item.selected ? (
      <FiCheck size={24} className="selected-icon" />
    ) : null;

    return (
      <div className="menu-option" key={item.value} onClick={onClick}>
        {item.label}
        {icon}
      </div>
    );
  });
};

const DropdownButton = ({
  value, //required
  onChange, //required
  options, //required -- Needs to be an array of objects with name and value
  label,

  hidden = false,
  disabled = false,

  icon,
  theme,
  style
}) => {
  const wrapperRef = useRef(null);
  const [open, setOpen] = useState(false);

  const selectionMade = option => {
    onChange(option);
  };

  const handleClick = e => {
    const targetChain = getElementAncestry(e.target);
    if (!targetChain.includes(wrapperRef.current)) {
      setOpen(false);
      window.removeEventListener("click", handleClick);
    }
  };

  const toggleMenu = e => {
    setOpen(!open);
    if (!open) {
      window.addEventListener("click", handleClick);
    }
  };

  options = options.map(o => ({ ...o, selected: o?.value === value?.value }));

  return (
    <div className="dropdown-button" ref={wrapperRef}>
      <Button
        text={label}
        icon={icon}
        onClick={toggleMenu}
        hidden={hidden}
        disabled={disabled}
        theme={theme}
        style={style}
      />
      <div
        className="select-menu"
        style={{ display: open ? "inherit" : "none" }}
      >
        <MenuOptions items={options} onChange={selectionMade} />
      </div>
    </div>
  );
};

export default DropdownButton;
