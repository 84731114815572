import React from "react";
import { observer } from "mobx-react";
import ReactPlayer from "react-player";
import { isVideoLink } from "../../../utils";
import { useBreakpoints } from "../../../hooks/useBreakpoints";
import TenantStore from "../../../stores/TenantStore";
import "./CampaignMedia.scss";

function CampaignMedia({ campaign, showEmblem = true }) {
  const { isMobile } = useBreakpoints();
  const { media } = campaign;
  const isLocalVideo = media?.includes("data:video/mp4") || media?.includes("blob:");
  const imageStyles = { backgroundImage: `url(${media})` };

  const campaignMedia = isVideoLink(media) || isLocalVideo ? (
    <div className="image">
      {isLocalVideo ? (
        <video
          height="100%"
          controls
        >
          <source
            type="video/mp4"
            src={media}
          />
        </video>
      ) : (
        <ReactPlayer
          url={media}
          height="100%"
          width="100%"
          controls
        />
      )}
    </div>
  ) : (
    <div className="image" style={imageStyles} />
  );

  const emblem = showEmblem ? (
    <img
      className="emblem-fade"
      src={TenantStore.tenant?.theme?.emblemURL}
      alt="School emblem."
    />
  ) : null;

  if (isMobile) {
    return campaignMedia;
  } else {
    return (
      <>
        {emblem}
        <div className="media-col">
          <div className="backer" />
          {campaignMedia}
        </div>
      </>
    );
  }
}

export default observer(CampaignMedia);
