import { observable, computed, action, autorun } from "mobx";
import { request } from "../utils";
import uploadFile from "../services/FileUploadService";
import AuthStore from "./AuthStore";
import UsersStore from "./UsersStore";
import AppStateStore from "./AppStateStore";
import { toast } from "react-toastify";

export const TEAM_SORTS = {
  PROVIDER: "PROVIDER",
  NAME: "NAME",
  LAST_UPDATED: "LAST_UPDATED",
  CURRENT_VALUE: "CURRENT_VALUE",
  TYPE: "TYPE"
};

export const TEAM_SORT_DIRECTIONS = {
  ASC: "ASC",
  DESC: "DESC"
};

class TeamsStore {
  constructor() {
    autorun(() => {
      if (AuthStore.APIReady) {
        this.fetchTeams();
      } else {
        this.clear();
      }
    });
  }

  // TEAMS
  @observable rawTeams = [];

  @computed get teams() {
    return (
      (this.rawTeams || [])
        // .filter(c => c.first_name.includes(this.search))
        .map(c => ({
          ...c,
          ownerName: (UsersStore.users.find(u => u.userId === c.owner) || {}).fullName || null
        }))
    );
  }

  @action async fetchTeams() {
    try {
      const teams = await request.get("/v1/teams");
      this.rawTeams = teams;
      return teams;
      // this.rawCampaigns = testCampaigns;
    } catch (err) {
      console.warn(err);
    }
  }

  @observable currentTeamId;

  @computed get currentTeam() {
    if (this.currentTeamId) {
      const team = this.teams.find(t => t.teamId === this.currentTeamId);
      return team || {};
    }
    return {};
  }

  @computed get currentTeamIndex() {
    if (this.currentTeam) {
      const index = this.teams.findIndex(t => t.teamId === this.currentTeamId);
      if (index >= 0) return index;
    }
    return -1;
  }

  @action setSelectedTeam(teamId) {
    this.currentTeamId = teamId;
  }

  @action refreshCurrentTeam() {
    return;
  }

  // MODALS
  @observable showNewTeamModal = false;

  @action openNewTeamModal = () => (this.showNewTeamModal = true);

  @action closeNewTeamModal = () => (this.showNewTeamModal = false);

  @action async uploadFile(file) {
    try {
      const url = await uploadFile(file, "team");
      return url;
    } catch (err) {
      console.warn(err);
    }
  }

  @action async addTeam(teamObject, file) {
    AppStateStore.setLoading(true);
    let newTeamId;
    try {
      if (file) {
        let url = await this.uploadFile(file);
        url = url.split("?")[0];
        const teamObj = { ...teamObject, media: url };
        const newTeam = await request.post("/v1/teams", {
          body: teamObj
        });
        newTeamId = newTeam.teamId;
        this.addTeamInPlace(newTeam);
      } else {
        const newTeam = await request.post("/v1/teams", {
          body: teamObject
        });
        newTeamId = newTeam.teamId;
        this.addTeamInPlace(newTeam);
      }
      AppStateStore.setLoading(false);
      toast("Team added", { autoClose: 3000 });
      return newTeamId;
    } catch (err) {
      console.warn(err);
      AppStateStore.setLoading(false);
      toast("Error adding team.");
    }
  }

  checkSocialDifferences(baseArray, comparisonArray) {
    const comparisonArrayIds = comparisonArray.map(ca => ca.teamSocialId);
    return baseArray.filter(b => !comparisonArrayIds.includes(b.teamSocialId));
  }

  @action async editTeam(localTeam, originalTeam) {
    try {
      AppStateStore.setLoading(true);
      const { teamId, media, name, owner } = localTeam;
      const teamObj = { teamId, media, name, owner };
      const teamUpdatePromise = this.updateTeam(teamObj, null, false);

      const socialIdsToBeDeleted = this.checkSocialDifferences(originalTeam?.social, localTeam?.social).map(
        d => d.teamSocialId
      );
      const socialToBeDeletedPromises = socialIdsToBeDeleted.map(social => this.deleteSocial(social));

      const socialToBeAdded = this.checkSocialDifferences(localTeam?.social, originalTeam?.social);
      const socialToBeAddedPromises = socialToBeAdded.map(social => {
        const newSocialObj = { ...social, teamId };
        return this.addSocial(newSocialObj);
      });

      await Promise.all([teamUpdatePromise].concat(socialToBeDeletedPromises).concat(socialToBeAddedPromises));
      AppStateStore.setLoading(false);
    } catch (e) {
      toast("Error updating team.");
      AppStateStore.setLoading(false);
    }
  }

  @action async updateTeam(teamObject, file, showLoading = true) {
    if (showLoading) AppStateStore.setLoading(true);
    const teamId = teamObject?.teamId || this.currentTeam.teamId;
    try {
      if (file) {
        let url = await this.uploadFile(file);
        url = url.split("?")[0];
        const teamObj = { ...teamObject, media: url };
        const updatedTeam = await request.put(`/v1/teams/${teamId}`, {
          body: teamObj
        });
        this.updateTeamInPlace(updatedTeam);
      } else {
        const updatedTeam = await request.put(`/v1/teams/${teamId}`, {
          body: teamObject
        });
        this.updateTeamInPlace(updatedTeam);
      }
      if (showLoading) AppStateStore.setLoading(false);
      toast("Team updated!", { autoClose: 3000 });
    } catch (err) {
      console.warn(err);
      if (showLoading) AppStateStore.setLoading(false);
      toast("Error updating team.");
    }
  }

  @action async deleteTeam(teamId) {
    AppStateStore.setLoading(true);
    try {
      await request.delete(`/v1/teams/${teamId}`);
      this.deleteTeamInPlace(teamId);
      AppStateStore.setLoading(false);
      toast("Team deleted", { autoClose: 3000 });
    } catch (err) {
      console.warn(err);
      AppStateStore.setLoading(false);
      toast("Error adding team.");
    }
  }

  @action addTeamInPlace(newTeam) {
    this.rawTeams = this.rawTeams.concat(newTeam);
  }

  @action updateTeamInPlace(updatedTeam) {
    this.rawTeams = this.rawTeams.map(c => {
      if (c.teamId === updatedTeam.teamId) {
        return updatedTeam;
      }
      return c;
    });
  }

  @action deleteTeamInPlace(deletedTeamId) {
    this.rawTeams = this.rawTeams.filter(c => c.teamId !== deletedTeamId);
  }

  @action deleteSocialInPlace(deletedSocialId) {
    this.rawTeams = this.rawTeams.map(t => ({
      ...t,
      social: t.social.filter(s => s.teamSocialId !== deletedSocialId)
    }));
  }

  @action async addSocial(socialObject) {
    try {
      const updatedTeam = await request.post(`/v1/teams/${socialObject.teamId}/social`, {
        body: socialObject
      });
      this.updateTeamInPlace(updatedTeam);
      toast("Social account(s) added", { autoClose: 3000 });
    } catch (err) {
      console.warn(err);
      toast("Error adding social account.");
    }
  }

  @action async deleteSocial(teamId, socialId) {
    try {
      await request.post(`/v1/teams/${teamId}/social/${socialId}/delete`);
      this.deleteSocialInPlace(socialId);
      toast("Social account removed!", { autoClose: 3000 });
    } catch (err) {
      toast("Error removing social account.");
    }
  }

  @action async addMember(memberArray) {
    try {
      const { teamId } = memberArray?.[0] || {};
      if (teamId) {
        const updatedTeam = await request.post(`/v1/teams/${teamId}/members`, {
          body: { memberList: memberArray }
        });
        this.updateTeamInPlace(updatedTeam);
        toast("Team member(s) added", { autoClose: 3000 });
      }
    } catch (err) {
      console.warn(err);
      toast("Error adding team member(s).");
    }
  }

  // TODO: needs UI (and testing of course)
  @action async deleteMember(teamId, teamMemberId) {
    try {
      const updatedTeam = await request.post(`/v1/teams/${teamId}/members/${teamMemberId}/delete`);
      this.updateTeamInPlace(updatedTeam);
      toast("Team member deleted.", { autoClose: 3000 });
    } catch (err) {
      console.warn(err);
      toast("Error deleting team member.");
    }
  }

  // SEARCH TEAMS
  @observable search = "";

  @action setSearch = search => (this.search = search);

  // CLEANUP
  @action clear() {
    this.rawTeams = [];
  }
}

export default new TeamsStore();
