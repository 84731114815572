import React from 'react';
import Label from '../Label';
import useFileDrop from '../../../hooks/useFileDrop';
import uploadImage from '../../../assets/upload.png';
import './FileUpload.scss';
import { FiCamera } from 'react-icons/fi';

const FileUpload = ({
  theme = 'main',
  accept,
  onUpload, //required
  fileURL,
  prompt = false,
  icon: Icon,
  label,
  height = 240,
  circular = false,
  style: fileUploadStyles,
}) => {
  const mediaStyles = {
    height,
    backgroundSize: fileURL ? 'contain' : null,
    backgroundImage: fileURL ? `url(${fileURL})` : null,
    backgroundRepeat: fileURL ? 'no-repeat' : null,
    backgroundPosition: fileURL ? 'center' : null,
    cursor: 'pointer',
    border: fileURL ? 'none' : null,
    backgroundColor: fileURL ? 'transparent' : null,
    ...(theme === 'alt'
      ? {
          border: 'none',
          outline: 'none',
          borderRadius: 12,
          boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.08)',
          backgroundColor: 'var(--White)',
        }
      : {}),
    ...(circular ? { width: height, borderRadius: height / 2, backgroundSize: 'cover' } : {}),
  };

  const { DropBox, HiddenInput, onClick, drag } = useFileDrop(onUpload, accept);
  const labelComponent = label ? <Label value={label} /> : null;
  const iconComponent = Icon ? <Icon key="icon-key" /> : null;
  const uploadState = fileURL ? (
    prompt ? (
      <div className="overlay">
        <FiCamera size={36} />
      </div>
    ) : null
  ) : (
    [
      iconComponent,
      <img src={uploadImage} alt="Upload your file" className="upload-placeholder-image" key="image-key" />,
      <div className="text-container" key="text-container-key">
        <div className="upload-instructions">Drag and drop file or click here to upload.</div>
      </div>,
    ]
  );

  return (
    <div className="media-container" style={fileUploadStyles}>
      {labelComponent}
      <HiddenInput />
      <DropBox
        onClick={onClick}
        style={mediaStyles}
        className={`upload-box ${drag ? 'drag' : ''}`}
      >
        {uploadState}
        {(fileURL?.includes('data:video/mp4') || fileURL?.includes('blob:')) && (
          <video height={height}>
            <source
              type="video/mp4"
              src={fileURL}
            />
          </video>
        )}
      </DropBox>
    </div>
  );
};

export default FileUpload;
