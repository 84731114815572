import React from "react";
import { observer } from "mobx-react";
import { Link, useLocation, navigate } from "@reach/router";
import { MdSearch, MdSettings } from "react-icons/md";
import AuthStore from "../../stores/AuthStore";
import GlobalSearchStore from "../../stores/GlobalSearchStore";
import UsersStore from "../../stores/UsersStore";
import NickelLogo from "../-common/NickelLogo";
import ProfilePictureButton from "../-common/ProfilePictureButton";
import "./Header.scss";

const signOut = () => AuthStore.signOut();

const headerProfileButtonOptions = [
  {
    label: "Profile",
    action: () => navigate("/profile")
  },
  { label: "Sign Out", action: signOut }
];

function Header() {
  const location = useLocation();

  const { placeholder, searchText } = GlobalSearchStore;
  const setSearch = ({ target }) => {
    const { value: searchText } = target || {};
    GlobalSearchStore.setSearch(searchText || "");
  };

  const searchInput =
    placeholder && placeholder !== "Search..." ? (
      <div className="search">
        <MdSearch className="search-icon" size={18} />
        <input
          type="text"
          placeholder={placeholder || "Search..."}
          className="search-input"
          value={searchText}
          onChange={setSearch}
        />
      </div>
    ) : null;

  const fromState = { from: location.pathname };

  return (
    <div className="header">
      <NickelLogo height={32.5} color="#fff" />
      {searchInput}
      <div className="header-buttons">
        <div className="header-button">
          <Link to="/settings" state={fromState}>
            <MdSettings className="header-button-icon" size={24} />
          </Link>
        </div>
        <ProfilePictureButton src={UsersStore?.me?.profile?.picture} options={headerProfileButtonOptions} />
      </div>
    </div>
  );
}

export default observer(Header);
