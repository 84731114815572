import React from "react";

export default function runningShoes({
  size = 24,
  fill = "none",
  stroke = "#000",
  strokeWidth = 13
}) {
  return (
    <svg
      viewBox="0 0 298.468 298.468"
      height={size}
      width={size}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    >
      <path d="M224.04,175.255 c4.335,1.904,12.775,4.2,36.02,8.163c15.326,2.613,30.013,8.783,31.909,27.681v0c0,13.381-11.228,24.229-25.077,24.229h-68.89 c-25.712,0-50.367-9.889-68.519-27.484L35.64,116.883c-4.585-4.43-6.992-10.166-7.221-15.969 c-0.302-7.651,3.429-14.933,9.668-19.653l56.87-43.026c3.356-3.242,9.066-0.607,8.564,3.953l0,0 c-3.606,32.747,21.023,62.089,54.932,65.443l29.392,2.907c6.91,0.683,12.817,5.102,15.232,11.394l20.175,52.562L224.04,175.255z" />
      <line x1="200.986" y1="156.409" x2="229.951" y2="156.409" />
      <line x1="189.164" y1="132.993" x2="218.129" y2="132.993" />
      <path d="M40.796,79.309 l-26.87,23.747c-8.85,7.867-9.931,20.994-2.479,30.113l16.621,20.341c3.622,4.433,8.787,7.332,14.458,8.115l0,0 c8.987,1.24,15.415,9.034,14.661,17.774v0c-0.638,7.392,2.5,14.605,8.342,19.179l59.205,46.354 c11.699,9.868,26.43,15.749,41.926,16.739l72.851-0.647c20.2,1.29,39.714-9.722,46.402-28.184l4.998-14.563" />
      <path d="M253.201,184.393 L253.201,184.393c-0.885,14.538-13.346,25.89-28.42,25.89h-37.785l-31.364-27.315c-14.208-12.374-35.578-13.059-50.603-1.621l0,0" />
      <path d="M56.953,70.171l2.283,2.255 c18.065,17.841,17.024,46.651-2.283,63.232l0,0" />
      <path d="M193.302,109.768 c0,0-4.729-25.129,22.856-35.028" />
    </svg>
  );
}
