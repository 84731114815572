import React, { useState } from "react";
import { createPortal } from "react-dom";
import { observer } from "mobx-react";
import usePortal from "../../../../hooks/usePortal";
import MembershipsStore from "../../../../stores/MembershipsStore";
import PerksStore from "../../../../stores/PerksStore";
import FullScreenModal from "../../../-common/FullScreenModal";
import Button from "../../../-common/Button";
import Select from "../../../-common/Select2";
import "./NewPerkModal.scss";
import Label from "../../../-common/Label";
import Input from "../../../-common/Input";

const NewPerkModal = observer(({ close, membership }) => {
  const [usingExisting, setUsingExisting] = useState(false);
  const [perk, setPerk] = useState({});
  const [details, setDetails] = useState("");

  const selectExistingPerk = selection => {
    if (!selection) {
      setPerk({});
      setUsingExisting(false);
    } else {
      const { value } = selection || {};
      setPerk(value);
      setUsingExisting(true);
    }
  };

  const setPerkTitle = title => {
    setUsingExisting(false);
    setPerk({ ...perk, title });
  };
  const setPerkRequirements = requirements => {
    setUsingExisting(false);
    setPerk({ ...perk, requirements });
  };

  const save = async () => {
    await MembershipsStore.addPerkToMembership({
      perk,
      membershipId: membership.membershipId,
      details,
      existing: usingExisting
    });
    close();
  };

  const saveEnabled = membership?.membershipId && perk?.title;

  const perkOptions = PerksStore.perks.map(p => ({
    label: p.title,
    value: p
  }));

  return (
    <FullScreenModal isOpen={true} close={close}>
      <div className="new-perk-modal">
        <div className="modal-contents">
          <div className="headline">Add perk to level</div>
          <div className="inputs">
            <Select
              value={usingExisting ? { label: perk.title, value: perk } : null}
              options={perkOptions}
              isClearable={true}
              onChange={selectExistingPerk}
              label="Select from existing"
            />
          </div>
          <div className="or">OR</div>
          <div>
            <div className="new-label-container">
              <Label value="Create new" />
            </div>
            <div className="inputs">
              <Input
                value={!usingExisting ? perk?.title || "" : ""}
                label="Title"
                onChange={setPerkTitle}
                disabled={usingExisting}
              />
              <Input
                value={!usingExisting ? perk?.requirements || "" : ""}
                label="Requirements"
                onChange={setPerkRequirements}
                disabled={usingExisting}
              />
            </div>
          </div>
          <div className="details-container">
            <Input
              value={details}
              label="Details for perk at this level"
              onChange={setDetails}
            />
          </div>
        </div>
        <div className="bottom-buttons">
          <Button text="cancel" theme="light" onClick={close} />
          <Button disabled={!saveEnabled} text="save" onClick={save} />
        </div>
      </div>
    </FullScreenModal>
  );
});

const NewPerkModalContainer = ({ isOpen, ...props }) => {
  const portalTarget = usePortal("new-perk-modal-container");
  const contents = isOpen ? <NewPerkModal {...props} /> : null;
  return createPortal(contents, portalTarget);
};

export default NewPerkModalContainer;
