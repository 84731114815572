import React, {
  useState,
  useEffect,
} from 'react';
import {
  Formik,
  Form,
  Field,
} from 'formik';
import { observer } from 'mobx-react';
import { navigate } from '@reach/router';

import { parse } from 'query-string';
import FormikAuthInput from '@/common/AuthInput/FormikAuthInput';
import AuthStore from '@/stores/AuthStore';
import InstanceConfigStore from '@/stores/InstanceConfigStore';
import Spinner from '@/common/Spinner';
import AuthLayout from '../AuthLayout';
import { updatePasswordSchema } from '../schemas';
import AuthSubmitButton from '../AuthSubmitButton';
import './UpdatePassword.scss';

function cleanURLOfParams() {
  const { location } = window;
  const cleanUri = `${location.protocol}//${location.host}${location.pathname}`;

  window.history.replaceState({}, document.title, cleanUri);
}

const UpdatePassword = ({ location }) => {
  const [
    email,
    setEmail,
  ] = useState('');
  const [
    code,
    setCode,
  ] = useState('');

  const { tenant } = InstanceConfigStore || {};

  useEffect(() => {
    (async () => {
      try {
        const {
          email: emailParam,
          code: codeParam,
        } = parse(location?.search);

        cleanURLOfParams();

        const { success: userFound } = await AuthStore.checkUsername(emailParam);

        if (!userFound) {
          throw new Error('Email lookup failed to return password criteria.');
        }

        if (emailParam && codeParam) {
          setEmail(emailParam);
          setCode(codeParam);
        }
      } catch (err) {
        navigate('/auth/forgotpassword');
      }
    })();
  }, [location]);

  const updatePassword = async values => {
    if (email && code) {
      await AuthStore.resetPasswordAfterForgot(email, values.password, code);
    }
  };

  const handleKeyup = e => {
    if (e.key === 'Enter' || e.keycode === 13) updatePassword();
  };

  useEffect(() => {
    window.addEventListener('keyup', handleKeyup);

    return () => window.removeEventListener('keyup', handleKeyup);
  }, []);

  const buttonContents = AuthStore.loading ? <Spinner /> : 'UPDATE PASSWORD';

  return (
    <div className="forgot-password-container">
      <AuthLayout withMenuHeader>
        <div className="auth-square">
          <img
            className="tenant-image"
            src={tenant?.theme.logo}
            alt="Tenant Logo"
          />
          <h3 className="auth-title">
            Looking good - as long as your reset link hasn&apos;t expired, you&apos;ll be able to
            update your password here.
          </h3>
          <div className="auth-inputs">
            <Formik
              validationSchema={updatePasswordSchema}
              onSubmit={updatePassword}
              initialValues={{
                password: '',
                confirmPassword: '',
              }}
            >
              <Form>
                <Field
                  name="password"
                  placeholder="New password"
                  type="password"
                  component={FormikAuthInput}
                />
                <Field
                  name="confirmPassword"
                  placeholder="...and your new password again"
                  type="password"
                  component={FormikAuthInput}
                />
                <AuthSubmitButton
                  loading={AuthStore.loading}
                  btnStyles={{ backgroundColor: tenant?.theme.appPrimaryColor }}
                >
                  {buttonContents}
                </AuthSubmitButton>
              </Form>
            </Formik>
          </div>
        </div>
      </AuthLayout>
    </div>
  );
};

export default observer(UpdatePassword);
