import React from 'react';
import { Router, Redirect } from '@reach/router';
import Teams from '../../-common/Teams';
import AppTheme from '../AppTheme';
import AppFeatures from '../AppFeatures';
import AppAchievements from '../AppAchievements';
import RenewalFlow from '../RenewalFlow';
import TicketRenewalPopup from '../TicketRenewalPopup';
import TicketsInterstitial from '../TicketsInterstitial';
import ContactDetails from '../ContactDetails';
import './DonorApp.scss';

export default function DonorAppRouter() {
  return (
    <Router>
      <AppTheme path="theme" />
      <AppFeatures path="features" />
      <AppAchievements path="achievements/*" />
      <Teams path="teams/*" />
      <RenewalFlow path="renewal" />
      <TicketRenewalPopup path="ticket-renewal-popup" />
      <TicketsInterstitial path="ticketsponsor" />
      <ContactDetails path="contact-details" />
      <Redirect from="*" to="/club/app/theme" noThrow />
    </Router>
  );
}
