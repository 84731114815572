const nextEnabled = state => {
  const { activeSlide, transactionType, fund, frequency, cardNumber, cvv, zip, expDate, amount } = state;
  if (activeSlide === 0 && transactionType && fund) return true;
  if (activeSlide === 1 && cardNumber && cvv && zip && expDate && frequency && amount) return true;
  if (activeSlide === 2) return true;
  return false;
};

export const addPaymentInitialState = { activeSlide: 0, nextEnabled: false };

export const addPaymentReducer = (state, action) => {
  const { type, payload } = action;
  const withSimpleUpdates = { ...state, ...payload };
  let result = state;

  switch (type) {
    case "resetState":
      result = { ...state, ...addPaymentInitialState };
      break;
    default:
      result = { ...withSimpleUpdates, nextEnabled: nextEnabled(withSimpleUpdates) };
      break;
  }
  return result;
};
