import { observable, computed, action, autorun, toJS } from "mobx";
import { toast } from "react-toastify";
import { request } from "../utils";
import AuthStore from "./AuthStore";

class RewardOptionsStore {
  constructor() {
    autorun(() => {
      if (AuthStore.APIReady) {
        this.fetchRewardOptions();
      } else {
        this.clear();
      }
    });
  }

  // REWARD OPTIONS
  @observable rawRewardOptions = [];

  @computed get allRewardOptions() {
    return this.rawRewardOptions || [];
  }

  @computed get rewardOptions() {
    return this.allRewardOptions;
  }

  @action async fetchRewardOptions() {
    try {
      const rewardOptions = await request.get("/v1/rewardoptions");
      this.rawRewardOptions = rewardOptions.sort((a, b) => a.sortIndex - b.sortIndex);
      return rewardOptions;
    } catch (err) {
      console.warn(err);
    }
  }

  @action async addRewardOption(rewardOptionObject, showToasts) {
    try {
      this.rawRewardOptions = this.rawRewardOptions.concat({
        ...rewardOptionObject,
        rewardOptionId: "optimistic"
      });

      const newRewardOption = await request.post("/v1/rewardoptions", {
        body: rewardOptionObject
      });
      const { rewardOptionId } = newRewardOption;

      this.rawRewardOptions = this.rawRewardOptions.map(ro => {
        if (ro.rewardOptionId === rewardOptionId) return newRewardOption;
        if (ro.rewardOptionId === "optimistic") return newRewardOption;
        return ro;
      });
      if (showToasts) toast("Reward option added.");
      return newRewardOption;
    } catch (err) {
      this.rawRewardOptions = this.rawRewardOptions.filter(ro => ro.rewardOptionId !== "optimistic");
      if (showToasts) toast("Error adding reward option to level.");
      console.warn(err);
    }
  }

  @action async updateRewardOption(rewardOptionObject) {
    try {
      const { rewardOptionId } = rewardOptionObject;
      const updatedRewardOption = await request.put(`/v1/rewardoptions/${rewardOptionId}`, {
        body: rewardOptionObject
      });
      if (updatedRewardOption) {
        this.rawRewardOptions = this.rawRewardOptions.map(ro => {
          if (ro.rewardOptionId === rewardOptionId) return updatedRewardOption;
          return ro;
        });
        return updatedRewardOption;
      } else throw new Error("Error upating reward option.");
    } catch (err) {
      console.warn(err);
    }
  }

  @action async deleteRewardOption(rewardOptionId) {
    try {
      await request.delete(`/v1/rewardoptions/${rewardOptionId}`);
      this.rawRewardOptions = this.rawRewardOptions.filter(ro => ro.rewardOptionId !== rewardOptionId);
    } catch (err) {
      console.warn(err);
    }
  }

  @action async sortRewardOptions(sortUpdatedRewardOptions, showToasts) {
    const preRewardOptions = toJS(this.rawRewardOptions, {
      recurseEverything: true
    });
    try {
      const rewardOptionSorts = sortUpdatedRewardOptions.map(ro => ({
        rewardOptionId: ro.rewardOptionId,
        sortIndex: ro.sortIndex
      }));
      this.rawRewardOptions = this.rawRewardOptions.sort((a, b) => {
        const aSort =
          sortUpdatedRewardOptions.find(ro => ro.rewardOptionId === a.rewardOptionId)?.sortIndex != null
            ? sortUpdatedRewardOptions.find(ro => ro.rewardOptionId === a.rewardOptionId)?.sortIndex
            : Infinity;
        const bSort =
          sortUpdatedRewardOptions.find(ro => ro.rewardOptionId === b.rewardOptionId)?.sortIndex != null
            ? sortUpdatedRewardOptions.find(ro => ro.rewardOptionId === b.rewardOptionId)?.sortIndex
            : Infinity;
        return aSort - bSort;
      });
      const updatedRewardOptions = await request.post(`/v1/rewardoptions/sort`, {
        body: { rewardOptionSorts }
      });
      this.rawRewardOptions = updatedRewardOptions.sort((a, b) => a.sortIndex - b.sortIndex);
      if (showToasts) toast("Reward option order updated!", { autoClose: 3000 });
    } catch (err) {
      this.rawRewardOptions = preRewardOptions;
      if (showToasts) toast("Error updating reward option order.");
      console.warn(err);
    }
  }

  // SEARCH
  @observable search = "";

  @action setSearch = search => (this.search = search);

  // CLEANUP
  @action clear() {
    this.rawRewards = [];
  }
}

export default new RewardOptionsStore();
