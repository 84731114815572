import React, { useState } from 'react';
import {
  Router,
  Link,
  Redirect,
  navigate,
} from '@reach/router';
import { observer } from 'mobx-react';
import {
  Formik,
  Form,
  Field,
} from 'formik';

import FormikAuthInput from '@/common/AuthInput/FormikAuthInput';
import Spinner from '@/common/Spinner';
import InstanceConfigStore from '@/stores/InstanceConfigStore';
import AuthStore from '@/stores/AuthStore';
import { onboardingResponseStages } from '@/constants/onboardingStages';
import {
  registerFormSchema,
  emailValidationFormSchema,
} from './schemas';
import Welcome from './Welcome';
import AuthLayout from './AuthLayout';
import NewPassword from './NewPassword';
import ForgotPassword from './ForgotPassword';
import UpdatePassword from './UpdatePassword';
import AuthSubmitButton from './AuthSubmitButton';

import './Auth.scss';

function Auth() {
  const [
    stage,
    setStage,
  ] = useState(onboardingResponseStages.START);
  const [
    title,
    setTitle,
  ] = useState("Let's get started!");
  const [
    subtitle,
    setSubtitle,
  ] = useState(
    'Please enter the email address associated with your account.'
  );
  const [
    email,
    setEmail,
  ] = useState('');

  const attemptAuth = async values => {
    await AuthStore.signIn(values.password, email);
  };

  const validateEmail = async values => {
    const { stage: responseStage } = await AuthStore.sendEmailVerificationRequest(values.email);

    setStage(responseStage);

    if (responseStage === onboardingResponseStages.ALREADY_ONBOARDED) {
      setTitle('Welcome Back!');
      setSubtitle('So good to see you again! Please enter your password.');
      setEmail(values.email);
    } else if (responseStage === onboardingResponseStages.NON_ONBOARDED) {
      setTitle('Confirm Your Account');
      setSubtitle('A confirmation code has been sent to the email provided.');
      setEmail(values.email);
    } else if (responseStage === onboardingResponseStages.CHANGE_PASSWORD) {
      navigate(`/auth/forgotpassword?email=${values.email}`);
    } else {
      setStage(onboardingResponseStages.NOT_ALLOWED);
      setTitle('Oops. Try Again.');
      setSubtitle("Sorry, we don't recognize the email address you entered.");
    }
  };

  const register = async values => {
    const requestBody = {
      email,
      password: values.password,
      confirmPassword: values.confirmPassword,
      code: values.code,
    };

    try {
      await AuthStore.sendRegisterRequest(requestBody);
      await AuthStore.signIn(values.password, email);
    } catch (err) {
      console.log(err);
    }
  };

  const {
    disabled,
    tenant,
  } = InstanceConfigStore || {};
  const buttonContents = AuthStore.loading ? <Spinner /> : 'SUBMIT';

  const emailValidationForm = (
    <Formik
      validationSchema={emailValidationFormSchema}
      onSubmit={validateEmail}
      initialValues={{ email: '' }}
    >
      <Form>
        <Field
          name="email"
          placeholder="Email Address"
          type="text"
          component={FormikAuthInput}
        />
        <AuthSubmitButton
          loading={AuthStore.loading}
          btnStyles={{ backgroundColor: tenant?.theme.appPrimaryColor }}
        >
          {buttonContents}
        </AuthSubmitButton>
      </Form>
    </Formik>
  );

  const loginForm = (
    <Formik
      onSubmit={attemptAuth}
      initialValues={{ password: '' }}
    >
      <Form>
        <Field
          name="password"
          placeholder="Password"
          type="password"
          component={FormikAuthInput}
        />
        <AuthSubmitButton
          loading={AuthStore.loading}
          btnStyles={{ backgroundColor: tenant?.theme.appPrimaryColor }}
        >
          {buttonContents}
        </AuthSubmitButton>
      </Form>
    </Formik>
  );

  const registerForm = (
    <Formik
      onSubmit={register}
      initialValues={{
        password: '',
        confirmPassword: '',
        confirmationCode: '',
      }}
      validationSchema={registerFormSchema}
    >
      <Form>
        <Field
          name="password"
          placeholder="Password"
          type="password"
          component={FormikAuthInput}
        />
        <Field
          name="confirmPassword"
          placeholder="Confirm your password"
          type="password"
          component={FormikAuthInput}
        />
        <Field
          name="code"
          placeholder="Confirmation code"
          type="text"
          component={FormikAuthInput}
          maxLength={6}
        />
        <AuthSubmitButton
          loading={AuthStore.loading}
          btnStyles={{ backgroundColor: tenant?.theme.appPrimaryColor }}
        >
          {buttonContents}
        </AuthSubmitButton>
      </Form>
    </Formik>
  );

  return (
    <AuthLayout withMenuHeader>
      <div className="auth-square">
        <img
          className="tenant-image"
          src={tenant?.theme.logo}
          alt="Tenant Logo"
        />
        {disabled ?
          (
            <>
              <h3 className="auth-title">Your instance has been disabled.</h3>
              <h3 className="auth-title">Contact your account representative to enable it again.</h3>
            </>
          ) :
          (
            <>
              <h3 className="auth-title">{title}</h3>
              <p className="auth-subtitle">{subtitle}</p>
              {(stage === onboardingResponseStages.START ||
              stage === onboardingResponseStages.NOT_ALLOWED) && (
                <div className="auth-inputs">{emailValidationForm}</div>
              )}
              {stage === onboardingResponseStages.ALREADY_ONBOARDED && (
                <>
                  <div className="auth-inputs">{loginForm}</div>
                  <Link
                    to="forgotpassword"
                    state={{ email }}
                    className="forgot-password"
                  >
                    Forgot your password?
                  </Link>
                </>
              )}
              {stage === onboardingResponseStages.NON_ONBOARDED && (
                <div className="auth-inputs">{registerForm}</div>
              )}
              {stage === onboardingResponseStages.NOT_ALLOWED &&
              tenant?.appConfig?.contactDetails && (
                <>
                  <p className="contact-title">Need some help?</p>
                  <div className="contact-box">
                    <span className="contact-label">Phone</span>
                    <span>{tenant.appConfig.contactDetails.phoneNumber}</span>
                  </div>
                  <div className="contact-box">
                    <span className="contact-label">Email</span>
                    <span>{tenant.appConfig.contactDetails.emailAddress}</span>
                  </div>
                </>
              )}
            </>
          )}
      </div>
    </AuthLayout>
  );
}

const ObserverWrappedAuth = observer(Auth);

export default function AuthRouter() {
  return (
    <Router>
      <ObserverWrappedAuth path="/" />
      <Welcome path="/welcome" />
      <NewPassword path="/newpassword" />
      <ForgotPassword path="/forgotpassword" />
      <UpdatePassword path="/updatepassword" />
      <Redirect
        from="*"
        to="/auth"
        noThrow
      />
    </Router>
  );
}
