import { observable, computed, action, autorun } from "mobx";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { request, downloadURLContents, formatCardBrand } from "../utils";
import AppStateStore from "./AppStateStore";
import AuthStore from "./AuthStore";
import CampaignsStore from "./CampaignsStore";
import RewardsStore from "./RewardsStore";

export const filterOptions = [
  { label: "All", value: "All" },
  { label: "Membership Renewals", value: "Membership Renewals" },
  { label: "Campaign Donations", value: "Campaign Donations" },
  { label: "Refunds", value: "Refunds" }
];

const filterTransactions = filter => p => {
  if (filter.value === "Membership Renewals") {
    return p?.type === "renewal";
  } else if (filter.value === "Campaign Donations") {
    return p?.type === "campaign donation";
  } else if (filter.value === "Refunds") {
    return !!p?.nickelMetadata?.refundId;
  } else {
    return true;
  }
};

export const sortOptions = [
  { label: "Newest", value: "Newest" },
  { label: "Oldest", value: "Oldest" },
  { label: "Amount High to Low", value: "Amount High to Low" },
  { label: "Amount Low to High", value: "Amount Low to High" },
  { label: "Name A to Z", value: "Name A to Z" },
  { label: "Name Z to A", value: "Name Z to A" }
];

const sortTransactions = sort => (t1, t2) => {
  const name1 = t1.userMetadata?.name?.toLowerCase().trim();
  const name2 = t2.userMetadata?.name?.toLowerCase().trim();
  let amountSort1 = t1.amountMetadata?.totalDonationAmount;
  let amountSort2 = t2.amountMetadata?.totalDonationAmount;
  if (sort.value === "Newest") {
    return t2.date.valueOf() - t1.date.valueOf();
  } else if (sort.value === "Oldest") {
    return t1.date.valueOf() - t2.date.valueOf();
  } else if (sort.value === "Amount High to Low") {
    if (t1?.nickelMetadata?.refundId) amountSort1 = 0;
    if (t2?.nickelMetadata?.refundId) amountSort2 = 0;
    return amountSort1 > amountSort2 ? -1 : amountSort1 < amountSort2 ? 1 : 0;
  } else if (sort.value === "Amount Low to High") {
    if (t1?.nickelMetadata?.refundId) amountSort1 = 99999999999;
    if (t2?.nickelMetadata?.refundId) amountSort2 = 99999999999;
    return amountSort1 < amountSort2 ? -1 : amountSort1 > amountSort2 ? 1 : 0;
  } else if (sort.value === "Name A to Z") {
    if (name1 === "(anonymous)" && name2 === "(anonymous)") return 0;
    else if (name1 === "(anonymous)") return 1;
    else if (name2 === "(anonymous)") return -1;
    else if (name1 < name2) return -1;
    else if (name1 > name2) return 1;
    else return 0;
  } else if (sort.value === "Name Z to A") {
    if (name1 === "(anonymous)" && name2 === "(anonymous)") return 0;
    else if (name1 === "(anonymous)") return 1;
    else if (name2 === "(anonymous)") return -1;
    else if (name2 < name1) return -1;
    else if (name2 > name1) return 1;
    else return 0;
  }
};

const searchTransactions = search => t => {
  // Date Stamp,
  // Name,
  // Last four Digits of CC used
  // with card type (e.g. Visa, AMEX)
  // or check number,
  // transaction amount,
  // the purpose of the transaction (Membership renewal, Campaign Name etc).
  // Email
  // and/or address collected
  const searchText = search.toLowerCase();
  const matchesDate =
    format(t.date, "M/d/yyyy").includes(searchText) || format(t.date, "MMM do, yyyy").includes(searchText);

  const matchesName = t?.userMetadata?.name?.toLowerCase().includes(searchText);

  const matchesCard =
    t?.processorMetadata?.card?.brand?.toLowerCase().includes(searchText) ||
    t?.processorMetadata?.card?.last4?.toLowerCase().includes(searchText);

  const matchesAmount = String(t?.amountMetadata?.totalDonationAmount)?.toLowerCase().includes(searchText);

  const matchesPurpose =
    t.type === "renewal"
      ? t?.type?.toLowerCase().includes(searchText)
      : t?.nickelMetadata?.campaign?.title?.toLowerCase().includes(searchText);

  const matchesEmail = t?.userMetadata?.email?.toLowerCase().includes(searchText);

  return matchesDate || matchesName || matchesCard || matchesAmount || matchesPurpose || matchesEmail;
};

class TransactionsStore {
  constructor() {
    autorun(() => {
      if (AuthStore.APIReady) {
        this.fetchTransactions();
      } else {
        this.clear();
      }
    });
  }

  @observable rawTransactions = [];

  @computed get transactions() {
    const mappedTransactions = this.rawTransactions.map(tx => {
      const date = new Date(tx.date);
      const amountMetadata = {
        ...(tx?.amountMetadata || {}),
        totalDonationAmount: tx?.amountMetadata?.totalDonationAmount,
        nickelCommissionAmount: tx?.amountMetadata?.nickelCommissionAmount,
        paymentProcessingAmount: tx?.amountMetadata?.paymentProcessingAmount
      };

      const brand = formatCardBrand(tx?.processorMetadata?.card?.brand);
      const processorMetadata = {
        ...(tx?.processorMetadata || {}),
        card: { ...(tx?.processorMetadata?.card || {}), brand }
      };

      const campaign = CampaignsStore.campaigns.find(c => c.campaignId === tx?.nickelMetadata?.campaignId);
      const tier = campaign?.tiers?.find(t => t.id === tx.tierId);
      const reward = tier && tier.rewardId ? RewardsStore.allRewards.find(r => r.rewardId === tier.rewardId) : null;
      return {
        ...tx,
        date,
        amountMetadata,
        processorMetadata,
        campaign,
        tier,
        reward,
        key: tx.transactionId
      };
    });

    const refundTransactions = mappedTransactions
      ?.map(t => t?.refund && { ...t?.refund, transaction: t, userMetadata: t?.userMetadata })
      ?.filter(Boolean)
      ?.map(r => ({
        ...r,
        date: new Date(r?.date),
        amountMetadata: { totalDonationAmount: -1 * r.amount },
        processorMetadata: { card: {} },
        key: r?.refundId
      }));
    const allTransactions = mappedTransactions.concat(refundTransactions);

    return allTransactions
      .filter(filterTransactions(this.filter))
      .filter(searchTransactions(this.search))
      .sort(sortTransactions(this.sort));
  }

  @computed get totalRevenue() {
    return this.transactions.reduce((acc, next) => acc + next?.amountMetadata?.totalDonationAmount * 1, 0);
  }

  @computed get totalTransactions() {
    return this.transactions.filter(t => !t?.nickelMetadata?.refundId).length;
  }

  @computed get totalRefunds() {
    return this.transactions.filter(t => !!t?.nickelMetadata?.refundId).length;
  }

  @computed get membershipRenewals() {
    return this.transactions.filter(t => t.type === "renewal" && !t?.nickelMetadata?.refundId).length;
  }

  @computed get campaignDonations() {
    return this.transactions.filter(t => t.type === "campaign donation" && !t?.nickelMetadata?.refundId).length;
  }

  @action async fetchTransactions() {
    try {
      const transactions = await request.get("/v1/transactions?offset=0");
      this.rawTransactions = transactions || [];
      return transactions;
    } catch (err) {
      console.warn(err);
    }
  }

  @action updateTransactionInPlace(updatedTransaction) {
    this.rawTransactions = this.rawTransactions.map(t => {
      if (t.transactionId === updatedTransaction.transactionId) {
        return updatedTransaction;
      }
      return t;
    });
  }

  @action addTransactionInPlace(newTransaction) {
    this.rawTransactions = this.rawTransactions.concat(newTransaction);
  }

  @action async refundTransaction(transactionId) {
    AppStateStore.setLoading(true);
    try {
      const updatedTransaction = await request.post(`/v1/transactions/${transactionId}/refund`);
      this.updateTransactionInPlace(updatedTransaction);
      AppStateStore.setLoading(false);
      toast("Transaction refunded successfully.", { autoClose: 3000 });
      return updatedTransaction;
    } catch (err) {
      console.warn(err);
      toast("Error refunding transaction.");
      AppStateStore.setLoading(false);
    }
  }

  @action async sendReceiptForTransaction(transactionId) {
    AppStateStore.setLoading(true);
    try {
      await request.post(`/v1/transactions/${transactionId}/sendreceipt`);
      AppStateStore.setLoading(false);
      toast("Receipt sent successfully.", { autoClose: 3000 });
    } catch (err) {
      console.warn(err);
      toast("Error sending receipt.");
      AppStateStore.setLoading(false);
    }
  }

  @action async getTransactionsCSV() {
    AppStateStore.setLoading(true);
    try {
      const { url } = await request.get("/v1/transactions?type=csv");
      downloadURLContents(url);
    } catch (err) {
      toast("Error exporting transactions.");
    }
    AppStateStore.setLoading(false);
  }

  //INDIVIDUAL MEMBERS TRANSACTIONS

  @observable memberTransactions = {};
  @observable memberTransactionRequests = {};

  @action async fetchTransactionsForMember(userId) {
    try {
      let memberTransactions = [];
      if (this.memberTransactionRequests[userId]) {
        memberTransactions = await this.memberTransactionRequests[userId];
        this.memberTransactionRequests = { ...this.memberTransactionRequests, [userId]: null };
      } else {
        const transactionsRequest = request.get(`/v1/transactions?userId=${userId}`);
        this.memberTransactionRequests = { ...this.memberTransactionRequests, [userId]: transactionsRequest };
        memberTransactions = await transactionsRequest;
        this.memberTransactions[userId] = memberTransactions.sort((a, b) =>
          a.date < b.date ? 1 : a.date > b.date ? -1 : 0
        );
        this.memberTransactionRequests = { ...this.memberTransactionRequests, [userId]: null };
      }
      return memberTransactions;
    } catch (err) {
      console.warn(err);
    }
  }

  // SEARCH
  @observable search = "";

  @action setSearch = search => (this.search = search);

  // FILTER AND SORT
  @observable filter = filterOptions[0];

  @action setFilter(filter) {
    this.filter = filter;
  }

  @observable sort = sortOptions[0];

  @action setSort(sort) {
    this.sort = sort;
  }

  // CLEANUP
  @action clear() {
    this.rawTransactions = [];
  }
}

export default new TransactionsStore();
