import React from "react";
import { observer } from "mobx-react";
import { Router } from "@reach/router";
import { FaFilter, FaSortAmountDown } from "react-icons/fa";
import { FiExternalLink } from "react-icons/fi";
import PayoutsStore, {
  filterOptions,
  sortOptions
} from "../../../stores/PayoutsStore";
import Page from "../../-common/Page";
import DropdownButton from "../../-common/DropdownButton";
import Button from "../../-common/Button";
import Payout from "../Payout";
import PayoutsTable from "./PayoutsTable";
import "./Payouts.scss";

function PayoutsInner() {
  const titleOptions = { title: "Payouts" };
  const { payouts, filter, sort } = PayoutsStore;
  const updateFilter = filter => PayoutsStore.setFilter(filter);
  const updateSort = sort => PayoutsStore.setSort(sort);

  return (
    <Page
      titleOpts={titleOptions}
      protectedContent={true}
      buttons={[
        <DropdownButton
          label="Filter"
          icon={FaFilter}
          theme="light"
          options={filterOptions}
          style={{ height: 42 }}
          value={filter}
          onChange={updateFilter}
          key="filter"
        />,
        <DropdownButton
          label="Sort"
          icon={FaSortAmountDown}
          theme="light"
          options={sortOptions}
          style={{ height: 42 }}
          value={sort}
          onChange={updateSort}
          key="sort"
        />,
        <Button
          text="Export"
          icon={FiExternalLink}
          onClick={() => PayoutsStore.getPayoutsCSV()}
          key="export"
        />
      ]}
    >
      <div className="audits-payouts">
        <PayoutsTable data={payouts} />
      </div>
    </Page>
  );
}

const Payouts = observer(PayoutsInner);

function PayoutsRouter() {
  return (
    <Router>
      <Payouts path="/" />
      <Payout path=":payoutId" />
    </Router>
  );
}

export default PayoutsRouter;
