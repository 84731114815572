import React, { useEffect, useState, useCallback } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import { MdCheck, MdClose } from "react-icons/md";
import { IoMdEye } from "react-icons/io";
import { GoPlus } from "react-icons/go";
import CampaignsStore from "../../../stores/CampaignsStore";
import TenantStore from "../../../stores/TenantStore";
import Page from "../../-common/Page";
import Button from "../../-common/Button";
import EditTierCard from "./EditTierCard";
import "./Tiers.scss";

const compareTierValues = originalTiers => tier => {
  const tierContext = originalTiers?.filter(t => t.tierId === tier.tierId);
  const {
    title,
    description,
    donationAmount,
    rewardId,
    quantity,
    media,
  } = tierContext[0];

  if (tier.title !== title) return true;
  if (tier.description !== description) return true;
  if (tier.donationAmount !== donationAmount) return true;
  if (tier.rewardId !== rewardId) return true;
  if (tier.quantity !== quantity) return true;
  if (tier.media !== media) return true;
  return false;
};

const checkIfTierHasMoved = (originalTiers, tiers) => {
  const originalTierOrder = Object.fromEntries(originalTiers.map((t, i) => [t.tierId, i]));
  return tiers.some(({ tierId }, i) => originalTierOrder[tierId] !== i);
};

function ZeroState({ addTier }) {
  return (
    <div className="zero-state">
      <div className="zero-state-text">
        Looks like there aren't any tiers for this campaign yet - add some to get started!
      </div>
      <Button text="Add Tier" icon={GoPlus} onClick={addTier} />
    </div>
  );
}

const SortableTierCard = SortableElement(
  ({ localSortIndex, campaignId, updateTier, tier, sorting, duringDragSortOrder }) => {
    return (
      <EditTierCard
        localSortIndex={localSortIndex}
        campaignId={campaignId}
        update={updateTier}
        tier={tier}
        sorting={sorting}
        duringDragSortOrder={duringDragSortOrder}
      />
    );
  }
);

const TierCardsList = SortableContainer(({ items, campaignId, updateTier, sorting, duringDragSortOrder }) => {
  return (
    <div className="edit-tier-cards">
      {items.map((tier, index) => (
        <SortableTierCard
          index={index}
          localSortIndex={index}
          campaignId={campaignId}
          updateTier={updateTier}
          tier={tier}
          sorting={sorting}
          duringDragSortOrder={duringDragSortOrder}
          key={tier.tierId}
        />
      ))}
    </div>
  );
});

const Tiers = ({ campaignId }) => {
  CampaignsStore.setSelectedCampaign(campaignId);
  const { tiers: currentTiersFromCampaign } = CampaignsStore.currentCampaign;
  const [originalTiers, setOriginalTiers] = useState([]);
  const [tiers, setTiers] = useState([]);

  const [sorting, setSorting] = useState(false);
  const [duringDragSortOrder, setDuringDragSortOrder] = useState([]);

  const [saving, setSaving] = useState(false);

  useEffect(() => {
    let currentTiers = currentTiersFromCampaign;
    if (currentTiers) {
      currentTiers = toJS(currentTiers, { recurseEverything: true }).sort((a, b) => a.sortIndex - b.sortIndex);
      setTiers(currentTiers);
      setOriginalTiers(currentTiers);
      setDuringDragSortOrder(currentTiers.map(({ tierId }) => tierId));
    }
  }, [currentTiersFromCampaign, setTiers, setDuringDragSortOrder]);

  const updateTier = useCallback(
    updatedTier => {
      setTiers(
        tiers.map(t => {
          if (t.tierId === updatedTier.tierId) return updatedTier;
          return t;
        })
      );
    },
    [tiers, setTiers]
  );

  const sortTiers = ({ oldIndex, newIndex }) => {
    const movedTiers = arrayMove(tiers, oldIndex, newIndex);
    const newTiers = movedTiers.map((t, i) => ({ ...t, sortIndex: i }));
    setTiers(newTiers);
  };

  const checkSaveEnabled = ({ tiers }) => {
    if (tiers?.length) {
      return Math.max(...(tiers?.map(compareTierValues(originalTiers)) || []));
    }
    return false;
  };

  const save = useCallback(async () => {
    setSaving(true);
    const tiersToSave = tiers.filter(compareTierValues(originalTiers));
    const tierSavePromises = tiersToSave.map(tier => {
      tier.campaignId = campaignId;
      tier.rewardId = tier?.reward?.rewardId;
      tier.quantity = !!tier.quantity ? tier.quantity : 0;

      const {
        reward,
        purchasedQuantity,
        media,
        ...tierObject
      } = tier;

      return CampaignsStore.updateTier(tierObject);
    });
    await Promise.all(tierSavePromises);

    const tierHasMoved = checkIfTierHasMoved(originalTiers, tiers);
    if (tierHasMoved) await CampaignsStore.sortTiers(campaignId, tiers);
    setSaving(false);
  }, [setSaving, tiers, campaignId, originalTiers]);

  const cancel = useCallback(() => {
    setTiers(originalTiers);
  }, [originalTiers, setTiers]);

  const { hostingURL } = TenantStore?.tenant?.config;
  const campaignLink = `${hostingURL}?campaignId=${campaignId}`;

  const tierHasMoved = checkIfTierHasMoved(originalTiers, tiers);
  const saveEnabled = tierHasMoved || checkSaveEnabled({ tiers });

  const addTier = () => CampaignsStore.openNewTierModal();
  const preview = () => window.open(`${campaignLink}&preview=true`, "_blank");

  const conditionalButtons = saveEnabled
    ? [
        <Button text="Cancel" theme="light" icon={MdClose} onClick={cancel} disabled={saving} key="cancel" />,
        <Button
          text={saving ? "Saving..." : "Save"}
          theme={saving ? "light" : null}
          icon={MdCheck}
          onClick={save}
          disabled={saving}
          key="save"
        />
      ]
    : [
        <Button text="Add Tier" onClick={addTier} icon={GoPlus} key="add" />,
        <Button text="Preview" onClick={preview} icon={IoMdEye} key="preview" />
      ];

  const updateBeforeSortStart = () => setSorting(true);
  const onSortOver = ({ oldIndex, newIndex }) => {
    setDuringDragSortOrder(arrayMove(duringDragSortOrder, oldIndex, newIndex));
  };
  const onSortEnd = (...args) => {
    setSorting(false);
    sortTiers(...args);
  };

  const contents = (tiers || []).length ? (
    <TierCardsList
      axis="xy"
      pressDelay={200}
      helperClass="tier-drag-ghost sorting"
      updateBeforeSortStart={updateBeforeSortStart}
      onSortOver={onSortOver}
      onSortEnd={onSortEnd}
      items={tiers}
      campaignId={campaignId}
      updateTier={updateTier}
      sorting={sorting}
      duringDragSortOrder={duringDragSortOrder}
    />
  ) : (
    <ZeroState addTier={addTier} />
  );

  return (
    <Page titleOpts={{ title: "Tiers" }} buttons={conditionalButtons}>
      <div className="campaign-tiers">{contents}</div>
    </Page>
  );
};

export default observer(Tiers);
