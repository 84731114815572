import React, { useCallback, useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import Label from "../Label";
import CharacterCounter from "../CharacterCounter";
import "./Input.scss";

const Input = ({
  theme = "normal",
  value, //required
  type, //required
  onChange, //required
  onBlur = () => null,
  countCharacters = false,
  clearable = false,
  onClear,
  formatter,
  validator,
  icon: Icon,
  label,
  labelStyles,
  inputStyles,
  inputContainerStyles,
  iconStyles,
  maxLength = 80,
  onClick,
  placeholder,
  disabled = false
}) => {
  const [displayValue, setDisplayValue] = useState();
  const valueLength = (value || "").length || 0;
  const clearStyles = Icon ? { right: 40 } : null;

  useEffect(() => {
    if (!!formatter) {
      setDisplayValue(formatter(value));
    } else {
      setDisplayValue(value);
    }
  }, [value, formatter]);

  const getNewValue = useCallback(
    ({ target: { value: newValue } = {} } = {}) => {
      let passedLengthTest = true;
      let passedValidationTest = true;

      if (countCharacters || maxLength) {
        if (newValue.length <= maxLength) {
          passedLengthTest = true;
        } else {
          passedLengthTest = false;
        }
      }

      if (validator) {
        if (!!validator(newValue)) {
          passedValidationTest = true;
        } else {
          passedValidationTest = false;
        }
      }

      if (typeof onChange === "function") {
        if (passedLengthTest && passedValidationTest) {
          onChange(newValue);
        } else {
          onChange(value);
        }
      }
    },
    [onChange, validator, value, maxLength, countCharacters]
  );

  const clear = useCallback(() => {
    onChange("");
    onClear();
  }, [onClear, onChange]);

  const labelComponent = label ? <Label style={labelStyles} value={label} /> : null;
  const iconComponent = Icon ? (
    <div className="icon-container" style={iconStyles}>
      <Icon />
    </div>
  ) : null;

  const clearComponent = clearable ? (
    <div className="clearable-container" style={clearStyles} onClick={clear}>
      <FiX />
    </div>
  ) : null;
  const characterCounterComponent = countCharacters ? (
    <div className="character-counter-container">
      <CharacterCounter valueLength={valueLength} maxLength={maxLength} />
    </div>
  ) : null;

  const combinedInputStyles = {
    ...inputStyles,
    ...(theme === "alt"
      ? {
          border: "none",
          outline: "none",
          borderRadius: 12,
          boxShadow: "0 2px 16px 0 rgba(0, 0, 0, 0.08)",
          backgroundColor: "var(--White)"
        }
      : {})
  };

  return (
    <div className={`input-component-container${disabled ? " disabled" : ""}`} onClick={onClick}>
      {labelComponent}
      <div className="input-container" style={inputContainerStyles}>
        <input
          type={type}
          placeholder={placeholder}
          value={displayValue || ""}
          onChange={getNewValue || (() => null)}
          onBlur={onBlur}
          style={combinedInputStyles}
        />
        {clearComponent}
        {iconComponent}
      </div>
      {characterCounterComponent}
    </div>
  );
};

export default Input;
