import React from "react";
import { observer } from "mobx-react";
import { FaBolt, FaBullhorn, FaUsers, FaStar } from "react-icons/fa";
import commaNumber from "comma-number";
import { abbreviateNumber } from "../../../../utils";
import MembershipTrend from "./MembershipTrend";
import ContributionsTable from "./ContributionsTable";
import "./Contributions.scss";

const SummaryNumber = ({ icon: Icon, value, title, indicator }) => {
  const indicatorComponent = indicator ? (
    <div className="indicator">{indicator}</div>
  ) : (
    <div className="indicator empty">&nbsp;</div>
  );
  return (
    <div className="summary-number">
      <div className="icon-wrapper">
        <Icon className="summary-icon" size={24} />
      </div>
      <div className="value">{value}</div>
      <div className="title">{title}</div>
      {indicatorComponent}
    </div>
  );
};

const SummaryNumbers = observer(({ clubMember }) => {
  const {
    YTDContributions,
    lifetimeContributions,
    // currentMembershipLevel,
    // nextMembershipLevel,
    // nextLevelAnnualContribution,
    totalYearsOfMembership,
    currentRank,
    totalRank,
    totalPriorityPoints
  } = clubMember;

  // const fromNextLevel = nextLevelAnnualContribution
  //   ? `$${nextLevelAnnualContribution - YTDContributions} from ${nextMembershipLevel}`
  //   : null;

  const rankStatement = `All time rank: ${commaNumber(currentRank)} of ${commaNumber(totalRank)}`;

  const summaryNumbers = [
    {
      icon: FaStar,
      title: `Year${totalYearsOfMembership > 1 ? "s" : ""} of Membership`,
      value: isNaN(totalYearsOfMembership) ? "-" : totalYearsOfMembership
    },
    {
      icon: FaBullhorn,
      title: "YTD Contributions",
      value: isNaN(YTDContributions) ? "-" : "$" + commaNumber(abbreviateNumber(YTDContributions / 100))
      // indicator: "$100 Remaining on Pledge"
    },
    {
      icon: FaUsers,
      title: "Lifetime Contributions",
      value: isNaN(lifetimeContributions) ? "-" : "$" + commaNumber(abbreviateNumber(lifetimeContributions / 100)),
      indicator: isNaN(lifetimeContributions) || !currentRank || !totalRank ? null : rankStatement
    },
    {
      icon: FaBolt,
      title: "Priority Points",
      value: totalPriorityPoints || "-"
      // indicator: fromNextLevel
    }
  ];
  const summaryNumberComponents = summaryNumbers.map((props, i) => <SummaryNumber {...props} key={i} />);
  return <div className="summary-numbers">{summaryNumberComponents}</div>;
});

function Contributions({ clubMember }) {
  return (
    <div className="contributions">
      <SummaryNumbers clubMember={clubMember} />
      <div className="metrics">
        <MembershipTrend transactions={clubMember?.transactions} />
        <div className="contributions-table">
          <ContributionsTable transactions={clubMember?.transactions} />
        </div>
      </div>
    </div>
  );
}

export default observer(Contributions);
