import React, { useState } from "react";
import { observer } from "mobx-react";
import { FiX } from "react-icons/fi";
import { IoMdEye } from "react-icons/io";
import useBulletin from "../../../hooks/useBulletin";
import Page from "../../-common/Page";
import Button from "../../-common/Button";
import BulletinPreview from "./BulletinPreview";
import ConfirmRemoveBulletinModal from "./ConfirmRemoveBulletinModal";
import "./BulletinOverview.scss";

const BulletinOverview = ({ bulletinId }) => {
  const {
    subject,
    message,
    media,
    type,
    schedules,
  } = useBulletin(bulletinId);
  const publishedScheduleSteps = schedules?.filter(s => s?.publishDate < new Date())?.length || 0;

  const [confirmRemoveBulletinModalOpen, setConfirmRemoveBulletinModalOpen] = useState(false);
  const openConfirmRemoveBulletinModal = () => setConfirmRemoveBulletinModalOpen(true);
  const closeConfirmRemoveBulletinModal = () => setConfirmRemoveBulletinModalOpen(false);

  const saveEnabled = false;

  const cancel = () => null;
  const save = async () => null;

  const headerButtons = saveEnabled
    ? [
        <Button key="cancel" text="Cancel" icon={FiX} theme="light" onClick={cancel} />,
        <Button key="save" text="Save" icon={IoMdEye} disabled={!saveEnabled} onClick={save} />
      ]
    : [<Button key="save" text="Delete" icon={FiX} onClick={openConfirmRemoveBulletinModal} />];

  const statusCol = schedules?.length ? (
    <div className="bulletin-content-col">
      <div className="bulletin-content-col-title">Status</div>
      <div className="bulletin-content-message">
        So far, {publishedScheduleSteps} out of {schedules.length} scheduled steps have been published.
      </div>
    </div>
  ) : null;

  return (
    <Page titleOpts={{ title: "Overview" }} buttons={headerButtons}>
      <div className="bulletin-overview">
        <div className="bulletin-content-col">
          <div className="bulletin-content-col-title">Preview</div>
          <BulletinPreview {...{ subject, message, media, type }} />
        </div>
        {statusCol}
      </div>
      <ConfirmRemoveBulletinModal
        isOpen={confirmRemoveBulletinModalOpen}
        bulletinId={bulletinId}
        close={closeConfirmRemoveBulletinModal}
      />
    </Page>
  );
};

export default observer(BulletinOverview);
