import React from "react";

export default function kayak({
  size = 24,
  fill = "none",
  stroke = "#000",
  strokeWidth = 13
}) {
  return (
    <svg
      viewBox="0 0 443 443"
      height={size}
      width={size}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    >
      <path d="M259.073,6.5 c0,0-45,96.259-45,215s45,215,45,215s45-96.259,45-215S259.073,6.5,259.073,6.5z" />
      <path d="M259.073,187.167 c-14.359,0-26,12.863-26,46s11.641,60,26,60s26-26.863,26-60S273.432,187.167,259.073,187.167z" />
      <line x1="259.076" y1="17.83" x2="259.076" y2="187.17" />
      <line x1="259.076" y1="293.17" x2="259.076" y2="430.5" />
      <line
        strokeWidth={(strokeWidth * 15) / 13}
        x1="234.649"
        y1="251.167"
        x2="281.983"
        y2="251.167"
      />
      <line x1="165.314" y1="109.537" x2="165.314" y2="333.467" />
      <path d="M165.316,333.465 c14.544,0,25.969,39.953,26.333,56.333c0.333,15-12.665,24.697-26.333,29.667c-14.667,5.333-27.333,2-26.333-29.667 C139.5,373.422,150.772,333.465,165.316,333.465z" />
      <path d="M165.267,109.535 c-14.544,0-25.969-39.953-26.333-56.333c-0.333-15,12.665-24.697,26.333-29.667c14.667-5.333,27.333-2,26.333,29.667 C191.083,69.578,179.81,109.535,165.267,109.535z" />
    </svg>
  );
}
