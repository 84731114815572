import React from "react";
import { observer } from "mobx-react";
import { FaMoneyBill } from "react-icons/fa";
import PledgeProgressGauge from "./PledgeProgressGauge";
import PledgeHistoryTable from "./PledgeHistoryTable";
import "./Pledges.scss";

function Pledges({ clubMember }) {
  return (
    <div className="pledges">
      <div className="progress-gauge">
        <PledgeProgressGauge icon={FaMoneyBill} />
      </div>
      <div className="history-table">
        <PledgeHistoryTable />
      </div>
    </div>
  );
}

export default observer(Pledges);
