import { useState, useEffect } from "react";
import { toJS } from "mobx";
import { navigate } from "@reach/router";
import AchievementsStore from "../../../stores/AchievementsStore";

export default function useAchievement(achievementId) {
  const [achievement, setAchievement] = useState({});

  const { allAchievements } = AchievementsStore;
  useEffect(() => {
    const matchingAchievement = allAchievements.find(p => p.achievementId === achievementId);
    if (matchingAchievement) {
      setAchievement(toJS(matchingAchievement, { recurseEverything: true }));
    } else if (allAchievements.length) {
      navigate("/campaigns/achievements");
    }
  }, [allAchievements, achievementId]);

  return achievement;
}
