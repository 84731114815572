import React from "react";
import "./NLogo.scss";

const logoAspect = 26.59 / (29.42 - 0.66);

const NLogo = ({ height }) => {
  const style = height
    ? {
        height,
        width: height * logoAspect
      }
    : null;

  return (
    <svg style={style} viewBox="0 0.66 26.59 29.42">
      <polygon
        id="N"
        fill="#2487FF"
        points="20.15, 0.66
                20.15, 19.18
                6,     0.66
                0,     0.66
                0,     29.42
                6.43,  29.42
                6.43,  11.4
                20.15, 29.42
                26.59, 29.42
                26.59, 0.66"
      />
    </svg>
  );
};

export default NLogo;
