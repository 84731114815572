import React from "react";
import "./NickelLogo.scss";

const logoAspect = 6.849798387;

const NickelLogo = ({ height, color = "#2487FF" }) => {
  const style = height
    ? {
        height,
        width: height * logoAspect
      }
    : null;

  return (
    <svg className="nickel-logo" style={style} viewBox="0 0 204 30">
      <g id="logo" fill={color}>
        <polygon
          id="N"
          points="20.1578706 0.658301887 20.1578706 19.1773585 6.0185916
          0.658301887 -0.0001134 0.658301887 -0.0001134 29.4237736 6.4308006
          29.4237736 6.4308006 11.4 20.1578706 29.4237736 26.5887846 29.4237736
          26.5887846 0.658301887"
        />
        <polygon
          id="I"
          points="43.983891 29.4242264 50.414805 29.4242264 50.414805
          0.658754717 43.983891 0.658754717"
        />
        <path
          id="C"
          d="M81.0420471,23.7451698 C84.2019381,23.7451698 86.7857571,22.4970566
          88.7918031,20.0002642 L92.9138931,24.2387547 C89.6434371,27.9151698
          85.7889711,29.7530943 81.3510621,29.7530943 C76.9125861,29.7530943
          73.2577041,28.3544151 70.3858491,25.5553585 C67.5139941,22.757434
          66.0783501,19.2253585 66.0783501,14.9591321 C66.0783501,10.6929057
          67.5417771,7.13309434 70.4686311,4.27969811 C73.3954851,1.42630189
          76.9743891,-0.000113207547 81.2064771,-0.000113207547
          C85.9329891,-0.000113207547 89.8906491,1.79762264
          93.0788901,5.39083019 L89.0804061,9.91743396 C87.0465771,7.3934717
          84.5183241,6.13177358 81.4956471,6.13177358 C79.0768251,6.13177358
          77.0089761,6.92083019 75.2915331,8.49781132 C73.5735231,10.0759245
          72.7150851,12.2013962 72.7150851,14.8764906 C72.7150851,17.5521509
          73.5253281,19.698566 75.1475151,21.3168679 C76.7685681,22.9357358
          78.7337901,23.7451698 81.0420471,23.7451698"
        />
        <path
          id="K"
          d="M108.289629,0.658075472 L114.72111,0.658075472
          L114.72111,12.4690189 L125.602974,0.658075472 L133.559118,0.658075472
          L122.098914,13.3746792 C123.088896,14.7467547 124.91577,17.3041132
          127.581804,21.0495849 C130.247271,24.7944906 132.240276,27.5861887
          133.559118,29.4235472 L126.057141,29.4235472 L117.606573,18.066
          L114.72111,21.276 L114.72111,29.4235472 L108.289629,29.4235472
          L108.289629,0.658075472 Z"
        />
        <polygon
          id="E_shell"
          points="169.04555 0.658075472 169.04555 6.37845283 154.293344
          6.37845283 154.293344 23.7450566 169.092611 23.7450566 169.092611
          29.4235472 147.862997 29.4235472 147.862997 0.658075472"
        />
        <path
          id="E_dot"
          d="M163.68672,15.1108302 C163.68672,16.4744151 162.579369,17.5798868
          161.213466,17.5798868 C159.847563,17.5798868 158.739645,16.4744151
          158.739645,15.1108302 C158.739645,13.7472453 159.847563,12.6423396
          161.213466,12.6423396 C162.579369,12.6423396 163.68672,13.7472453
          163.68672,15.1108302"
        />
        <polygon
          id="L"
          points="185.126974 29.4237736 185.126974 0.658301887 191.558455
          0.658301887 191.558455 23.7039623 203.84251 23.7039623 203.84251
          29.4237736"
        />
      </g>
    </svg>
  );
};

export default NickelLogo;
