export default function getPasswordCriteriaErrors(criteria, password) {
  const {
    MinimumLength,
    RequireUppercase,
    RequireLowercase,
    RequireNumbers,
    RequireSymbols
  } = criteria || {};

  let errors = [];
  if (MinimumLength && password?.length < MinimumLength) {
    errors.push("MinimumLength");
  }
  if (RequireUppercase && !password?.match(/[A-Z]/g)?.length) {
    errors.push("RequireUppercase");
  }
  if (RequireLowercase && !password?.match(/[a-z]/g)?.length) {
    errors.push("RequireLowercase");
  }
  if (RequireNumbers && !password?.match(/[0-9]/g)?.length) {
    errors.push("RequireNumbers");
  }
  if (
    RequireSymbols &&
    !password?.match(
      // eslint-disable-next-line
      /[\*\.\^\$\[\]\{\}\(\)\?\-\”\!\@\#\%\&\/\\\,\>\<\'\:\;\|\_\~\`]/g
    )?.length
  ) {
    errors.push("RequireSymbols");
  }

  return errors;
}
