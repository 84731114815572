import React from 'react';

const StarIcon = ({
  size = 24,
  color = '#fff',
}) => (
  <svg
    style={{
      height: size,
      width: size,
    }}
    viewBox="0 0 57.1 57.09"
    fill={color}
  >
    <path
      className="cls-1"
      d="M36.21,64.83A28.57,28.57,0,0,1,16,16.08h0a28.56,28.56,0,1,1,20.2,48.75Zm0-55.19a26.54,26.54,0,0,0-18.84,7.8h0a26.65,26.65,0,1,0,18.84-7.8ZM16.69,16.76h0Z"
      transform="translate(-7.66 -7.73)"
    />
    <path
      className="cls-1"
      d="M26.71,52.27a2,2,0,0,1-1.15-.38,1.93,1.93,0,0,1-.71-2.13l3-9.66L19.67,34A1.91,1.91,0,0,1,19,31.85a1.93,1.93,0,0,1,1.8-1.35L31,30.3l3.36-9.55a1.91,1.91,0,0,1,1.83-1.3h0A1.92,1.92,0,0,1,38,20.75l3.35,9.55,10.23.2h0A1.94,1.94,0,0,1,52.75,34L44.61,40.1l3,9.66a1.94,1.94,0,0,1-3,2.16l-8.4-5.78L27.8,51.92A1.91,1.91,0,0,1,26.71,52.27ZM36.21,44a.89.89,0,0,1,.54.17l9,6.16L42.58,40A1,1,0,0,1,42.92,39l8.68-6.51v-1l0,1L40.69,32.2a1,1,0,0,1-.88-.64L36.23,21.38,32.61,31.56a1,1,0,0,1-.88.64l-10.9.22L29.49,39A1,1,0,0,1,29.84,40l-3.16,10.3,9-6.13A.89.89,0,0,1,36.21,44Z"
      transform="translate(-7.66 -7.73)"
    />
  </svg>
);

export default StarIcon;
