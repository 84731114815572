import React from "react";

export default function beam({
  size = 24,
  fill = "none",
  stroke = "#000",
  strokeWidth = 13
}) {
  return (
    <svg
      viewBox="0 0 334.676 334.676"
      height={size}
      width={size}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M302.818,169.128H30.316 c-13.153,0-23.816-10.663-23.816-23.816V101.75c0-26.82,21.742-48.563,48.563-48.563h223.01c26.82,0,48.563,21.742,48.563,48.563 v43.562C326.635,158.465,315.972,169.128,302.818,169.128z" />
      <path d="M111.74,169.128 c-11.381,0-20.607-9.226-20.607-20.607V95.205c0-23.206,18.812-42.018,42.018-42.018" />
      <polygon points="70.586,171.948 14.636,287.988 0.196,287.988 58.876,166.308 	" />
      <polygon points="159.896,287.988 145.456,287.988 89.506,171.948 101.216,166.308 	" />
      <polygon points="245.356,171.948 189.406,287.988 174.966,287.988 233.656,166.308 	" />
      <polygon points="334.676,287.988 320.236,287.988 264.276,171.948 275.986,166.308 	" />
    </svg>
  );
}
