import React from 'react';
import {
  Router,
  Redirect,
} from '@reach/router';
import DXMTheme from './DXMTheme';
import Users from './Users';
import DefaultOfficer from './DefaultOfficer';
import Teams from '../-common/Teams';
import PaymentProcessor from './PaymentProcessor';
import Campaign from './Campaign';
import Hosting from './Hosting';
import Perks from './Perks';
import RewardOptions from './RewardOptions';
import './Settings.scss';

export default function Settings() {
  return (
    <Router>
      <Users path="users/*" />
      <DefaultOfficer path="default-officer/*" />
      <Teams path="teams/*" />
      <Campaign path="campaigns" />
      <Perks path="perks" />
      <RewardOptions path="rewardoptions" />
      <PaymentProcessor path="payments" />
      <Hosting path="hosting" />
      <DXMTheme path="theme" />
      <Redirect from="*" to="/settings/users" noThrow />
    </Router>
  );
}
