const nextEnabled = state => {
  const { name, owner, activeSlide } = state;
  if (activeSlide === 0) return name && owner;
  if (activeSlide === 1) return true;
  if (activeSlide === 2) return true;
  return false;
};

const addSocial = (existingSocialArray, newSocialObj) => {
  const exists = !!existingSocialArray.find(o => {
    return o.platform === newSocialObj.social.platform;
  });
  if (exists) {
    return existingSocialArray.map(o => {
      if (o.platform === newSocialObj.social.platform)
        return newSocialObj.social;
      return o;
    });
  }
  return existingSocialArray.concat(newSocialObj.social);
};

const deleteSocial = (existingSocialArray, platform) => {
  return existingSocialArray.filter(a => a.platform !== platform);
};

export const teamInitialState = {
  name: null,
  owner: null,
  media: null,
  file: null,
  fileURL: null,
  social: [],
  members: [],
  activeSlide: 0,
  editMode: false,
  nextEnabled: false
};
export const teamReducer = (state, action) => {
  const { type, payload } = action;
  const withSimpleUpdates = { ...state, ...payload };
  const { file, fileURL, editMode } = withSimpleUpdates;
  let result = state;
  switch (type) {
    case "setActiveSlide":
    case "setMedia":
    case "setName":
    case "setOwner":
    case "setMembers":
      result = {
        ...withSimpleUpdates,
        nextEnabled: nextEnabled(withSimpleUpdates)
      };
      break;
    case "setFile":
      result = { ...state, file };
      break;
    case "setFileURL":
      result = { ...state, fileURL };
      break;
    case "setEditMode":
      result = { ...state, editMode };
      break;
    case "setSocial":
      result = {
        ...withSimpleUpdates,
        social: addSocial(state.social, payload)
      };
      break;
    case "deleteSocial":
      result = {
        ...withSimpleUpdates,
        social: deleteSocial(state.social, payload)
      };
      break;
    case "setCurrentTeam":
      result = { ...teamInitialState, ...payload };
      break;
    case "resetState":
      result = { ...state, ...teamInitialState };
      break;
    default:
      break;
  }
  return result;
};
