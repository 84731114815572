import React, { useReducer, useCallback } from "react";
import { observer } from "mobx-react";
import {
  rewardReducer,
  rewardInitialState
} from "../../../reducers/RewardReducer";
import Modal from "../../-common/Modal";
import RewardsStore from "../../../stores/RewardsStore";
import TitleSlide from "./TitleSlide";
import NumberSlide from "./NumberSlide";
import DonorInputSlide from "./DonorInputSlide";
import getFileURLFromFile from "../../../utils/getFileURLFromFile";
import "./NewRewardModal.scss";

const NewRewardModal = () => {
  const [state, dispatch] = useReducer(rewardReducer, rewardInitialState);

  const categoryOptions = [
    { label: "Physical Good", value: "physical" },
    { label: "Experience", value: "experience" },
    { label: "Sponsorhip or Discount Code", value: "sponsorship" },
    { label: "Contest", value: "contest" }
  ];

  const updateValue = useCallback(
    field => value => {
      const actionType = "set" + field[0].toUpperCase() + field.slice(1);
      dispatch({ type: actionType, payload: { [field]: value } });
    },
    []
  );

  const uploadFile = async file => {
    dispatch({ type: "setFile", payload: { file } });
    const fileURL = await getFileURLFromFile(file);
    dispatch({ type: "setFileURL", payload: { fileURL } });
  };

  const close = () => {
    dispatch({ type: "resetState", payload: null });
    RewardsStore.closeNewRewardModal();
  };

  const finish = () => {
    const {
      title,
      description,
      category,
      file,
      initialQuantity,
      unitPrice,
      rewardOptions
    } = state;

    const listOfRewardOptionIds = rewardOptions
      ?.map(option => option.value)
      .filter(id => id !== "Shipping Address");
    const categoryValue = category?.value;
    RewardsStore.addReward(
      {
        title,
        description,
        category: categoryValue,
        initialQuantity,
        unitPrice,
        rewardOptionIds: listOfRewardOptionIds
      },
      file
    );
    close();
  };

  const { slides, progress, progressTitle } = {
    slides: [
      <TitleSlide
        title={state.title}
        setTitle={updateValue("title")}
        description={state.description}
        setDescription={updateValue("description")}
        fileURL={state.fileURL}
        onUpload={uploadFile}
      />,
      <NumberSlide
        options={categoryOptions}
        category={state.category}
        setCategory={updateValue("category")}
        unitPrice={state.unitPrice}
        setUnitPrice={updateValue("unitPrice")}
        initialQuantity={state.initialQuantity}
        setInitialQuantity={updateValue("initialQuantity")}
      />,
      <DonorInputSlide
        rewardOptions={state.rewardOptions}
        setRewardOptions={updateValue("rewardOptions")}
        category={state.category}
      />
    ],
    progress: [
      { title: "Info", description: "Add the new reward item" },
      {
        title: "Numbers",
        description: "Fill in quantity and value"
      },
      {
        title: "Inputs",
        description: "What do we need to collect?"
      }
    ],
    progressTitle: "Add a new team in 3 easy steps"
  };

  return (
    <Modal
      nextEnabled={state.nextEnabled}
      slides={slides}
      progress={progress}
      progressTitle={progressTitle}
      isOpen={RewardsStore.showNewRewardModal}
      activeSlide={state.activeSlide}
      setActiveSlide={updateValue("activeSlide")}
      close={close}
      finish={finish}
    />
  );
};

export default observer(NewRewardModal);
