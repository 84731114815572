import React from "react";

export default function waterPolo({
  size = 24,
  fill = "none",
  stroke = "#000",
  strokeWidth = 13
}) {
  return (
    <svg
      viewBox="0 0 399.1 399.1"
      height={size}
      width={size}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M322.48,202.629 c3.42-1.67,6.54-4.16,9.05-7.45c5.12-6.69,12.72-10.04,20.32-10.04s15.19,3.35,20.31,10.04c5.12,6.7,12.72,10.05,20.32,10.05 c0.04,0,0.08,0,0.12,0" />
      <path d="M6.5,195.179 c10.23-13.39,30.4-13.39,40.63,0c5.11,6.7,12.71,10.05,20.31,10.05c4.56,0,9.12-1.21,13.15-3.64" />
      <path d="M6.5,254.709 c10.23-13.4,30.4-13.4,40.63,0c5.11,6.7,12.71,10.04,20.31,10.04s15.2-3.34,20.32-10.04c5.12-6.7,12.72-10.05,20.32-10.05 s15.2,3.35,20.31,10.05c5.11,6.7,12.71,10.04,20.31,10.04c7.6,0,15.2-3.34,20.32-10.04c5.12-6.7,12.72-10.05,20.32-10.05 c7.6,0,15.2,3.35,20.31,10.05c5.11,6.7,12.71,10.04,20.31,10.04c7.6,0,15.2-3.34,20.32-10.04c5.12-6.7,12.71-10.05,20.31-10.05 s15.19,3.35,20.31,10.05c0,0,0.83,1.032,1.261,1.517c10.548,11.852,29.541,11.347,39.369-1.517c5.12-6.7,12.72-10.05,20.32-10.05 s15.19,3.35,20.31,10.05c5.12,6.7,12.72,10.04,20.32,10.04c0.04,0,0.08,0,0.12,0" />
      <path d="M6.5,314.229 c10.23-13.39,30.4-13.39,40.63,0c5.11,6.7,12.71,10.04,20.31,10.04s15.2-3.34,20.32-10.04c5.12-6.7,12.72-10.04,20.32-10.04 s15.2,3.34,20.31,10.04c5.11,6.7,12.71,10.04,20.31,10.04c7.6,0,15.2-3.34,20.32-10.04c5.12-6.7,12.72-10.04,20.32-10.04 c7.6,0,15.2,3.34,20.31,10.04c5.11,6.7,12.71,10.04,20.31,10.04c7.6,0,15.2-3.34,20.32-10.04c5.12-6.7,12.71-10.04,20.31-10.04 s15.19,3.34,20.31,10.04c0,0,0.773,0.963,1.173,1.417c10.53,11.955,29.602,11.482,39.457-1.417c5.12-6.7,12.72-10.04,20.32-10.04 s15.19,3.34,20.31,10.04c5.12,6.7,12.72,10.04,20.32,10.04c0.04,0,0.08,0,0.12,0" />
      <path d="M92.95,249.609 c-8.01-16.17-12.5-34.39-12.5-53.67c0-66.94,54.17-121.11,121.11-121.11s121.11,54.17,121.11,121.11c0,24.88-7.48,48-20.33,67.23" />
      <line x1="201.56" y1="247.759" x2="201.56" y2="195.939" />
      <line x1="201.559" y1="195.942" x2="306.444" y2="135.387" />
      <line x1="201.559" y1="195.942" x2="96.673" y2="135.387" />
      <path d="M242.69,173.239c4,28.1,5.52,56.47,4.56,84.78" />
      <path d="M284.15,149.269 c8.78,34.22,10.79,69.73,6.02,104.54" />
      <path d="M279.953,103.57 c-42.529,16.953-82.443,39.843-118.554,67.989" />
      <path d="M237.344,80.214 c-44.165,12.193-84.534,35.344-117.362,67.305" />
      <path d="M82.372,174.196 c35.937,28.367,75.709,51.502,118.133,68.717" />
    </svg>
  );
}
