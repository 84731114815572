import React, { useEffect, useState, useCallback } from "react";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import isEqual from "lodash.isequal";
import { MdCheck, MdClose } from "react-icons/md";
import { GoPlus } from "react-icons/go";
import RewardOptionsStore from "../../../stores/RewardOptionsStore";
import Page from "../../-common/Page";
import Button from "../../-common/Button";
import AddRewardOptionModal from "./AddRewardOptionModal";
import EditRewardOptionCard from "./EditRewardOptionCard";
import "./RewardOptions.scss";

const compareRewardOptionValues = originalRewardOptions => rewardOption => {
  const rewardOptionContext = originalRewardOptions?.filter(t => t.rewardOptionId === rewardOption.rewardOptionId);
  const { title, values } = rewardOptionContext[0];

  if (rewardOption.title !== title) return true;
  if (!isEqual(rewardOption.values, values)) return true;
  return false;
};

const checkIfRewardOptionHasMoved = (originalRewardOptions, rewardOptions) => {
  const originalRewardOptionOrder = Object.fromEntries(originalRewardOptions.map((t, i) => [t.rewardOptionId, i]));
  return rewardOptions.some(({ rewardOptionId }, i) => originalRewardOptionOrder[rewardOptionId] !== i);
};

function ZeroState({ addRewardOption }) {
  return (
    <div className="zero-state">
      <div className="zero-state-text">
        Looks like you haven't created any reward options yet - add some to get started!
      </div>
      <Button text="Add Reward Option" icon={GoPlus} onClick={addRewardOption} />
    </div>
  );
}

const SortableRewardOptionCard = SortableElement(
  ({ localSortIndex, campaignId, updateRewardOption, rewardOption, sorting, duringDragSortOrder }) => {
    return (
      <EditRewardOptionCard
        localSortIndex={localSortIndex}
        campaignId={campaignId}
        update={updateRewardOption}
        rewardOption={rewardOption}
        sorting={sorting}
        duringDragSortOrder={duringDragSortOrder}
      />
    );
  }
);

const RewardOptionCardsList = SortableContainer(
  ({ items, campaignId, updateRewardOption, sorting, duringDragSortOrder }) => {
    return (
      <div className="edit-reward-option-cards">
        {items.map((rewardOption, index) => (
          <SortableRewardOptionCard
            index={index}
            localSortIndex={index}
            campaignId={campaignId}
            updateRewardOption={updateRewardOption}
            rewardOption={rewardOption}
            sorting={sorting}
            duringDragSortOrder={duringDragSortOrder}
            key={rewardOption.rewardOptionId}
          />
        ))}
      </div>
    );
  }
);

const RewardOptions = ({ campaignId }) => {
  const [addRewardOptionModalOpen, setAddRewardOptionModalOpen] = useState(false);
  const openAddRewardOptionModal = () => setAddRewardOptionModalOpen(true);
  const closeAddRewardOptionModal = () => setAddRewardOptionModalOpen(false);

  const [originalRewardOptions, setOriginalRewardOptions] = useState([]);
  const [rewardOptions, setRewardOptions] = useState([]);
  const { rewardOptions: storeRewardOptions } = RewardOptionsStore;
  useEffect(() => {
    setOriginalRewardOptions(toJS(storeRewardOptions, { recurseEverything: true }));
    setRewardOptions(toJS(storeRewardOptions, { recurseEverything: true }));
  }, [storeRewardOptions]);

  const [sorting, setSorting] = useState(false);
  const [duringDragSortOrder, setDuringDragSortOrder] = useState([]);

  const [saving, setSaving] = useState(false);

  const updateRewardOption = useCallback(
    updatedRewardOption => {
      setRewardOptions(
        rewardOptions.map(ro => {
          if (ro.rewardOptionId === updatedRewardOption.rewardOptionId) return updatedRewardOption;
          return ro;
        })
      );
    },
    [rewardOptions, setRewardOptions]
  );

  const sortRewardOptions = ({ oldIndex, newIndex }) => {
    const movedRewardOptions = arrayMove(rewardOptions, oldIndex, newIndex);
    const newRewardOptions = movedRewardOptions.map((ro, i) => ({
      ...ro,
      sortIndex: i
    }));
    setRewardOptions(newRewardOptions);
  };

  const checkSaveEnabled = ({ rewardOptions }) => {
    if (rewardOptions?.length) {
      return (rewardOptions?.map(compareRewardOptionValues(originalRewardOptions)) || []).some(Boolean);
    }
    return false;
  };

  const save = useCallback(async () => {
    setSaving(true);
    const rewardOptionsToSave = rewardOptions.filter(compareRewardOptionValues(originalRewardOptions));
    const rewardOptionSavePromises = rewardOptionsToSave.map(rewardOption => {
      return RewardOptionsStore.updateRewardOption(rewardOption);
    });
    await Promise.all(rewardOptionSavePromises);
    const rewardOptionHasMoved = checkIfRewardOptionHasMoved(originalRewardOptions, rewardOptions);
    if (rewardOptionHasMoved) {
      await RewardOptionsStore.sortRewardOptions(rewardOptions);
    }
    setSaving(false);
  }, [setSaving, rewardOptions, originalRewardOptions]);

  const cancel = useCallback(() => {
    setRewardOptions(originalRewardOptions);
  }, [originalRewardOptions, setRewardOptions]);

  const rewardOptionHasMoved = checkIfRewardOptionHasMoved(originalRewardOptions, rewardOptions);
  const saveEnabled = rewardOptionHasMoved || checkSaveEnabled({ rewardOptions });

  const conditionalButtons = saveEnabled
    ? [
        <Button text="Cancel" theme="light" icon={MdClose} onClick={cancel} disabled={saving} key="cancel" />,
        <Button
          text={saving ? "Saving..." : "Save"}
          theme={saving ? "light" : null}
          icon={MdCheck}
          onClick={save}
          disabled={saving}
          key="save"
        />
      ]
    : [<Button text="Add Reward Option" onClick={openAddRewardOptionModal} icon={GoPlus} key="add" />];

  const updateBeforeSortStart = () => setSorting(true);
  const onSortOver = ({ oldIndex, newIndex }) => {
    setDuringDragSortOrder(arrayMove(duringDragSortOrder, oldIndex, newIndex));
  };
  const onSortEnd = (...args) => {
    setSorting(false);
    sortRewardOptions(...args);
  };

  const contents = (rewardOptions || []).length ? (
    <RewardOptionCardsList
      axis="xy"
      pressDelay={200}
      helperClass="reward-option-drag-ghost sorting"
      updateBeforeSortStart={updateBeforeSortStart}
      onSortOver={onSortOver}
      onSortEnd={onSortEnd}
      items={rewardOptions}
      campaignId={campaignId}
      updateRewardOption={updateRewardOption}
      sorting={sorting}
      duringDragSortOrder={duringDragSortOrder}
    />
  ) : (
    <ZeroState addRewardOption={openAddRewardOptionModal} />
  );

  return (
    <Page titleOpts={{ title: "Reward Options" }} buttons={conditionalButtons}>
      <div className="reward-options">{contents}</div>
      <AddRewardOptionModal isOpen={addRewardOptionModalOpen} close={closeAddRewardOptionModal} />
    </Page>
  );
};

export default observer(RewardOptions);
