import React, {
  useReducer,
  useCallback,
  useEffect,
} from 'react';
import { observer } from 'mobx-react';
import { jsToSqlDate } from '@/utils/jsToSqlDate';
import getFileURLFromFile from '@/utils/getFileURLFromFile';
import CampaignsStore from '@/stores/CampaignsStore';
import TeamsStore from '@/stores/TeamsStore';
import TenantStore from '@/stores/TenantStore';
import MediaSlide from '@/common/MediaSlide';
import Modal from '@/common/Modal';
import {
  campaignReducer,
  campaignInitialState,
} from './campaignReducer';
import TargetsSlide from './TargetsSlide';
import TitleSlide from './TitleSlide';
import './NewCampaignModal.scss';

const NewCampaignModal = () => {
  const [
    state,
    dispatch,
  ] = useReducer(campaignReducer, campaignInitialState);
  const { tenant } = TenantStore;
  const { showNewCampaignModal } = CampaignsStore;

  const updateValue = useCallback(
    field => value => {
      const actionType = `set${field[0].toUpperCase()}${field.slice(1)}`;

      dispatch({
        type: actionType,
        payload: { [field]: value },
      });
    },
    []
  );

  useEffect(() => {
    const {
      pointMultiplier = 0, quid = 'no',
    } = tenant?.config || {};

    updateValue('pointMultiplier')(pointMultiplier?.toString());
    updateValue('quid')(quid);
  }, [
    updateValue,
    tenant,
    showNewCampaignModal,
  ]);

  const teamOptions = TeamsStore.teams.map(team => ({
    label: team.name,
    value: team.teamId,
  }));

  const uploadFile = async file => {
    dispatch({
      type: 'setFile',
      payload: { file },
    });
    const fileURL = await getFileURLFromFile(file, file.type === 'video/mp4');

    dispatch({
      type: 'setFileURL',
      payload: { fileURL },
    });
  };

  const close = () => {
    dispatch({
      type: 'resetState',
      payload: null,
    });
    CampaignsStore.closeNewCampaignModal();
  };

  const finish = () => {
    const {
      teams,
      title,
      description,
      amount,
      endDate,
      file,
      quid,
    } = state;
    let { pointMultiplier } = state;

    close();

    const teamIds =
      typeof teams === 'object' && teams.value === 'All' ?
        TeamsStore.teams?.map(t => t.teamId) :
        teams.map(t => t.value);

    if (pointMultiplier.endsWith('.')) pointMultiplier += '0';
    pointMultiplier = +pointMultiplier;

    CampaignsStore.addCampaign(
      {
        teamIds,
        title,
        description,
        revenueGoal: amount,
        endDate: endDate ? jsToSqlDate(endDate) : null,
        pointMultiplier,
        quid,
      },
      file
    );
  };

  const {
    slides,
    progress,
    progressTitle,
  } = {
    slides: [
      <TitleSlide
        options={teamOptions}
        teams={state.teams}
        setTeams={updateValue('teams')}
        title={state.title}
        setTitle={updateValue('title')}
        description={state.description}
        setDescription={updateValue('description')}
      />,
      <MediaSlide
        fileURL={state.fileURL}
        onUpload={uploadFile}
        headline="Let's get it started."
        uploadLabelPrefix="Cover"
        isSupportingVideo
      />,
      <TargetsSlide
        amount={state.amount}
        setAmount={updateValue('amount')}
        endDate={state.endDate}
        setEndDate={updateValue('endDate')}
        pointMultiplier={state.pointMultiplier}
        setPointMultiplier={updateValue('pointMultiplier')}
        quid={state.quid}
        setQuid={updateValue('quid')}
      />,
    ],
    progress: [
      {
        title: 'Title',
        description: 'Name your campaign',
      },
      {
        title: 'Media',
        description: 'Choose your media',
      },
      {
        title: 'Targets',
        description: 'Prepare your campaign',
      },
    ],
    progressTitle: 'Build your campaign in 3 simple steps',
  };

  return (
    <Modal
      nextEnabled={state.nextEnabled}
      slides={slides}
      progress={progress}
      progressTitle={progressTitle}
      activeSlide={state.activeSlide}
      isOpen={showNewCampaignModal}
      setActiveSlide={updateValue('activeSlide')}
      close={close}
      finish={finish}
    />
  );
};

export default observer(NewCampaignModal);
