import React, { useEffect, useState, useCallback } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { MdCheck, MdClose } from "react-icons/md";
import { IoMdEye } from "react-icons/io";
import { FaImage } from "react-icons/fa";
import CampaignsStore from "../../../stores/CampaignsStore";
import TenantStore from "../../../stores/TenantStore";
import useFileDrop from "../../../hooks/useFileDrop";
import { isVideoLink, getFileURLFromFile } from "../../../utils";
import Page from "../../-common/Page";
import Button from "../../-common/Button";
import CampaignMedia from "../../-common/CampaignMedia";
import "./Media.scss";

const Media = ({ campaignId }) => {
  CampaignsStore.setSelectedCampaign(campaignId);
  const [originalCampaign, setOriginalCampaign] = useState({});
  const [campaign, setCampaign] = useState({});
  const [saving, setSaving] = useState(false);

  const currentCampaign = CampaignsStore.currentCampaign;
  useEffect(() => {
    let campaign = currentCampaign;
    if (campaign) {
      campaign = toJS(campaign, { recurseEverything: true });
      setOriginalCampaign(campaign);
      setCampaign(campaign);
    }
  }, [currentCampaign, setOriginalCampaign, setCampaign]);

  const [fileURL, setFileURL] = useState();
  const [file, setFile] = useState();

  const updateFileURL = useCallback(
    (fileURL, campaign) => {
      setFileURL(fileURL);
      setCampaign({ ...campaign, media: fileURL });
    },
    [setFileURL, setCampaign]
  );

  const replaceImage = useCallback(
    async file => {
      const fileURL = await getFileURLFromFile(file);
      setFile(file);
      updateFileURL(fileURL, campaign);
    },
    [setFile, updateFileURL, campaign]
  );
  const { HiddenInput, onClick: chooseFile } = useFileDrop(replaceImage);

  const save = useCallback(async () => {
    setSaving(true);
    const isNewVideo = campaign.media !== originalCampaign.media && isVideoLink(campaign.media);
    const media = isNewVideo ? campaign.media : file;
    const updatedCampaign = await CampaignsStore.updateCampaign(campaign, media);
    setFile(null);
    setFileURL(null);
    setOriginalCampaign(updatedCampaign);
    setSaving(false);
  }, [setSaving, campaign, originalCampaign, file, setOriginalCampaign]);

  const cancel = useCallback(() => {
    setFile(null);
    setFileURL(null);
    setCampaign(originalCampaign);
  }, [originalCampaign, setCampaign]);

  const { hostingURL } = TenantStore?.tenant?.config;
  const campaignLink = `${hostingURL}?campaignId=${campaign.campaignId}`;

  const saveEnabled = (!!file && !!fileURL) || campaign.media !== originalCampaign.media;

  const conditionalButtons = saveEnabled
    ? [
        <Button
          text={saving ? "Saving..." : "Save"}
          theme={saving ? "light" : null}
          icon={MdCheck}
          onClick={save}
          disabled={saving}
          key="save"
        />,
        <Button
          text="Cancel"
          icon={MdClose}
          onClick={cancel}
          disabled={saving}
          key="cancel"
        />
      ]
    : [
        <Button
          text="Replace Media"
          icon={FaImage}
          onClick={chooseFile}
          key="replace-media"
        />,
        <Button
          text="Preview"
          onClick={() => window.open(`${campaignLink}&preview=true`, "_blank")}
          icon={IoMdEye}
          key="preview"
        />
      ];

  return (
    <Page titleOpts={{ title: "Media" }} buttons={conditionalButtons}>
      <div className="campaign-media-container">
        <CampaignMedia campaign={campaign} showEmblem={false} />
      </div>
      <HiddenInput />
    </Page>
  );
};

export default observer(Media);
