import React, { useState, useEffect, useCallback } from "react";
import { createPortal } from "react-dom";
import { observer } from "mobx-react";
import AuthStore from "../../../stores/AuthStore";
import InstanceConfigStore from "../../../stores/InstanceConfigStore";
import usePortal from "../../../hooks/usePortal";
import Button from "../../-common/Button";
import { getPasswordCriteriaErrors } from "../../../utils";
import AuthInput from "../../-common/AuthInput";
import FullScreenModal from "../../-common/FullScreenModal";
import { toast } from "react-toastify";
import "./NewPasswordModal.scss";

const NewPasswordModal = observer(({ close, user }) => {
  const { username } = user;
  const { passwordPolicy } = InstanceConfigStore || {};
  const { MinimumLength, RequireLowercase, RequireNumbers, RequireSymbols, RequireUppercase } = passwordPolicy || {};

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const handlePasswordInput = ({ target }) => {
    setPassword(target.value);
    if (confirmPassword && target.value && confirmPassword !== target.value) {
      setPasswordError("Passwords don't match.");
    } else {
      setPasswordError("");
    }
  };
  const handleConfirmPasswordInput = ({ target }) => {
    setConfirmPassword(target.value);
    if (password && target.value && password !== target.value) {
      setPasswordError("Passwords don't match.");
    } else {
      setPasswordError("");
    }
  };

  const [passwordCriteriaErrors, setPasswordCriteriaErrors] = useState(getPasswordCriteriaErrors(""));
  useEffect(() => {
    const criteriaErrors = getPasswordCriteriaErrors(passwordPolicy, password);
    setPasswordCriteriaErrors(criteriaErrors);
  }, [passwordPolicy, password]);

  const reset = useCallback(() => {
    setPassword("");
    setConfirmPassword("");
    setPasswordError("");
  }, [setPassword, setConfirmPassword, setPasswordError]);

  const changePassword = useCallback(async () => {
    if (
      password &&
      confirmPassword &&
      password === confirmPassword &&
      !passwordCriteriaErrors?.length &&
      !passwordError
    ) {
      try {
        await AuthStore.adminResetPassword(username, password);
        toast("Password updated!", { autoClose: 3000 });
        setPasswordError("");
        close();
      } catch (err) {
        const errPrefix = "Password does not conform to policy: ";
        if (err.message.includes(errPrefix)) {
          err.message = err.message.replace(errPrefix, "");
        }
        setPasswordError(err.message);
        reset();
      }
    } else {
      setPasswordError("Passwords don't match.");
    }
  }, [password, confirmPassword, passwordCriteriaErrors, passwordError, setPasswordError, reset, close, username]);

  const handleKeyup = useCallback(
    e => {
      if (e.key === "Enter" || e.keycode === 13) changePassword();
    },
    [changePassword]
  );

  useEffect(() => {
    window.addEventListener("keyup", handleKeyup);
    return () => window.removeEventListener("keyup", handleKeyup);
  }, [handleKeyup]);

  const buttonDisabled = !password || !confirmPassword || passwordCriteriaErrors.length || passwordError;

  return (
    <FullScreenModal isOpen={true} close={close}>
      <div className="change-password-modal">
        <div className="modal-contents">
          <div className="auth-content">
            <h3 className="headline">Time for a new password</h3>
            <div className="auth-square">
              <div className="criteria">
                <h4 className="criteria-title">The new password should:</h4>
                <ul>
                  {MinimumLength ? (
                    <li className={passwordCriteriaErrors.includes("MinimumLength") ? "error" : null}>
                      Have {MinimumLength} characters
                    </li>
                  ) : null}
                  {RequireUppercase ? (
                    <li className={passwordCriteriaErrors.includes("RequireUppercase") ? "error" : null}>
                      Include at least one uppercase letter
                    </li>
                  ) : null}
                  {RequireLowercase ? (
                    <li className={passwordCriteriaErrors.includes("RequireLowercase") ? "error" : null}>
                      Include at least one lowercase letter
                    </li>
                  ) : null}
                  {RequireNumbers ? (
                    <li className={passwordCriteriaErrors.includes("RequireNumbers") ? "error" : null}>
                      Include at least one number
                    </li>
                  ) : null}
                  {RequireSymbols ? (
                    <li className={passwordCriteriaErrors.includes("RequireSymbols") ? "error" : null}>
                      Include at least one special character
                    </li>
                  ) : null}
                </ul>
              </div>

              <div className="auth-inputs">
                <AuthInput
                  type="password"
                  theme="alt"
                  placeholder="New password"
                  value={password}
                  onChange={handlePasswordInput}
                />
                <AuthInput
                  type="password"
                  theme="alt"
                  placeholder="...and the new password again"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordInput}
                  error={passwordError}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-buttons">
          <Button text="cancel" theme="light" onClick={close} />
          <Button disabled={buttonDisabled} text="save" onClick={changePassword} />
        </div>
      </div>
    </FullScreenModal>
  );
});

const NewPasswordModalContainer = ({ isOpen, ...props }) => {
  const portalTarget = usePortal("video-link-modal-container");
  const contents = isOpen ? <NewPasswordModal {...props} /> : null;
  return createPortal(contents, portalTarget);
};

export default NewPasswordModalContainer;
