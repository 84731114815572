import { observable, computed, action, autorun } from "mobx";
import { request } from "../utils";
import AuthStore from "./AuthStore";

export const filterAccountsOptions = [{ value: "All", label: "All" }];
export const sortAccountsOptions = [
  { label: "A to Z", value: "A to Z" },
  { label: "Z to A", value: "Z to A" },
  { label: "Newest", value: "Newest" },
  { label: "Oldest", value: "Oldest" }
];

class IntegrationsStore {
  constructor() {
    autorun(() => {
      if (AuthStore.APIReady) {
        // this.fetchSourcesForTarget("user");
        //         this.createSource("user", {
        //           creds: {
        //             host: "209.67.131.208",
        //             port: 22,
        //             username: "pacsftp-byu-nickel",
        //             privateKey: `-----BEGIN OPENSSH PRIVATE KEY-----
        // b3BlbnNzaC1rZXktdjEAAAAABG5vbmUAAAAEbm9uZQAAAAAAAAABAAABlwAAAAdzc2gtcn
        // NhAAAAAwEAAQAAAYEAu4sEyqFCfpyVIszPl0tBP7bgoLRyEdWCXAyiThzFKkWAFljrZt7n
        // 0jnBx4z93C2CMyEfO6z9tQUY8frQ8bR7BDGLdH6sqKAU/PJD97qRL8mFOvuwbkDfaU8nic
        // juI7wJopikSnYBPfjc6U3vZSPONJmTqb31bIDono0PzuqiX9U0K93JzvpRi+Q7JSxxbure
        // /sRP65B5IsdDiNQZKPtAiu1jaUyzz0a/k1ROSaFZyTLhntMJgAnhYptguNntONKjRCN92D
        // UP2LOko9ag7rPR1+AC7xRYA6nKm88d49hE28WL0iX72XMywYgIu6zh1MJY9fAZrmQRFcHg
        // MXn3zdro9BVj+WfTGWPL9Is0ae/GlWloFXW5mIBlurs8OvLyq3AjFKUYL2WAFC5k9ruY4Y
        // jIEJsqzBcxCW8BuCO2KXXJxXO/ApvxsiBoYBkjjY38/T5zPPg9QkKHySpLhJSXrykYHpDM
        // kYkOhnPO5/uKYbVi4Z6SGN1i1KOdqINTszeVxxYhAAAFkFb6IZJW+iGSAAAAB3NzaC1yc2
        // EAAAGBALuLBMqhQn6clSLMz5dLQT+24KC0chHVglwMok4cxSpFgBZY62be59I5wceM/dwt
        // gjMhHzus/bUFGPH60PG0ewQxi3R+rKigFPzyQ/e6kS/JhTr7sG5A32lPJ4nI7iO8CaKYpE
        // p2AT343OlN72UjzjSZk6m99WyA6J6ND87qol/VNCvdyc76UYvkOyUscW7q3v7ET+uQeSLH
        // Q4jUGSj7QIrtY2lMs89Gv5NUTkmhWcky4Z7TCYAJ4WKbYLjZ7TjSo0Qjfdg1D9izpKPWoO
        // 6z0dfgAu8UWAOpypvPHePYRNvFi9Il+9lzMsGICLus4dTCWPXwGa5kERXB4DF5983a6PQV
        // Y/ln0xljy/SLNGnvxpVpaBV1uZiAZbq7PDry8qtwIxSlGC9lgBQuZPa7mOGIyBCbKswXMQ
        // lvAbgjtil1ycVzvwKb8bIgaGAZI42N/P0+czz4PUJCh8kqS4SUl68pGB6QzJGJDoZzzuf7
        // imG1YuGekhjdYtSjnaiDU7M3lccWIQAAAAMBAAEAAAGAM5QANVo0V23o9jEtxoZD46oADT
        // KTTKRTvc0bti/jacTDfWHnG9uiFgccbC0yhCNf83/gfyEMhYRpX+ODI3ZnfrfBisBr69Ra
        // 8VYPq0n8NBBQO/CMjUX6K5RBY3uHQvysCbPBO1lI7ipLVz6/4CkAxSaXnc9IeBbb4v8Yoe
        // eV0JVqS8YrgmkFlFuoiIi4ibC+s4J6GGcbaZ0zzaXHZaX0EkFHXeZibVcfyP0eqtFdSCBe
        // PLf9nOEaw2vIJg6JV19WXnqZKlYDyCeZ3wBYAvPOlpIS3uOY9MtVQQ2+m1tK0k18YBnRjL
        // lNHdARmW63Piuk10N6y1sRTERgrhPE8/JlyIVM2sXljz0ukvVx+cxPIx9fm+AEqN9ifzxK
        // Jb3FkfhU/R8oHX9fdnK8pbNknJ2VHYsrmj8fuOueiXdPjohNgruB1wDFXUrjXuuOdu/TRI
        // 5cc9gUMzhWiMSrEtWl5VHNt49FdZCnPnkyWESofLPn+CKZWnUVgpX7zgzDPtxi6TWhAAAA
        // wCbfwXHL346cshgukEeCZACgvUmUhgi0eqUIZG+fIJXBGzmyD61gkizvzvNcRKizdvCgBt
        // z7HMxF9bzxhnb5wQStzVSgG5uHQDql2L4ZZ50SlPs2/fhaGEnhBYudkGUUHLASz+DVTKbP
        // Q/QRoHzdXNpBGkD3VboULykWjntExDgmaMNHzmrPP06mDKPtNj8kg3pVKvoaPri63jk028
        // CTl9n8ePf2Byeq65laT440R6/gZnbRxEltKby1TeJkbSkn8AAAAMEA8nh/mRytp+IaN09N
        // kQ2sWnJlMgCLBleBOQIDMRSuB6PQUNKv/YQmdE58zpO0zn281urjR/xjkbGCjwX4L5ma22
        // JN0xvnW418dlUcz4gp1zoXkBlU0/JCAAM87cuKasG6Xo/Mg2Bb+iRYyGa0exTcapzr1RlJ
        // LJMiqf1Q++eFSYvq+HfwXnzX8d8fLBjzUadhvTIACo45bI6PUSUB6p71CzaLjcxRpO6Dft
        // rYdCjuezhYel5F8Xr9d/+Pv/BZHI33AAAAwQDGAewcHCjy9TDK66vyjw71S2TpadFqV3wA
        // Pp8rv8BzNEW1gWe3366vqkWvDc28e8oZOIrXRCkJfoNS2f3q9kmCjjH8q5bnRZTqqtR19H
        // 7eBXYVyQNDiC7iZDwtHMr/YD+6dKrTHWL3znakgJr/XNRsm5ohONmLkZFDPngvJgPJnIrx
        // OCsrLzOSfRgArABngTzffzjaWkoHOsHjqPS3lykxKliUhiD/gwKrRbn53xu2z2v/VAyHo9
        // HJcpbnIB/W1qcAAAAbdmxleXZhQElSVi1WTEVZVkEtT1NYLmxvY2Fs
        // -----END OPENSSH PRIVATE KEY-----
        // `
        //           },
        //           type: "sftp",
        //           updateType: "append",
        //           directoryPath: "/paciolan-sftp/byu-nickel/",
        //           filePattern:
        //             "^byu_account_20[0-9]{2}-(0?[0-9]|1[012])-(0?[0-9]|[12][0-9]|3[01])-(0?[0-9]|1[0-9]|2[0-3])-([0-5]?[0-9]|60)-([0-5]?[0-9]|60)\\.csv$",
        //           validation: [],
        //           schedule: "1800"
        //         });
      } else this.clear();
    });
  }

  // ACCOUNTS
  @observable rawSourcesByTarget = {};

  @computed get sourcesByTarget() {
    return Object.fromEntries(
      Object.entries(this.rawSourcesByTarget || {}).map((key, sources) => [key, sources.filter(Boolean)])
    );
  }

  @action async fetchSourcesForTarget(target) {
    try {
      const sources = await request.get(`/v1/integrations/${target}/sources`);
      this.rawSources = sources.map(a => ({ ...a }));
      return sources;
    } catch (err) {
      console.warn(err);
    }
  }

  @action async createSource(target, sourceParams) {
    try {
      const newSource = await request.post(`/v1/integrations/${target}/sources`, { body: sourceParams });
      this.rawSourcesByTarget[target] = this.rawSourcesByTarget?.[target]?.concat(newSource) || [newSource];
      return newSource;
    } catch (err) {
      console.warn(err);
    }
  }

  // CLEANUP
  @action clear() {
    this.rawSources = [];
  }
}

export default new IntegrationsStore();
