import React from "react";
import ReactSelect from "react-select";
import Label from "../../-common/Label";
import "./Select2.scss";

const altStyles = {
  border: "none",
  outline: "none",
  borderRadius: 12,
  boxShadow: "0 2px 16px 0 rgba(0, 0, 0, 0.08)",
  backgroundColor: "var(--White)"
};

const authStyles = {
  borderRadius: 3,
  outline: "none",
  border: "none",
  boxShadow: "0px 2px 5px 0px rgba(0, 0, 0, 0.2)",
  backgroundColor: "var(--White)"
};

const emblem = media => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    background: `url(${media})`,
    backgroundSize: 30,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    content: '" "',
    display: "block",
    marginRight: 12,
    height: 30,
    width: 30
  }
});

const makeStyles = ({ theme, value, autoGrow = false }) => ({
  container: provided => ({
    ...provided,
    ...(autoGrow ? {} : { height: 40 })
  }),
  singleValue: provided => ({
    ...provided,
    ...(value?.media ? emblem(value.media) : {})
  }),
  control: provided => ({
    ...provided,
    ...(autoGrow ? {} : { height: 40 }),
    fontSize: 16,
    fontWeight: "500",
    borderRadius: 8,
    borderColor: "var(--Gray400) !important",
    outline: "none",
    boxShadow: "none",
    ...(theme === "alt" ? altStyles : {}),
    ...(theme === "auth" ? authStyles : {})
  }),
  menu: provided => ({
    ...provided,
    ...(theme === "alt" ? altStyles : {}),
    ...(theme === "auth" ? authStyles : {})
  }),
  menuList: provided => ({
    ...provided,
    maxHeight: 160,
    ...(theme === "alt" ? altStyles : {}),
    ...(theme === "auth" ? authStyles : {})
  }),
  placeholder: provided => ({
    ...provided,
    color: "#ccc",
    fontWeight: "300"
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: "none"
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: "var(--Gray600)",
    paddingRight: 16
  }),
  option: (provided, { data }) => ({
    ...provided,
    display: "block",
    paddingTop: 16,
    paddingBottom: 16,
    ...(data?.media ? emblem(data.media) : {})
  }),
  // multiValue: provided => ({
  //   ...provided,
  //   whiteSpace: "nowrap",
  //   overflowX: "scroll",
  //   minWidth: 0
  // }),
  multiValueRemove: (base, state) => {
    return state.data.isFixed ? { ...base, display: "none" } : base;
  },
  multiValueLabel: (base, state) => {
    return state.data.isFixed ? { ...base, paddingRight: 6 } : base;
  }
});

const theme = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: "var(--primary-color)",
    primary25: "#f0f0f0",
    primary50: "#e0e0e0"
  }
});

const Select2 = props => {
  return (
    <div className="select-component">
      <Label value={props.label} />
      <ReactSelect
        className={`nickel-select ${props.className || ""}`}
        theme={theme}
        styles={makeStyles(props)}
        closeMenuOnSelect={!props?.isMulti}
        {...props}
      />
    </div>
  );
};
export default Select2;
