import React from "react";
import Input from "../../../-common/Input";
import "./SubjectSlide.scss";

const SubjectSlide = ({ subject, setSubject, type, setType, typeOptions }) => {
  return (
    <div className="slide info-slide">
      <div className="headline">Let's get the word out</div>
      <div className="inputs">
        <Input value={subject} onChange={setSubject} label="Bulletin Subject" />
        {/* <RadioGroup
          id="plan-option"
          options={typeOptions}
          value={type}
          onChange={setType}
        /> */}
      </div>
    </div>
  );
};

export default SubjectSlide;
