import React from "react";

export default function football({
  size = 24,
  fill = "none",
  stroke = "#000",
  strokeWidth = 13
}) {
  return (
    <svg
      viewBox="0 0 355.796 355.796"
      height={size}
      width={size}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M349.296,177.898 l-4.088,5.053c-86.134,106.466-248.486,106.466-334.62,0L6.5,177.898l4.088-5.053c86.134-106.466,248.486-106.466,334.62,0 L349.296,177.898z" />
      <line x1="122.034" y1="158.208" x2="122.034" y2="197.588" />
      <line x1="158.937" y1="158.208" x2="158.937" y2="197.588" />
      <line x1="195.839" y1="158.208" x2="195.839" y2="197.588" />
      <line x1="232.742" y1="158.208" x2="232.742" y2="197.588" />
      <line x1="90.828" y1="177.898" x2="262.462" y2="177.898" />
      <line x1="51.216" y1="132.478" x2="51.216" y2="221.638" />
      <line x1="304.58" y1="132.478" x2="304.58" y2="221.638" />
    </svg>
  );
}
