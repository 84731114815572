import React from "react";

export default function tennisBall({
  size = 24,
  fill = "none",
  stroke = "#000",
  strokeWidth = 13
}) {
  return (
    <svg
      viewBox="0 0 255.222 255.222"
      height={size}
      width={size}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
    >
      <circle cx="127.611" cy="127.611" r="121.09" />
      <path d="M31.759,53.561 c-5.115,50.233,29.997,79.963,27.88,120.769c-0.981,18.888-5.193,34.599-9.605,46.289" />
      <path d="M235.768,73.139v0.011 c1.047,12.469-12.324,30.298-39.068,38.098c-54.515,15.9-75.773,49.03-76.888,86.917c-0.579,19.59,10.764,39.982,21.852,49.732" />
    </svg>
  );
}
