import React from "react";
import "./Spinner.scss";

export default ({ size = 64, color = "var(--White)" }) => {
  const circleHeight = 0.171875 * size;
  const circleStyles = { width: circleHeight, height: circleHeight };
  const firstStyles = { ...circleStyles, left: 0.09375 * size, background: color };
  const secondStyles = { ...circleStyles, left: 0.09375 * size, background: color };
  const thirdStyles = { ...circleStyles, left: 0.40625 * size, background: color };
  const fourthStyles = { ...circleStyles, left: 0.703125 * size, background: color };
  return (
    <div className="lds-ellipsis" style={{ width: size, height: circleHeight }}>
      <div style={firstStyles} />
      <div style={secondStyles} />
      <div style={thirdStyles} />
      <div style={fourthStyles} />
    </div>
  );
};
