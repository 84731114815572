import React from 'react';
import { observer } from 'mobx-react';
import ReactPlayer from 'react-player';
import Label from '../../../-common/Label';
import {
  AlertIcon,
  VideoIcon,
  NewsIcon,
  StarIcon,
} from '../../../-common/Icons';
import { isVideoLink } from '../../../../utils';
import { bulletinsTypes } from '../../../../constants/bulletins';
import './BulletinPreview.scss';

const BulletinPreview = observer(({ subject, message, media, type }) => {
  const isVideoMedia = isVideoLink(media);
  const mediaPreview = isVideoMedia ? (
    <div className="media-preview video">
      <ReactPlayer
        url={media}
        height="100%"
        width="100%"
        controls
      />
    </div>
  ) : (
    <img
      className="preview-image"
      src={media}
      alt="bulletin-preview"
    />
  );

  return (
    <div className="bulletin-preview-container">
      <Label value="Message Item Preview" />
      <div className="preview-box spaced">
        <div className="message-item-preview" style={media ? { backgroundColor: "var(--White)" } : {}}>
          <div className="message-item-image">
            {type === bulletinsTypes.VIDEO && (
              <VideoIcon strokeWidth={2} />
            )}
            {type === bulletinsTypes.NEWS && (
              <NewsIcon strokeWidth={2} />
            )}
            {type === bulletinsTypes.SPECIAL && (
              <StarIcon />
            )}
            {type === bulletinsTypes.ALERT && (
              <AlertIcon strokeWidth={2} />
            )}
          </div>
          <div className="message-item-text">
            <div className="message-item-title">{subject}</div>
            <div className="message-item-body" style={{ color: message ? null : "var(--Gray300)" }}>
              {message || "(no message yet)"}
            </div>
          </div>
        </div>
      </div>
      <Label value="Detail Page Preview" />
      <div className="preview-box">
        {!!media && <div className="bulletin-image message-item">{mediaPreview}</div>}
        <div className="bulletin-campaign-name">{subject}</div>
        <div className="bulletin-text message-item" style={{ color: message ? null : "var(--Gray300)" }}>
          {message || "(no message yet)"}
        </div>
      </div>
    </div>
  );
});

export default BulletinPreview;
