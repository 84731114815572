import { observable, computed, action, autorun, toJS } from "mobx";
import { request } from "../utils";
import AuthStore from "./AuthStore";
import { toast } from "react-toastify";
import PerksStore from "./PerksStore";

export const categories = [
  { value: "Points-Based", label: "Points-Based" },
  { value: "Time-Based", label: "Time-Based" },
  { value: "Amount-Based", label: "Amount-Based" },
];

const searchLevels = (searchText) => (a) => {
  const search = searchText.toLowerCase();
  const matchesTitle = a.level.toLowerCase().includes(search);
  return matchesTitle;
};

export const filterOptions = [{ value: "All", label: "All" }].concat(categories);

const filterLevels = (filter) => (a) => {
  if (filter.value === "All") return true;
  return a.category === filter.value;
};

export const sortOptions = [
  { label: "Lowest Threshold", value: "Lowest Threshold" },
  { label: "Highest Threshold", value: "Highest Threshold" },
  { label: "A to Z", value: "A to Z" },
  { label: "Z to A", value: "Z to A" },
];

const sortLevels = (sort) => (a1, a2) => {
  const title1 = a1.level.toUpperCase();
  const title2 = a2.level.toUpperCase();
  if (sort.value === "A to Z") {
    return title1 < title2 ? -1 : title1 > title2 ? 1 : 0;
  } else if (sort.value === "Z to A") {
    return title1 > title2 ? -1 : title1 < title2 ? 1 : 0;
  } else if (sort.value === "Highest Threshold") {
    if (a1.pointThreshold === a2.pointThreshold) {
      return title1 < title2 ? -1 : title1 > title2 ? 1 : 0;
    } else {
      return a2.pointThreshold - a1.pointThreshold;
    }
  } else if (sort.value === "Lowest Threshold") {
    if (a1.pointThreshold === a2.pointThreshold) {
      return title1 < title2 ? -1 : title1 > title2 ? 1 : 0;
    } else {
      return a1.pointThreshold - a2.pointThreshold;
    }
  }
};

class MembershipsStore {
  constructor() {
    autorun(() => {
      if (AuthStore.APIReady) {
        this.fetchMemberships();
      } else {
        this.clear();
      }
    });
  }

  // CRUD
  @observable rawMemberships = [];

  @computed get memberships() {
    return this.rawMemberships
      .filter(filterLevels(this.filter))
      .filter(searchLevels(this.search))
      .sort(sortLevels(this.sort));
  }

  @computed get membershipsIncludingPublic() {
    return [
      ...this.memberships,
      {
        level: "Public",
        membershipId: "",
      },
    ].sort(sortLevels(this.sort));
  }

  @action async fetchMemberships() {
    try {
      const memberships = await request.get("/v1/memberships");
      this.rawMemberships = memberships;
      return memberships;
    } catch (err) {
      console.warn(err);
    }
  }

  @action async addMembership(membershipObject) {
    membershipObject.sortIndex = this.rawMemberships.length;
    try {
      this.rawMemberships = this.rawMemberships.concat({
        ...membershipObject,
        membershipId: "optimistic",
      });

      const newMembership = await request.post("/v1/memberships", {
        body: membershipObject,
      });
      const { membershipId } = newMembership;

      this.rawMemberships = this.rawMemberships.map((m) => {
        if (m.membershipId === membershipId) return newMembership;
        if (m.membershipId === "optimistic") return newMembership;
        return m;
      });
      toast("Membership level added.");
      return newMembership;
    } catch (err) {
      this.rawMemberships = this.rawMemberships.filter((m) => m.membershipId !== "optimistic");
      toast("Error adding membership level.");
      console.warn(err);
    }
  }

  @action async updateMembership(membershipObject) {
    try {
      const { membershipId } = membershipObject;
      const updatedMembership = await request.put(`/v1/memberships/${membershipId}`, { body: membershipObject });
      this.rawMemberships = this.rawMemberships.map((m) => {
        if (m.membershipId === membershipId) return updatedMembership;
        return m;
      });
      return updatedMembership;
    } catch (err) {
      console.warn(err);
    }
  }

  @action async deleteMembership(membershipId) {
    try {
      await request.delete(`/v1/memberships/${membershipId}`);
      this.rawMemberships = this.rawMemberships.filter((m) => m.membershipId !== membershipId);
      toast("Membership level removed.");
    } catch (err) {
      toast("Error removing embership level.");
      console.warn(err);
    }
  }

  @action async addPerkToMembership({ perk, membershipId, details, existing }) {
    try {
      this.rawMemberships = this.rawMemberships.map((m) => {
        if (m.membershipId === membershipId) {
          return {
            ...m,
            perks: (m.perks || []).concat({
              ...perk,
              details,
              perkId: "optimistic",
            }),
          };
        }
        return m;
      });

      let newPerk = perk;
      if (!existing) newPerk = await PerksStore.addPerk(perk);
      const newMembership = await request.post(`/v1/memberships/${membershipId}/perks/${newPerk.perkId}`, {
        body: { details },
      });

      this.rawMemberships = this.rawMemberships.map((m) => {
        if (m.membershipId === newMembership.membershipId) {
          return newMembership;
        }
        return m;
      });
      toast("Perk added to membership level.");
      return newMembership;
    } catch (err) {
      this.rawMemberships = this.rawMemberships.map((m) => {
        if (m.membershipId === membershipId) {
          return {
            ...m,
            perks: (m.perks || []).filter((p) => p.perkId !== "optimistic"),
          };
        }
        return m;
      });
      toast("Error adding perk to membership level.");
      console.warn(err);
    }
  }

  @action async editPerkOnMembership(membershipId, perkId, details) {
    try {
      const newMembership = await request.put(`/v1/memberships/${membershipId}/perks/${perkId}`, {
        body: { details },
      });
      this.rawMemberships = this.rawMemberships.map((m) => {
        if (m.membershipId === newMembership.membershipId) {
          return newMembership;
        }
        return m;
      });
      toast("Perk has been edited");
      return newMembership;
    } catch (err) {
      toast("Error adding perk to membership level.");
      console.warn(err);
    }
  }

  @action async removePerkFromMembership(perkId, membershipId) {
    const preMemberships = toJS(this.rawMemberships, {
      recurseEverything: true,
    });
    try {
      this.rawMemberships = this.rawMemberships.map((m) => {
        if (m.membershipId === membershipId) {
          return {
            ...m,
            perks: (m.perks || []).filter((p) => p.perkId !== perkId),
          };
        }
        return m;
      });

      await request.delete(`/v1/memberships/${membershipId}/perks/${perkId}`);
      toast("Perk removed from membership level.");
    } catch (err) {
      this.rawMemberships = preMemberships;
      toast("Error removing perk from membership level.");
      console.warn(err);
    }
  }

  // ADD MODAL
  @observable showNewMembershipModal = false;

  @action openNewMembershipModal = () => (this.showNewMembershipModal = true);

  @action closeNewMembershipModal = () => (this.showNewMembershipModal = false);

  // SEARCH
  @observable search = "";

  @action setSearch = (search) => (this.search = search);

  // FILTER AND SORT
  @observable filter = filterOptions[0];

  @action setFilter = (filter) => (this.filter = filter);

  @observable sort = sortOptions[0];

  @action setSort = (sort) => (this.sort = sort);

  // CLEANUP
  @action clear() {
    this.rawMemberships = [];
  }
}

export default new MembershipsStore();
