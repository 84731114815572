import React, { useState } from "react";
import { observer } from "mobx-react";
import { toast } from "react-toastify";
import { MdCheck, MdClose } from "react-icons/md";
import { getFileURLFromFile } from "../../../utils";
import uploadRenewalImages from "../../../services/RenewalMediaService";
import TenantStore from "../../../stores/TenantStore";
import Page from "../../-common/Page";
import Button from "../../-common/Button";
import FileUpload from "../../-common/FileUpload";
import TabBar from "../../-common/TabBar";
import Input from "../../-common/Input";
import Textarea from "../../-common/Textarea";
import "./RenewalFlow.scss";

const titleOptions = { title: "Renewal Flow" };
const tabs = [
  { id: "90 Days Out", title: "90 Days Out" },
  { id: "60 Days Out", title: "60 Days Out" },
  { id: "30 Days Out", title: "30 Days Out" }
];

const getContentChanged = (appConfig, localAppConfig) => {
  const { "90": subject90, "60": subject60, "30": subject30 } = localAppConfig?.renewal?.content?.subject || {};
  const { "90": preSubject90, "60": preSubject60, "30": preSubject30 } = appConfig?.renewal?.content?.subject || {};
  const { "90": message90, "60": message60, "30": message30 } = localAppConfig?.renewal?.content?.message || {};
  const { "90": preMessage90, "60": preMessage60, "30": preMessage30 } = appConfig?.renewal?.content?.message || {};
  const contentChanged =
    subject90 !== preSubject90 ||
    message90 !== preMessage90 ||
    subject60 !== preSubject60 ||
    message60 !== preMessage60 ||
    subject30 !== preSubject30 ||
    message30 !== preMessage30;
  return contentChanged;
};

const DaysOutTab = ({ media, onMediaUpload, subject, setSubject, message, setMessage }) => {
  return (
    <div className="days-out-body">
      <div className="days-out-col">
        <div className="days-out-col-title">Media</div>
        <FileUpload accept="image/png, image/jpeg" fileURL={media} onUpload={onMediaUpload} />
      </div>
      <div className="days-out-col">
        <div className="days-out-col-title">Content</div>
        <Input value={subject} onChange={setSubject} label="Subject" />
        <Textarea value={message} onChange={setMessage} label="Message" />
      </div>
    </div>
  );
};

function RenewalFlow() {
  const { appConfig } = TenantStore?.tenant || {};

  const [saving, setSaving] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const [localAppConfig, setLocalAppConfig] = useState(appConfig);
  const [localMedia, setLocalMedia] = useState({});

  const setLocalMediaForDays = days => async file => {
    const preview = await getFileURLFromFile(file);
    setLocalMedia({
      ...(localMedia || {}),
      [String(days)]: { file, preview }
    });
  };

  const setSubjectForDays = days => subject => {
    setLocalAppConfig({
      ...(localAppConfig || {}),
      renewal: {
        ...(localAppConfig?.renewal || {}),
        content: {
          ...(localAppConfig?.renewal?.content || {}),
          subject: {
            ...(localAppConfig?.renewal?.content?.subject || {}),
            [String(days)]: subject
          }
        }
      }
    });
  };

  const setMessageForDays = days => message => {
    setLocalAppConfig({
      ...(localAppConfig || {}),
      renewal: {
        ...(localAppConfig?.renewal || {}),
        content: {
          ...(localAppConfig?.renewal?.content || {}),
          message: {
            ...(localAppConfig?.renewal?.content?.message || {}),
            [String(days)]: message
          }
        }
      }
    });
  };

  const cancel = () => {
    setLocalAppConfig(appConfig);
    setLocalMedia({});
  };

  const contentChanged = getContentChanged(appConfig, localAppConfig);
  const saveEnabled = Object.keys(localMedia)?.length || contentChanged;

  const save = async () => {
    setSaving(true);

    const { "90": { file: media90 } = {}, "60": { file: media60 } = {}, "30": { file: media30 } = {} } =
      localMedia || {};
    let tenantUpdates;
    if (media90 || media60 || media30) {
      const { updatedTenant } = await uploadRenewalImages({ media90, media60, media30 });
      tenantUpdates = updatedTenant;
      if (!!updatedTenant) toast("Renewal flow media updated.");
      else toast("Error updating renewal flow media.");

      setLocalMedia({});
    }

    if (contentChanged) {
      const updatedTenant = await TenantStore?.updateTenant({
        appConfig: {
          ...(appConfig || {}),
          renewal: {
            ...(appConfig?.renewal || {}),
            ...(tenantUpdates?.appConfig?.renewal || {}),
            content: localAppConfig?.renewal?.content || {}
          }
        }
      });
      if (!!updatedTenant) toast("Renewal flow content updated.");
      else toast("Error updating renewal flow content.");
    }

    setSaving(false);
  };

  const conditionalButtons = saveEnabled
    ? [
        <Button text="Cancel" icon={MdClose} theme="light" onClick={cancel} disabled={saving} key="cancel" />,
        <Button
          text={saving ? "Saving..." : "Save"}
          theme={saving ? "light" : null}
          icon={MdCheck}
          onClick={save}
          disabled={saving}
          key="save"
        />
      ]
    : null;

  const currentTab = tabs[selectedTab];
  const currentDays = currentTab?.id?.replace(" Days Out", "");
  const media = localMedia?.[currentDays]?.preview || appConfig?.renewal?.media?.[currentDays];
  const onMediaUpload = setLocalMediaForDays(currentDays);
  const subject = localAppConfig?.renewal?.content?.subject?.[currentDays];
  const setSubject = setSubjectForDays(currentDays);
  const message = localAppConfig?.renewal?.content?.message?.[currentDays];
  const setMessage = setMessageForDays(currentDays);

  return (
    <Page titleOpts={titleOptions} buttons={conditionalButtons}>
      <div className="renewal-flow-container">
        <TabBar tabs={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        <div className="tab-detail">
          <DaysOutTab {...{ media, onMediaUpload, subject, setSubject, message, setMessage }} />
        </div>
      </div>
    </Page>
  );
}

export default observer(RenewalFlow);
