import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import { parse } from "query-string";
import AuthStore from "../../../stores/AuthStore";
import NLogo from "../../-common/NLogo";
import "./Welcome.scss";

function cleanURLOfParams() {
  const { location } = window;
  const cleanUri = location.protocol + "//" + location.host + location.pathname;
  window.history.replaceState({}, document.title, cleanUri);
}

const Welcome = ({ location }) => {
  useEffect(() => {
    const { q } = parse(location?.search);
    let params;
    try {
      params = JSON.parse(atob(q));
      const { username, temp } = params;
      cleanURLOfParams();

      if (username && temp) {
        (async () => {
          await AuthStore.checkUsername(username);
          await AuthStore.signIn(temp, username);
        })();
      }
    } catch (err) {
      navigate("/auth");
    }
  }, [location]);

  return (
    <div className="welcome-container">
      <NLogo height={168} />
    </div>
  );
};

export default observer(Welcome);
