import React from "react";
import { Router, Redirect } from "@reach/router";
import Messages from "./Messages";
import "./MyHome.scss";

export default function MyHome() {
  return (
    <Router>
      <Messages path="messages/*" />
      <Redirect from="*" to="/home/messages" noThrow />
    </Router>
  );
}
