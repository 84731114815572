import baseball from "./baseball";
import basketball from "./basketball";
import football from "./football";
import golfClubs from "./golfClubs";
import hockeySticks from "./hockeySticks";
import skates from "./skates";
import tennisBall from "./tennisBall";
import volleyballNet from "./volleyballNet";
import baseballBat from "./baseballBat";
import beam from "./beam";
import footballHelmet from "./footballHelmet";
import golfHole from "./golfHole";
import kayak from "./kayak";
import soccerBall from "./soccerBall";
import tennisRacquet from "./tennisRacquet";
import waterPolo from "./waterPolo";
import baseballHelmet from "./baseballHelmet";
import cleats from "./cleats";
import golfBag from "./golfBag";
import hockeyPuck from "./hockeyPuck";
import runningShoes from "./runningShoes";
import swimmingGoggles from "./swimmingGoggles";
import volleyball from "./volleyball";

const sportIcons = {
  cleats,
  football,
  footballHelmet,
  basketball,
  soccerBall,
  baseball,
  baseballBat,
  baseballHelmet,
  tennisRacquet,
  tennisBall,
  volleyball,
  volleyballNet,
  beam,
  skates,
  hockeySticks,
  hockeyPuck,
  golfBag,
  golfClubs,
  golfHole,
  kayak,
  swimmingGoggles,
  waterPolo,
  runningShoes
};

export default sportIcons;
