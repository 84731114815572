import React, { useEffect } from "react";
import Input from "../../../-common/Input";
import Select from "../../../-common/Select2";
import sportIcons from "../../../-common/sportIcons";
import Label from "../../../-common/Label";
import "./InfoSlide.scss";

const InfoSlide = ({ media, setMedia, name, setName, owner, setOwner, options }) => {
  const onChange = selectedOwnerObject => {
    setOwner(selectedOwnerObject);
  };

  const iconNames = Object.keys(sportIcons);
  useEffect(() => {
    if (!media) setMedia(iconNames[0]);
  }, [media, setMedia, iconNames]);
  const selectIcon = icon => () => setMedia(icon);

  const icons = iconNames.map(icon => {
    const Component = sportIcons[icon];
    return (
      <div className={`icon-square${media === icon ? " selected" : ""}`} onClick={selectIcon(icon)} key={icon}>
        <Component size={32} />
      </div>
    );
  });

  const selectedOwner = options?.find(o => o.value === owner?.value) || {};

  return (
    <div className="slide info-slide">
      <div className="headline">Another team. Another legacy.</div>
      <Label value="Team Icon" />
      <div className="inputs">
        {/* <div className="team-photo-container">
          <FileUpload
            fileURL={fileURL}
            onUpload={onUpload}
            accept="image/png, image/jpeg"
            style={{ width: "50%" }}
          />
        </div> */}
        <div className="team-icons-container">{icons}</div>
        <Input value={name} onChange={setName} label="Team name" />
        <Select options={options} value={selectedOwner} onChange={onChange} label="Select the team owner" />
      </div>
    </div>
  );
};

export default InfoSlide;
