import abbrev from "number-abbreviate";
import commaSeparateNumber from "./commaSeparateNumber";

export default function abbreviateNumber(num, abbrevPlaces, limit = 100000) {
  if (isNaN(num)) return num;
  if (num < limit) return commaSeparateNumber(num); // Default per conversation with Dan on 3/22/2021

  const abbreviated = abbrev(Number(num).toFixed(2), abbrevPlaces);
  // eslint-disable-next-line no-unused-vars
  const [_, decimals] = String(abbreviated).split(".");
  if (decimals && decimals.length === 1) {
    return abbreviated + "0";
  } else {
    return abbreviated;
  }
}
