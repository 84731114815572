import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Link, Router } from "@reach/router";
import { FaFilter, FaSortAmountDown, FaMoneyBill, FaBolt, FaBullhorn, FaUsers, FaStar } from "react-icons/fa";
import { FiExternalLink } from "react-icons/fi";
import { abbreviateNumber } from "../../../utils";
import ClubMembersStore from "../../../stores/ClubMembersStore";
import GlobalSearchStore from "../../../stores/GlobalSearchStore";
import Page from "../../-common/Page";
import DropdownButton from "../../-common/DropdownButton";
import Button from "../../-common/Button";
import ClubMember from "../ClubMember";
import "./Explore.scss";

const SummaryNumber = ({ icon: Icon, value, title, indicator }) => {
  let indicatorClass = "indicator";
  if (indicator > 0) indicatorClass += " positive";
  else if (indicator < 0) indicatorClass += " negative";

  const indicatorComponent = indicator ? <div className={indicatorClass}>{indicator * 100}%</div> : null;
  return (
    <div className="summary-number">
      <div className="icon-wrapper">
        <Icon className="summary-icon" size={24} />
      </div>
      <div className="value">{value}</div>
      <div className="title">{title}</div>
      {indicatorComponent}
    </div>
  );
};

const SummaryNumbers = observer(() => {
  const { totalMembers, totalDonatedYTD, totalMembersThatLeveledUp, increasedAnnualDonation, totalInactiveMembers } =
    ClubMembersStore.clubStats || {};
  const summaryNumbers = [
    {
      icon: FaUsers,
      title: "Total Members",
      value: totalMembers ? abbreviateNumber(totalMembers, 1) : "-"
    },
    {
      icon: FaMoneyBill,
      title: "Donated by Members (YTD)",
      value: "$" + abbreviateNumber((totalDonatedYTD || 0).toFixed(2), 0)
    },
    {
      icon: FaBolt,
      title: "Leveled Up This Year",
      value: totalMembersThatLeveledUp ? abbreviateNumber(totalMembersThatLeveledUp, 1) : "-"
    },
    {
      icon: FaStar,
      title: "Increased Annual Donation",
      value: increasedAnnualDonation ? abbreviateNumber(increasedAnnualDonation, 1) : "-"
    },
    {
      icon: FaBullhorn,
      title: "Inactive",
      value: totalInactiveMembers ? abbreviateNumber(totalInactiveMembers, 1) : "-"
    }
  ];
  const summaryNumberComponents = summaryNumbers.map((props, i) => <SummaryNumber {...props} key={i} />);
  return <div className="summary-numbers">{summaryNumberComponents}</div>;
});

const filterOptions = [
  { label: "All", value: "All" },
  { label: "Club Member", value: "Club Member" },
  { label: "Bronze", value: "Bronze" },
  { label: "Silver", value: "Silver" },
  { label: "Gold", value: "Gold" },
  { label: "President's Circle", value: "President's Circle" }
];

const sortOptions = [
  { label: "Membership Level", value: "Membership Level" },
  { label: "Total Donated", value: "Total Donated" },
  { label: "A-Z", value: "A-Z" },
  { label: "Z-A", value: "Z-A" },
  { label: "Newest", value: "Newest" },
  { label: "Oldest", value: "Oldest" }
];

function ExploreInner() {
  const titleOptions = { title: "Explore" };

  useEffect(() => {
    GlobalSearchStore.setSearchAction(searchText => {
      ClubMembersStore.setSearch(searchText);
    });

    return () => ClubMembersStore.setSearch("");
  }, []);

  const [filter, setFilter] = useState(filterOptions[0]);
  const updateFilter = newFilter => setFilter(newFilter);
  const [sort, setSort] = useState(sortOptions[0]);
  const updateSort = newSort => setSort(newSort);

  const recentlyViewedLinks = ClubMembersStore?.recentlyViewed?.map(({ userId, profile }) => {
    return (
      <Link className="explore-body-link" to={`/club/explore/members/${userId}`} key={userId}>
        {profile?.firstName} {profile?.lastName}
      </Link>
    );
  });

  const recentlyViewedBody = recentlyViewedLinks?.length ? (
    recentlyViewedLinks
  ) : (
    <div className="explore-body-zero-state">No recently viewed members - search above to get started!</div>
  );

  const searchLinks = ClubMembersStore?.searchResults?.map(({ userId, profile }) => {
    return (
      <Link className="explore-body-link" to={`/club/explore/members/${userId}`} key={userId}>
        {profile?.fullName}
      </Link>
    );
  });

  const bodyTitle = searchLinks?.length ? "Search Results" : "Recently Viewed";
  const body = searchLinks?.length ? searchLinks : recentlyViewedBody;

  return (
    <Page
      titleOpts={titleOptions}
      buttons={[
        <DropdownButton
          label="Filter"
          icon={FaFilter}
          theme="light"
          options={filterOptions}
          style={{ height: 42 }}
          value={filter}
          onChange={updateFilter}
          key="filter"
        />,
        <DropdownButton
          label="Sort"
          icon={FaSortAmountDown}
          theme="light"
          options={sortOptions}
          style={{ height: 42 }}
          value={sort}
          onChange={updateSort}
          key="sort"
        />,
        <Button text="Export" icon={FiExternalLink} onClick={() => null} key="export" />
      ]}
    >
      <div className="clubhub-explore">
        <SummaryNumbers />
        <div className="explore-body">
          <div className="explore-body-title">{bodyTitle}</div>
          <div className="explore-body-results">{body}</div>
        </div>
      </div>
    </Page>
  );
}

const Explore = observer(ExploreInner);

function ExploreRouter() {
  return (
    <Router>
      <Explore path="/" />
      <ClubMember path="/members/:userId" />
    </Router>
  );
}

export default ExploreRouter;
