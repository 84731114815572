import React from "react";
import "./RadioButton.scss";

const RadioButton = ({ id, onChange, value, label, checked, disabled }) => {
  const handleOnChange = e => onChange(e.target.value);

  const disabledStyles = disabled
    ? {
        opacity: 0.3,
        pointerEvents: "none"
      }
    : null;

  return (
    <div className="radio-button" style={disabledStyles}>
      <input
        id={id}
        onChange={handleOnChange}
        value={value}
        type="radio"
        checked={checked}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export default RadioButton;
