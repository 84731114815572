import React from "react";
import "./InstructionsSlide.scss";

const InstructionsSlide = () => {
  return (
    <div className="slide instructions-slide">
      <div className="headline">What happens next?</div>
      <div className="inputs"></div>
    </div>
  );
};

export default InstructionsSlide;
