import React from "react";

export default function footballHelmet({
  size = 24,
  fill = "none",
  stroke = "#000",
  strokeWidth = 13
}) {
  return (
    <svg
      viewBox="0 0 379 379"
      height={size}
      width={size}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    >
      <path d="M52.231,312.346 c7.032,6.836,17.742,8.18,26.18,3.181l13.97-8.277c10.26-6.09,23.24-5.05,32.41,2.59c12.77,10.64,28.53,16.06,44.37,16.06 c12.78,0,25.61-3.53,36.95-10.69l7.72-4.87c28.61-18.06,36.2-56.44,16.6-84.02c-5.51-7.75-8.03-16.43-8.03-24.93 c0-20.01,14.01-39.06,36.09-42.79l34.499-5.835c3.241-0.548,5.1-3.976,3.829-7.007C273.995,91.337,220.215,53.101,157.502,53.1 C74.194,53.099,6.301,121.161,6.5,204.469C6.602,246.778,24.101,285,52.231,312.346z" />
      <path d="M166.45,263.34 c20.531,12.73,103.297,55.96,206.05-0.24" />
      <path d="M320.689,112.278 c-24.208,2.395-116.91,18.493-165.565,125.988" />
      <path d="M355.5,233.1 c0,0-27,21-76,27" />
      <path d="M211.5,158.1c0,0,68,58,70,126 c0.986,33.527,31,34,55,28s33-16,33-16l-25-55" />
      <circle cx="146.5" cy="260.1" r="19" />
    </svg>
  );
}
