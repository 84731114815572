import React, { useState, useEffect } from "react";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import { format } from "date-fns";
import { FaFilter, FaSortAmountDown } from "react-icons/fa";
import { MdCheckCircle, MdUndo } from "react-icons/md";
import commaNumber from "comma-number";
import PayoutsStore from "../../../stores/PayoutsStore";
import GlobalSearchStore from "../../../stores/GlobalSearchStore";
import Page from "../../-common/Page";
import Button from "../../-common/Button";
import DropdownButton from "../../-common/DropdownButton";
import PayoutTransactionsTable from "./PayoutTransactionsTable";
import "./Payout.scss";

const searchTransactions = searchText => t => {
  const search = searchText.toLowerCase();
  const date = new Date(t.date || t.refundDate || t.feeRefundDate);
  const matchesDate = date ? format(date, "M/d/yyyy").includes(search) : false;
  const matchesName = t?.userMetadata?.name ? t?.userMetadata?.name?.toLowerCase()?.includes(search) : false;

  let matchesGross,
    matchesProcessing,
    matchesTotal,
    matchesRefund = false;
  if (t?.refundId) {
    matchesRefund =
      String(t?.refundAmount).includes(search) || ("$" + commaNumber(t?.refundAmount, 2)).includes(search);
  } else {
    matchesGross =
      String(t?.amountMetadata.totalDonationAmount).includes(search) ||
      ("$" + commaNumber(t?.amountMetadata.totalDonationAmount, 2)).includes(search);
    matchesProcessing =
      String(t?.amountMetadata.totalFees).includes(search) ||
      ("$" + commaNumber(t?.amountMetadata.totalFees, 2)).includes(search);
    matchesTotal =
      String(t?.amountMetadata.netRevenue).includes(search) ||
      ("$" + commaNumber(t?.amountMetadata.netRevenue, 2)).includes(search);
  }

  return matchesDate || matchesName || matchesGross || matchesProcessing || matchesTotal || matchesRefund;
};

const filterOptions = [
  { label: "All", value: "All" },
  { label: "Campaign Donations", value: "Campaign Donations" },
  { label: "Membership Renewals", value: "Membership Renewals" }
];

const filterTransactions = filter => t => {
  if (filter.value === "Campaign Donations") {
    return t?.type === "donation";
  } else if (filter.value === "Membership Renewals") {
    return t?.type === "renewal";
  } else {
    return true;
  }
};

const sortOptions = [
  { label: "Newest", value: "Newest" },
  { label: "Oldest", value: "Oldest" },
  { label: "Amount High to Low", value: "Amount High to Low" },
  { label: "Amount Low to High", value: "Amount Low to High" }
];

const sortTransactions = sort => (t1, t2) => {
  const t1date = t1.date || t1.refundDate || t1.feeRefundDate;
  const t2date = t2.date || t2.refundDate || t2.feeRefundDate;
  const t1revenue = t1.revenue || t1.refundAmount || t1.feeRefundAmount;
  const t2revenue = t2.revenue || t2.refundAmount || t2.feeRefundAmount;
  if (sort.value === "Newest") {
    return new Date(t2date).valueOf() - new Date(t1date).valueOf();
  } else if (sort.value === "Oldest") {
    return new Date(t1date).valueOf() - new Date(t2date).valueOf();
  } else if (sort.value === "Amount High to Low") {
    return t1revenue > t2revenue ? -1 : t1revenue < t2revenue ? 1 : 0;
  } else if (sort.value === "Amount Low to High") {
    return t1revenue < t2revenue ? -1 : t1revenue > t2revenue ? 1 : 0;
  }
};

function PayoutDetails({
  totalTransactions,
  totalAmountProcessed,
  totalRefunds,
  totalAmountRefunded,
  totalNickelFeeRefundsAmount,
  totalFees,
  netRevenue
}) {
  const total = "$" + commaNumber(Number(totalAmountProcessed / 100).toFixed(2));

  let pFee = "$" + commaNumber(Number(totalFees / 100).toFixed(2));
  if (totalFees > 0) pFee = "-" + pFee;

  let rev = "$" + commaNumber(Number(Math.abs(netRevenue) / 100).toFixed(2));
  if (netRevenue < 0) rev = "-" + rev;

  let refAmount = "$" + commaNumber(Number(totalAmountRefunded / 100).toFixed(2));
  if (totalAmountRefunded > 0) refAmount = "-" + refAmount;

  const feeRefAmount = "$" + commaNumber(Number(totalNickelFeeRefundsAmount / 100).toFixed(2));

  return (
    <div className="payout-details">
      {/* <div className="payout-detail-icon-container">
        <BillIcon size={184} color="var(--primary-color)" />
      </div> */}
      <div className="detail-item bold">
        <div className="detail-item-title">Deposit Total</div>
        <div className={`detail-item-value${netRevenue < 0 ? " negative" : ""}`}>{rev}</div>
      </div>

      <div className="detail-item">
        <div className="detail-item-title">Transaction Amount</div>
        <div className="detail-item-value">{total}</div>
      </div>
      <div className="detail-item">
        <div className="detail-item-title">Commission & Processing</div>
        <div className={`detail-item-value${totalFees > 0 ? " negative" : ""}`}>{pFee}</div>
      </div>
      <div className="detail-item">
        <div className="detail-item-title">Total Transactions</div>
        <div className="detail-item-value">{totalTransactions}</div>
      </div>
      <div className="detail-item">
        <div className="detail-item-title">Total Refunds</div>
        <div className="detail-item-value">{totalRefunds}</div>
      </div>
      <div className="detail-item">
        <div className="detail-item-title">Total Refund Amount</div>
        <div className={`detail-item-value${totalAmountRefunded > 0 ? " negative" : ""}`}>{refAmount}</div>
      </div>
      <div className="detail-item">
        <div className="detail-item-title">Refunded Commission</div>
        <div className="detail-item-value">{feeRefAmount}</div>
      </div>
    </div>
  );
}

function Payout({ payoutId }) {
  const { payouts } = PayoutsStore;
  const [payout, setPayout] = useState({});

  useEffect(() => {
    const matchingPayout = payouts.find(p => p.payoutId === payoutId);
    if (matchingPayout) setPayout(toJS(matchingPayout, { recurseEverything: true }));
    else if (payouts.length) navigate("/audit/payouts");
  }, [payouts, payoutId]);

  const [filter, setFilter] = useState(filterOptions[0]);
  const [sort, setSort] = useState(sortOptions[0]);

  const filteredSortedTransactions = payout?.transactions
    ?.filter(searchTransactions(GlobalSearchStore.searchText))
    ?.filter(filterTransactions(filter))
    ?.sort(sortTransactions(sort));

  const content = payout.payoutId ? (
    <div className="payout">
      <PayoutDetails {...payout} />
      <PayoutTransactionsTable data={filteredSortedTransactions} payout={payout} />
    </div>
  ) : null;

  const reconcile = () => PayoutsStore.markPayoutReconciled(payoutId);
  const unreconcile = () => PayoutsStore.markPayoutUnreconciled(payoutId);

  const actionButton = payout.reconciled ? (
    <Button text="Undo Reconciliation" icon={MdUndo} onClick={unreconcile} key="unreconcile" />
  ) : (
    <Button text="Mark as Reconciled" icon={MdCheckCircle} onClick={reconcile} key="reconcile" />
  );

  return (
    <Page
      titleOpts={{
        title: "Payout",
        backButton: true
      }}
      buttons={[
        <DropdownButton
          label="Filter"
          icon={FaFilter}
          theme="light"
          options={filterOptions}
          style={{ height: 42 }}
          value={filter}
          onChange={setFilter}
          key="filter"
        />,
        <DropdownButton
          label="Sort"
          icon={FaSortAmountDown}
          theme="light"
          options={sortOptions}
          style={{ height: 42 }}
          value={sort}
          onChange={setSort}
          key="sort"
        />,
        actionButton
      ]}
    >
      {content}
    </Page>
  );
}

export default observer(Payout);
