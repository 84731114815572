import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { toast } from 'react-toastify';
import {
  MdCheck,
  MdClose,
} from 'react-icons/md';

import TenantStore from '../../../stores/TenantStore';
import Page from '../../-common/Page';
import Button from '../../-common/Button';
import Input from '../../-common/Input';
import { isEmailAddress } from '../../../utils';

const titleOptions = { title: 'Contact Details' };

function ContactDetails() {
  const { appConfig } = TenantStore?.tenant || {};

  const [saving, setSaving] = useState(false);
  const [localEmailAddress, setLocalEmailAddress] = useState('');
  const [localPhoneNumber, setLocalPhoneNumber] = useState('');

  const clear = () => {
    setLocalEmailAddress('');
    setLocalPhoneNumber('');
  };

  const emailAddress = localEmailAddress || appConfig?.contactDetails?.emailAddress;
  const phoneNumber = localPhoneNumber || appConfig?.contactDetails?.phoneNumber;
  const saveEnabled = isEmailAddress(localEmailAddress) || localPhoneNumber;

  const save = async () => {
    setSaving(true);
    
    await TenantStore.updateTenant({
      appConfig: {
        contactDetails: {
          emailAddress,
          phoneNumber,
        },
      },
    })
      .then(() => {
        toast('Contact Details updated.');
        clear();
      })
      .catch(() => {
        toast.error('Error updating Contact Details.');
      });

    setSaving(false);
  };

  const conditionalButtons = saveEnabled
    ? [
        <Button
          text="Cancel"
          theme="light"
          icon={MdClose}
          onClick={clear}
          disabled={saving}
          key="cancel"
        />,
        <Button
          text={saving ? 'Saving...' : 'Save'}
          theme={saving ? 'light' : null}
          icon={MdCheck}
          onClick={save}
          disabled={saving}
          key="save"
        />,
      ]
    : null;

  return (
    <Page
      titleOpts={titleOptions}
      buttons={conditionalButtons}
    >
      <div className="club-hub-container">
        <div className="theme-item">
          <div className="theme-item-titles">
            <div className="theme-item-title">Club Email</div>
            <div className="theme-item-subtitle">
              This email address will be displayed to donors in the mobile app as a support contact.
            </div>
          </div>
          <div className="theme-item-content theme-item-content--extended">
            <Input
              value={emailAddress}
              onChange={setLocalEmailAddress}
              placeholder="contact-email@example.com" 
            />
          </div>
        </div>
        <div className="theme-item">
          <div className="theme-item-titles">
            <div className="theme-item-title">Club Phone Number</div>
            <div className="theme-item-subtitle">
              This phone number will be displayed to donors in the mobile app as a support contact.
            </div>
          </div>
          <div className="theme-item-content theme-item-content--extended">
            <Input
              value={phoneNumber}
              onChange={setLocalPhoneNumber}
              placeholder="555-555-5555" 
            />
          </div>
        </div>
      </div>
    </Page>
  );
}

export default observer(ContactDetails);
