import React, { useRef, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { format } from "date-fns";
import { FaRegSmile } from "react-icons/fa";
// import { FiPaperclip } from "react-icons/fi";
import { MdSend } from "react-icons/md";
import { Picker } from "emoji-mart";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import MessagesStore from "../../../../stores/MessagesStore";
import "emoji-mart/css/emoji-mart.css";
import "./Messages.scss";

const Message = observer(({ createdDate, message, fromDonor, user, officer, receipt, last }) => {
  const src = !fromDonor ? officer?.profile?.picture : user?.media;
  const profilePicture = src ? <img src={src} alt="User profile avatar." className="profile-image" /> : null;

  const now = new Date();
  const isToday = now.valueOf() - createdDate.valueOf() > 86400000;
  const isThisYear = now.getFullYear() === createdDate.getFullYear();
  const dateTimeMessage = isToday
    ? `on ${format(createdDate, `MMM do${isThisYear ? "" : ", yyyy"}`)}`
    : `at ${format(createdDate, "h:mm a")}`;

  const name = fromDonor ? user?.fullName : officer?.profile?.firstName + " " + officer?.profile?.lastName;

  const readMessage = !!receipt
    ? isToday
      ? `Read on ${format(createdDate, `MMM do${isThisYear ? "" : ", yyyy"}`)}`
      : `Read at ${format(createdDate, "h:mm a")}`
    : "Delivered";

  const readIndicator = !fromDonor ? <div className="read-indicator">{readMessage}</div> : null;

  return (
    <div className={`message-wrapper${!fromDonor ? " mine" : ""}${last ? " last" : ""}`}>
      <div className="profile-picture-wrapper">{profilePicture}</div>
      <div className="message-info-wrapper">
        <div className="message-info-row">
          <span className="user-name">{name}</span> {dateTimeMessage}
        </div>
        <div className="message-contents">{message}</div>
        {readIndicator}
      </div>
    </div>
  );
});

function Messages({ clubMember }) {
  const { userId } = clubMember || {};
  useEffect(() => {
    MessagesStore.markMessagesReadForMemberThread(userId);
  }, [userId]);

  const messagesContainerRef = useRef();
  const scrollMessagesToBottom = () => {
    const { current = {} } = messagesContainerRef || {};
    current.scrollTop = current?.scrollHeight;
  };

  useEffect(() => {
    scrollMessagesToBottom();
  }, []);

  const totalMessages = MessagesStore?.threads?.[userId]?.length;
  useEffect(() => {
    scrollMessagesToBottom();
  }, [totalMessages]);

  const [message, setMessage] = useState("");
  const updateMessage = ({ target }) => setMessage(target.value);

  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
  const openEmojiPicker = () => setEmojiPickerOpen(true);
  const addEmoji = emoji => {
    setMessage(message + emoji?.native);
    setEmojiPickerOpen(false);
  };

  const sendMessage = async () => {
    const trimmedMessage = message.trim();
    if (trimmedMessage) {
      setMessage("");
      await MessagesStore.createMessage(userId, trimmedMessage);
    }
  };

  const messageItems = (MessagesStore?.threads?.[userId] || []).map((m, i) => (
    <Message {...m} user={clubMember} key={i} />
  ));

  const content = messageItems?.length ? (
    messageItems
  ) : (
    <div className="zero-state-wrapper">
      <div className="zero-state-message">No message history - say something to get the conversation going!</div>
    </div>
  );

  const emojiPickerRef = useRef();
  useOnClickOutside(emojiPickerRef, () => setEmojiPickerOpen(false));
  const emojiPicker = emojiPickerOpen ? (
    <div ref={emojiPickerRef} className="emoji-picker-wrapper" onClick={e => e.stopPropagation()}>
      <Picker onSelect={addEmoji} title="Pick your emoji" emoji="point_up" />
    </div>
  ) : null;

  return (
    <div className="messages">
      <div className="messages-container" ref={messagesContainerRef}>
        {content}
      </div>
      <div className="message-input-bar">
        <div className={`emoji icon-wrapper${emojiPickerOpen ? " open" : ""}`} onClick={openEmojiPicker}>
          <FaRegSmile size={22} color="var(--Gray600)" />
          {emojiPicker}
        </div>
        <input
          className="message-input"
          placeholder="Type something..."
          value={message || ""}
          onChange={updateMessage}
        />
        {/* <div className="attachment icon-wrapper">
          <FiPaperclip size={20} color="var(--Gray600)" strokeWidth="1.25" />
        </div> */}
        <div className="send icon-wrapper" onClick={sendMessage}>
          <MdSend size={24} color="var(--White)" />
        </div>
      </div>
    </div>
  );
}

export default observer(Messages);
