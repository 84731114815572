import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import { format } from "date-fns";
import { Table } from "ka-table";
import { DataType } from "ka-table/enums";
import { FiCircle, FiCheckCircle } from "react-icons/fi";
import commaNumber from "comma-number";
import getFieldForTable from "../../../../utils/getFieldForTable";
import PayoutsStore from "../../../../stores/PayoutsStore";
import GlobalSearchStore from "../../../../stores/GlobalSearchStore";
import "./PayoutsTable.scss";

const ReconciledCell = ({ rowData }) => {
  const { payoutId, reconciled, reconciledByUser } = rowData;

  const goToPayout = () => {
    navigate(`/audit/payouts/${payoutId}`);
  };

  const markReconciled = e => {
    e.stopPropagation();
    PayoutsStore.markPayoutReconciled(payoutId, true);
  };

  const markUnreconciled = e => {
    e.stopPropagation();
    PayoutsStore.markPayoutUnreconciled(payoutId, true);
  };

  const icon = reconciled ? (
    <FiCheckCircle className="reconcile-icon" size={24} color="var(--Green)" onClick={markUnreconciled} />
  ) : (
    <FiCircle className="reconcile-icon" size={24} color="var(--Gray300)" onClick={markReconciled} />
  );
  const reconciledBy = reconciledByUser ? (
    <div className="reconciled-timestamp">
      by {reconciledByUser?.profile?.firstName + " " + reconciledByUser?.profile?.lastName}
    </div>
  ) : null;
  const timestamp = reconciled ? (
    <div className="reconciled-info">
      <div className="reconciled-timestamp">on {format(reconciled, "MMM do, yyyy")}</div>
      {reconciledBy}
    </div>
  ) : null;
  return (
    <div className="reconciled-cell" onClick={goToPayout}>
      {icon}
      {timestamp}
    </div>
  );
};

const AmountCell = ({ field, rowData, dispatch }) => {
  let amount = getFieldForTable(rowData, field);
  if (field === "totalAmountProcessed") amount -= rowData.totalAmountRefunded || 0;

  let displayAmount = "$" + commaNumber(Number(Math.abs(amount) / 100)?.toFixed(2));
  let negative = false;
  if ((["totalAmountProcessed", "netRevenue"].includes(field) && amount < 0) || (field === "totalFees" && amount > 0)) {
    displayAmount = "-" + displayAmount;
    negative = true;
  }

  return (
    <div className={`amount-cell${negative ? " negative" : ""}`} onClick={() => dispatch("Row Click", rowData)}>
      {displayAmount}
    </div>
  );
};

const columns = [
  {
    cell: ReconciledCell,
    dataType: DataType.Date,
    key: "reconciled",
    title: "Reconciled",
    style: { width: "15%" }
  },
  {
    dataType: DataType.Date,
    key: "date",
    format: d => format(d, "E MMM do, yyyy"),
    title: "Date"
  },
  {
    dataType: DataType.Number,
    key: "totalTransactions",
    title: "Total Transactions"
  },
  {
    cell: AmountCell,
    dataType: DataType.Number,
    key: "totalAmountProcessed",
    format: a => "$" + commaNumber(a, 1),
    title: "Total Amount Processed"
  },
  {
    cell: AmountCell,
    dataType: DataType.Number,
    key: "totalFees",
    format: a => "- $" + commaNumber(a, 1),
    title: "Processing Fee"
  },
  {
    cell: AmountCell,
    dataType: DataType.Number,
    key: "netRevenue",
    format: a => "- $" + commaNumber(a, 1),
    title: "Net",
    style: { width: "10%" }
  }
];

const childAttributes = {
  cell: {
    onClick: (_, e) => {
      const { dispatch, rowData } = e.childProps;
      dispatch("Row Click", rowData);
    }
  }
};

function PayoutsTable({ data }) {
  const onTableEvent = useCallback((type, data) => {
    if (type === "Row Click") {
      const rowData = data;
      const { payoutId } = rowData;
      navigate(`/audit/payouts/${payoutId}`);
    }
  }, []);

  return (
    <div className="payouts-table-container">
      <Table
        childAttributes={childAttributes}
        columns={columns}
        data={data}
        search={GlobalSearchStore.searchText}
        onEvent={onTableEvent}
        rowKeyField="payoutId"
      />
    </div>
  );
}

export default observer(PayoutsTable);
