import React from 'react';
import { Link } from '@reach/router';

import NickelLogo from '@/common/NickelLogo';
import BackgroundBlobs from '../BackgroundBlobs';
import AuthHeader from '../AuthHeader';

import './AuthLayout.scss';

const AuthLayout = ({
  children,
  withMenuHeader,
}) => (
  <div className="auth-container">
    <div className="auth-wrapper">
      <BackgroundBlobs />
      {withMenuHeader ?
        (
          <AuthHeader />
        ) :
        (
          <div className="auth-header">
            <Link to="/auth">
              <NickelLogo height={30} />
            </Link>
          </div>
        )}
      <div className="auth-content">{children}</div>
    </div>
  </div>
);

export default AuthLayout;
