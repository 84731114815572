import React from "react";
import { Link } from "@reach/router";
import { postAnalytic } from "../../services/AnalyticsService";

const SectionLink = ({ title, href, location }) => {
  let selected = false;
  if (location.pathname.includes("campaigns/manage/")) {
    selected = location.pathname === href;
  } else {
    selected = location.pathname.includes(href);
  }

  return (
    <Link className={`section-link${selected ? " selected" : ""}`} to={href} onClick={() => postAnalytic(href)}>
      {title}
    </Link>
  );
};

const Section = ({ title, links, location }) => {
  return (
    <div className="section">
      <div className="section-title">{title}</div>
      <div className="section-links">
        {links.map(link => (
          <SectionLink {...link} location={location} key={link.title} />
        ))}
      </div>
    </div>
  );
};

export default Section;
