import React from "react";

export default function hockeyPuck({
  size = 24,
  fill = "none",
  stroke = "#000",
  strokeWidth = 13
}) {
  return (
    <svg
      viewBox="0 0 254.393 254.393"
      height={size}
      width={size}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
    >
      <ellipse cx="127.197" cy="86.401" rx="120.697" ry="38.994" />
      <path d="M247.893,86.401v81.61 c-0.037,21.521-54.063,38.976-120.697,38.976c-33.312,0-63.486-4.364-85.323-11.42C20.037,188.511,6.519,178.771,6.5,168.011 v-81.61" />
      <line x1="25.858" y1="110.076" x2="25.858" y2="186.672" />
      <line x1="53.247" y1="117.968" x2="53.247" y2="197.349" />
      <line x1="84.814" y1="124.003" x2="84.814" y2="204.498" />
      <line x1="228.535" y1="110.076" x2="228.535" y2="186.672" />
      <line x1="201.147" y1="117.968" x2="201.147" y2="197.349" />
      <line x1="169.58" y1="124.003" x2="169.58" y2="204.498" />
      <line x1="127.057" y1="128.181" x2="127.057" y2="208.676" />
    </svg>
  );
}
