import React from "react";
import Page from "../../-common/Page";
import "./AppFeatures.scss";
import Checkbox from "../../-common/StandaloneCheckbox";
import TenantStore from "../../../stores/TenantStore";

export default function AppFeatures() {
  const titleOptions = { title: "Features" };

  const { showPriorityPoints, showAchievements, showOverallRanking, showLevelRanking } =
    TenantStore?.tenant?.appConfig || {};
  const toggleFeature = feature => async () => {
    await TenantStore.toggleAppFeatureEnabled(feature);
  };

  return (
    <Page titleOpts={titleOptions}>
      <div className="clubhub-app-features">
        <div className="feature-row" onClick={toggleFeature("showPriorityPoints")}>
          <div className="feature-label">Show Priority Points</div>
          <Checkbox isChecked={showPriorityPoints} />
        </div>
        <div className="feature-row" onClick={toggleFeature("showAchievements")}>
          <div className="feature-label">Show Achievements</div>
          <Checkbox isChecked={showAchievements} />
        </div>
        <div className="feature-row" onClick={toggleFeature("showOverallRanking")}>
          <div className="feature-label">Show Rank in Club</div>
          <Checkbox isChecked={showOverallRanking} />
        </div>
        <div className="feature-row" onClick={toggleFeature("showLevelRanking")}>
          <div className="feature-label">Show Rank in Level</div>
          <Checkbox isChecked={showLevelRanking} />
        </div>
      </div>
    </Page>
  );
}
