import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { toast } from "react-toastify";
import { MdCheck, MdClose } from "react-icons/md";
import { getFileURLFromFile } from "../../../utils";
import TenantStore from "../../../stores/TenantStore";
import Page from "../../-common/Page";
import Button from "../../-common/Button";
import FileUpload from "../../-common/FileUpload";
import ColorPickerButton from "../../-common/ColorPickerButton";
import "./CampaignsTheme.scss";

const titleOptions = { title: "Donation Page Theme" };

function CampaignsTheme() {
  const [localTheme, setLocalTheme] = useState();
  const [localLogo, setLocalLogo] = useState();
  const [localEmblem, setLocalEmblem] = useState();

  const { theme } = TenantStore?.tenant || {};
  useEffect(() => {
    if (theme) setLocalTheme(theme);
  }, [theme]);

  const [saving, setSaving] = useState(false);

  const themeUpdatedLocally = Object.keys(localTheme || {}).some(k => theme[k] !== localTheme[k]);
  const updatedLocally = themeUpdatedLocally || localLogo || localEmblem;

  const setPrimaryColor = primaryColor => setLocalTheme({ ...localTheme, primaryColor });

  const setSecondaryColor = secondaryColor => setLocalTheme({ ...localTheme, secondaryColor });

  const setLogo = async file => {
    const preview = await getFileURLFromFile(file);
    setLocalLogo({ file, preview });
  };

  const setEmblem = async file => {
    const preview = await getFileURLFromFile(file);
    setLocalEmblem({ file, preview });
  };

  const save = async () => {
    setSaving(true);
    const { primaryColor, secondaryColor } = localTheme;
    const colorsUpdated = await TenantStore.updateTenant({ theme: { primaryColor, secondaryColor } });
    if (!!colorsUpdated) toast("Donation page theme colors updated.");
    else toast("Error updating donation page theme colors.");

    if (localLogo || localEmblem) {
      const imagesUpdated = await TenantStore.uploadImages({ logo: localLogo?.file, emblem: localEmblem?.file });

      if (imagesUpdated) toast("Donation page theme images updated.");
      else toast("Error updating donation page theme images.");

      setLocalLogo();
      setLocalEmblem();
    }

    setSaving(false);
  };

  const cancel = () => {
    setLocalTheme(theme);
    setLocalLogo();
    setLocalEmblem();
  };

  let { primaryColor, secondaryColor, logo, emblem } = localTheme || {};

  const conditionalButtons = updatedLocally
    ? [
        <Button text="Cancel" icon={MdClose} theme="light" onClick={cancel} disabled={saving} key="cancel" />,
        <Button
          text={saving ? "Saving..." : "Save"}
          theme={saving ? "light" : null}
          icon={MdCheck}
          onClick={save}
          disabled={saving}
          key="save"
        />
      ]
    : null;

  return (
    <Page titleOpts={titleOptions} buttons={conditionalButtons}>
      <div className="campaigns-theme-container">
        <div className="theme-section">
          <div className="theme-section-header">Images</div>
          <div className="theme-section-items">
            <div className="theme-item">
              <div className="theme-item-titles">
                <div className="theme-item-title">Header Logo</div>
                <div className="theme-item-subtitle">Upload a .PNG at 270px by 54px.</div>
                <div className="theme-item-note">
                  NOTE: Make sure your .PNG has a transparent background. If the image is smaller than the space, center
                  it before uploading.
                </div>
              </div>
              <div className="theme-item-content">
                <FileUpload
                  height={54}
                  width={270}
                  accept="image/png, image/jpeg"
                  fileURL={localLogo?.preview || logo}
                  onUpload={setLogo}
                />
              </div>
            </div>
            <div className="theme-item">
              <div className="theme-item-titles">
                <div className="theme-item-title">Background Emblem</div>
                <div className="theme-item-subtitle">Upload a .PNG at 1280px by 800px.</div>
                <div className="theme-item-note">
                  NOTE: Make sure your .PNG has a transparent background. If the image is smaller than the space, center
                  it before uploading.
                </div>
              </div>
              <div className="theme-item-content">
                <FileUpload
                  height={80}
                  width={128}
                  accept="image/png, image/jpeg"
                  fileURL={localEmblem?.preview || emblem}
                  onUpload={setEmblem}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="theme-section">
          <div className="theme-section-header">Colors</div>
          <div className="theme-section-items">
            <div className="theme-item">
              <div className="theme-item-titles">
                <div className="theme-item-title">Primary Color</div>
                <div className="theme-item-subtitle">
                  This will color the donation page's header, primary action buttons, and major design elements.
                </div>
              </div>
              <div className="theme-item-content">
                <ColorPickerButton color={primaryColor || ""} onSelect={setPrimaryColor} />
              </div>
            </div>
            <div className="theme-item">
              <div className="theme-item-titles">
                <div className="theme-item-title">Secondary Color</div>
                <div className="theme-item-subtitle">
                  This will color the donation page's design accents and seconary buttons.
                </div>
              </div>
              <div className="theme-item-content">
                <ColorPickerButton color={secondaryColor || ""} onSelect={setSecondaryColor} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default observer(CampaignsTheme);
