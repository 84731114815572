import React, { useState, useEffect, useRef } from "react";
import { BlockPicker } from "react-color";
import "./ColorPickerButton.scss";

function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = event => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);

      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}

const ColorPickerButton = ({ color, onSelect }) => {
  const container = useRef(null);
  const [pickerVisible, setPickerVisible] = useState(false);

  useOnClickOutside(container, () => {
    if (pickerVisible) {
      setPickerVisible(false);
      typeof onSelect === "function" && onSelect(color);
    }
  });

  const onChange = ({ hex }) => typeof onSelect === "function" && onSelect(hex);

  const previewStyles = { backgroundColor: color };

  const picker = pickerVisible ? (
    <div className="picker">
      <BlockPicker color={color} colors={[]} onChange={onChange} />
    </div>
  ) : null;

  const openPicker = () => {
    if (!pickerVisible) setPickerVisible(true);
  };

  return (
    <div ref={container} className="color-picker-button" onClick={openPicker}>
      <div className="color-preview" style={previewStyles} />
      <div className="button-text">SELECT COLOR</div>
      {picker}
    </div>
  );
};
export default ColorPickerButton;
