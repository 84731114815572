import React from "react";

export default function golfBag({
  size = 24,
  fill = "none",
  stroke = "#000",
  strokeWidth = 13
}) {
  return (
    <svg
      viewBox="0 0 419.534 419.534"
      height={size}
      width={size}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polyline points="201.907,45.91 201.907,48.929 201.999,184.565" />
      <path d="M202.802,51.806 c-6.174-16.633,4.117-35.794,22.984-42.797c3.702-1.374,7.46-2.178,11.155-2.457c17.883-1.35,23.153,23.989,6.34,30.23 L202.802,51.806z" />
      <line x1="181.999" y1="103.863" x2="182.078" y2="185.016" />
      <path d="M159.756,67.512l-6.732-2.486c-6.427-2.373-13.562,0.913-15.935,7.34l-3.146,8.518c-2.374,6.427,0.913,13.562,7.34,15.935 l38.525,14.227l0,0C186.293,93.487,177.315,73.996,159.756,67.512z" />
      <line x1="221.846" y1="119.475" x2="221.779" y2="185.123" />
      <path d="M244.086,83.122l6.732-2.486c6.427-2.374,13.562,0.913,15.935,7.34l3.146,8.518c2.373,6.427-0.913,13.562-7.34,15.935 l-38.525,14.227l0,0C217.548,109.097,226.526,89.606,244.086,83.122z" />
      <path d="M157.152,354.469v22.042c0,20.171,16.352,36.523,36.523,36.523h53.803V187.213h-90.326v51.289" />
      <path d="M247.479,232.365 l24.195,19.232c9.28,7.377,14.688,18.584,14.688,30.439v57.01c0,21.475-17.409,38.883-38.883,38.883h0" />
      <path d="M168.398,354.466h-19.168V238.502h42.371v92.761C191.601,344.078,181.212,354.466,168.398,354.466z" />
      <line x1="157.143" y1="209.651" x2="246.207" y2="209.651" />
      <line x1="269.444" y1="251.597" x2="269.444" y2="367.847" />
    </svg>
  );
}
