import React from "react";
import { AsYouType } from "libphonenumber-js";
import Input from "../../../../-common/Input";
import "./PersonalSlide.scss";

const PersonalSlide = ({ state, dispatch }) => {
  const { firstName, lastName, email, phone } = state;

  const setFirstName = v => dispatch({ type: "setFirstName", payload: { firstName: v } });
  const setLastName = v => dispatch({ type: "setLastName", payload: { lastName: v } });
  const setEmail = v => dispatch({ type: "setEmail", payload: { email: v } });
  const setPhone = v => dispatch({ type: "setPhone", payload: { phone: v } });
  const updatePhone = raw => {
    if (raw.replace(/[^\d]/g, "")?.length <= 10) {
      if (!isNaN(Number(phone?.slice(-1) || ""))) {
        const phoneFormatter = new AsYouType("US");
        const phone = phoneFormatter.input(raw);
        setPhone(phone);
      } else {
        setPhone(raw);
      }
    }
  };

  return (
    <div className="slide personal-slide">
      <div className="headline">Officer Personal Info</div>
      <div className="inputs">
        <Input value={firstName} onChange={setFirstName} label="First Name" placeholder="Jane" />
        <Input value={lastName} onChange={setLastName} label="Last Name" placeholder="Doe" />
        <Input value={email} onChange={setEmail} label="Email Address" placeholder="your@email.here" />
        <Input value={phone} onChange={updatePhone} label="Phone Number" placeholder="(XXX) XXX-XXXX" />
      </div>
    </div>
  );
};

export default PersonalSlide;
