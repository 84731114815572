import React, { useReducer } from "react";
import { createPortal } from "react-dom";
import { observer } from "mobx-react";
import { parsePhoneNumber } from "libphonenumber-js";
import TenantStore from "../../../../stores/TenantStore";
import usePortal from "../../../../hooks/usePortal";
import Modal from "../../../-common/Modal";
import { financialContactReducer, financialContactInitialState } from "./financialContactReducer";
import WhySlide from "./WhySlide";
import PersonalSlide from "./PersonalSlide";
import AddressSlide from "./AddressSlide";
import "./FinancialContactModal.scss";

const FinancialContactModal = observer(({ close }) => {
  const [state, dispatch] = useReducer(financialContactReducer, financialContactInitialState);
  const setActiveSlide = activeSlide => dispatch({ type: "setActiveSlide", payload: { activeSlide } });

  const finish = async () => {
    let { firstName, lastName, email, phone, street1, street2, city, state: addressState, zipCode } = state;

    try {
      const phoneNumber = parsePhoneNumber(phone, "US");
      phone = phoneNumber.format("E.164").replace(/^\+1/, "");
    } catch {}

    const financialContact = {
      firstName,
      lastName,
      email,
      phone,
      street1,
      street2,
      city,
      state: addressState,
      zipCode
    };
    const { config } = TenantStore?.tenant || {};
    const { zift } = config || {};
    await TenantStore?.updateTenant({
      config: { ...config, zift: { ...zift, financialContact } }
    });
    close();
    dispatch({ type: "resetState" });
  };

  const slideProps = { state, dispatch };
  const { slides, progress, progressTitle } = {
    slides: [<WhySlide {...slideProps} />, <PersonalSlide {...slideProps} />, <AddressSlide {...slideProps} />],
    progress: [
      { title: "Why", description: "Why do we need this?" },
      { title: "Personal", description: "Add officer's details" },
      { title: "Address", description: "Add officer's address" }
    ],
    progressTitle: "Three steps and you're there"
  };

  return (
    <Modal
      nextEnabled={state.nextEnabled}
      slides={slides}
      progress={progress}
      progressTitle={progressTitle}
      activeSlide={state.activeSlide}
      isOpen={true}
      setActiveSlide={setActiveSlide}
      close={close}
      finish={finish}
    />
  );
});

const FinancialContactModalContainer = ({ isOpen, ...props }) => {
  const portalTarget = usePortal("officer-info-modal-container");
  const contents = isOpen ? <FinancialContactModal {...props} /> : null;
  return createPortal(contents, portalTarget);
};

export default FinancialContactModalContainer;
