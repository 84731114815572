import React from "react";
import Label from "../Label";
import RadioButton from "../RadioButton";
import "./RadioGroup.scss";

const RadioGroup = ({
  options,
  label,
  labelStyles,
  selected,
  onChange,
  noBorder = false,
  id
}) => {
  if (!id) throw new Error("Radio Group requires a unique id.");

  const labelComponent = label ? (
    <Label style={labelStyles} value={label} />
  ) : null;
  const radioButtons = (options || []).map(({ value, label, disabled }, i) => (
    <RadioButton
      id={id + "--" + i}
      onChange={onChange}
      checked={value === selected}
      label={label}
      value={value}
      disabled={disabled}
      key={i}
    />
  ));
  const containerStyles = noBorder ? { border: "none", padding: 0 } : null;
  return (
    <div className="radio-group-container">
      {labelComponent}
      <div className="radio-group" style={containerStyles}>
        {radioButtons}
      </div>
    </div>
  );
};

export default RadioGroup;
