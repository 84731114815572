import React from 'react';

const AlertIcon = ({
  size = 24,
  color = '#fff',
  strokeWidth = 2,
}) => (
  <svg
    style={{
      height: size,
      width: size,
      stroke: color,
    }}
    strokeWidth={strokeWidth}
    strokeLinecap="round"
    viewBox="0 0 50.11 56.26"
    fill={color}
  >
    <path className="cls-1" d="M60.93,46.84" transform="translate(-11.78 -7.56)" />
    <path
      className="cls-2"
      d="M60.93,47.8a1,1,0,0,1-.7-.3,32.89,32.89,0,0,1-8.52-23.85c.22-5.65-2.19-9.8-7.18-12.33a17,17,0,0,0-15.39,0c-5,2.53-7.39,6.68-7.17,12.33A32.85,32.85,0,0,1,13.44,47.5a1,1,0,1,1-1.39-1.32,31,31,0,0,0,8-22.46A14.28,14.28,0,0,1,28.27,9.61a18.94,18.94,0,0,1,17.13,0,14.26,14.26,0,0,1,8.22,14.11,30.92,30.92,0,0,0,8,22.46,1,1,0,0,1,0,1.36A1,1,0,0,1,60.93,47.8Z"
      transform="translate(-11.78 -7.56)"
    />
    <path className="cls-1" d="M12.74,46.84" transform="translate(-11.78 -7.56)" />
    <path
      className="cls-2"
      d="M36.84,55.77c-9,0-18-2.73-24.7-8.18a1,1,0,0,1,1.21-1.49c12.73,10.37,34.25,10.37,47,0a1,1,0,1,1,1.21,1.49C54.85,53,45.84,55.77,36.84,55.77Z"
      transform="translate(-11.78 -7.56)"
    />
    <path className="cls-1" d="M12.74,46.84" transform="translate(-11.78 -7.56)" />
    <path className="cls-1" d="M60.93,46.84" transform="translate(-11.78 -7.56)" />
    <path
      className="cls-2"
      d="M36.84,63.82c-3.52,0-6.39-2.35-6.39-5.25a1,1,0,1,1,1.92,0c0,1.84,2,3.33,4.47,3.33s4.46-1.49,4.46-3.33a1,1,0,0,1,1.92,0C43.22,61.47,40.36,63.82,36.84,63.82Z"
      transform="translate(-11.78 -7.56)"
    />
  </svg>
);

export default AlertIcon;
