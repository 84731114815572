import React from "react";

export default function basketball({
  size = 24,
  fill = "none",
  stroke = "#000",
  strokeWidth = 13
}) {
  return (
    <svg
      viewBox="0 0 255.222 255.222"
      height={size}
      width={size}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
    >
      <circle cx="127.611" cy="127.611" r="121.1" />
      <path d="M230.508,63.768 c-29.523-7.332-61.919-2.426-88.908,16.211c-8.04,5.552-15.103,11.978-21.16,19.062c-31.587,36.962-35.744,91.887-6.819,133.776 c4.012,5.81,8.496,11.117,13.348,15.902" />
      <path d="M8.961,151.969 c20.081-20.702,34.409-45.533,42.508-72.115c5.157-16.895,7.791-34.504,7.81-52.214" />
      <path d="M172.409,15.106 c-15.685,5.206-30.907,12.708-45.191,22.572c-19.142,13.218-34.919,29.507-47.096,47.76 c-27.146,40.66-36.478,91.005-25.723,138.677" />
      <path d="M232.255,188.602 c-29.494-40.856-68.476-71.012-111.816-89.561c-13.087-5.597-26.575-10.151-40.317-13.604c-9.454-2.373-19.021-4.24-28.654-5.582 C40.204,78.288,28.84,77.429,17.44,77.29" />
    </svg>
  );
}
