import React from "react";
import { AsYouType } from "libphonenumber-js";
import Input from "../../../../-common/Input";
import "./DetailsSlide.scss";

const DetailsSlide = ({ state, dispatch }) => {
  const { name, taxId, email, descriptorPhone } = state;

  const setName = v => dispatch({ type: "setName", payload: { name: v } });
  const setTaxId = raw => {
    let formatted = raw.replace(/[^\d]/g, "").slice(0, 9);
    if (formatted?.length > 2) formatted = formatted.slice(0, 2) + "-" + formatted.slice(2);
    dispatch({ type: "setTaxId", payload: { taxId: formatted } });
  };
  const setEmail = v => dispatch({ type: "setEmail", payload: { email: v } });
  const setDescriptorPhone = v => dispatch({ type: "setDescriptorPhone", payload: { descriptorPhone: v } });
  const updateDescriptorPhone = raw => {
    if (raw.replace(/[^\d]/g, "")?.length <= 10) {
      if (!isNaN(Number(descriptorPhone?.slice(-1) || ""))) {
        const phoneFormatter = new AsYouType("US");
        const phone = phoneFormatter.input(raw);
        setDescriptorPhone(phone);
      } else {
        setDescriptorPhone(raw);
      }
    }
  };

  return (
    <div className="slide personal-slide">
      <div className="headline">Department Details</div>
      <div className="inputs">
        <Input
          value={name}
          onChange={setName}
          label="Legal Entity Name"
          placeholder="Nickel University Athletics, LLC"
        />
        <Input value={taxId} onChange={setTaxId} label="Federal Tax ID Number (FEIN)" placeholder="XX-XXXXXXX" />
        <Input
          value={email}
          onChange={setEmail}
          label="Customer Service Email Address"
          placeholder="support@nickelathletics.com"
        />
        <Input
          value={descriptorPhone}
          onChange={updateDescriptorPhone}
          label="Customer Service Phone Number"
          placeholder="(XXX) XXX-XXXX"
        />
      </div>
    </div>
  );
};

export default DetailsSlide;
