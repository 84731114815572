import React from "react";
import { MdChevronLeft } from "react-icons/md";
import { goBack } from "../../../utils";
import "./PageHeader.scss";

export default ({ title, backButton = false, backAction, fullWidthBorder = false, buttons }) => {
  const wrapperStyles = {
    ...(fullWidthBorder
      ? {
          width: "100%",
          padding: "var(--page-header-spacing)",
          margin: "0px"
        }
      : {}),
    ...(backButton ? { gridTemplate: "64px / 32px 1fr max-content" } : {})
  };

  backAction = typeof backAction === "function" ? backAction : goBack;
  const backIcon = backButton ? <MdChevronLeft className="back-icon" size={64} onClick={backAction} /> : null;

  return (
    <div className="page-header" style={wrapperStyles}>
      {backIcon}
      <div className="header-title">{title}</div>
      <div className="buttons-wrapper">{buttons}</div>
    </div>
  );
};
