import React, { useState, useEffect, useCallback } from "react";
import { observer } from "mobx-react";
import { Link } from "@reach/router";
import { navigate } from "@reach/router";
import { getPasswordCriteriaErrors } from "../../../utils";
import AuthStore from "../../../stores/AuthStore";
import UsersStore from "../../../stores/UsersStore";
import InstanceConfigStore from "../../../stores/InstanceConfigStore";
import Spinner from "../../-common/Spinner";
import NickelLogo from "../../-common/NickelLogo";
import BackgroundBlobs from "../BackgroundBlobs";
import AuthInput from "../../-common/AuthInput";
import "./NewPassword.scss";

const NewPassword = ({ location }) => {
  if (!AuthStore.forcePasswordReset) {
    navigate("/auth");
  }

  const { passwordPolicy } = InstanceConfigStore || {};
  const { MinimumLength, RequireLowercase, RequireNumbers, RequireSymbols, RequireUppercase } = passwordPolicy || {};

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const handlePasswordInput = ({ target }) => {
    setPassword(target.value);
    if (confirmPassword && target.value && confirmPassword !== target.value) {
      setPasswordError("Passwords don't match.");
    } else {
      setPasswordError("");
    }
  };
  const handleConfirmPasswordInput = ({ target }) => {
    setConfirmPassword(target.value);
    if (password && target.value && password !== target.value) {
      setPasswordError("Passwords don't match.");
    } else {
      setPasswordError("");
    }
  };

  const [passwordCriteriaErrors, setPasswordCriteriaErrors] = useState(getPasswordCriteriaErrors(""));
  useEffect(() => {
    const criteriaErrors = getPasswordCriteriaErrors(passwordPolicy, password);
    setPasswordCriteriaErrors(criteriaErrors);
  }, [passwordPolicy, password]);

  const reset = useCallback(() => {
    setPassword("");
    setConfirmPassword("");
    setPasswordError("");
  }, [setPassword, setConfirmPassword, setPasswordError]);

  const changePassword = useCallback(async () => {
    if (
      password &&
      confirmPassword &&
      password === confirmPassword &&
      !passwordCriteriaErrors?.length &&
      !passwordError
    ) {
      try {
        await AuthStore.resetPassword(password);
        await UsersStore?.onboardUser();
        setPasswordError("");
      } catch (err) {
        const errPrefix = "Password does not conform to policy: ";
        if (err.message.includes(errPrefix)) {
          err.message = err.message.replace(errPrefix, "");
        }
        setPasswordError(err.message);
        reset();
      }
    } else {
      setPasswordError("Passwords don't match.");
    }
  }, [password, confirmPassword, passwordCriteriaErrors, passwordError, setPasswordError, reset]);

  const handleKeyup = useCallback(
    e => {
      if (e.key === "Enter" || e.keycode === 13) changePassword();
    },
    [changePassword]
  );

  useEffect(() => {
    window.addEventListener("keyup", handleKeyup);
    return () => window.removeEventListener("keyup", handleKeyup);
  }, [handleKeyup]);

  const buttonDisabled = !password || !confirmPassword || passwordCriteriaErrors.length || passwordError;
  const buttonContents = AuthStore.loading ? <Spinner /> : "LOG IN ›";
  const buttonStyles = AuthStore.loading
    ? {
        opacity: 0.7,
        pointerEvents: "none"
      }
    : buttonDisabled
    ? {
        opacity: 0.3,
        pointerEvents: "none"
      }
    : {};

  return (
    <div className="new-password-container">
      <div className="auth-container">
        <div className="auth-wrapper">
          <BackgroundBlobs />
          <div className="auth-header">
            <Link to="/auth">
              <NickelLogo height={30} />
            </Link>
          </div>
          <div className="auth-content">
            <h3 className="auth-title">Let's get your password set up!</h3>
            <div className="auth-square">
              <div className="criteria">
                <h4 className="criteria-title">Your new password should:</h4>
                <ul>
                  {MinimumLength ? (
                    <li className={passwordCriteriaErrors.includes("MinimumLength") ? "error" : null}>
                      Have a minimum of {MinimumLength} characters
                    </li>
                  ) : null}
                  {RequireUppercase ? (
                    <li className={passwordCriteriaErrors.includes("RequireUppercase") ? "error" : null}>
                      Include at least one uppercase letter
                    </li>
                  ) : null}
                  {RequireLowercase ? (
                    <li className={passwordCriteriaErrors.includes("RequireLowercase") ? "error" : null}>
                      Include at least one lowercase letter
                    </li>
                  ) : null}
                  {RequireNumbers ? (
                    <li className={passwordCriteriaErrors.includes("RequireNumbers") ? "error" : null}>
                      Include at least one number
                    </li>
                  ) : null}
                  {RequireSymbols ? (
                    <li className={passwordCriteriaErrors.includes("RequireSymbols") ? "error" : null}>
                      Include at least one special character
                    </li>
                  ) : null}
                </ul>
              </div>

              <div className="auth-inputs">
                <AuthInput type="password" placeholder="New password" value={password} onChange={handlePasswordInput} />
                <AuthInput
                  type="password"
                  placeholder="...and your new password again"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordInput}
                  error={passwordError}
                />
                <button style={buttonStyles} className="auth-button" onClick={changePassword} disabled={buttonDisabled}>
                  {buttonContents}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(NewPassword);
