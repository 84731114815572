import React, { useState, useCallback } from "react";
import { createPortal } from "react-dom";
import { observer } from "mobx-react";
import usePortal from "../../../../hooks/usePortal";
import AchievementsStore from "../../../../stores/AchievementsStore";
import FullScreenModal from "../../../-common/FullScreenModal";
import Button from "../../../-common/Button";
import PieTimer from "../../../-common/PieTimer";
import "./ConfirmRemoveAchievementModal.scss";

const ConfirmRemoveAchievementModal = observer(({ close, achievementId }) => {
  const [removeEnabled, setRemoveEnabled] = useState(false);

  const showRemoveButton = useCallback(() => {
    setRemoveEnabled(true);
  }, [setRemoveEnabled]);

  const remove = useCallback(async () => {
    close("delete");
    AchievementsStore.deleteAchievement(achievementId);
  }, [close, achievementId]);

  const removeButton = removeEnabled ? (
    <Button text="Remove Achievement" onClick={remove} />
  ) : (
    <div className="timer-container">
      <PieTimer color="var(--primary-color)" width={24} height={24} duration={3000} onComplete={showRemoveButton} />
    </div>
  );

  return (
    <FullScreenModal isOpen={true} close={close}>
      <div className="confirm-remove-achievement-modal">
        <div className="modal-contents">
          <div className="headline">You sure?</div>
          <div className="headline">Once you remove this achievement, it can't be undone.</div>
        </div>
        <div className="bottom-buttons">
          <Button text="Cancel" theme="light" onClick={close} />
          {removeButton}
        </div>
      </div>
    </FullScreenModal>
  );
});

const ConfirmRemoveAchievementModalContainer = ({ isOpen, ...props }) => {
  const portalTarget = usePortal("confirm-remove-achievement-modal-container");
  const contents = isOpen ? <ConfirmRemoveAchievementModal {...props} /> : null;
  return createPortal(contents, portalTarget);
};

export default ConfirmRemoveAchievementModalContainer;
