import React from "react";
import { observer } from "mobx-react";
import { parsePhoneNumber } from "libphonenumber-js";
import { Table } from "ka-table";
import { DataType } from "ka-table/enums";
import GlobalSearchStore from "../../../../stores/GlobalSearchStore";
import "./TeamMembersTable.scss";

const EllipsisCell = ({ field, rowData, dispatch }) => {
  const sendClickEvent = () => dispatch("Row Click", rowData);
  const phoneNum =
    field === "phoneNumber" && rowData[field]
      ? parsePhoneNumber(rowData[field], "US")
      : null;
  const formattedPhone = phoneNum?.format("NATIONAL");
  return (
    <div className="ellipsis-cell" onClick={sendClickEvent}>
      {field === "phoneNumber" ? formattedPhone : rowData[field]}
    </div>
  );
};

const columns = [
  {
    cell: EllipsisCell,
    dataType: DataType.String,
    key: "name",
    title: "Name"
  },
  {
    cell: EllipsisCell,
    dataType: DataType.String,
    key: "phoneNumber",
    title: "Phone Number"
  }
];

// const childAttributes = {
//   cell: {
//     onClick: (_, e) => {
//       const { dispatch, rowData } = e.childProps;
//       dispatch("Row Click", rowData);
//     }
//   }
// };

function TeamMembersTable({ data }) {
  // const onTableEvent = async (type, data) => {
  //   const { teamId } = data;
  //   if (type === "Row Click") {
  //     navigate(`/settings/teams/${teamId}`);
  //   }
  // };

  return (
    <div className="teams-table-container">
      <Table
        // childAttributes={childAttributes}
        columns={columns}
        data={data}
        search={GlobalSearchStore.searchText}
        // onEvent={onTableEvent}
        rowKeyField="teamMemberId"
      />
    </div>
  );
}

export default observer(TeamMembersTable);
