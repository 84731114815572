import React from "react";

export default function golfClubs({
  size = 24,
  fill = "none",
  stroke = "#000",
  strokeWidth = 13
}) {
  return (
    <svg
      viewBox="0 0 374.734 374.734"
      height={size}
      width={size}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="246.068" y1="6.5" x2="124.28" y2="335.307" />
      <line x1="133.784" y1="6.5" x2="255.572" y2="335.307" />
      <path d="M125.727,328.32 c0,21.215-19.509,38.414-43.574,38.414c-4.722,0-9.269-0.662-13.527-1.887c-20.609-5.928-15.973-36.527,5.471-36.527H125.727z" />
      <path d="M295.235,366.734h9.56c8.048,0,14.572-6.524,14.572-14.572v-11.383c0-8.048-6.524-14.572-14.572-14.572h-49.371v0.717 C255.425,348.91,273.249,366.734,295.235,366.734z" />
      <circle cx="187.367" cy="335.733" r="20" />
      <line x1="187.367" y1="355.734" x2="187.367" y2="374.734" />
    </svg>
  );
}
