import React from "react";

export default function baseballBat({
  size = 24,
  fill = "none",
  stroke = "#000",
  strokeWidth = 13
}) {
  return (
    <svg
      viewBox="0 0 349.949 349.949"
      height={size}
      width={size}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
    >
      <path d="M200.348,108.588L87.564,246.591l-62.697,62.697 l-1.296-1.296c-3.905-3.905-10.237-3.905-14.142,0l0,0c-3.905,3.905-3.905,10.237,0,14.142l18.385,18.385 c3.905,3.905,10.237,3.905,14.142,0l0,0c3.905-3.905,3.905-10.237,0-14.142l-1.296-1.296l62.697-62.697L241.36,149.6l97.606-97.606 c5.974-5.974,5.974-15.661,0-21.635l-19.377-19.377c-5.974-5.974-15.661-5.974-21.635,0L200.348,108.588z" />
      <line x1="115.495" y1="215.125" x2="135.294" y2="234.924" />
      <line x1="279.072" y1="30.806" x2="317.256" y2="68.99" />
      <path d="M206.439,178.142l55.94,68.45l62.7,62.7l1.3-1.3 c3.9-3.91,10.23-3.91,14.14,0c1.95,1.96,2.93,4.52,2.93,7.07c0,2.56-0.98,5.12-2.93,7.07l-18.39,18.39c-3.9,3.9-10.23,3.9-14.14,0 c-1.95-1.95-2.92-4.51-2.92-7.07s0.97-5.12,2.92-7.08l1.3-1.29l-62.7-62.7l-71.61-58.53" />
      <path d="M143.509,178.142l-34.92-28.54l-97.61-97.61 c-5.97-5.97-5.97-15.66,0-21.63l19.38-19.38c5.97-5.97,15.66-5.97,21.63,0l97.61,97.61l25.38,31.05" />
      <line x1="234.451" y1="215.125" x2="214.652" y2="234.924" />
      <line x1="70.874" y1="30.806" x2="32.69" y2="68.99" />
    </svg>
  );
}
