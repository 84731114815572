import React from "react";
import { Router, Redirect } from "@reach/router";
import Personal from "./Personal";
// import Activity from "./Activity";
// import Roles from "./Roles";
// import Notifications from "./Notifications";
import "./Profile.scss";

export default function Profile() {
  return (
    <Router>
      <Personal path="personal" default />
      {/* <Roles path="roles" />
      <Activity path="activity" />
      <Notifications path="notifications" /> */}
      <Redirect from="/" to="/profile/personal" noThrow />
      <Redirect from="profile" to="/profile/personal" noThrow />
    </Router>
  );
}
