import React from "react";

export default function cleats({
  size = 24,
  fill = "none",
  stroke = "#000",
  strokeWidth = 13
}) {
  return (
    <svg
      viewBox="0 0 296.673 296.673"
      height={size}
      width={size}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
    >
      <path
        strokeLinejoin="round"
        d="M21.609,102.761 L21.609,102.761c18.116,22.429,51.012,25.863,73.369,7.658l26.821-21.84l84.313,38.26c18.182,8.25,37.408,13.849,57.07,17.318 c12.964,2.288,25.387,7.689,26.991,24.234v0c0,11.715-9.497,21.212-21.212,21.212H148.235H27.249 c-5.484,0-10.475-2.112-14.204-5.567c-4.917-4.555-7.193-11.296-6.384-17.95l7.379-60.651 C14.04,101.421,19.087,99.638,21.609,102.761z"
      />
      <line x1="131.962" y1="113.416" x2="146.755" y2="93.445" />
      <line x1="157.851" y1="123.956" x2="172.644" y2="103.985" />
      <line x1="183" y1="135.015" x2="197.793" y2="115.043" />
      <line x1="209.258" y1="145.925" x2="224.052" y2="125.954" />
      <line x1="251.05" y1="208.095" x2="251.05" y2="191.822" />
      <line x1="39.502" y1="208.095" x2="39.502" y2="191.822" />
      <line x1="81.812" y1="208.095" x2="81.812" y2="191.822" />
      <line x1="124.121" y1="208.095" x2="124.121" y2="191.822" />
      <line x1="208.74" y1="208.095" x2="208.74" y2="191.822" />
      <line x1="166.431" y1="208.095" x2="166.431" y2="191.822" />
    </svg>
  );
}
