import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { format } from "date-fns";
import groupBy from "lodash.groupby";
import { FaRegCalendarAlt } from "react-icons/fa";
import { FiX } from "react-icons/fi";
import { IoMdEye } from "react-icons/io";
import BulletinsStore from "../../../stores/BulletinsStore";
import MembershipsStore from "../../../stores/MembershipsStore";
import Page from "../../-common/Page";
import Button from "../../-common/Button";
import PlanStep from "../../-common/PlanStep";
import AddScheduleStepModal from "../../-common/AddScheduleStepsModal";
import "./BulletinSchedule.scss";

const STEP_SIZE = 72;
const STEP_MARGIN = 24;

function ZeroState({ openAddStepsModal }) {
  return (
    <div className="zero-state">
      <div className="zero-state-text">
        Looks like you don't have any release steps on the calendar yet - add some to get started!
      </div>
      <Button text="Add Step" icon={FaRegCalendarAlt} onClick={openAddStepsModal} />
    </div>
  );
}

const BulletinSchedule = ({ bulletinId }) => {
  const { schedules: currentSteps } = BulletinsStore?.bulletins?.find((c) => c?.bulletinId === bulletinId) || {};

  const [steps, setSteps] = useState(currentSteps || []);
  useEffect(() => {
    if (currentSteps?.length) setSteps(currentSteps);
  }, [currentSteps]);

  const addSteps = (newSteps) => setSteps(steps.concat(newSteps));
  const removeStepGroup = (publishDate) => {
    setSteps(steps.filter((s) => s?.publishDate?.valueOf() !== new Date(publishDate)?.valueOf()));
  };

  const groupedSteps = Object.entries(groupBy(steps, (s) => s?.publishDate?.valueOf()))
    ?.map(([publishDate, steps]) => ({
      publishDate: Number(publishDate),
      membershipIds: steps?.map((s) => s?.referenceId),
    }))
    ?.sort((a, b) => a.publishDate - b.publishDate);

  const selectedLevels = groupedSteps?.map((s) => s?.membershipIds)?.flat();
  const availableLevels = MembershipsStore.memberships
    .map((m) => m.membershipId)
    .filter((mid) => !selectedLevels?.includes(mid));

  const [addStepsModalOpen, setAddStepsModalOpen] = useState(false);
  const openAddStepsModal = () => setAddStepsModalOpen(true);
  const closeAddStepsModal = () => setAddStepsModalOpen(false);

  const remainingStepIds = steps?.map((s) => s?.scheduleId)?.filter(Boolean);
  const stepsToRemove = currentSteps?.filter((cs) => !remainingStepIds?.includes(cs?.scheduleId)) || [];
  const saveEnabled = stepsToRemove?.length || steps?.length !== currentSteps?.length;

  const cancel = () => setSteps(currentSteps);

  const save = async () => {
    if (stepsToRemove?.length) {
      const stepIdsToRemove = stepsToRemove?.map((str) => str?.scheduleId);
      await BulletinsStore?.deleteScheduleStepsForBulletin(bulletinId, stepIdsToRemove);
    }

    await BulletinsStore?.updateScheduleForBulletin(bulletinId, steps);
  };

  const stepRows = groupedSteps.map((s, i) => (
    <div className="date-and-step-container" key={i}>
      <div className="date-details">
        <div className="date-day">{format(s?.publishDate, "dd")}</div>
        <div className="date-month">{format(s?.publishDate, "LLL")}</div>
      </div>
      <PlanStep {...s} removePlanStep={() => removeStepGroup(s?.publishDate)} />
    </div>
  ));

  const content =
    currentSteps?.length || groupedSteps?.length ? (
      <div className="timeline" style={{ height: (STEP_SIZE + STEP_MARGIN) * groupedSteps?.length }}>
        <div className="timeline-dashes" />
        <div className="steps-container">{stepRows}</div>
      </div>
    ) : (
      <ZeroState {...{ openAddStepsModal }} />
    );

  return (
    <Page
      titleOpts={{ title: "Schedule" }}
      buttons={
        saveEnabled
          ? [
              <Button text="Add step" icon={FaRegCalendarAlt} onClick={openAddStepsModal} key="add-launch-plan" />,
              <Button key="cancel" text="Cancel" icon={FiX} theme="light" onClick={cancel} />,
              <Button key="save" text="Save" icon={IoMdEye} disabled={!saveEnabled} onClick={save} />,
            ]
          : [<Button text="Add step" icon={FaRegCalendarAlt} onClick={openAddStepsModal} key="add-launch-plan" />]
      }
    >
      <div className="bulletin-schedule">{content}</div>
      <AddScheduleStepModal
        isOpen={addStepsModalOpen}
        close={closeAddStepsModal}
        addSteps={addSteps}
        availableLevels={availableLevels}
      />
    </Page>
  );
};

export default observer(BulletinSchedule);
