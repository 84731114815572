import React from 'react';
import Input from '../../../../-common/Input';
import Select from '../../../../-common/Select2';
import Textarea from '../../../../-common/Textarea';
import { bulletinsTypeOptions } from '../../../../../constants/bulletins';
import './ContentSlide.scss';

const ContentSlide = ({
  subject,
  setSubject,
  message,
  setMessage,
  updateBulletinType,
}) => (
  <div className="slide content-slide">
    <div className="headline">Let's get the word out</div>
    <div className="inputs">
      <Input
        value={subject}
        onChange={setSubject}
        label="Subject"
        placeholder="Something Great"
      />
      <Select
        label="Type"
        options={bulletinsTypeOptions}
        onChange={updateBulletinType}
        autoGrow={true}
      />
      <Textarea
        value={message}
        onChange={setMessage}
        label="Message"
        placeholder="What do you want to say?"
        maxLength={1000}
      />
    </div>
  </div>
);

export default ContentSlide;
