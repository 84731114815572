import React from "react";
import { observer } from "mobx-react";
import { navigate, Router, Redirect } from "@reach/router";
import Page from "../../-common/Page";
import Sources from "../Sources";
import History from "../History";
import Validation from "../Validation";
import Mapping from "../Mapping";
import "./DataSources.scss";

const targets = [
  { target: "Users", progress: Math.random() },
  { target: "Donations", progress: Math.random() },
  { target: "Tickets", progress: Math.random() },
  { target: "Pledges", progress: Math.random() },
  { target: "Messages", progress: Math.random() },
  { target: "Notes", progress: Math.random() }
];

const TargetSquare = ({ target, progress }) => {
  const open = () => {
    navigate(`/integrations/datasources/${target.toLowerCase()}/sources`, { state: { useBack: true } });
  };

  const progressStyles = { width: `${(progress * 100).toFixed(2)}%` };

  return (
    <div className="target-square" onClick={open}>
      <div className="titles">
        <div className="info-title">{target}</div>
        <div className="info-subtitle">{(progress * 100).toFixed(2)}% complete</div>
      </div>
      <div className="info-wrapper">
        <div className="metrics">
          <div className="metric-wrapper donated">
            <div className="metric">53</div>
            <div className="label">Total {target}</div>
          </div>
          <div className="metric-wrapper donors">
            <div className="metric">1172</div>
            <div className="label">Input data rows</div>
          </div>
          <div className="metric-wrapper days-left">
            <div className="metric">0</div>
            <div className="label">Errors</div>
          </div>
        </div>
        <div className="progress-bar">
          <div className="progress" style={progressStyles} />
        </div>
      </div>
    </div>
  );
};

const DataSources = observer(() => {
  const titleOptions = { title: "Data Sources" };

  const squares = targets.map(props => <TargetSquare {...props} key={props.target} />);

  return (
    <Page titleOpts={titleOptions}>
      <div className="integrations-data-sources">
        <div className="target-squares">{squares}</div>
      </div>
    </Page>
  );
});

export default function DataSourcesRouter() {
  return (
    <Router>
      <DataSources path="/" />
      <Sources path=":target/sources" />
      <History path=":target/history" />
      <Validation path=":target/validation" />
      <Mapping path=":target/mapping" />
      <Redirect from="*" to="/integrations/datasources" noThrow />
    </Router>
  );
}
