import React, { useState } from "react";
import { createPortal } from "react-dom";
import { observer } from "mobx-react";
import { FiCalendar } from "react-icons/fi";
import usePortal from "../../../hooks/usePortal";
import FullScreenModal from "../FullScreenModal";
import Button from "../Button";
import Select from "../Select2";
import Datepicker from "../Datepicker";
import Timepicker from "../Timepicker";
import MembershipsStore from "../../../stores/MembershipsStore";
import "./AddScheduleStepsModal.scss";

const AddScheduleStepModal = observer(({ close, addSteps, availableLevels }) => {
  const membershipOptions = MembershipsStore.membershipsIncludingPublic
    .filter((m) => availableLevels.includes(m.membershipId))
    .map((m) => ({
      label: m.level,
      value: m.membershipId,
    }));

  const [localStep, setLocalStep] = useState({});
  const { date, time } = localStep;

  const updateSelectedOptions = (value) => setLocalStep({ ...localStep, selectedOptions: value });
  const updateDate = (value) => setLocalStep({ ...localStep, date: value });
  const updateTime = (value) => setLocalStep({ ...localStep, time: value });

  const getFinalFormat = () => {
    const { date, selectedOptions, time } = localStep;
    const day = date.toISOString().split("T")[0]; // 01/01/2020
    const hour = String(time.hour()).padStart(2, "0"); // Pad with 0 if only 1 digit - "09"
    const minues = String(time.minutes()).padStart(2, "0"); // Pad with 0 if minutes are 00
    const assembledDate = new Date(`${day}T${hour}:${minues}:00`);
    const publishDate = assembledDate.getTime();
    return selectedOptions.map(({ value: referenceId }) => ({ publishDate, referenceId }));
  };

  const checkDisabled = () => {
    const { date, selectedOptions, time } = localStep;
    if (date && time && selectedOptions?.length) return false;
    return true;
  };

  const finish = () => {
    const formattedSteps = getFinalFormat();
    addSteps(formattedSteps);
    close();
  };

  return (
    <FullScreenModal isOpen={true} close={close}>
      <div className="schedule-steps-modal">
        <div className="modal-contents">
          <div className="headline">Add Step</div>
          <div className="content-container">
            <Select
              label="Select club level(s)"
              isMulti={true}
              options={membershipOptions}
              onChange={updateSelectedOptions}
              autoGrow={true}
            />
            <div className="double-input-container">
              <Datepicker value={date} onChange={updateDate} label="Select date" icon={FiCalendar} blockPast={true} />
              <Timepicker label="Select time" value={time} onChange={updateTime} />
            </div>
          </div>
        </div>
        <div className="bottom-buttons">
          <Button text="cancel" theme="light" onClick={close} />
          <Button text="Add" disabled={checkDisabled()} onClick={finish} />
        </div>
      </div>
    </FullScreenModal>
  );
});

const AddScheduleStepModalContainer = ({ isOpen, ...props }) => {
  const portalTarget = usePortal("plan-step-modal-container");
  const contents = isOpen ? <AddScheduleStepModal {...props} /> : null;
  return createPortal(contents, portalTarget);
};

export default AddScheduleStepModalContainer;
