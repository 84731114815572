import React from 'react';
import { ErrorMessage } from 'formik';

import ErrorText from './ErrorText/ErrorText';

const FieldErrorText = ({ fieldName }) => (
  <ErrorMessage
    name={fieldName}
    component={ErrorText}
  />
);

export default FieldErrorText;
