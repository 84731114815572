import React, {
  useState,
  useEffect,
} from 'react';
import { observer } from 'mobx-react';
import { toast } from 'react-toastify';
import {
  MdCheck,
  MdClose,
} from 'react-icons/md';
import TenantStore from '../../../stores/TenantStore';
import Page from '../../-common/Page';
import Button from '../../-common/Button';
import ColorPickerButton from '../../-common/ColorPickerButton';

const titleOptions = { title: 'Theme' };

function DXMTheme() {
  const [localTheme, setLocalTheme] = useState();

  const { theme } = TenantStore?.tenant || {};
  useEffect(() => {
    if (theme) setLocalTheme(theme);
  }, [theme]);

  const [saving, setSaving] = useState(false);

  const updatedLocally = Object.keys(localTheme || {}).some(k => theme[k] !== localTheme[k]);

  const setDXMPrimaryColor = dxmPrimaryColor => setLocalTheme({ ...localTheme, dxmPrimaryColor });

  const setDXMSecondaryColor = dxmSecondaryColor => setLocalTheme({ ...localTheme, dxmSecondaryColor });

  const save = async () => {
    setSaving(true);
    const { dxmPrimaryColor, dxmSecondaryColor } = localTheme;
    const success = await TenantStore.updateTenant({ theme: { dxmPrimaryColor, dxmSecondaryColor } });
    if (!!success) toast("Theme updated.");
    else toast("Error updating theme.");
    setSaving(false);
  };

  const cancel = () => setLocalTheme(theme);

  let { dxmPrimaryColor, dxmSecondaryColor } = localTheme || {};

  const conditionalButtons = updatedLocally
    ? [
        <Button text="Cancel" icon={MdClose} theme="light" onClick={cancel} disabled={saving} key="cancel" />,
        <Button
          text={saving ? "Saving..." : "Save"}
          theme={saving ? "light" : null}
          icon={MdCheck}
          onClick={save}
          disabled={saving}
          key="save"
        />
      ]
    : null;

  return (
    <Page titleOpts={titleOptions} buttons={conditionalButtons}>
      <div className="dxm-settings-container">
        <div className="theme-section">
          <div className="theme-section-header">Colors</div>
          <div className="theme-section-items">
            <div className="theme-item">
              <div className="theme-item-titles">
                <div className="theme-item-title">Primary Color</div>
                <div className="theme-item-subtitle">
                  This will color the header bar, major design elements, most buttons, and some titles.
                </div>
              </div>
              <div className="theme-item-content">
                <ColorPickerButton color={dxmPrimaryColor || ""} onSelect={setDXMPrimaryColor} />
              </div>
            </div>
            <div className="theme-item">
              <div className="theme-item-titles">
                <div className="theme-item-title">Secondary Color</div>
                <div className="theme-item-subtitle">This will color secondary design elements and some buttons.</div>
              </div>
              <div className="theme-item-content">
                <ColorPickerButton color={dxmSecondaryColor || ""} onSelect={setDXMSecondaryColor} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default observer(DXMTheme);
