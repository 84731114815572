import { useState, useEffect } from "react";
import { toJS } from "mobx";
import { navigate } from "@reach/router";
import BulletinsStore from "../stores/BulletinsStore";

export default function useBulletin(bulletinId) {
  const { bulletins } = BulletinsStore;
  const [bulletin, setBulletin] = useState();
  useEffect(() => {
    const matchingBulletin = bulletins.find(p => p.bulletinId === bulletinId);
    if (matchingBulletin) {
      setBulletin(toJS(matchingBulletin, { recurseEverything: true }));
    } else if (bulletins.length) {
      navigate("/club/bulletins");
    }
  }, [bulletins, bulletinId]);
  return bulletin || {};
}
