import React from "react";
import { observer } from "mobx-react";
import { Router } from "@reach/router";
import { GoPlus } from "react-icons/go";
import UsersStore from "../../../stores/UsersStore";
import Page from "../../-common/Page";
import Button from "../../-common/Button";
import User from "../User";
import UsersTable from "./UsersTable";
import "./Users.scss";

const Users = observer(() => {
  const titleOptions = { title: "Users" };
  return (
    <Page
      titleOpts={titleOptions}
      buttons={[
        <Button text="Add User" icon={GoPlus} onClick={UsersStore.openNewUserModal} theme="dark" key="add-user" />
      ]}
    >
      <div className="users-container">
        <UsersTable />
      </div>
    </Page>
  );
});

function UsersRouter() {
  return (
    <Router>
      <Users path="/" />
      <User path=":userId" />
    </Router>
  );
}

export default UsersRouter;
