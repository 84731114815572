import React, { useState, useEffect } from "react";
import Label from "../Label";
import {
  FiChevronsRight,
  FiChevronRight,
  FiChevronLeft,
  FiChevronsLeft,
  FiCalendar
} from "react-icons/fi";
import "./Datepicker.scss";
import Input from "../Input";
import {
  format,
  startOfMonth,
  getDaysInMonth,
  getDay,
  addMonths,
  addYears,
  subMonths,
  subYears,
  isThisMonth,
  getDate,
  toDate,
  getMonth,
  getYear,
  isPast,
  isToday
} from "date-fns";

const today = new Date();

const checkToday = (i, referenceDate) => {
  if (isThisMonth(referenceDate) && getDate(today) === i) {
    return "today";
  } else return "";
};

const checkSelected = (i, selectedDate, referenceDate) => {
  if (
    selectedDate &&
    referenceDate &&
    getYear(selectedDate) === getYear(referenceDate) &&
    getMonth(selectedDate) === getMonth(referenceDate) &&
    getDate(selectedDate) === i
  ) {
    return "selected";
  } else return "";
};

const checkPast = (i, referenceDate, blockPast) => {
  const dateTest = new Date(getYear(referenceDate), getMonth(referenceDate), i);
  if (blockPast && isPast(dateTest) && !isToday(dateTest)) {
    return "unselectable";
  } else {
    return "selectable";
  }
};

const BlankDaysBefore = ({ dayOfWeek }) => {
  const daysBefore = [...new Array(dayOfWeek)].map((_, i) => (
    <div key={`before-${i + 1}`} className="calendar-day empty" />
  ));
  return daysBefore;
};
const DaysDuringMonth = ({
  onChange,
  daysInMonth,
  referenceDate,
  selectedDate,
  blockPast
}) => {
  const days = [...new Array(daysInMonth)].map((_, i) => {
    const currentDay = i + 1;
    return (
      <div
        key={`during-${currentDay}`}
        className={`calendar-day ${checkPast(
          currentDay,
          referenceDate,
          blockPast
        )} ${checkSelected(currentDay, selectedDate, referenceDate)}`}
        onClick={onChange(currentDay)}
      >
        <div className={`date ${checkToday(currentDay, referenceDate)}`}>
          {currentDay}
        </div>
      </div>
    );
  });
  return days;
};

const DatepickerMenu = ({
  referenceDate,
  selectedDate,
  addOneMonth,
  addOneYear,
  subtractOneMonth,
  subtractOneYear,
  setReferenceDateBackToToday,
  onChange,
  blockPast
}) => {
  const monthStart = startOfMonth(referenceDate);
  const dayOfWeek = getDay(monthStart);
  const daysInMonth = getDaysInMonth(referenceDate);

  return (
    <div className="calendar-menu-container">
      <div className="menu-header">
        <div className="year-back" onClick={subtractOneYear}>
          <FiChevronsLeft className="icon" />
        </div>
        <div className="month-back" onClick={subtractOneMonth}>
          <FiChevronLeft className="icon" />
        </div>
        <div className="date-title" onClick={setReferenceDateBackToToday}>
          {format(referenceDate, "MMM yyyy")}
        </div>
        <div className="month-forward" onClick={addOneMonth}>
          <FiChevronRight className="icon" />
        </div>
        <div className="year-forward" onClick={addOneYear}>
          <FiChevronsRight className="icon" />
        </div>
      </div>
      <div className="calendar">
        <BlankDaysBefore dayOfWeek={dayOfWeek} />
        <DaysDuringMonth
          onChange={onChange}
          daysInMonth={daysInMonth}
          referenceDate={referenceDate}
          selectedDate={selectedDate}
          blockPast={blockPast}
        />
      </div>
    </div>
  );
};

const Datepicker = ({
  value, //required
  onChange, //required
  label,
  labelStyles,
  blockPast = false
}) => {
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [referenceDate, setReferenceDate] = useState(new Date());
  const labelComponent = label ? (
    <Label value={label} style={labelStyles} />
  ) : null;

  useEffect(() => {
    if (value) {
      const baseDate = new Date(value);
      const tzOffset = baseDate.getTimezoneOffset() * 60 * 1000;
      setSelectedDate(new Date(baseDate.valueOf() + tzOffset));
    }
  }, [value]);

  const addOneMonth = () => {
    setReferenceDate(addMonths(referenceDate, 1));
  };

  const addOneYear = () => {
    setReferenceDate(addYears(referenceDate, 1));
  };

  const subtractOneMonth = () => {
    setReferenceDate(subMonths(referenceDate, 1));
  };

  const subtractOneYear = () => {
    setReferenceDate(subYears(referenceDate, 1));
  };

  const setReferenceDateBackToToday = () => {
    setReferenceDate(new Date());
  };

  const selectionMade = dateNumber => () => {
    const refYear = getYear(referenceDate);
    const refMonth = getMonth(referenceDate);
    const newSelectedDate = toDate(new Date(refYear, refMonth, dateNumber));
    setOpen(false);
    onChange(newSelectedDate);
  };

  const handleClick = evt => {
    if (!evt.path.find(pathItem => pathItem.className === "menu-header")) {
      setOpen(false);
      window.removeEventListener("click", handleClick);
    }
  };

  const toggleMenu = e => {
    e.stopPropagation();
    setOpen(!open);
    window.addEventListener("click", handleClick);
  };

  const formattedDate = selectedDate ? format(selectedDate, "MM/dd/yy") : null;
  const inputContainerStyles = { margin: 0, padding: 0 };

  return (
    <div className="datepicker-component-container">
      {labelComponent}
      <div className="datepicker-container">
        <div className="datepicker-input-box" onClick={toggleMenu}>
          <Input
            value={formattedDate}
            icon={FiCalendar}
            inputContainerStyles={inputContainerStyles}
          />
        </div>
        <div className={`datepicker-menu ${open ? "open" : "closed"}`}>
          <DatepickerMenu
            selectedDate={selectedDate}
            referenceDate={referenceDate}
            onChange={selectionMade}
            addOneMonth={addOneMonth}
            addOneYear={addOneYear}
            subtractOneMonth={subtractOneMonth}
            subtractOneYear={subtractOneYear}
            setReferenceDateBackToToday={setReferenceDateBackToToday}
            blockPast={blockPast}
          />
        </div>
      </div>
    </div>
  );
};

export default Datepicker;
