import { useState, useEffect } from "react";
import { toJS } from "mobx";
import { navigate } from "@reach/router";
import ClubMembersStore from "../../../stores/ClubMembersStore";
import MessagesStore from "../../../stores/MessagesStore";
import TransactionsStore from "../../../stores/TransactionsStore";
import PledgesStore from "../../../stores/PledgesStore";
import TicketsStore from "../../../stores/TicketsStore";
import NotesStore from "../../../stores/NotesStore";

export default function useClubMember(userId) {
  const { clubMemberDetails } = ClubMembersStore;
  const { threads } = MessagesStore;
  const { memberTransactions } = TransactionsStore;
  const { memberPledges } = PledgesStore;
  const { memberTickets } = TicketsStore;
  const { memberNotes } = NotesStore;
  const [clubMember, setClubMember] = useState({});

  useEffect(() => {
    const matchingMemberDetails = clubMemberDetails.find(cmd => cmd?.userId === userId);
    if (matchingMemberDetails) {
      setClubMember(cm => ({ ...cm, ...toJS(matchingMemberDetails) }));
    } else {
      if (userId) {
        (async () => {
          const fetchedClubMember = await ClubMembersStore.fetchClubMemberDetails(userId);
          setClubMember(cm => ({ ...cm, ...fetchedClubMember }));
          if (!fetchedClubMember) navigate("/club/explore");
        })();
      }
    }
  }, [clubMemberDetails, userId]);

  useEffect(() => {
    const matchingMemberTransactions = memberTransactions[userId];
    if (matchingMemberTransactions) {
      setClubMember(cm => ({ ...cm, transactions: toJS(matchingMemberTransactions) }));
    } else {
      if (userId) {
        (async () => {
          const fetchedTransactions = await TransactionsStore.fetchTransactionsForMember(userId);
          setClubMember(cm => ({ ...cm, transactions: fetchedTransactions }));
        })();
      }
    }
  }, [memberTransactions, userId]);

  useEffect(() => {
    const matchingMemberPledges = memberPledges[userId];
    if (matchingMemberPledges) {
      setClubMember(cm => ({ ...cm, pledges: toJS(matchingMemberPledges) }));
    } else {
      if (userId) {
        (async () => {
          const fetchedMemberPledges = await PledgesStore.fetchPledgesForMember(userId);
          setClubMember(cm => ({ ...cm, pledges: fetchedMemberPledges }));
        })();
      }
    }
  }, [memberPledges, userId]);

  useEffect(() => {
    const matchingMemberMessages = threads[userId];
    if (matchingMemberMessages) {
      setClubMember(cm => ({ ...cm, messages: toJS(matchingMemberMessages) }));
    } else {
      if (userId) {
        (async () => {
          const memberMessages = await MessagesStore.fetchMessagesForMemberThread(userId, true);
          setClubMember(cm => ({ ...cm, messages: memberMessages }));
        })();
      }
    }
  }, [threads, userId]);

  useEffect(() => {
    const matchingMemberTickets = memberTickets[userId];
    if (matchingMemberTickets) {
      setClubMember(cm => ({ ...cm, tickets: toJS(matchingMemberTickets) }));
    } else {
      if (userId) {
        (async () => {
          const fetchedMemberTickets = await TicketsStore.fetchTicketsForMember(userId);
          setClubMember(cm => ({ ...cm, tickets: fetchedMemberTickets }));
        })();
      }
    }
  }, [memberTickets, userId]);

  useEffect(() => {
    const matchingMemberNotes = memberNotes[userId];
    if (matchingMemberNotes) {
      setClubMember(cm => ({ ...cm, notes: toJS(matchingMemberNotes) }));
    } else {
      if (userId) {
        (async () => {
          const fetchedMemberNotes = await NotesStore.fetchNotesForMember(userId);
          setClubMember(cm => ({ ...cm, notes: fetchedMemberNotes }));
        })();
      }
    }
  }, [memberNotes, userId]);

  return clubMember || {};
}
