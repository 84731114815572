import React, { useRef, useState } from "react";
import { FiCheck, FiUser } from "react-icons/fi";
import { getElementAncestry } from "../../../utils";
import "./ProfilePictureButton.scss";

const MenuOptions = ({ items, onChange, toggle }) => {
  return (items || []).map(item => {
    const icon = item.selected ? <FiCheck size={24} className="selected-icon" /> : null;

    const clickHandler = () => {
      if (typeof item.action === "function") item.action();
      toggle();
    };

    return (
      <div className="menu-option" key={item.label} onClick={clickHandler}>
        {item.label}
        {icon}
      </div>
    );
  });
};

const ProfilePictureButton = ({ src, onSelect, options, style }) => {
  const wrapperRef = useRef(null);
  const [open, setOpen] = useState(false);

  const selectionMade = option => {
    onSelect(option);
  };

  const handleClick = e => {
    const targetChain = getElementAncestry(e.target);
    if (!targetChain.includes(wrapperRef.current)) {
      setOpen(false);
      window.removeEventListener("click", handleClick);
    }
  };

  const toggleMenu = () => {
    setOpen(!open);
    if (!open) {
      window.addEventListener("click", handleClick);
    }
  };

  const image = src ? (
    <img src={src} alt="User profile avatar." className="profile-image" />
  ) : (
    <div className="profile-image-placeholder">
      <FiUser size={24} className="profile-placeholder-icon" />
    </div>
  );

  return (
    <div className="profile-picture-button" ref={wrapperRef} style={style}>
      <div className="header-button profile" onClick={toggleMenu}>
        {image}
      </div>
      <div className="select-menu" style={{ display: open ? "inherit" : "none" }}>
        <MenuOptions items={options} onChange={selectionMade} toggle={toggleMenu} />
      </div>
    </div>
  );
};

export default ProfilePictureButton;
