import React from "react";
import { VictoryChart, VictoryTheme, VictoryAxis, VictoryBar } from "victory";
import commaNumber from "comma-number";
import { abbreviateNumber } from "../../../../utils";
import "./RevenueTrend.scss";

const colors = ["Green", "Purple", "Yellow", "Blue", "Orange", "Pink"].map(c => `var(--${c})`);

const RevenueTrend = ({ data }) => {
  const chartHeight = 330;
  const chartWidth = 1008;

  const hasData = data.reduce((acc, next) => acc + next.y, 0) > 0;

  if (!hasData)
    return (
      <div className="revenue-trend">
        <div className="chart-title">Total Donation Revenue</div>
        <div className="no-data">(no data)</div>
      </div>
    );

  return (
    <div className="revenue-trend">
      <div className="chart-title">Total Donation Revenue</div>
      <div className="chart-wrapper">
        <VictoryChart
          height={chartHeight}
          width={chartWidth}
          theme={VictoryTheme.material}
          domainPadding={{
            x: [chartWidth / 25, chartWidth / 25],
            y: [0, 0]
          }}
          padding={{ top: 16, bottom: 50, left: 50, right: 50 }}
        >
          <VictoryAxis
            style={{
              axis: { stroke: "none" },
              ticks: { stroke: "none" },
              tickLabels: { padding: 24, fill: "var(--Gray500)" },
              grid: { stroke: "none" }
            }}
          />
          <VictoryAxis
            dependentAxis
            width={chartWidth}
            tickFormat={t => "$" + commaNumber(abbreviateNumber(t, 0, 10000))}
            style={{
              axis: { stroke: "none" },
              ticks: { stroke: "none" },
              tickLabels: { fill: "var(--Gray500)" },
              grid: {
                width: chartWidth,
                stroke: "var(--Gray300)"
              }
            }}
          />
          <VictoryBar style={{ data: { fill: colors[0] } }} data={data} cornerRadius={{ topLeft: 3, topRight: 3 }} />
        </VictoryChart>
      </div>
    </div>
  );
};

export default RevenueTrend;
