import React from "react";
import "./BackgroundBlobs.scss";

export default () => (
  <>
    <svg
      className="blob top-left"
      width="618px"
      height="545px"
      viewBox="0 0 618 545"
    >
      <path
        d="M68,0 L618,0 C713.265555,171.647923 713.265555,314.012506 618,427.09375
            C475.101668,596.715616 203.248367,569.674297 68,427.09375
            C-22.1655782,332.040052 -22.1655782,189.675468 68,0 Z"
      />
    </svg>
    <svg
      className="blob bottom-right"
      width="437px"
      height="345px"
      viewBox="0 0 437 345"
    >
      <path
        d="M0,0.7109375 L370,0.7109375 C459.166667,164.022135
            459.166667,267.785156 370,312 C280.833333,356.214844 157.5,356.214844
            0,312 L0,0.7109375 Z"
        id="back"
      />
    </svg>
  </>
);
