import React from "react";
import { PaymentInputsWrapper, usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";
import { FiDollarSign } from "react-icons/fi";
import Select from "../../../../-common/Select2";
import CurrencyInput from "../../../../-common/CurrencyInput";
import "./PaymentInfoSlide.scss";

const PaymentInfoSlide = ({ state, dispatch }) => {
  const { amount, frequency } = state;

  const setCardNumber = ({ target }) => dispatch({ type: "setCardNumber", payload: { cardNumber: target.value } });

  const setExpDate = ({ target: t }) => dispatch({ type: "setExpDate", payload: { expDate: t.value } });
  const setCVV = ({ target: t }) => dispatch({ type: "setCVV", payload: { cvv: t.value } });
  const setZip = ({ target: t }) => dispatch({ type: "setZip", payload: { zip: t.value } });
  const setAmount = v => dispatch({ type: "setAmount", payload: { amount: v } });
  const setFrequency = v => dispatch({ type: "setFrequency", payload: { frequency: v } });

  const frequencyOptions = [
    { value: "One-time", label: "One-time" },
    { value: "Monthly", label: "Monthly" },
    { value: "Every 3 Months", label: "Every 3 Months" }
  ];

  const {
    wrapperProps,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
    getZIPProps
  } = usePaymentInputs();

  return (
    <div className="slide payment-info-slide">
      <div className="headline">Card Information</div>

      <div className="inputs">
        <PaymentInputsWrapper
          {...wrapperProps}
          styles={{
            fieldWrapper: { base: { height: 60, width: "100%" } },
            inputWrapper: {
              base: {
                height: 40,
                width: "100%",
                paddingLeft: 16,
                display: "flex",
                boxSizing: "border-box",
                borderRadius: 8,
                border: "solid 1px var(--Gray400)",
                boxShadow: "none"
              },
              focused: { borderWidth: 1, borderColor: "var(--Gray400)", boxShadow: "none" },
              errored: { borderWidth: 1, borderColor: "var(--Gray400)", boxShadow: "none" },
              errorText: { base: { color: "var(--Red)" } }
            }
          }}
        >
          <svg {...getCardImageProps({ images })} />
          <input {...getCardNumberProps({ onChange: setCardNumber })} style={{ flex: 2 }} />
          <input {...getExpiryDateProps({ onChange: setExpDate })} style={{ flex: 1 }} />
          <input {...getCVCProps({ onChange: setCVV })} style={{ flex: 1 }} />
          <input {...getZIPProps({ onChange: setZip })} style={{ flex: 1 }} />
        </PaymentInputsWrapper>
        {/* <CreditCardInput
          cardNumberInputProps={{ value: cardNumber || "", onChange: setCardNumber }}
          cardExpiryInputProps={{ value: expDate || "", onChange: setExpDate }}
          cardCVCInputProps={{ value: cvv || "", onChange: setCVV }}
          cardZipInputProps={{ value: cvv || "", onChange: setCVV }}
          fieldClassName="card-input-field"
          containerClassName="card-input-container"
          inputClassName="card-input"
        /> */}
        <div className="double-input">
          <CurrencyInput
            label="Total Amount"
            value={amount || 0}
            placeholder="xxx"
            onChange={setAmount}
            maxLength={50}
            icon={FiDollarSign}
          />
          <Select label="Billing Frequency" value={frequency} onChange={setFrequency} options={frequencyOptions} />
        </div>
        <div className="body-text">
          Note: Payment information will be saved securely and available for subsequent payments
        </div>
      </div>
    </div>
  );
};

export default PaymentInfoSlide;
