import React from "react";
import { observer } from "mobx-react";
import Page from "../../-common/Page";
import "./Validation.scss";

const Validation = observer(() => {
  const titleOptions = { title: "Validation" };

  return (
    <Page titleOpts={titleOptions}>
      <div className="integrations-datasource-validation"></div>
    </Page>
  );
});

export default Validation;
